import { Component, OnInit, ViewChild } from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import { FormBuilder,FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ChildActivationStart } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
import { Domicilio } from "src/app/model/domicilio";
import { Alumnos } from 'src/app/model/alumnos';
import { AlumnsService } from 'src/app/services/alumns-service';
import { DomSanitizer } from '@angular/platform-browser';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Datos} from 'src/app/model/datos';
import { ParametrosService } from 'src/app/services/parametros.service';
import { SessionServiceService } from 'src/app/services/session-service.service';
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DesitionBoxComponent } from 'src/app/modales/desition-box/desition-box.component';
import { ConfigurationService } from 'src/app/services/configuration.service';

const ELEMENT_DATA: any[] = [
];
@Component({
  selector: 'app-new-expediente',
  templateUrl: './new-expediente.component.html',
  styleUrls: ['./new-expediente.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}}]
})
export class NewExpedienteComponent implements OnInit {
  endpoint = "";
  @ViewChild(MatAccordion) accordion: MatAccordion;
  tipo_documento = [];
  cargaFotos = false;
  panelOpenState = false;
  isLinear = false;
  preview:String;
  fotosView : any = [];
  fotos: any = [];
  archivos: any = [];
  age;
  showAge;
  date = new Date();
  data: Datos;
  estadoValues = [];
  estadoKeys = [];
  nivelValues = [];
  nivelKeys = [];
  gradoValues = [];
  gradoKeys = [];
  civilValues = [];
  civilKeys = [];
  nivelpValues = [];
  nivelpKeys = [];
  ocupacionValues = [];
  ocupacionKeys = [];
  seguroValues = [];
  seguroKeys = [];
  grupoSanguineoValues = [];
  grupoSanguineoKeys = [];
  contactoValues = [];
  contactoKeys = [];
  credencialValues = [];
  credencialKeys = [];
  dataSource = ELEMENT_DATA;
  id:number;
  isLoading = false;
  formularioAlumno = new FormGroup({
    url :new FormControl("",[]),
    foto: new FormControl("",[]),
    nombre: new FormControl("",[Validators.required]),
    apellidop:new FormControl("",[Validators.required]),
    apellidom:new FormControl("",[Validators.required]),
    fecha_nacimiento:new FormControl("",[Validators.required]),
    edad:new FormControl(),
    estado : new FormControl("",[Validators.required]),
    nombre_extranjero : new FormControl("",[]),
    genero: new FormControl("",[Validators.required]),
    curp: new FormControl("",[]),
    //nivel_educativo: new FormControl("",[Validators.required]),
    //grado: new FormControl("",[Validators.required]),
    colonia: new FormControl("",[Validators.required]),
    colonia2: new FormControl(),
    calle: new FormControl("",[Validators.required]),
    numExt: new FormControl("",[]),
    numInt: new FormControl("",[]),
    codigo: new FormControl("",[Validators.required]),
    //familia: new FormControl("",[Validators.required]),
    //nombre_mama: new FormControl("",[Validators.required]),
    //apellidop_mama:new FormControl("",[Validators.required]),
    //apellidom_mama:new FormControl("",[Validators.required]),
    //estado_civil_mama:new FormControl(),
    //nivel_mama:new FormControl(),
    //ocupacion_mama:new FormControl(),
    //correo_mama: new FormControl(),
    //telefono_mama: new FormControl(),
    //celular_mama: new FormControl(),
    //nombre_papa: new FormControl("",[Validators.required]),
    //apellidop_papa:new FormControl("",[Validators.required]),
    //apellidom_papa:new FormControl("",[Validators.required]),
    //estado_civil_papa:new FormControl(),
    //nivel_papa:new FormControl(),
    //ocupacion_papa:new FormControl(),
    //correo_papa: new FormControl(),
    //telefono_papa: new FormControl(),
    //celular_papa: new FormControl()
    viveCon:new FormControl("",[Validators.required]),
    viveConOtro:new FormControl(null,[]),
  });

  formularioEscolar = new FormGroup({
    procedencia: new FormControl("",[Validators.maxLength(100)]),
    escuela: new FormControl("",[]),
    cambio: new FormControl("",[Validators.maxLength(100)]),
    alergias: new FormControl(),
    alergiaWhich: new FormControl("",[Validators.maxLength(100)]),
    enfermedad:new FormControl(),
    enfermedadWhich:new FormControl("",[Validators.maxLength(100)]),
    grupo_sanguineo: new FormControl(),
    seguro:new FormControl(),
    seguroWhich:new FormControl(),
    religion:new FormControl("",[Validators.maxLength(100)]),
    bautizo:new FormControl(),
    confirmado:new FormControl(),
    primeraComunion:new FormControl(),
    enfermedadesFamiliares:new FormControl(),
    otraenfermedad:new FormControl(),
    doctorDeConfianza:new FormControl(),
    telDoctorDeConfianza: new FormControl(),
  });
  
  formularioDocumentos = new FormGroup({
    tipoDocto:new FormControl("",[Validators.required]),
    documento:new FormControl("",[Validators.required]),
    
  });

  formularioContacto = new FormGroup({
    parentesco: new FormControl("",[Validators.required]),
    nombre:new FormControl("",[Validators.required]),
    apaterno:new FormControl("",[Validators.required]),
    amaterno:new FormControl("",[Validators.required]),
    celular:new FormControl("",[Validators.pattern('[0-9]*')]),
    trabajo:new FormControl("",[Validators.pattern('[0-9]*')]),
    telefono:new FormControl("",[Validators.pattern('[0-9]*')]),
    correo:new FormControl("",[Validators.email]),
    tipo:new FormControl("",[Validators.required]),
    credencial:new FormControl(),
    escolaridad: new FormControl("",[Validators.required]),
    ocupacion: new FormControl("",[Validators.required]),
    oficios: new FormControl("",[Validators.required]),
    calle: new FormControl("",[Validators.required]),
    numero: new FormControl("",[Validators.required]),
    colonia: new FormControl("",[Validators.required]),
    codigopostal: new FormControl("",[Validators.required]),
  });
  
  constructor(
    private _parametros:ParametrosService,
    private _formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private dialog:MatDialog,
    private _snackBar: MatSnackBar,
    private _alumnos:AlumnsService,
    private _session:SessionServiceService,
    private activatedRoute:ActivatedRoute,
    private _config:ConfigurationService) 
    { 
      this.endpoint = _config.getConfiguration().hostapi;
      this.activatedRoute.queryParams.subscribe(params => {
        this.id = params['id']; 
      });
    }
  
  alumno = new Alumnos();
  domicilio = new Domicilio();

  calculaEdad(){
    
      const convertAge = (new Date(this.formularioAlumno.value.fecha_nacimiento));
      var today = new Date();
      var age = today.getFullYear() - convertAge.getFullYear();
      var m = today.getMonth() - convertAge.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < convertAge.getDate())) 
      {
          age--;
      }
      this.showAge = age;
      return this.showAge;
    
  }

  get form(){
    return this.formularioAlumno.controls;
  }
  tipoDeStepper = false;
  ngOnInit(): void {

    
    this.traestados();
    this.traeColonias();
    this.traeGrupoSanguineo();
    this.traeSeguroMedico();
    this.traeReligion();
    this.traeTipoDocumentos();
    this.traeParentezcos();
    this.getCredenciales();
    this.traeViveCon();
    this.traeEnfermedades();
    this.traeEscolaridades();
    /*this.traenivel();
    this.traedoCivil();
    this.traenivelP();
    this.traeOcupaciones();
    this.traeSeguro();
    this.traeGrupoS();
    this.traeCredenciales();
    this.traeTipoContacto();*/

    //No entiendo con que valor querian setear este atributo
    // this.formularioAlumno.controls["fecha_nacimiento"];
    
    if(this.id>0){
      this.idAlumno = this.id;
      this.getDocumentos();
      this.findContactos();
      this.tipoDeStepper =false;
      this.isLoading=true;
      this._alumnos.getAlumnoById(this.id).subscribe(result=>{
        console.log("this._alumnos.getAlumnoById result:", result);
        if(result.code==200){
          this._alumnos.getFotos(this.id).subscribe(result=>{
            this.fotos = result.datos.fotos;
            console.info(result.datos.fotos);
          });
          this.alumno = result.datosBasicos.datos;
          let domicilio = result.domicilio.datos;
          let adicionales = result.adicionales.datos;
          this.form.nombre.setValue(this.alumno.nombre.toString());
          this.form.apellidop.setValue(this.alumno.apellidop.toString());
          this.form.apellidom.setValue(this.alumno.apellidom.toString());
          if(this.alumno.nombre_extranjero != null){
            console.log('hhhhhhhhhhhhhhhh');
            this.form.nombre_extranjero.setValue(this.alumno.nombre_extranjero.toString());
          }

          this.form.genero.setValue(this.alumno.id_genero_fk.toString());
          this.form.fecha_nacimiento.setValue(this.alumno.fecha_nacimiento.toString());
          this.form.curp.setValue(this.alumno.curp.toString());
          this.form.estado.setValue(this.alumno.id_estado_fk.toString());
          this.form.viveCon.setValue(this.alumno.id_vivecon_fk.toString());
          this.form.viveConOtro.setValue(this.alumno.vive_con_otro);
          //this.form.nivel_educativo.setValue(this.alumno.);
          this.isLoading=false;

          this.form.colonia.setValue(domicilio.colonia);
          this.form.calle.setValue(domicilio.calle);
          this.form.colonia2.setValue(domicilio.calle2);
          this.form.codigo.setValue(domicilio.codigo_postal);
          this.form.numExt.setValue(domicilio.num_ext);
          this.form.numInt.setValue(domicilio.num_int);

          this.formularioEscolar.controls["procedencia"].setValue(adicionales.escuela_de_procedencia);
          this.formularioEscolar.controls["escuela"].setValue(adicionales.id_tipo_institucion_fk);
          this.formularioEscolar.controls["cambio"].setValue(adicionales.motivo_del_cambio);
          this.formularioEscolar.controls["alergias"].setValue(adicionales.alergias);
          this.formularioEscolar.controls["alergiaWhich"].setValue(adicionales.descripcion_alergias);
          this.formularioEscolar.controls["enfermedad"].setValue(adicionales.enfermedad_cronica);
          this.formularioEscolar.controls["enfermedadWhich"].setValue(adicionales.descripcion_enfermedad_cronica);
          this.formularioEscolar.controls["grupo_sanguineo"].setValue(adicionales.id_grupo_sanguineo_fk);
          this.formularioEscolar.controls["seguro"].setValue(adicionales.tiene_seguro_medico);
          this.formularioEscolar.controls["seguroWhich"].setValue(adicionales.seguro_medico);
          this.formularioEscolar.controls["religion"].setValue(adicionales.religion);
          this.formularioEscolar.controls["bautizo"].setValue(adicionales.bautizado);
          this.formularioEscolar.controls["confirmado"].setValue(adicionales.confirmado);
          this.formularioEscolar.controls["primeraComunion"].setValue(adicionales.primera_comunion);

          this.formularioEscolar.controls["doctorDeConfianza"].setValue(adicionales.doctor);
          this.formularioEscolar.controls["telDoctorDeConfianza"].setValue(adicionales.telefono_doctor);
          this.formularioEscolar.controls["enfermedadesFamiliares"].setValue(adicionales.id_enfermedades_fk);
          this.formularioEscolar.controls["otraenfermedad"].setValue(adicionales.otra_enfermedad);
          
          this.calculaEdad();
          this.cambioComboViveCon();
          this.cambioComboColonia();
          this.cambioAlergias();
          this.cambioSeguroMedico();
          this.cambioEnfermedad();
          this.cambioEnfermedadesFamiliares();
        }else{
          this.cambioComboViveCon();
          this.cambioComboColonia();
          this.cambioAlergias();
          this.cambioSeguroMedico();
          this.cambioEnfermedad();
          this.cambioEnfermedadesFamiliares();
          this.isLoading=false;
        }
      },error=>{
        console.log(error);
        this.isLoading=false;
      });
    }else{
      this.tipoDeStepper = true;
    }
  }

  cambioAlergias(){
    if(this.formularioEscolar.controls["alergias"].value=="1"){
      this.formularioEscolar.controls["alergiaWhich"].setValue(null);
      this.formularioEscolar.controls["alergiaWhich"].disable();
    }else{
      this.formularioEscolar.controls["alergiaWhich"].enable();
    }
  }
  cambioEnfermedad(){
    if(this.formularioEscolar.controls["enfermedad"].value=="1"){
      this.formularioEscolar.controls["enfermedadWhich"].setValue(null);
      this.formularioEscolar.controls["enfermedadWhich"].disable();
    }else{
      this.formularioEscolar.controls["enfermedadWhich"].enable();
    }
  }
  cambioSeguroMedico(){
    if(this.formularioEscolar.controls["seguro"].value=="1"){
      this.formularioEscolar.controls["seguroWhich"].setValue(null);
      this.formularioEscolar.controls["seguroWhich"].disable();
    }else{
      this.formularioEscolar.controls["seguroWhich"].enable();
    }
  }
  cambioEnfermedadesFamiliares(){
    if(this.formularioEscolar.controls["enfermedadesFamiliares"].value!="7"){
      this.formularioEscolar.controls["otraenfermedad"].setValue(null);
      this.formularioEscolar.controls["otraenfermedad"].disable();
    }else{
      this.formularioEscolar.controls["otraenfermedad"].enable();
    }
  }
  cambioComboViveCon(){
    if(this.formularioAlumno.controls["viveCon"].value=="7"){
      this.form.viveConOtro.enable();
    }else{
      this.form.viveConOtro.setValue(null);
      this.form.viveConOtro.disable();
    }
  }
  cambioComboColonia(){
    if(this.formularioAlumno.controls["colonia"].value=="6"){
      this.form.colonia2.enable();
    }else{
      this.form.colonia2.setValue(null);
      this.form.colonia2.disable();
    }
  }
  subirFoto(event):any{
    this.cargaFotos = false;
      const fotoCargada = event.target.files[0];
      this.extraerBase64(fotoCargada).then((imagen: any) =>{
        this.preview = imagen.base;
        this.fotosView.push(this.preview);
      });
      this.fotos.push(fotoCargada);
      console.log(this.fotos);
      if(this.fotos.length > 9){
        this.cargaFotos = true;
        this._snackBar.open('Ya hay 10 fotos almacenadas', 'Close', {
          duration: 10000
        });
      }
  }

  subirFile(event):any{
    if(event.target.files == " "){
      console.log("esta vacio");
    }
      const archivoCargado = event.target.files[0];
      this.archivos.push(archivoCargado);
      //this.archivos.
      console.log(this.archivos);
  }

  datos(){
    this._alumnos.getDatos(this.data).subscribe(result =>{
      let resultado:Datos = new Datos();
    resultado.setEstados(result.datos.estados);
    resultado.setNivel(result.datos.nivel);
    resultado.setGrado(result.datos.grado);
    resultado.setEdoCivil(result.datos.estado_civil);
    resultado.setNivel(result.datos.nivel_educativo_padres);
    resultado.setOcupacion(result.datos.ocupacion);
    resultado.setSeguro(result.datos.seguro);
    resultado.setGrupoS(result.datos.grupo_sanguineo);
    resultado.setFamiliar(result.datos.tipo_familiar);
    resultado.setCredencial(result.datos.credencial);
    })
  }
  escolaridades=[];
  traeEscolaridades(){
    this.isLoading = true;
    this._parametros.findAll("escolaridad").subscribe(result =>{
      
      this.isLoading = false;
      if(result.code==200){
        this.escolaridades = result.datos.escolaridad;
      }else{
        this.escolaridades = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.enfermedades = [];
    });
  }
  enfermedades=[];
  traeEnfermedades(){
    this.isLoading = true;
    this._parametros.findAll("enfermedades").subscribe(result =>{
      
      this.isLoading = false;
      if(result.code==200){
        this.enfermedades = result.datos.enfermedades;
      }else{
        this.enfermedades = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.enfermedades = [];
    });
  }
  colonias = [];
  traeColonias(){
    this.isLoading = true;
    this._parametros.findAll("colonias").subscribe(result =>{
      
      this.isLoading = false;
      if(result.code==200){
        this.colonias = result.datos.colonias;
      }else{
        this.colonias = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.colonias = [];
    });
  }
  estados = [];
  traestados(){
    this.isLoading = true;
    this._parametros.findAll("estados").subscribe(result =>{
      
      this.isLoading = false;
      if(result.code==200){
        this.estados = result.datos.estados;
      }else{
        this.estados = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.estados = [];
    });
  }
  grupo_sangineo = [];
  traeGrupoSanguineo(){
    this.isLoading = true;
    this._parametros.findAll("grupo_sanguineo").subscribe(result =>{
      
      this.isLoading = false;
      if(result.code==200){
        this.grupo_sangineo = result.datos.grupo_sanguineo;
      }else{
        this.grupo_sangineo = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.grupo_sangineo = [];
    });
  }
  traeTipoDocumentos(){
    this.isLoading = true;
    this._parametros.findAll("tipo_documento").subscribe(result =>{
      
      this.isLoading = false;
      if(result.code==200){
        this.tipo_documento = result.datos.tipo_documento;
      }else{
        this.tipo_documento = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.tipo_documento = [];
    });
  }
  seguro_medico=[];
  traeSeguroMedico(){
    this.isLoading = true;
    this._parametros.findAll("seguro_medico").subscribe(result =>{
      
      this.isLoading = false;
      if(result.code==200){
        this.seguro_medico = result.datos.seguro_medico;
      }else{
        this.seguro_medico = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.seguro_medico = [];
    });
  }
  religion = [];
  traeReligion(){
    this.isLoading = true;
    this._parametros.findAll("religion").subscribe(result =>{
      
      this.isLoading = false;
      if(result.code==200){
        this.religion = result.datos.religion;
      }else{
        this.religion = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.religion = [];
    });
  }
  vivecon = [];
  traeViveCon(){
    this.isLoading = true;
    this._parametros.findAll("vive_con").subscribe(result =>{
      
      this.isLoading = false;
      if(result.code==200){
        this.vivecon = result.datos.vivecon;
      }else{
        this.vivecon = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.vivecon = [];
    });
  }
  credenciales = [];
  getCredenciales(){
    this.isLoading = true;
    this._parametros.findAll("credenciales").subscribe(result =>{
      
      this.isLoading = false;
      if(result.code==200){
        this.credenciales = result.datos.credenciales;
      }else{
        this.credenciales = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.credenciales = [];
    });
  }
  parentescos = [];
  traeParentezcos(){
    this.isLoading = true;
    this._parametros.findAll("parentesco").subscribe(result =>{
      
      this.isLoading = false;
      if(result.code==200){
        this.parentescos = result.datos.parentesco;
      }else{
        this.parentescos = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.parentescos = [];
    });
  }
  traenivel(){
    this.isLoading = true;
    this._alumnos.getDatos(this.data).subscribe(result =>{
      let resultado:Datos = new Datos();
      resultado.setNivel(result.datos.nivel);
      this.isLoading = false;
      if(result.code==200){
        let level = resultado.getNivel();
        for(let key in level) {
          this.nivelValues.push(level[key]);
          this.nivelKeys.push(key);
        }
      }else{
        this.dataSource = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.dataSource = [];
    });
  }
  

  traenivelP(){
    this.isLoading = true;
    this._alumnos.getDatos(this.data).subscribe(result =>{
      let resultado:Datos = new Datos();
      resultado.setNivel(result.datos.nivel_educativo_padres);
      this.isLoading = false;
      if(result.code==200){
        let level = resultado.getNivel();
        for(let key in level) {
          this.nivelpValues.push(level[key]);
          this.nivelpKeys.push(key);
        }
      }else{
        this.dataSource = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.dataSource = [];
    });
  }

  traegrado(dato){
    this.isLoading = true;
    console.log(dato);
    this.gradoValues = [];
    this._alumnos.getGrados(dato).subscribe(result=>{
      this.isLoading = false;
      if(result.code==200){
        let grade = result.grados;
        for(let key in grade) {
          this.gradoValues.push(grade[key]);
          this.gradoKeys.push(key);
        }
      }else{
        this.dataSource = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.dataSource = [];
    });
  }

  traeOcupaciones(){
    this.isLoading = true;
    this._alumnos.getDatos(this.data).subscribe(result =>{
      let resultado:Datos = new Datos();
      resultado.setOcupacion(result.datos.ocupacion);
      if(result.code==200){
        let grade = resultado.getOcupacion();
        for(let key in grade) {
          this.ocupacionValues.push(grade[key]);
          this.ocupacionKeys.push(key);
        }
      }else{
        this.dataSource = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.dataSource = [];
    });
  }

  traedoCivil(){
    this.isLoading = true;
    this._alumnos.getDatos(this.data).subscribe(result =>{
      let resultado:Datos = new Datos();
      resultado.setEdoCivil(result.datos.estado_civil);
      if(result.code==200){
        let edoC = resultado.getEdoCivil();
        for(let key in edoC) {
          this.civilValues.push(edoC[key]);
          this.civilKeys.push(key);
        }
      }else{
        this.dataSource = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.dataSource = [];
    });
  }

  traeSeguro(){
    this.isLoading = true;
    this._alumnos.getDatos(this.data).subscribe(result =>{
      let resultado:Datos = new Datos();
      resultado.setSeguro(result.datos.seguro);
      if(result.code==200){
        let edoC = resultado.getSeguro();
        for(let key in edoC) {
          this.seguroValues.push(edoC[key]);
          this.seguroKeys.push(key);
        }
      }else{
        this.dataSource = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.dataSource = [];
    });
  }

  traeGrupoS(){
    this.isLoading = true;
    this._alumnos.getDatos(this.data).subscribe(result =>{
      let resultado:Datos = new Datos();
      resultado.setGrupoS(result.datos.grupo_sanguineo);
      if(result.code==200){
        let edoC = resultado.getGrupoS();
        for(let key in edoC) {
          this.grupoSanguineoValues.push(edoC[key]);
          this.grupoSanguineoKeys.push(key);
        }
      }else{
        this.dataSource = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.dataSource = [];
    });
  }

  traeTipoContacto(){
    this.isLoading = true;
    this._alumnos.getDatos(this.data).subscribe(result =>{
      let resultado:Datos = new Datos();
      resultado.setFamiliar(result.datos.tipo_familiar);
      if(result.code==200){
        let edoC = resultado.getFamiliar();
        for(let key in edoC) {
          this.contactoValues.push(edoC[key]);
          this.contactoKeys.push(key);
        }
      }else{
        this.dataSource = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.dataSource = [];
    });
  }

  traeCredenciales(){
    this.isLoading = true;
    this._alumnos.getDatos(this.data).subscribe(result =>{
      let resultado:Datos = new Datos();
      resultado.setCredencial(result.datos.credencial);
      if(result.code==200){
        let edoC = resultado.getCredencial();
        for(let key in edoC) {
          this.credencialValues.push(edoC[key]);
          this.credencialKeys.push(key);
        }
      }else{
        this.dataSource = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.dataSource = [];
    });
  }

  extraerBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };

    } catch (e) {
      return null;
    }
  })

  sendForm(){
    if(this.id>0){
      if(this.formularioAlumno.valid){
        this.isLoading=true;
        this._alumnos.actualizarAlumnos(this.id,this.formularioAlumno.getRawValue()).subscribe(result=>{
          console.log("SendForm result: ", result);
          this.responseActualizarAlumno(result);
        },error=>{
          this.isLoading=false;
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al modificar el alumno", text: "No fué posible modificar el alumno, si piensa que ésto es un error, contacte al administrador del sistema", icon:"error_outline", href:null}});
        })
      }
    }else{
      if(this.formularioAlumno.valid){
        this.isLoading=true;
        console.log(this.formularioAlumno.getRawValue());
        this._alumnos.altaAlumnos(this.formularioAlumno.getRawValue(),0).subscribe(result=>{
          this.isLoading=false;
          this.responseAltaAlumno(result);
        },error=>{
          this.isLoading=false;
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al crear el expediente del alumno", text: "No fué posible crear el alumno, si piensa que ésto es un error, contacte al administrador del sistema", icon:"error_outline", href:"/administracion/user-mannager/new"}});
        })
      }
    }
  }

  responseActualizarAlumno(result:any){
    if(result.code==200){
      this.isLoading=false;
      this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "El alumno ha actualizado con éxito", text: "La información del alumno se ha actualizadoen el sistema", icon:"done", href:"/administracion/user-mannager"}});
    }else{
      this.isLoading=false;
      this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al modificar el alumno", text: "No fué posible modificar el alumno, si piensa que ésto es un error, contacte al administrador del sistema", icon:"error_outline", href:null}});
    }
  }
  responseAltaAlumno(result:any){
    if(result.code==200){
      this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "El expediente ha sido creado con éxito", text: "el alumno ya puede ingresar al sistema", icon:"done", href:"/administracion/user-mannager"}});
    }else{
      this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al crear el expediente del alumno", text: "No fué posible crear el alumno, si piensa que ésto es un error, contacte al administrador del sistema", icon:"error_outline", href:"/administracion/user-mannager/new"}});
    }
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.formularioAlumno.controls['foto'].setValue(file);
    }
  }
  onFileSelection(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.formularioDocumentos.controls['documento'].setValue(file);
    }
  }
  idAlumno=null;
  saveAlumno(){
    
    if(this.formularioAlumno.valid){
      this.isLoading=true;
      console.log("this.idAlumno: ",this.idAlumno);
      console.log("this.formularioAlumno.getRawValue(): ",this.formularioAlumno.getRawValue());
      if(this.idAlumno>0){
        console.log("Entrando a modificar");
        // return; 
        this.modificarAlumnoProceso();
      }else{
        console.log("Entrando a dar de alta");
        // return;
        this.altaAlumnoProceso();
      }
    }
  }

  modificarAlumnoProceso(){
    this._alumnos.modificaAlumnos(this.formularioAlumno.getRawValue(),this._session.getSession().getUsuario().id_usuario_pk,this.idAlumno).subscribe(result=>{
      this.isLoading = false;
      console.log("result: ", result);
      if(result.code == 200){
        // this.idAlumno = result.idAlumno;
        // console.log("va a intentar subir foto: ", this.formularioAlumno.controls["foto"].value.name);
        var a = this.formularioAlumno.controls["foto"].value;
        if(a){
          // console.log("Si tiene foto pendiente por subir");
          this._alumnos.cargarFoto(this.formularioAlumno.controls["foto"].value,this.idAlumno).subscribe(result=>{
            console.info(result);
          })
        }
      }else if(result.code == 402){
        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Curp duplicada", text: "No fué posible actualizar el alumno, ya existe otro alumno con esa CURP. Favor de revisar el formulario y verificarlo", icon:"error_outline"}});
        // this.idAlumno = null;
        // stepper.selectedIndex === 0;
      }else{
        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al actualizar el expediente del alumno", text: "No fué posible actualizar el alumno, si piensa que ésto es un error, contacte al administrador del sistema", icon:"error_outline"}});
        // this.idAlumno = null;
      }
    });
  }
  altaAlumnoProceso(){
    this._alumnos.altaAlumnos(this.formularioAlumno.getRawValue(),this._session.getSession().getUsuario().id_usuario_pk).subscribe(result=>{
      this.isLoading = false;
      console.log("Dando de alta result: ", result)
      if(result.code == 200){
        this.idAlumno = result.idAlumno;
        this._alumnos.cargarFoto(this.formularioAlumno.controls["foto"].value,this.idAlumno).subscribe(result=>{
          console.info(result);
        })
      }else if(result.code == 402){
        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Curp duplicada", text: "No fué posible crear el alumno, ya existe otro alumno con esa CURP. Favor de regresar al formulario anterior y verificarlo", icon:"error_outline"}});
        this.idAlumno = null;
        // stepper.selectedIndex === 0;
      }else{
        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al crear el expediente del alumno", text: "No fué posible crear el alumno, si piensa que ésto es un error, contacte al administrador del sistema", icon:"error_outline"}});
        this.idAlumno = null;
      }
      console.info(result);
    })
  }
  saveDatosComplementarios(){
      console.log("saveDatosComplementarios this.idAlumno: ", this.idAlumno);
    
    if(this.formularioEscolar.valid){
      this.isLoading=true;
      this._alumnos.altaActualizarDatosComplementarios(this.formularioEscolar.getRawValue(),this._session.getSession().getUsuario().id_usuario_pk,this.idAlumno).subscribe(result=>{
        this.isLoading = false;
        console.log("result:", result);
        // if(result.code == 200){
        //   console.log("result:", result);
        // }else{
        //   console.log("result: ",result);
        // }
        // console.info(result);
      })
    }
  }
  documentos = [];
  addDocument(){
    if(this.formularioDocumentos.valid){
      this.isLoading=true;
      this._alumnos.altaDocumento(this.formularioDocumentos.controls["documento"].value,this.idAlumno,this.formularioDocumentos.controls["tipoDocto"].value).subscribe(result=>{
        this.isLoading = false;
        if(result.code == 200){
          document.getElementById("btnReset").click();
          this.getDocumentos();
        }else{
          
        }
        console.info(result);
      })
    }
  }

  saveContacto(){
    if(this.formularioContacto.valid){
      this.isLoading = true;
      this._alumnos.altaContacto(this.formularioContacto.getRawValue(),this.idAlumno).subscribe(result=>{
        this.isLoading = false;
        if(result.code == 200){
          this.findContactos();
          this.formularioContacto.reset();
        }else if(result.code == 410){
          this.dialog.open(MessageBoxComponent,{ disableClose: false ,data: {title: "Ya se cuenta con 3 contactos", text: result.message, icon:"error_outline"}});
        }else if(result.code == 500){
          this.dialog.open(MessageBoxComponent,{ disableClose: false ,data: {title: "Problema al registrar contacto", text: result.message, icon:"error_outline"}});
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: false ,data: {title: "Problema al registrar contacto", text: "Ocurrio un error en el servicio", icon:"error_outline"}});
        }
      });
    }
  }
  contactos = [];
  findContactos(){
    this.isLoading = true;
    this._alumnos.getContactos(this.idAlumno).subscribe(result=>{
      console.log("findContactos result: ", result);
      this.isLoading = false;
      if(result.code==200){
        this.contactos = result.datos.contactos;
        for(let i=0; i<this.contactos.length; i++){
          this.contactos[i].edit = false;
        }
      }else{
        this.contactos = [];
      }      
    });
    console.log("findContactos contactos: ", this.contactos);
  }
  mydocuments = [];
  getDocumentos(){
    this.isLoading=true;
    this._alumnos.consultaDocumentos(this.idAlumno).subscribe(result=>{
      this.isLoading=false;
      if(result.code==200){
        this.documentos = result.datos.tipo_documento;
      }else{
        this.documentos = [];
      }      
    })
  }
  cargarFoto(){
    this.isLoading = true;
    console.log("this.formularioAlumno.controls:", this.formularioAlumno.controls["foto"].value);
    this._alumnos.cargarFoto(this.formularioAlumno.controls["foto"].value,this.idAlumno).subscribe(result=>{
      console.info(result);
      this.formularioAlumno.controls["foto"].setValue("");
      this.formularioAlumno.controls["url"].setValue("");
      if(result.code == 200){
        this._alumnos.getFotos(this.id).subscribe(result=>{
          this.fotos = result.datos.fotos;
          
          this.formularioAlumno.controls["url"].setValue("");
          this.isLoading = false;
        });
      }else if(result.code == 410){
        this.isLoading = false;
        this.dialog.open(MessageBoxComponent,{ disableClose: false ,data: {title: "Problemas de formato", text: result.mensaje, icon:"error_outline"}});
      }else{
        this.isLoading = false;
        this.dialog.open(MessageBoxComponent,{ disableClose: false ,data: {title: "Problemas al crear el expediente del alumno", text: "No fué posible crear el alumno, si piensa que ésto es un error, contacte al administrador del sistema", icon:"error_outline"}});
      }
      
    })
  }
  eliminar(id){
    const dialogRef = this.dialog.open(DesitionBoxComponent, {
      data: {title:"Eliminar contacto", text:"¿Está seguro que desea eliminar el contacto seleccionado?",icon:"help"}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);
      if(result){
        this.eliminarContacto(id);
      }
    });
  }
  eliminarContacto(id){
    this._alumnos.eliminarContacto(id).subscribe(result=>{
      this.findContactos();
    })
  }
  editarContacto(idContacto:Number,json:any){
    console.log("editarContacto idContacto", idContacto);
    console.log("editarContacto json", json);
    this.isLoading=true;
    this._alumnos.actualizaContacto(json,idContacto).subscribe(result=>{
      console.log("editarContacto result", result);
      if(result.code==200){
        console.log("Contacto actualizado corretamente")
        this.findContactos();
      }else{
        this.isLoading=false;
      }
      
    })
    
    
  }
  modificandoContacto(){
    // This is intentional
  }
}