import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment'; 
import { ConfigurationService } from './configuration.service';


@Injectable({
  providedIn: 'root'
})
export class UsersServiceService {
  private base_url = environment.base_url;
  endpoint = "";
  constructor(private _http:HttpClient, private _config:ConfigurationService) { 
    this.endpoint = _config.getConfiguration().hostapi;
  }

  getUsuario(json:any):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  let options = { headers: headers };
    
    return this._http.post(this.endpoint+"/php/usuarios-service.php?opc=login",json,options);
  }
  getLicenses():Observable<any>{
    return this._http.get(this.endpoint+"/php/usuarios-service.php?opc=getLicenses");
    // return this._http.get("http://localhost/ng-san-juan-pablo/php/usuarios-service.php?opc=getLicenses");
  }
  getUsuarioById(id:number):Observable<any>{
    
    return this._http.get(this.endpoint+"/php/usuarios-service.php?opc=findById&id="+id);
  }
  deleteUserById(id:number):Observable<any>{
    return this._http.delete(this.endpoint+"/php/usuarios-service.php?opc=delete&id="+id);
  }

  findUsers(busqueda:string):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  let options = { headers: headers };
    return this._http.get(this.endpoint+"/php/usuarios-service.php?opc=find&busqueda="+busqueda,options);
  }

  altaUsuario(json:any):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  
  let options = { headers: headers };
    return this._http.post(this.endpoint+"/php/usuarios-service.php?opc=alta",json,options);
  }

  actualizarUsuario(id:number,json:any):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });
  let options = { headers: headers };
    return this._http.post(this.endpoint+"/php/usuarios-service.php?opc=update&id="+id,json,options);
  }
  getCorreo(json:any):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  let options = { headers: headers };
    return this._http.post(this.endpoint+"/php/usuarios-service.php?opc=validacorreo",json,options);
  }

  sendCorreo(json:any):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  let options = { headers: headers };
    return this._http.post(this.endpoint+"/php/correo.php",json,options);
  }

  getPerfiles(json:any):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  let options = { headers: headers };
    return this._http.post(this.endpoint+"/php/usuarios-service.php?opc=perfiles",json,options);
  }

  
}
