<div class="container-fluid gx-0">
	<section class="vh-100 bg-pistacho">
		<div class="container h-100">
			<div class="row d-flex justify-content-center align-items-center h-100 gx-0">
				<div class="col col-12 col-md-8 col-lg-6 col-xl-5">
					<div class="card shadow mx-2 bg-carnita gx-0 " style="border-radius: 2rem;">
						

						<img src="assets/img/logo.png" class="card-img-top rad-card bg-carnita p-5 pb-3 rounded mx-auto d-block" style="height: auto !important; width: 20rem !important;" alt="Imagen de fondo de san juan pablo">

						<h1 class="card-title p-5 pb-0 pt-0 text-primary mb-0 text-center">SISTEMA DE GESTIÓN INTEGRAL</h1>
						<h2 class="card-title p-5 pb-0 pt-0 text-primary mb-0 text-center">PADRES DE FAMILIA</h2>
						<!--<div class="card-img-overlay">
							<h2 class="card-title">Tutores</h2>
						</div>-->	

						<div class="spinner" *ngIf="isLoading">
							<mat-spinner mode="indeterminate"></mat-spinner>
						</div>

						<div class="card-body text-center">
							<mat-tab-group mat-align-tabs="center">
								<mat-tab label="Iniciar sesión" (click)="clearForm()">
									<div class="mt-2">
										<div class="container">
											<form novalidate [formGroup]="form" autocomplete="off" (ngSubmit)="postIniciarSesion()"><!--(ngSubmit)="postIniciarSesion()"-->
												
												
													<mat-form-field class="input-full-width" appearance="fill">
														<mat-label>Correo</mat-label>
														<input type="email" formControlName="correo" matInput placeholder="correo" matInput [errorStateMatcher]="matcher">
													</mat-form-field>	
												
													<mat-form-field class="input-full-width" appearance="fill">
														<mat-label>Contraseña</mat-label>
														<input type="password" formControlName="contrasena" matInput placeholder="Contraseña" matInput [errorStateMatcher]="matcher">
													</mat-form-field>
												

												
												<div class="gap-2 mt-2">
													<button mat-flat-button color="primary" class="flat" type="submit">
														Iniciar sesión
													</button>
												</div>
											</form>
										</div>										
									</div>
								</mat-tab>
								<mat-tab label="Recuperar sesión" (click)="clearForm()">
									<div class="mt-2">
										<div class="container p-0">
											<form novalidate [formGroup]="form" autocomplete="off" (ngSubmit)="postRecuperarCuenta()">
										
												
												<p class="lead"> 
													La contraseña se enviará a su cuenta de correo que tiene registrada en el sistema.
												</p>
										
												<mat-form-field class="example-full-width" appearance="fill">
													<mat-label>Correo</mat-label>
													<input type="email" formControlName="correo" matInput placeholder="correo" matInput [errorStateMatcher]="matcher">
												</mat-form-field>
										
												
												<div class="gap-2 mt-2">
													<button type="submit" mat-flat-button color="primary">Recuperar cuenta</button>
												</div>
											</form>
										</div>	
									</div>
								</mat-tab>
							</mat-tab-group>
							<br>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>
