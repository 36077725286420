

<h1 mat-dialog-title>
    <mat-icon>receipt</mat-icon>
</h1>
<div mat-dialog-content *ngIf="data[0]?.forma_de_pago; else consultar">
  <div class="container p-3 pt-0" id="elticket">
      <br><br>
      <div class="row">
              
            <div class="d-flex justify-content-center">
                <img src="../../../assets/img/logo.png" width="30%" alt="Logotipo">
            </div>
            
            <br><br>
            <div class="col-md-12 text-center mt-3">
                <span class="fs-5 text-uppercase">Comprobante de pago</span>
            </div>
      </div>
      <br>
      <div class="row">
          
          <div class="col-md-8">
              <div class="col-md-12">{{data[0].fecha_alta | date: 'dd/MM/yyyy hh:mm:ss'}}</div>
          </div>
          <div class="col-md-4">
            <div class="col-md-12 text-end">FP: {{data[0].forma_de_pago}}</div>
          </div>
          <div class="col-md-12">
            <div class="col-md-12">Alumno: {{data[0].apellidop}} {{data[0].apellidom}} {{data[0].nombre}}</div>
          </div>
          <div class="col-md-12">
            <div class="col-md-12">RFC Alumno: {{data[0].curp}} </div>
          </div>
          <div class="col-md-12">
            <div class="col-md-12">Lo atendio: {{data[0].nombre_usuario}} ({{data[0].usuario}})</div>
          </div>
          <div class="col-md-12">
            <div class="col-md-12">ID: {{data[0].id_ticket_pk}}</div>
          </div>
          <div class="col-md-12">
            <div class="col-md-12" *ngIf="data[0].forma_de_pago == 1">Forma de pago: Efectivo</div>
            <div class="col-md-12" *ngIf="data[0].forma_de_pago == 2">Forma de pago: Transferencia</div>
            <div class="col-md-12" *ngIf="data[0].forma_de_pago == 3">Forma de pago: Deposito</div>
          </div>
      </div>
      <br>
        <div class="row">
            <div class="col-md-4">Concepto</div>
            <div class="col-md-3 text-end">Precio</div>
            <div class="col-md-2">Dscto</div>
            <div class="col-md-3 text-end">Importe</div>
        </div>
      <hr/>
      
      <div class="row" *ngFor="let concepto of data">
        <div class="col-md-4">{{concepto.concepto}}</div>
        <div class="col-md-3 text-end">{{concepto.precio_unitario | currency: 'MXN'}}</div>
        <div class="col-md-2 text-end">{{concepto.descuento | currency: 'MXN'}}</div>
        <div class="col-md-3 text-end">{{concepto.importe_pagado | currency: 'MXN'}}</div>
    </div>
      
      <hr/>
      <div class="row">
        <div class="col-md-8 fw-bold">Subtotal:</div>
        <div class="col-md-4 text-end fw-bold">{{getTotalCost().subtotal| currency: 'MXN'}}</div>
      </div>
      <div class="row">
        <div class="col-md-8 fw-bold">Descuentos:</div>
        <div class="col-md-4 text-end fw-bold">{{getTotalCost().descuentos| currency: 'MXN'}}</div>
      </div>
      <div class="row">
        <div class="col-md-8 fw-bold">Recargos:</div>
        <div class="col-md-4 text-end fw-bold">{{getTotalCost().recargos| currency: 'MXN'}}</div>
      </div>
      <div class="row">
        <div class="col-md-8 fw-bold">Saldo aplicado:</div>
        <div class="col-md-4 text-end fw-bold">{{data[0].saldo_favor_aplicados | currency: 'MXN'}}</div>
      </div>
      <div class="row">
        <div class="col-md-8 fw-bold">Total:</div>
        <div class="col-md-4 text-end fw-bold">{{getTotalCost().total| currency: 'MXN'}}</div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-12">
            <div class="col-md-12 text-center fw-bold">T{{data[0].id_ticket_pk}}_DT_{{date_ticket_final}}_U{{data[0].id_usuario_fk}}</div>
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-6"></div>
      </div>
      <br>
  </div>
</div>





<ng-template #consultar>
  <div mat-dialog-content>
    <div class="container p-3 pt-0" id="elticket">
        <br><br>
        <div class="row">
              
              <div class="d-flex justify-content-center">
                  <img src="../../../assets/img/logo.png" width="30%" alt="Logotipo">
              </div>
              
              <br><br>
              <div class="col-md-12 text-center mt-3">
                  <span class="fs-5 text-uppercase">Comprobante de pago</span>
              </div>
        </div>
        <br>
        <div class="row">
            
            
            <div class="col-md-8">
                <div class="col-md-12">{{data?.fecha_alta | date: 'dd/MM/yyyy hh:mm:ss'}}</div>
            </div>
            <div class="col-md-4">
              <div class="col-md-12 text-end">FP: {{data?.forma_de_pago}}</div>
            </div>
            <div class="col-md-12">
              <div class="col-md-12">Alumno: {{data?.tesoreria_movimientos[0]?.alumno?.apellidop}} {{data?.tesoreria_movimientos[0]?.alumno?.apellidom}} {{data?.tesoreria_movimientos[0]?.alumno?.nombre}}</div>
            </div>
            <div class="col-md-12">
              <div class="col-md-12">RFC Alumno: {{data?.tesoreria_movimientos[0]?.alumno?.curp}} </div>
            </div>
            <div class="col-md-12">
              <div class="col-md-12">Lo atendio: {{data?.usuario?.nombre}} ({{data?.usuario?.nombre}})</div>
            </div>
            <div class="col-md-12">
              <div class="col-md-12">ID: {{data?.tesoreria_movimientos[0]?.pivot?.id_ticket_fk}}</div>
            </div>
            <div class="col-md-12">
              <div class="col-md-12" *ngIf="data.forma_de_pago == 1">Forma de pago: Efectivo</div>
              <div class="col-md-12" *ngIf="data.forma_de_pago == 2">Forma de pago: Transferencia</div>
              <div class="col-md-12" *ngIf="data.forma_de_pago == 3">Forma de pago: Deposito</div>
            </div>
        </div>
        <br>
          <div class="row">
              <div class="col-md-4">Concepto</div>
              <div class="col-md-3 text-end">Precio</div>
              <div class="col-md-2">Dscto</div>
              <div class="col-md-3 text-end">Importe</div>
          </div>
        <hr/>
        
        <div class="row" *ngFor="let concepto of data?.tesoreria_movimientos">
          <div class="col-md-4">{{concepto?.conceptos?.nombre}}</div>
          <!-- antes <div class="col-md-3 text-end">{{concepto?.concepto_version_dos?.precio_unitario | currency: 'MXN'}}</div> -->
          <div class="col-md-3 text-end">{{+concepto?.concepto_version_dos?.precio_unitario | currency: 'MXN'}}</div> <!-- despues -->
          
          <div class="col-md-2 text-end">
            <span *ngIf="concepto?.conceptos?.aplica_beca == 1">{{( (concepto?.concepto_version_dos?.precio_unitario * concepto?.alumno?.beca_beca)            ) | currency: 'MXN'}}</span>
            <span *ngIf="concepto?.conceptos?.aplica_beca == 0">0</span>
          </div>
          <div class="col-md-3 text-end">{{concepto?.precio_unitario | currency: 'MXN'}}</div>
      </div>
      
        <hr/>
        <div class="row">
          <div class="col-md-8 fw-bold">Subtotal:</div>
          <div class="col-md-4 text-end fw-bold">{{getTotalCost2().subtotal| currency: 'MXN'}}</div>
        </div>
        <div class="row">
          <div class="col-md-8 fw-bold">Descuentos:</div>
          <div class="col-md-4 text-end fw-bold">{{getTotalCost2().descuentos| currency: 'MXN'}}</div>
        </div>
        <div class="row">
          <div class="col-md-8 fw-bold">Recargos:</div>
          <div class="col-md-4 text-end fw-bold">{{getTotalCost2().recargos| currency: 'MXN'}}</div>
        </div>
        <div class="row">
          <div class="col-md-8 fw-bold">Saldo aplicado:</div>
          <div class="col-md-4 text-end fw-bold">{{data.saldo_favor_aplicados | currency: 'MXN'}}</div>
        </div>
        <div class="row">
          <div class="col-md-8 fw-bold">Total:</div>
          <div class="col-md-4 text-end fw-bold">{{getTotalCost2().total| currency: 'MXN'}}</div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
              <div class="col-md-12 text-center fw-bold">T{{data?.tesoreria_movimientos[0]?.pivot?.id_ticket_fk}}_DT_{{data?.fecha_alta}}_U{{data[0]?.tesoreria_movimientos?.id_usuario_fk}}</div>
          </div>
          <div class="col-md-6"></div>
          <div class="col-md-6"></div>
        </div>
        <br>
    </div>
  </div>
</ng-template>











<div mat-dialog-actions align="center">
    <button mat-flat-button color="accent" (click)="imprimir(data)">Imprimir</button>
    <button mat-flat-button color="primary" mat-dialog-close>Cerrar</button>
</div>
