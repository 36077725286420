import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { TtrSesionService } from 'src/app/services/tutores/ttr-sesion.service'; 


@Injectable()
export class TtrUserNotGuard implements CanActivate{

    constructor(
        private _router: Router,
        private _ttrSesionService: TtrSesionService
    ){

    }

    canActivate(): boolean{
        return true;
        /*let token = this._ttrSesionService.getDataToken(); 
        if(token){
            this._router.navigate(['tutores/dashboard']);
            return false;
        }else{
            return true;
        }*/
    }
}