import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-grant',
  templateUrl: './no-grant.component.html',
  styleUrls: ['./no-grant.component.scss']
})
export class NoGrantComponent implements OnInit {

  constructor() {
    // This is intentional
   }

  ngOnInit(): void {
    // This is intentional
  }

}
