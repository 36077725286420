<br><br>
<div class="lightbox">

</div>
<div class="card-container encuesta">
    <mat-card class="example-card">
        <div class="spinner" *ngIf="isLoading">
            <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
        </div>

        <mat-card-header>
            <mat-card-title>Detalles De Expediente De Alumno</mat-card-title>
        </mat-card-header>

        <mat-horizontal-stepper [linear]="tipoDeStepper" #stepper>
            <mat-step [stepControl]="formularioAlumno">
                <form [formGroup]="formularioAlumno">
                    <ng-template matStepLabel>Datos Generales</ng-template>
                    <mat-card-subtitle>1. DATOS DEL ALUMNO</mat-card-subtitle>

                    <div class="bt col-md-12">
                        <ng-container *ngFor="let foto of fotos; let i= index">
                            <div class="bt col-md-2 thumbnail" *ngIf="i==0">
                                <img src="/assets/img/transparent.png"
                                    style="background-image:url({{endpoint}}/php/{{foto.ruta}})" alt="Foto{{i+1}}"
                                    width="100%" height="auto" />
                            </div>
                            <div class="bt col-md-1 thumbnail" *ngIf="i>0">
                                <img src="/assets/img/transparent.png"
                                    style="background-image:url({{endpoint}}/php/{{foto.ruta}})" alt="Foto{{i+1}}"
                                    width="100%" height="auto" />
                            </div>
                        </ng-container>

                    </div>

                    <div class="bt col-md-2" *ngIf="preview">
                        <img [src]="preview" alt="" width="200px">
                    </div>

                    <br>
                    <mat-form-field appearance="fill" class="bt col-md-3">
                        <mat-label>Nombre</mat-label>
                        <input matInput placeholder="" formControlName="nombre" maxlength="100">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-3">
                        <mat-label>Apellido paterno</mat-label>
                        <input matInput placeholder="" formControlName="apellidop" maxlength="100">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-3">
                        <mat-label>Apellido materno</mat-label>
                        <input matInput placeholder="" formControlName="apellidom" maxlength="100">
                    </mat-form-field>
                    <section class="bt col-md-3">
                        <mat-radio-group formControlName="genero">&nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="1">Niño</mat-radio-button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="2">Niña</mat-radio-button>
                        </mat-radio-group>
                    </section>
                    <mat-card-subtitle></mat-card-subtitle>
                    <mat-form-field appearance="fill" class="bt col-md-3">
                        <mat-label>Fecha de nacimiento</mat-label>
                        <input type="date" matInput formControlName="fecha_nacimiento" readonly>
                        
                    </mat-form-field>
                    <mat-form-field class="bt col-md-1">
                        <mat-label>Edad</mat-label>
                        <input matInput value="{{showAge}}" disabled>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-4">
                        <mat-label>Lugar de nacimiento</mat-label>
                        <mat-select formControlName="estado">
                            <mat-option *ngFor="let edo of estados" [value]="edo.id_estado_pk">{{edo.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-4" *ngIf="formularioAlumno.value.estado == '33' || alumno?.id_estado_fk == '33'">
                        <mat-label>Nombre del lugar del extranjero</mat-label>
                        <input matInput placeholder="" formControlName="nombre_extranjero" maxlength="250">
                    </mat-form-field>



                    <mat-form-field appearance="fill" class="bt col-md-4">
                        <mat-label>CURP</mat-label>
                        <input matInput placeholder="" formControlName="curp" maxlength="18">
                    </mat-form-field>
                    <mat-card-subtitle>2. DOMICILIO</mat-card-subtitle>
                    <mat-form-field appearance="fill" class="bt col-md-6">
                        <mat-label>Colonia</mat-label>
                        <mat-select formControlName="colonia">
                            <mat-option *ngFor="let colonia of colonias" [value]="colonia.id_colonia_pk">
                                {{colonia.descripcion}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-6">
                        <mat-label>Otra colonia</mat-label>
                        <input matInput placeholder="" formControlName="colonia2" maxlength="100">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-4">
                        <mat-label>Calle</mat-label>
                        <input matInput placeholder="" formControlName="calle" maxlength="100">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-2">
                        <mat-label>Num EXT</mat-label>
                        <input matInput placeholder="" formControlName="numExt" maxlength="15">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-2">
                        <mat-label>Num INT</mat-label>
                        <input matInput placeholder="" formControlName="numInt" maxlength="15">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-2">
                        <mat-label>Código postal</mat-label>
                        <input matInput placeholder="" formControlName="codigo" pattern="^[0-9]+" maxlength="5">
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-3">
                        <mat-label>Víve con</mat-label>
                        <mat-select formControlName="viveCon">
                            <mat-option *ngFor="let s of vivecon" [value]="s.id_vivecon_pk">{{s.nombre}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-3">
                        <mat-label>Otro</mat-label>
                        <input matInput placeholder="" formControlName="viveConOtro" maxlength="100">
                    </mat-form-field>
                    <br>
                    <br><br>
                    <div class="text-align-center">
                        <button mat-flat-button color="primary" matStepperNext>Siguiente</button>
                    </div>
                </form>
            </mat-step>

            <mat-step [stepControl]="formularioEscolar" label="Datos Complementarios">
                <form [formGroup]="formularioEscolar">
                    <mat-card-subtitle>1. PROCEDENCIA ACADÉMICA</mat-card-subtitle>
                    <mat-form-field appearance="fill" class="bt col-md-6">
                        <mat-label>Escuela de procedencia</mat-label>
                        <input matInput placeholder="" formControlName="procedencia" maxlength="300">
                    </mat-form-field>
                    <section class="bt col-md-5">
                        <mat-radio-group formControlName="escuela">&nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="1">Pública</mat-radio-button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="2">Privada</mat-radio-button>
                        </mat-radio-group>
                    </section>
                    <mat-form-field appearance="fill" class="bt col-md-12">
                        <mat-label>Motivo del cambio</mat-label>
                        <input matInput placeholder="" formControlName="cambio" maxlength="2500">
                    </mat-form-field>
                    <br><br>
                    <mat-card-subtitle>2. CONSIDERACIONES MÉDICAS</mat-card-subtitle>
                    <section class="bt col-md-6">
                        <mat-label>Alergias</mat-label>
                        <mat-radio-group formControlName="alergias">&nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="1">No</mat-radio-button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="2">Si</mat-radio-button>
                        </mat-radio-group>
                    </section>
                    <mat-form-field appearance="fill" class="bt col-md-6">
                        <mat-label>Cual</mat-label>
                        <input matInput placeholder="" formControlName="alergiaWhich" maxlength="2500" disabled>
                    </mat-form-field>
                    <section class="bt col-md-6">
                        <mat-label>Enfermedad crónica</mat-label>
                        <mat-radio-group formControlName="enfermedad">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="1">No</mat-radio-button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="2">Si</mat-radio-button>
                        </mat-radio-group>
                    </section>
                    <mat-form-field appearance="fill" class="bt col-md-6">
                        <mat-label>Cual</mat-label>
                        <input matInput placeholder="" formControlName="enfermedadWhich" maxlength="2500" disabled>
                    </mat-form-field>
                    <div class="bt col-md-6"></div>
                    <mat-form-field appearance="fill" class="bt col-md-6">
                        <mat-label>Grupo sanguineo</mat-label>
                        <mat-select formControlName="grupo_sanguineo">
                            <mat-option *ngFor="let gs of grupo_sangineo" [value]="gs.id_grupo_sanguineo_pk">
                                {{gs.descripcion}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <section class="bt col-md-6">
                        <mat-label>Seguro médico</mat-label>
                        <mat-radio-group formControlName="seguro">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="1">No</mat-radio-button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="2">Si</mat-radio-button>
                        </mat-radio-group>
                    </section>
                    <mat-form-field appearance="fill" class="bt col-md-6">
                        <mat-label>Cual</mat-label>
                        <input matInput placeholder="" formControlName="seguroWhich" maxlength="150">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-6">
                        <mat-label>Enfermedades familiares</mat-label>
                        <mat-select formControlName="enfermedadesFamiliares">
                            <mat-option *ngFor="let enfermedad of enfermedades" [value]="enfermedad.id_enfermedades_pk">
                                {{enfermedad.descripcion}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-6">
                        <mat-label>Otra</mat-label>
                        <input matInput placeholder="" formControlName="otraenfermedad" maxlength="150">
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="bt col-md-6">
                        <mat-label>Doctor de confianza</mat-label>
                        <input matInput placeholder="" formControlName="doctorDeConfianza" maxlength="150">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-6">
                        <mat-label>Teléfono</mat-label>
                        <input matInput placeholder="" formControlName="telDoctorDeConfianza" maxlength="10">
                    </mat-form-field>
                    <br><br>
                    <mat-card-subtitle>3. RELIGIÓN</mat-card-subtitle>
                    <mat-form-field appearance="fill" class="bt col-md-6">
                        <mat-label>Religión que profesa</mat-label>
                        <input matInput placeholder="" formControlName="religion" maxlength="100">
                    </mat-form-field>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <section class="bt col-md-12"></section>
                    <section class="bt col-md-3">
                        <mat-label>Bautizado</mat-label>
                        <mat-radio-group formControlName="bautizo">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="1">No</mat-radio-button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="2">Si</mat-radio-button>
                        </mat-radio-group>
                    </section>
                    <section class="bt col-md-12"></section>
                    <section class="bt col-md-3">
                        <mat-label>Confirmado</mat-label>
                        <mat-radio-group formControlName="confirmado">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="1">No</mat-radio-button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="2">Si</mat-radio-button>
                        </mat-radio-group>
                    </section>
                    <section class="bt col-md-12"></section>
                    <section class="bt col-md-3">
                        <mat-label>Primera comunión</mat-label>
                        <mat-radio-group formControlName="primeraComunion">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="1">No</mat-radio-button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-radio-button class="example-margin" value="2">Si</mat-radio-button>
                        </mat-radio-group>
                    </section>
                    <br><br>
                    <div class="text-align-center">
                        <button mat-flat-button matStepperPrevious color="primary"
                            style="margin-right: 15px;">Anterior</button>
                        <button mat-flat-button matStepperNext color="primary">Siguiente</button>
                    </div>
                </form>
            </mat-step>

            <mat-step label="Documentos">
                <form [formGroup]="formularioDocumentos">
                    <mat-card-subtitle>1. DOCUMENTOS DE EXPEDIENTE</mat-card-subtitle>
                    <div class="bt col-md-12">
                        <mat-list>
                            <div mat-subheader>Documentos</div>
                            <mat-list-item *ngFor="let documento of documentos">
                                <mat-icon mat-list-icon>description</mat-icon>
                                <div mat-line>{{documento.nombre}}</div>
                                <div mat-line> {{documento.fecha_alta | date}} </div>
                                <div mat-line>
                                    <button mat-icon-button
                                        aria-label="Example icon button with a vertical three dot icon">
                                        <mat-icon>download</mat-icon>
                                    </button>
                                    <a href="/php/{{documento.ruta_descarga}}" download="{{documento.nombre}}"
                                        target="_self">Descargar</a>
                                </div>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </form>
                <div class="text-align-center">
                    <button mat-flat-button matStepperPrevious color="primary"
                        style="margin-right: 15px;">Anterior</button>
                    <button mat-flat-button matStepperNext color="primary">Siguiente</button>
                </div>

            </mat-step>

            
            <mat-step [stepControl]="formularioContacto" label="Contactos">
                <div class="bt col-md-12">
                    <mat-accordion>
                        <mat-expansion-panel *ngFor="let contacto of contactos">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>account_circle</mat-icon>
                                    &nbsp;{{contacto.nombre}} {{contacto.apellidop}} {{contacto.apellidom}}
                                </mat-panel-title>
                                <mat-panel-description>
                                    {{contacto.descripcion}}
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <ng-container *ngIf="contacto.edit==true">
                                <mat-form-field appearance="fill" class="bt col-md-4">
                                    <mat-label>Parentesco {{contacto.id_parentesco_pk}}</mat-label>
                                    <mat-select [value]="contacto.id_parentesco_pk" #parentesco>
                                        <mat-option *ngFor="let p of parentescos" [value]="p.id_parentesco_pk">
                                            {{p.descripcion}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-card-subtitle></mat-card-subtitle>
                                <mat-form-field appearance="fill" class="bt col-md-5">
                                    <mat-label>Nombre</mat-label>
                                    <input matInput placeholder="" maxlength="100" [value]="contacto.nombre" #nombre>
                                </mat-form-field>
                                <mat-form-field appearance="fill" class="bt col-md-2">
                                    <mat-label>Teléfono</mat-label>
                                    <input matInput pattern="^[0-9]+" maxlength="10" [value]="contacto.telefono_movil"
                                        #telefono>
                                </mat-form-field>
                                <mat-form-field appearance="fill" class="bt col-md-5">
                                    <mat-label>Correo electrónico</mat-label>
                                    <input matInput placeholder="" type="email" maxlength="100"
                                        [value]="contacto.correo" #correo>
                                </mat-form-field>
                                <section class="bt col-md-7">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <mat-label>Tipo de contacto</mat-label>
                                    <input matInput placeholder="" maxlength="100" #tipo style="display: none;"
                                        [value]="contacto.tipo">

                                    <mat-radio-group [value]="contacto.tipo">
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <mat-radio-button class="example-margin" value="1">1er contacto
                                        </mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <mat-radio-button class="example-margin" value="2">2do contacto
                                        </mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <mat-radio-button class="example-margin" value="3">3er contacto
                                        </mat-radio-button>
                                    </mat-radio-group>

                                </section>
                                <br><br>
                                <mat-action-row>
                                    <button mat-stroked-button type="button" color="default"
                                        (click)="editar(contacto.id_familiar_pk,{'parentesco':parentesco.value,'nombre':nombre.value,'telefono':telefono.value,'correo':correo.value,'tipo':tipo.value})">
                                        <mat-icon>save</mat-icon>
                                        Guardar
                                    </button>&nbsp;
                                    <button mat-flat-button type="button" color="warn" (click)="contacto.edit=false">
                                        <mat-icon>close</mat-icon>
                                        Cancelar
                                    </button>
                                </mat-action-row>
                            </ng-container>
                            <ng-container *ngIf="contacto.edit==false">
                                <div class="bt col-md-12">
                                    <div class="bt col-md-12">
                                        <mat-icon>map</mat-icon>&nbsp;
                                        <strong>Dirección:&nbsp;</strong><span>{{contacto.calle}} {{contacto.numero}}
                                            {{contacto.colonia}} {{contacto.codigo_postal}}</span>
                                    </div>
                                    <div class="bt col-md-4">
                                        <mat-icon>smartphone</mat-icon>&nbsp;
                                        <strong>Celular:&nbsp;</strong><span>{{contacto.celular}}</span>
                                    </div>
                                    <div class="bt col-md-4">
                                        <mat-icon>business</mat-icon>&nbsp;
                                        <strong>Trabajo:&nbsp;</strong><span>{{contacto.trabajo}}</span>
                                    </div>
                                    <div class="bt col-md-4">
                                        <mat-icon>phone</mat-icon>&nbsp;
                                        <strong>Casa:&nbsp;</strong><span>{{contacto.telefono_movil}}</span>
                                    </div>
                                    <div class="bt col-md-4">
                                        <mat-icon>email</mat-icon>&nbsp;
                                        <strong>Correo:&nbsp;</strong><span>{{contacto.correo}}</span>
                                    </div>
                                    <div class="bt col-md-4">
                                        <mat-icon>school</mat-icon>&nbsp;
                                        <strong>Escolaridad:&nbsp;</strong><span>{{contacto.escolaridad}}</span>
                                    </div>
                                    <div class="bt col-md-4">
                                        <mat-icon>work</mat-icon>&nbsp;
                                        <strong>Ocupación:&nbsp;</strong><span>{{contacto.ocupacion}}</span>
                                    </div>
                                    <div class="bt col-md-4">
                                        <mat-icon>handyman</mat-icon>&nbsp;
                                        <strong>Oficios que domina:&nbsp;</strong><span>{{contacto.oficios}}</span>
                                    </div>


                                </div>


                                <!--<p>
                          <mat-icon>badge</mat-icon>&nbsp;
                          <strong>Credencial:&nbsp;</strong><span>{{contacto.nombre_credencial}}</span>
                        </p>-->
                            </ng-container>

                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <br>
                <br>
                <div class="text-align-center">
                    <button mat-flat-button matStepperPrevious color="primary"
                        style="margin-right: 15px;">Anterior</button>
                    <button mat-flat-button matStepperNext color="primary" [routerLink]="['../']">Finalizar</button>
                </div>

            </mat-step>

            <mat-step label="Intranet">
                <ng-template matStepLabel>Intranet</ng-template>
                <mat-card-subtitle>1. Intranet</mat-card-subtitle>
                <app-intranet></app-intranet>
            </mat-step>
            
        </mat-horizontal-stepper>
    </mat-card>
</div>