import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormArray,FormBuilder, FormGroup,FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Params, RouterOutlet, Router, ActivationStart } from '@angular/router';


import { TtrSesionService } from 'src/app/services/tutores/ttr-sesion.service';

import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';



/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-ttr-dashboard',
  templateUrl: './ttr-dashboard.component.html',
  styleUrls: ['./ttr-dashboard.component.scss'],
  providers: [ TtrSesionService ]
})
export class TtrDashboardComponent implements OnInit {

  public dataSJP: any;
  public matcher: MyErrorStateMatcher;

  public alumno_fc: FormControl;

  @ViewChild(RouterOutlet) outlet: RouterOutlet;

  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _ttrSesionService: TtrSesionService,
  ){ 

    //this.dataSJP = JSON.parse(this._ttrSesionService.getDataSJP());

    this.alumno_fc = new FormControl(0, [Validators.required]);

    this._router.events.subscribe((e: any) => {
      
      if (e instanceof ActivationStart && e.snapshot.outlet === "ttr-dashboard"){
        console.log(e);
        this.outlet.deactivate();
      }
    });

    //console.log(this.dataSJP);
  }

  ngOnInit(): void {
    this.getDatosSesion();
  }

  public cerrarSesion(){
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('data_girasol');
    sessionStorage.removeItem('alumno');
    this._router.navigate(['tutores/sesion']);
  }

  cambiarAlumno(){
    console.log('cambiando', this.alumno_fc.value)
    localStorage.setItem('alumno', this.alumno_fc.value);
  }

  getDatosSesion(){
    //this.user = this.form.value;
    this._ttrSesionService.getDatosSesion().subscribe(
      (response: any) => {console.log(response);
          if(response.estado == 200){
            this.dataSJP = response.data;
            this.val();
            this._router.navigate(['tutores/dashboard/inicio']);
          }
        },
      (error: any) => {
        
      } 
    );
  }

  val(){
    if(this.dataSJP.alumnos.length > 0){
      //this.id = this.dataSJP.alumnos[0].id_alumno_pk;
      localStorage.setItem('alumno', this.dataSJP.alumnos[0].id_alumno_pk.toString());
      //this.alumno_fc = new FormControl(this.dataSJP.alumnos[0].id_alumno_pk, [Validators.required]);
      //this.alumno_fc.setValue(this.dataSJP.alumnos[0].id_alumno_pk, {}); 
    }
  }



}
