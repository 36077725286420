import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DesitionBoxComponent } from 'src/app/modales/desition-box/desition-box.component';
import { Grupos } from 'src/app/model/grupos';
import {NivelEducativo} from 'src/app/model/nivel_educativo';
import { AlumnsService } from 'src/app/services/alumns-service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ParametrosService } from 'src/app/services/parametros.service';
import { SessionServiceService } from 'src/app/services/session-service.service';
import { ControlEscolarService } from 'src/app/services/control-escolar.service';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm, UntypedFormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-parametrizacion-ciclo',
  templateUrl: './parametrizacion-ciclo.component.html',
  styleUrls: ['./parametrizacion-ciclo.component.scss'],
  providers: [ ControlEscolarService ],
})
export class ParametrizacionCicloComponent implements OnInit {

  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public isLoading: boolean = false;
  public ciclos: any = [];
  public data: any;
  public registrados: any = [];
  public noRegistrados: any = [];

  public niveles: any = [];
  public grados: any = [];
  public ofertaEducativa: any = [];

  public form1: UntypedFormGroup  = this.fb.group({
    nombre : new UntypedFormControl("",[Validators.required]),
    fecha_inicio: new UntypedFormControl("",[Validators.required]),
    fecha_fin: new UntypedFormControl("",[Validators.required]),
    fecha_alta: new UntypedFormControl("",[]),
    activo: new UntypedFormControl(0,[]),
  });

  public form2: UntypedFormGroup  = this.fb.group({
    concepto : new UntypedFormControl("",[]),
    numero_ciclo: new UntypedFormControl("",[]),
    nivel: new UntypedFormControl(1,[]),
    fecha_inicio: new UntypedFormControl("",[]),
    fecha_limite: new UntypedFormControl("",[]),
    aplica_recargo: new UntypedFormControl(0,[]),
  });



  constructor(
    private fb: UntypedFormBuilder,
    private params:ParametrosService,
    private router:Router,
    private _alumn:AlumnsService,
    public dialog: MatDialog, 
    private config:ConfigurationService, 
    public _session:SessionServiceService,
    private controlEscolarService:ControlEscolarService,
    ) { 
  }

  public currentNivel: any = "";
  ngOnInit(): void {
    this.getCiclos();
    this.getConsultaNivel();
    this.getConsultaGrados();
    this.getConsultaGrados();
    this.getConsultarOfertaEducativa();
  }

  public getFechaActual(){

    const date = new Date();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }


  public getConsultaNivel(){
    this.isLoading = true;
    this.controlEscolarService.getConsultaNivel().subscribe((result: any)=>{
      this.isLoading = false;
      console.info(result);
      if(result.estado==200){
        this.niveles = result.data;
      }
      },()=>{
        this.isLoading = false;
      });
  }

  public getConsultaGrados(){
    this.isLoading = true;
    this.controlEscolarService.getConsultaGrados().subscribe((result: any)=>{
      this.isLoading = false;
      console.info(result);
      if(result.estado==200){
        this.grados = result.data;
      }
      },()=>{
        this.isLoading = false;
      });
    }


    public getConsultarOfertaEducativa(){
      this.isLoading = true;
      this.controlEscolarService.getConsultarOfertaEducativa().subscribe((result: any)=>{
        this.isLoading = false;
        console.info(result);
        if(result.estado==200){
          this.ofertaEducativa = result.data;

          for(let i = 0; i < this.ofertaEducativa.length; i++){
            this.ofertaEducativa[i].nivel_antiguo = this.ofertaEducativa[i].id_nivel_fk;
            this.ofertaEducativa[i].grado_antiguo = this.ofertaEducativa[i].id_grado_fk;
          }

          this.currentNivel = this.ofertaEducativa[0].nivel.descripcion;
          this.ofertaEducativa.sort(function(a, b) {
            if (a.id_nivel_fk !== b.id_nivel_fk) {
                return a.id_nivel_fk - b.id_nivel_fk;
            } else {
                return a.grado.id_grado_pk - b.grado.id_grado_pk;
            }
        });


        }
        },()=>{
          this.isLoading = false;
        });
      }

      gradosFiltrados(id_nivel_fk: number) {
        return this.grados.filter(g => g.id_nivel_fk === id_nivel_fk);
      }



      public postRegistrarOfertaEducativa(ciclo: number, nivel: number, grado: number){
        this.isLoading = true;
        this.controlEscolarService.postRegistrarOfertaEducativa({
          nivel: nivel,
          ciclo: ciclo,
          grado: grado
        }).subscribe((res: any)=>{
          this.isLoading = false;
          console.info(res);
          if(res.estado==200){
            this.getConsultarOfertaEducativa();
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: res.mensaje, icon:"done", href:null}});
          }else{
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Ya esta asociado', icon:"error_outline", href:null}});
          }
          
    
          },()=>{
            this.isLoading = false;
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Ya esta asociado', icon:"error_outline", href:null}});
          });
      }

      public postModificarOfertaEducativa(ciclo: number, nivel: number, grado: number, nivel_a: number, grado_a: number){
        this.isLoading = true;
        this.controlEscolarService.postModificarOfertaEducativa({
          nivel: nivel,
          ciclo: ciclo,
          grado: grado,
          nivel_antiguo: nivel_a,
          ciclo_antiguo: this.ciclos[this.index].id_ciclo_pk,
          grado_antiguo: grado_a
        }).subscribe((res: any)=>{
          this.isLoading = false;
          console.info(res);
          if(res.estado==200){
            this.getConsultarOfertaEducativa();
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: res.mensaje, icon:"done", href:null}});
          }else{
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Ya esta asociado esta modificación', icon:"error_outline", href:null}});
          }
          
    
          },()=>{
            this.isLoading = false;
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Ya esta asociado esta modificación', icon:"error_outline", href:null}});
          });
      }











  public index = -1;
  public getCiclos(){
    this.isLoading = true;
    // this.controlEscolarService.getDeudas(this.idAlumno,2,2,9).subscribe(result=>{
       //id_alumno, id_ciclo, id_nivel, id_grado
    this.controlEscolarService.getConsultarCiclos().subscribe((result: any)=>{
      this.isLoading = false;
      console.info(result);
      if(result.estado==200){
        this.ciclos = result.data;

        let k = 0;
        let index = -1;
        this.registrados = [];
        this.noRegistrados = [];
        for(let i = 0; i<  this.ciclos.length; i++){
          if(this.ciclos[i].activo == 1){
            k++;
            this.index = i;
          }
        }

        if( k <= 1){
          this.getConsultarConceptosNoRegistrados(this.ciclos[this.index].id_ciclo_pk);
          this.getConsultarConceptosRegistrados(this.ciclos[this.index].id_ciclo_pk);
        }else{
          this.index = -1;
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Recuerde que solo se puede tener un ciclo activo', icon:"error_outline", href:null}});
        }
        
      }
      },()=>{
        this.isLoading = false;
      });
    }

    public postRegistrarCiclo(){
      this.isLoading = true;
      console.log(this.form1.value);
      this.form1.patchValue({
        fecha_alta: this.getFechaActual()
      });
      this.controlEscolarService.postRegistrarCiclo(this.form1.value).subscribe((res: any)=>{
        this.isLoading = false;
        console.info(res);
        if(res.estado==200){
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: res.mensaje, icon:"done", href:null}});
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: res.mensaje, icon:"error_outline", href:null}});
        }
        
  
        },()=>{
          this.isLoading = false;
        });
    }

    public postModificarCiclos(i: number, activo: number = 0){
      this.isLoading = true;
      console.log(i);
      this.controlEscolarService.postModificarCiclos({
        numero_ciclo: this.ciclos[i].id_ciclo_pk,
        nombre: this.ciclos[i].nombre,
        fecha_inicio: this.ciclos[i].fecha_inicio,
        fecha_fin: this.ciclos[i].fecha_fin,
        fecha_alta: this.getFechaActual(),
        activo: activo,
      }).subscribe((res: any)=>{
        this.isLoading = false;
        console.info(res);
        if(res.estado==200){
          this.getCiclos();
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: res.mensaje, icon:"done", href:null}});
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: res.mensaje, icon:"error_outline", href:null}});
        }
        
  
        },()=>{
          this.isLoading = false;
        });
    }


    public postRegistrarConceptoTesoreriaCiclo(i: number){
      this.isLoading = true;
      console.log(this.noRegistrados[i]);
      this.controlEscolarService.postRegistrarConceptoTesoreriaCiclo({
        precio_unitario: this.noRegistrados[i].precio_unitario,
        numero_ciclo: this.ciclos[this.index].id_ciclo_pk,
        concepto: this.noRegistrados[i].id_concepto_pk,
      }).subscribe((res: any)=>{
        this.isLoading = false;
        console.info(res);
        if(res.estado==200){
          this.getCiclos();
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: res.mensaje, icon:"done", href:null}});
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: res.mensaje, icon:"error_outline", href:null}});
        }
        
  
        },()=>{
          this.isLoading = false;
        });
    }

    public postEliminarConceptoTesoreriaCiclo(i: number){
      this.isLoading = true;
      console.log(this.registrados[i]);
      this.controlEscolarService.postEliminarConceptoTesoreriaCiclo({
        numero_ciclo: this.ciclos[this.index].id_ciclo_pk,
        concepto: this.registrados[i].id_concepto_fk,
      }).subscribe((res: any)=>{
        this.isLoading = false;
        console.info(res);
        if(res.estado==200){
          this.getCiclos();
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: res.mensaje, icon:"done", href:null}});
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: res.mensaje, icon:"error_outline", href:null}});
        }
        
  
        },()=>{
          this.isLoading = false;
        });
    }


    public getConsultarConceptosNoRegistrados(id: number){
      this.isLoading = true;
      this.controlEscolarService.getConsultarConceptosNoRegistrados(id).subscribe((res: any)=>{
        this.isLoading = false;
        console.info(res);
        if(res.estado == 200){
          this.noRegistrados = res.data;
          for(let t = 0; t < this.noRegistrados.length; t++){
            this.noRegistrados[t].precio_unitario = 0;



          }
          //this.noRegistrados = this.filterByName(this.noRegistrados, 'Aportación, Estudio Socioeconómico, Inscripción , Extemporaneo');
        }
        },()=>{
          this.isLoading = false;
        });
    }

    public getConsultarConceptosRegistrados(id: number){
      this.isLoading = true;
      this.controlEscolarService.getConsultarConceptosRegistrados(id).subscribe((res: any)=>{
        this.isLoading = false;
        console.info(res);
        if(res.estado == 200){
          this.registrados = res.data;
          this.registrados.sort((a, b) => {
            if (a.concepto.nombre < b.concepto.nombre) {
              return -1;
            }
            if (a.concepto.nombre > b.concepto.nombre) {
              return 1;
            }
            return 0;
          });

          //this.registrados = this.filterByName(this.registrados, 'Aportación');
        }
        },()=>{
          this.isLoading = false;
        });
    }



  public  filterByName(items: any[], searchWords: string) {
    const searchWordsArr = searchWords.split(',');
    return items.filter(item => searchWordsArr.some(word => item.nombre.includes(word)));
  }
      









  public cuentas_por_cobrar: any;
  public getConsultarCuentasPorCobrar(i: number){
    console.log(this.registrados[i]);
    var id = this.ciclos[this.index].id_ciclo_pk;
    var text = this.registrados[i].concepto.nombre;
    var id3 = this.registrados[i].id_concepto_fk;
    var id2 = -1;

    if(text.indexOf('Preescolar') !== -1){
      id2 = 1;
    }else if(text.indexOf('Primaria') !== -1){
      id2 = 2;
    }else if(text.indexOf('Secundaria') !== -1){
      id2 = 3;
    }else if(text.indexOf('Preparatoria') !== -1){
      id2 = 4;
    }else if(text.indexOf('Estudio Socioeconómico') !== -1){
      this.auxAnterior(id, 1, id3);
      this.auxAnterior(id, 2, id3);
      this.auxAnterior(id, 3, id3);
      this.auxAnterior(id, 4, id3);
    }


    if(id2 == 1 || id2 == 2 || id2 == 3 || id2 == 4){
      this.isLoading = true;
      this.controlEscolarService.getConsultarCuentasPorCobrarDos(id, id2, id3).subscribe((res: any)=>{
        this.isLoading = false;
        console.info(res);
        if(res.estado == 200){
          if(res?.data?.length > 0){
            console.log('MAE');
            this.cuentas_por_cobrar = res?.data[0];
            if(this.cuentas_por_cobrar.length == 0){
              this.cuentas_por_cobrar = null;
            }
          }else{
            this.cuentas_por_cobrar = null;
          }
          //this.registrados = this.filterByName(this.registrados, 'Aportación');
        }
        },()=>{
          this.isLoading = false;
        });
    }
  }

  public auxAnterior(id: number, num: number, id3: number){
    this.controlEscolarService.getConsultarCuentasPorCobrar(id, num, id3).subscribe((res: any)=>{
      this.isLoading = false;
      console.info(res);
      if(res.estado == 200){
        if(res?.data?.length > 0){
          console.log('MAE');
          this.cuentas_por_cobrar = res?.data[0];
          if(this.cuentas_por_cobrar.length == 0){
            this.cuentas_por_cobrar = null;
          }
        }
        //this.registrados = this.filterByName(this.registrados, 'Aportación');
      }
      },()=>{
        this.isLoading = false;
      });
  }





  public postModificarConceptoPorCobrar(i: number){
    this.isLoading = true;
    console.log(this.cuentas_por_cobrar);
    this.controlEscolarService.postModificarConceptoPorCobrar({
      numero_ciclo: this.ciclos[this.index].id_ciclo_pk,
      concepto: this.cuentas_por_cobrar.id_concepto_fk,
      nivel: this.cuentas_por_cobrar.id_nivel_fk,
      fecha_inicio: this.cuentas_por_cobrar.fecha_inicio,
      fecha_limite: this.cuentas_por_cobrar.fecha_limite,
      aplica_recargo: this.cuentas_por_cobrar.aplica_recargo,
      numero_por_cobrar: this.cuentas_por_cobrar.id_cuenta_por_cobrar_pk,
    }).subscribe((res: any)=>{
      this.isLoading = false;
      console.info(res);
      if(res.estado==200){
        this.getCiclos();
        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: res.mensaje, icon:"done", href:null}});
      }else{
        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: res.mensaje, icon:"error_outline", href:null}});
      }
      

      },()=>{
        this.isLoading = false;
      });
  }
  

  public postRegistrarConceptoPorCobrar(i: number){
    this.isLoading = true;
    
    this.form2.patchValue({
      concepto: this.registrados[i].id_concepto_fk,
      numero_ciclo: this.ciclos[this.index].id_ciclo_pk,
    });

    if(this.registrados[i].concepto.nombre.indexOf('Preescolar') !== -1){
      this.form2.patchValue({nivel: 1  });
    }else if(this.registrados[i].concepto.nombre.indexOf('Primaria') !== -1){
      this.form2.patchValue({nivel: 2  });
    }else if(this.registrados[i].concepto.nombre.indexOf('Secundaria') !== -1){
      this.form2.patchValue({nivel: 3  });
    }else if(this.registrados[i].concepto.nombre.indexOf('Preparatoria') !== -1){
      this.form2.patchValue({nivel: 4  });
    }

    this.controlEscolarService.postRegistrarConceptoPorCobrar(this.form2.value).subscribe((res: any)=>{
      this.isLoading = false;
      console.info(res);
      if(res.estado==200){
        this.getCiclos();
        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: res.mensaje, icon:"done", href:null}});
      }else{
        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: res.mensaje, icon:"error_outline", href:null}});
      }
      

      },()=>{
        this.isLoading = false;
      });
  }


  public postEliminarConceptoPorCobrar(){
    this.isLoading = true;
    console.log(this.cuentas_por_cobrar);
    this.controlEscolarService.postEliminarConceptoPorCobrar({
      numero_por_cobrar: this.cuentas_por_cobrar.id_cuenta_por_cobrar_pk,
    }).subscribe((res: any)=>{
      this.isLoading = false;
      console.info(res);
      if(res.estado==200){
        this.getCiclos();
        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: res.mensaje, icon:"done", href:null}});
      }else{
        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: res.mensaje, icon:"error_outline", href:null}});
      }
      

      },()=>{
        this.isLoading = false;
      });
  }



  

}
