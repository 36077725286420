import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SessionServiceService } from 'src/app/services/session-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  
  constructor(
    private route:Router, 
    public _session:SessionServiceService,
    ) { }

  ngOnInit(): void {
    console.info(this.route.url);
  }

  validaRuta(){
    return this.route.url.indexOf("login")>0;
  }
  esEncuesta(){
    return this.route.url.indexOf("encuesta")>0;
  }
  esAdministracion(){
    return this.route.url.indexOf("administracion")>0;
  }
  esReporteria(){
    return this.route.url.indexOf("reporteria")>0;
  }
  esMedicionResultados(){
    return this.route.url.indexOf("medicion-resultados")>0;
  }
  esControlEscolar(){
    return this.route.url.indexOf("control-escolar")>0;
  }
  esTesoreria(){
    return this.route.url.indexOf("tesoreria")>0;
  }
  esEstudioSocioeconomico(){
    return this.route.url.indexOf("estudio-socioeconomico")>0;
  }
  esEvaluaciones(){
    return this.route.url.indexOf("evaluaciones")>0;
  }
  salir(){
    this._session.logOut();
    this.route.navigate(['/security/login']);
  }

  get nombreUsuario(){
    return this._session.getSession().getUsuario().nombre + " " +this._session.getSession().getUsuario().apellidop;
  }
}
