import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { DesitionBoxComponent } from 'src/app/modales/desition-box/desition-box.component';
import { PrintEncuestaComponent } from 'src/app/modales/print-encuesta/print-encuesta.component';
import { EncuestasServiceService } from 'src/app/services/encuestas-service.service';
import { ReportingService } from 'src/app/services/reporting.service';


const ELEMENT_DATA: any[] = [
];

@Component({
  selector: 'app-ciclos-mannager',
  templateUrl: './ciclos-mannager.component.html',
  styleUrls: ['./ciclos-mannager.component.scss']
})
export class CiclosMannagerComponent implements OnInit {
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  resultado:any;
  code = 0;

  // MatPaginator Output
  pageEvent: PageEvent;
  
  isLoading = false;
  formulario = new FormGroup({
    busqueda : new FormControl("",[])
  });
  displayedColumns: string[] = ['position','periodo', 'publico', 'grado','grupo','nombre','encuestas_contestadas','estatus','token','editar','reporte_excel','eliminar'];
  dataSource = ELEMENT_DATA;
  constructor(private route:Router,private dialog:MatDialog,private encuestasService:EncuestasServiceService, private _reportingService:ReportingService,) { 
    this.isReporteria = (this.route.url.indexOf("reporteria")>0);
  }
  isReporteria:Boolean = false;
  ngOnInit(): void {
    if(this.isReporteria){
      this.displayedColumns = ['position','periodo', 'publico', 'grado','grupo','nombre','encuestas_contestadas','estatus','token','reporteria','reporte_excel'];
    }
    this.buscar();
  }
  buscar(){
    this.isLoading = true;
    this.encuestasService.findAll(this.formulario.controls["busqueda"].value).subscribe(result=>{
      console.info(result);
      this.isLoading = false;
      if(result.code==200){
        this.dataSource = result.datos.aplicaciones;
        console.log("this.dataSource: ", this.dataSource);
      }else{
        this.dataSource = [];
      }
    },error=>{
      this.isLoading = false;
      console.info(error);
      this.dataSource = [];
    });
  }
  delete(id:string){
    this.openDialog(id);
  }

  openEncuesta(idEncuesta:Number,idAplicacion){
    this.dialog.open(PrintEncuestaComponent,{ data: {id: idEncuesta, idAplicacion:idAplicacion }});
  }

  getDatosUrlNormal(){
    return this._reportingService.getEndPoint();
  }

  enviarDatos(idEncuesta:Number,idAplicacion){
    this.dialog.open(PrintEncuestaComponent,{ data: {id: idEncuesta, idAplicacion:idAplicacion, excel: 1 }});
  /*  this._reportingService.getExcelReporteEncuestas(idEncuesta, idAplicacion).subscribe((result: any)=>{
      // console.info(result);
      console.log("result: ",result);
      //this.code=result.code;
      if(result.estado == 200){
        //this.resultado = result.datos;
        //console.log("iniciarEncuesta datos: ", this.resultado);
        //alert(JSON.stringify(this.resultado));
     //   this.iniciarEncuesta(result.datos);
        
      }else{
        //this.openDialog(this.tituloError,this.mensajeError,this.erroricon);
      }
    },error=>{
      console.info(error);
    })*/
  }

  public getBaseUrl(){
    return this._reportingService.getEndPoint2();
  }

  openDialog(id): void {
    const dialogRef = this.dialog.open(DesitionBoxComponent, {
      //width: '250px',
      data: {title:"Solicitud de confirmación", text:"¿Está seguro que desea eliminar la encuesta?",icon:"help_outline"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);
      if(result){
        this.isLoading = true;
        this.encuestasService.deleteEncuestaById(id).subscribe(resulta2=>{
          if(resulta2.code==200){
            this.isLoading = false;
            this.buscar();
          }
        },error=>{
          console.info(error);
          this.isLoading = false;
        });
        
      }
    });
  }
}
