import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { TtrSesionService } from 'src/app/services/tutores/ttr-sesion.service'; 

@Injectable()
export class TtrUserGuard implements CanActivate{

    public estado: number;
    public mensaje: string;

    constructor(
        private _router: Router,
        private _ttrSesionService: TtrSesionService
        ){
        this.estado = 0;
        
    }

    canActivate(): boolean{
        console.log('guardian');
        let token = sessionStorage.getItem('token'); 
        if(token){
            console.log(token);
            this.getDatosSesion();
            return true;
        }else{
            this._router.navigate(['tutores/sesion']);
            return false;
        }
    }

    getDatosSesion(){
    //this.user = this.form.value;
    this._ttrSesionService.getDatosSesion().subscribe(
        (response: any) => {console.log(response);
            if(response.estado == 200){
                //Sonar
            }else{
                sessionStorage.removeItem('token');
                this._router.navigate(['tutores/sesion']);
            }
    },
    (error: any) => {
        console.log(error);
        sessionStorage.removeItem('token');
        this._router.navigate(['tutores/sesion']);
    });
}



}