<div class="card-container encuesta">
    <mat-card class="example-card">
        <div id="htmlData">
            <mat-card-header>
                <mat-card-title>Gestor de ciclo escolar del alumno</mat-card-title>
                <mat-card-subtitle>Seleccione el ciclo escolar a gestionar</mat-card-subtitle>
            </mat-card-header>
        </div>
        <mat-card-content>
            <app-ficha-informativa></app-ficha-informativa>
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <mat-toolbar color="primary">
                            <mat-icon>school</mat-icon>
                            <span>&nbsp;Inscripciones</span>
                            <span class="example-spacer"></span>
                            <ng-container *ngIf="!procesoControlEscolarInicializado">
                                <button mat-flat-button class="btn-light" [routerLink]="['inscripcion']" [disabled]="hayProcesoPendiente"
                                [queryParams]="{id:id}">
                                <mat-icon>person_add</mat-icon>&nbsp;Nueva inscripción
                                </button>
                            </ng-container>
                        </mat-toolbar>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <table mat-table matTableExporter [dataSource]="dataSource" class="mat-elevation-z0"
                            #exporter="matTableExporter" hiddenColumns="[6][7][8]">
                            <caption *ngIf="length<=0">Sin ciclos cursados</caption>
                            <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->

                            <!-- Position Column -->
                            <ng-container matColumnDef="ciclo">
                                <th scope="col" mat-header-cell *matHeaderCellDef> Nivel, grado y ciclo</th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="bg fw-bold">{{element.nivel_grado}}</div>
                                    <div class="bg">{{element.nombre_ciclo}}</div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="fechaInscripcion">
                                <th scope="col" mat-header-cell *matHeaderCellDef> Fecha de inscripción </th>
                                <td mat-cell *matCellDef="let element">                                  
                                    <div class="bg">
                                        <span class="text-capitalize">{{element.fecha_alta| date: 'EEEE d'}}</span>
                                        <span>&nbsp;de</span><span class="text-capitalize">&nbsp;{{element.fecha_alta| date: 'MMMM'}}</span>  
                                        <span>&nbsp;del {{element.fecha_alta| date: 'y'}}</span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="inscripcion">
                                <th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Inscripción </th>
                                <td mat-cell *matCellDef="let element" class="text-align-center">
                                    <button mat-flat-button color="{{element.step == 0 ? 'accent':'primary'}}"
                                        [routerLink]="['inscripcion']" [queryParams]="{id:id,key:element.llave}" class="px-2">
                                        
                                    
                                        <div *ngIf="element.step<0">
                                            <mat-icon class="fs-6">flag</mat-icon>No iniciado
                                        </div>
                                        <div *ngIf="element.step>0">
                                            <mat-icon class="fs-6">done</mat-icon>Concluido
                                        </div>
                                        <div *ngIf="element.step==0">
                                            <mat-icon class="fs-6">pending</mat-icon>En proceso
                                        </div>
                                    </button>
                                </td>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="cursando">
                                <th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Cursando </th>
                                <td mat-cell *matCellDef="let element" class="text-align-center">
                                    <button mat-flat-button color="accent" [disabled]="element.step<1" class="rounded-start px-2">
                                        <div *ngIf="element.step<1">
                                            <mat-icon class="fs-6">flag</mat-icon>No iniciado
                                        </div>
                                        <div *ngIf="element.step>1">
                                            <mat-icon class="fs-6">done</mat-icon>Concluido
                                        </div>
                                        <div *ngIf="element.step==1">
                                            <mat-icon class="fs-6">pending</mat-icon>En proceso
                                        </div>
                                    </button>
                                    <button *ngIf="element.step==1" mat-flat-button color="warn" class="rounded-end shadow-none" (click)="procesarBaja(element.id_control_escolar_pk)">
                                        <mat-icon class="fs-5">thumb_down_alt</mat-icon>
                                        Baja
                                    </button>
                                </td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="evaluacion">
                                <th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Evaluacion de cierre
                                </th>
                                <td mat-cell *matCellDef="let element" class="text-align-center">
                                    <button mat-flat-button color="accent" [disabled]="element.step<2" class="px-2">
                                        <div *ngIf="element.step<2">
                                            <mat-icon class="fs-6">flag</mat-icon>No iniciado
                                        </div>
                                        <div *ngIf="element.step>2">
                                            <mat-icon class="fs-6">done</mat-icon>Concluido
                                        </div>
                                        <div *ngIf="element.step==2">
                                            <mat-icon class="fs-6">pending</mat-icon>En proceso
                                        </div>
                                    </button>

                                </td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="cierre">
                                <th scope="col" mat-header-cell *matHeaderCellDef> Fecha de cierre </th>
                                <td mat-cell *matCellDef="let element">
                                    <span class="text-capitalize">{{element.fecha_fin| date: 'EEEE d'}}</span>
                                    <span>&nbsp;de</span><span class="text-capitalize">&nbsp;{{element.fecha_fin| date: 'MMMM'}}</span>  
                                    <span>&nbsp;del {{element.fecha_fin| date: 'y'}}</span>
                                </td>
                            </ng-container>



                            <!-- Symbol Column -->
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </div>
            </div>
            

            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
            
        </mat-card-content>
        <mat-card-actions>
            <!--<mat-paginator [length]="length"
               [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions"
               (page)="pageEvent = $event">
            </mat-paginator>-->
        </mat-card-actions>
    </mat-card>
</div>