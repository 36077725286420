import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import $ from "jquery";
import { from } from 'rxjs';
export interface ReciboData {
  data:any;
}

@Component({
  selector: 'app-recibo',
  templateUrl: './recibo.component.html',
  styleUrls: ['./recibo.component.scss'],
})
export class ReciboComponent implements OnInit {
  date_ticket:Date = new Date();
  date_ticket_final: string;
  
  constructor(public dialogRef: MatDialogRef<ReciboComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(this.data);
      dialogRef.disableClose = true;
      this.date_ticket_final = this.date_ticket.getFullYear().toString() + this.getActualDate(this.date_ticket.getMonth() + 1) + this.getActualDate(this.date_ticket.getDate()) + this.getActualDate(this.date_ticket.getHours() ) + this.getActualDate(this.date_ticket.getMinutes() ) + this.getActualDate(this.date_ticket.getSeconds() );
    }
    
    ngOnInit(): void {
      console.info(this.data);

  }

  
  getTotalCost(){
    let datos:any = this.data;
    
    let totales = {
      subtotal:0,
      descuentos:0,
      total:0,
      recargos: 0,
    }
    for(let i=0; i<datos.length;i++){
      totales.total= totales.total+ Number(datos[i].importe_pagado);
      totales.descuentos= totales.descuentos+ Number(datos[i].descuento);
      //totales.subtotal= totales.subtotal+ Number(datos[i].importe_pagado);
      totales.subtotal= totales.subtotal+ Number(datos[i].precio_unitario);
      totales.recargos= totales.recargos+ Number(datos[i].recargos_axuliar);

    }

    if(datos[0].saldo_favor_aplicados == null){
      datos[0].saldo_favor_aplicados = 0;
    }
   
    //totales.total =  totales.total - +datos[0].saldo_favor_aplicados;antes
    //totales.total =  totales.total;


    return totales;
  }


  getTotalCost2(){
    let datos:any = this.data.tesoreria_movimientos;
    
    let totales = {
      subtotal:0,
      descuentos:0,
      total:0,
      recargos: 0,
    }
    for(let i=0; i<this.data.tesoreria_movimientos.length;i++){
      totales.total= totales.total+ Number(datos[i].precio_unitario);
      //totales.descuentos= totales.descuentos+ Number(datos[i]?.descuento);
      //totales.subtotal= totales.subtotal+ Number(datos[i].importe_pagado);
      totales.subtotal= totales.subtotal+ Number(datos[i].concepto_version_dos?.precio_unitario);
      totales.recargos= totales.recargos+ Number(datos[i].recargos);

      /*this.data.tesoreria_movimientos[i].forEach(movimiento => { //buscamos la beca correspondiente
        movimiento.alumno.estudio_socioeconomico.forEach(estudio => {
          if (movimiento.id_ciclo_fk === estudio.id_ciclo_fk) {
            movimiento.alumno.beca_beca = estudio.beca;
          }
        });
      });*/
      for (let estudio of this.data.tesoreria_movimientos[i].alumno.estudio_socioeconomico) {//buscamos la beca correspondiente
        if (estudio.id_ciclo_fk === this.data.tesoreria_movimientos[i].id_ciclo_fk && this.data.tesoreria_movimientos[i].conceptos?.aplica_beca == 1) {
          this.data.tesoreria_movimientos[i].alumno.beca_beca = estudio.beca/100;
          totales.descuentos =totales.descuentos + this.data.tesoreria_movimientos[i].alumno.beca_beca*this.data.tesoreria_movimientos[i]?.concepto_version_dos?.precio_unitario;
          break;
        }
      }

    }

    

    if(this.data.saldo_favor_aplicados == null){
      this.data.saldo_favor_aplicados = 0;
    }
   
    //totales.total =  totales.total;//  +this.data.saldo_favor_aplicados;
    
    
    return totales;
  }

  async imprimir(objSend:any){
    // console.info(objSend);
    let dataCanv = document.getElementById("elticket") as HTMLCanvasElement;
    this.data = objSend;
    // console.log("data: ", this.data);
    let ancho = $("#elticket").width();
    let alto = $("#elticket").height();
    // let nombre = 'T'+this.data[0].id_ticket_pk+'DT'+this.data[0].fecha_alta.replace(" ","").replaceAll('-','').replaceAll('T','').replaceAll(':','')+'U'+this.data[0].id_usuario_fk;
    let nombre = 'documento'
    if(this.data[0]?.forma_de_pago){
      nombre = 'T'+this.data[0].id_ticket_pk+'_DT_'+this.date_ticket_final+'_U'+this.data[0].id_usuario_fk;
    }else{
      nombre = 'T'+this.data?.tesoreria_movimientos[0]?.pivot?.id_ticket_fk+'_DT_'+this.data?.fecha_alta+'_U'+this.data?.tesoreria_movimientos[0]?.alumno?.id_usuario_fk;
    }
    
    // console.log(this.data);  
    let pdf = new jsPDF('l', 'pt','a4'); //Generates PDF in landscape mode

    if (dataCanv.getContext) {
      console.log('entra');
      // Establecemos el tamaño del canvas al tamaño de una página A4 en modo horizontal
      dataCanv.width = 841;
      dataCanv.height = 595;
    }
    //pdf.scaleToFit(841,  595);

   

    await html2canvas(dataCanv, { height: 1024}).then(canvas=>{
      const contentDataURL = canvas.toDataURL('image/jpeg', 100.0);
      //console.log(contentDataURL);
      // console.log(contentDataURL);
      let anchoDocumento = 841 *0.80; // Ancho de la página A4 en modo horizontal en pt
      let anchoImagen = anchoDocumento / 2; // Cada imagen ocupará el 50% del ancho del documento
      
      //var width = pdf.internal.pageSize.getWidth();    
      //var height = pdf.internal.pageSize.getHeight();
      var height = 594+200;


      //pdf.scaleToFit(ancho, anchoDocumento);
      // Agregamos la primera imagen al documento PDF sumale 50
      pdf.addImage(contentDataURL, 'PNG', 50, 0, anchoImagen, height);

      // Agregamos la segunda imagen al documento PDF, a continuación de la primera
      pdf.addImage(contentDataURL, 'PNG', 841/2+50, 0, anchoImagen, height);
      //pdf.save( nombre+'.pdf'); 


      const pdfOutput = pdf.output('datauristring');
      const win = window.open();
      win.document.write('<iframe src="' + pdfOutput + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');

      


    });   

     
    
    
    
    
  }

  getActualDate(n:any) { return n < 10 ? '0' + n : n }
  
    
}
