import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DesitionBoxComponent } from 'src/app/modales/desition-box/desition-box.component';
import { Grupos } from 'src/app/model/grupos';
import {NivelEducativo} from 'src/app/model/nivel_educativo';
import { ActivatedRoute, Router } from '@angular/router';
import {Alumnos} from 'src/app/model/alumnos';
import { AlumnsService } from 'src/app/services/alumns-service';
import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ControlEscolarService } from 'src/app/services/control-escolar.service';

/** @type {*} */
const ELEMENT_DATA: any[] = [
];
/**
 *
 *
 * @export
 * @class FichaInformativaComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-ficha-informativa',
  templateUrl: './ficha-informativa.component.html',
  styleUrls: ['./ficha-informativa.component.scss']
})
export class FichaInformativaComponent implements OnInit {

  @Output() alumnoSeleccionado:any = new EventEmitter();
  id:number;
  isLoading = false;
  displayedColumns: string[] = ['nombre', 'genero', 'fecha', 'curp', 'edad'];
  dataSource = ELEMENT_DATA;
  endpoint = "";
  datosAlumno = new FormGroup({
    matricula:new FormControl("",[]),
    nombre : new FormControl("",[]),
    genero: new FormControl("",[]),
    fecha:new FormControl("",[]),
    curp: new FormControl("",[]),
    edad: new FormControl("",[])
  });
  public observaciones: string = '';
  public idProceso: any;
  
  constructor(
    private route:Router, 
    private _alumn:AlumnsService,
    private activatedRoute:ActivatedRoute,
    private _config:ConfigurationService,
    private control_escolar_service: ControlEscolarService,
  ) { 
    this.endpoint = _config.getConfiguration().hostapi;
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params['id']; 
      this.idProceso = params['key']; //1 1 2
      this.getObservaciones();
    });

  }

    
  alumno = new Alumnos();

  public getObservaciones(){
    console.log(this.id, this.idProceso);
    if(this.idProceso){
      let arreglo = this.idProceso.toString().split("-");
      this.control_escolar_service.findByKey(this.id, parseInt(arreglo[0]), parseInt(arreglo[1]), parseInt(arreglo[2])).subscribe(result => {
        console.log("result findByKey: ", result);
        
        let proceso = result.datos;
        this.observaciones = proceso.observaciones;
        console.log(this.observaciones);
  
        //this.validarRequisitos();
  
      }, () => {
        this.isLoading = false;
      });
    }
  }

  get form(){
    return this.datosAlumno.controls;
  }
  contactos:any;
  ngOnInit(): void {
    if(this.id>0){
      this.isLoading=true;
      this._alumn.getAlumnoById(this.id).subscribe(result=>{
        if(result.code==200){
          this._alumn.getFotos(this.id).subscribe(resultado=>{
            console.log("resultado: ", resultado);
            if(resultado.code="200" && resultado.datos.length != 0){
                console.log("entrando a obtener foto");
                this.foto = resultado.datos.fotos[0].ruta;
            }
          });
          this.alumno = result.datosBasicos.datos;
          console.log("this.alumno ficha info: ", this.alumno);
          let nombre = this.alumno.nombre +" "+this.alumno.apellidop+" "+this.alumno.apellidom;
          this.alumnoSeleccionado.emit(this.alumno);
          this.form.matricula.setValue("SJP-"+this.alumno.id_alumno_pk.toString().padStart(4,"0"));
          this.form.nombre.setValue(nombre);
          this.form.genero.setValue(this.alumno.genero.toString());
          this.form.fecha.setValue(this.alumno.fecha_nacimiento.toString().split("-")[2]+"/"+this.alumno.fecha_nacimiento.toString().split("-")[1]+"/"+this.alumno.fecha_nacimiento.toString().split("-")[0]);
          this.form.curp.setValue(this.alumno.curp.toString());
          this.form.edad.setValue(this.calculaEdad(this.alumno.fecha_nacimiento).toString());
          this.isLoading=false;

          this._alumn.getContactos(this.id.toString()).subscribe(resultado=>{
            this.contactos = resultado.datos.contactos;
            console.log("getContactos this.contactos: ", this.contactos);
          });

        }else{
          this.isLoading=false;
        }
      },error=>{
        console.log(error);
        this.isLoading=false;
      });
    }
  }

  calculaEdad(fechanac){
    
    const convertAge = new Date(fechanac);
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    return Math.floor((timeDiff / (1000 * 3600 * 24))/365);
  
  }
  headers = ["Parentesco","Nombre","Celular","Trabajo","Casa"];
  downloadPDF() {
    this.isLoading=true;

    let doc = new jsPDF('p','px','letter',true);
    doc.setFont("helvetica", "bold");
    doc.text("FICHA INFORMATIVA", 70, 20,{align:"left"},null);
    doc.setFont("helvetica", "normal");
    doc.text("Información general del alumno", 70, 35,{align:"left"},null);
    doc.setFontSize(10);
    
    doc.addImage("/assets/img/LOGOTIPO.png","PNG", 10, 5, 40, 45,'','FAST');
    let iy=55;
    doc.setFillColor(245, 245, 245);
    doc.rect(10, iy, 425, 90,"F");
    if(this.foto!="URL FOTO"){
      //this.convertImgToBase64("/apiphp/"+this.beneficiaria.foto,null);
      doc.addImage("assets/img/unknown.png","PNG", 15, iy+5, 80, 80,'','FAST');
      doc.addImage("/php/"+this.foto,"JPEG",15, iy+5, 80, 80,'','FAST');
      
    }else{
      doc.addImage("assets/img/unknown.png","PNG", 15, iy+5, 80, 80,'','FAST');
    }
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(">Contactos",10,160);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.table(10, 170, this.generateData(this.contactos), this.createHeaders(this.headers), { autoSize: true });
    
    this.imprimirFichaTecnica(doc,iy+10);

    
    

    // Extraemos el
    /*const DATA = document.getElementById('htmlData');
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
    };
    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 15;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}.pdf`);
      this.isLoading=false;
    });*/
  }
  generateData(contactos) {
    let result = [];
    let data = {
      No:null,
      Parentesco: "100",
      Nombre: "GameGroup",
      Celular: "XPTO2",
      Trabajo: "25",
      Casa: "20485861",
      vlt: "0"
    };
    console.log("contactos: ", contactos);
    if(!contactos){
      console.log("contactos viene vacio");
      let data = {
        No:"1",
        Parentesco: " ",
        Nombre: " ",
        Celular: " ",
        Trabajo: " ",
        Casa: " "
        
      };
      result.push(Object.assign({}, data));
    }else{
      for (var i = 0; i < contactos.length; i += 1) {
        let contacto = contactos[i];
        let data = {
          No:null,
          Parentesco: contacto.descripcion,
          Nombre: contacto.nombre + ' '+ contacto.apellidop + ' ' + contacto.apellidom,
          Celular: contacto.celular,
          Trabajo: contacto.trabajo,
          Casa: contacto.telefono_movil
          
        };
        data.No = (i + 1).toString();
        result.push(Object.assign({}, data));
      }
    }
    return result;
  };
  
  createHeaders(keys) {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        id: keys[i],
        name: keys[i],
        prompt: keys[i],
        width: 65,
        align: "center",
        padding: 0
      });
    }
    return result;
  }
  
  
  imprimirFichaTecnica(doc,iy){
    
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.text("Matrícula".toUpperCase(),100,iy+10);
    

    doc.text("Nombre completo".toUpperCase(),100,iy+25);
    

    doc.text("Fecha de nacimiento".toUpperCase(),100,iy+40);
    

    doc.text("Programa".toUpperCase(),100,iy+55);
    

    doc.text("Grado".toUpperCase(),270,iy+55);
    
    
    doc.text("Grupo".toUpperCase(),300,iy+55);
    
    
    doc.text("Genero".toUpperCase(),270,iy+25);
    

    doc.text("Edad".toUpperCase(),270,iy+40);
    

    doc.text("CURP".toUpperCase(),300,iy+40);
    

    
    
    
    doc.setFont("helvetica", "normal");
    doc.text(this.form.matricula.value,100,iy+16+1);
    doc.text(this.form.nombre.value,100,iy+31+1);
    doc.text(this.form.fecha.value ,100,iy+46+1);
    doc.text("",100,iy+61+1);   //Programa  
    doc.text("",270,iy+61+1);   //Grado
    doc.text("",300,iy+61+1);   //Grupo
    doc.text(this.form.genero.value,270,iy+31+1);
    doc.text(this.form.edad.value.toString(),270,iy+46+1);
    doc.text(this.form.curp.value,300,iy+46+1);
    
    
    
    //doc.text("XXX",100,iy+76);
    //doc.text("XXX",100,iy+91);
    //doc.text("XXX",100,iy+106);

    //doc.text(this.beneficiaria.nombre,50,10);

    //doc.text("XXX",270,iy+16);
    
    
    
    //doc.text("xxx",270,iy+76);
    //doc.text("XXX",270,iy+91);
    //doc.text("XXX",270,iy+106);

    doc.setFontSize(9);
      //doc.html(this.el.nativeElement,{
        //  callback:(doc)=>{
          doc.save("Ficha informativa "+this.form.matricula.value+".pdf");
          this.isLoading = false;
        
        //},x:10,y:altura
      //});
  }
  foto="";
  buscar(){
    console.info(this.datosAlumno.controls["busqueda"].value);
    this.isLoading = true;
    this._alumn.findAlumnos(this.datosAlumno.controls["busqueda"].value).subscribe(result=>{

      
      this.isLoading = false;
      if(result.code==200){
        console.info(result);
        this.dataSource = result.datos.alumnos;
      }else{
        this.dataSource = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.dataSource = [];
    });
  }
  esFicha(){
    return this.route.url.indexOf("ficha")>0;
  }
  esInscripcion(){
    return this.route.url.indexOf("inscripcion")>0;
  }

/*

  openDialog(id): void {
    const dialogRef = this.dialog.open(DesitionBoxComponent, {
      //width: '250px',
      data: {title:"Solicitud de confirmación", text:"¿Está seguro que desea eliminar el usuario?",icon:"help_outline"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);
      if(result){
        this.isLoading = true;
        this._alumn.deleteUserById(id).subscribe(resulta2=>{
          if(resulta2.code==200){
            this.isLoading = false;
            this.buscar();
          }
        },error=>{
          console.info(error);
          this.isLoading = false;
        });
        
      }
    });
  }*/
}
