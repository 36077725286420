<h2  *ngIf="!data.excel" mat-dialog-title>Vista previa</h2>
<h2  *ngIf="data.excel" mat-dialog-title>Descargando Excel</h2>
<mat-dialog-content class="mat-typography" *ngIf="!data.excel">
    <div class="card-container" id="areaImprimir">
        <mat-card class="example-card">
            <mat-card-header>
                <mat-card-title style="width: 100%; float: left; font-size:20px;">{{nombreEncuesta}}</mat-card-title>
                <mat-card-subtitle>{{subtituloEncuesta}}</mat-card-subtitle>
            </mat-card-header>
            <div *ngIf="code == 0 ">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <h1 *ngIf="code == 0 ">
                &nbsp;Estámos cargando la encuesta, gracias por esperar...
            </h1>
            <h1 *ngIf="code != 200 && code != 0">
                <mat-icon>error_outline</mat-icon>
                &nbsp;Lo sentimos, no logramos localizar tu encuesta.
            </h1>
            <mat-card-content>

                <p *ngIf="code==200">Agradecemos tu participación. Siéntete libre de llenar esta encuesta con las
                    respuestas que mejor consideres.</p>

                <!--<p *ngIf="code==200">Instrucciones para el llenado de encuestas.</p>
                <ol *ngIf="code==200">
                    <li>Rellena la opción que desees contestar</li>
                    <li>Cuando termines de llenar la encuesta oprime la opción terminar.</li>
                </ol>-->
                <!-- <br *ngIf="code==200">
                <br> -->

                <ng-container *ngIf="data.idAplicacion;else impresion">
                    <ng-container *ngFor="let laPregunta of resultado; let i = index;">
                        <div *ngIf="code==200">
                            <p>
                                <strong>{{i+1}}.- {{laPregunta?.descripcion_pregunta}}</strong>
                            </p>
                        </div>
                        <ng-container *ngIf="code==200">
                            <div
                                *ngIf="laPregunta?.id_tipo_pregunta_fk == '1' || laPregunta?.id_tipo_pregunta_fk == '4'">
                                <!--<mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" id="respuesta{{laPregunta?.id_pregunta_pk}}" ngModel="respuesta{{laPregunta?.id_pregunta_pk}}">-->

                                <dl>

                                    <!-- <dd class="percentage percentage-{{(( convierteNumber(laRespuesta.total) * 100 ) / convierteNumber(laRespuesta?.total_elements) ) }}" *ngFor="let laRespuesta of laPregunta?.respuestas"> -->
                                    <dd class="percentage" *ngFor="let laRespuesta of laPregunta?.respuestas"
                                        style="display: flex; margin-bottom: 2px;">
                                        <div
                                            [ngStyle]="{'background': '#3B5FA7','width': (( convierteNumber(laRespuesta.total) * 100 ) / convierteNumber(laRespuesta?.total_elements) )+'%'}">
                                        </div>
                                        <!-- <span class="text">{{laRespuesta?.respuesta}}: {{(( convierteNumber(laRespuesta.total) * 100 ) / convierteNumber(laRespuesta?.total_elements) ) }}%</span> -->
                                        <span class="text">{{laRespuesta?.respuesta}}: {{
                                            convierteEnteroFinal(
                                            (
                                            ( laRespuesta.total * 100)
                                            / laRespuesta.total_elements
                                            )
                                            ) }}%</span>
                                    </dd>

                                </dl>
                            </div>
                            <!-- <dl>
                                    <p>Respuesta abierta</p>
                                    <div *ngFor="let laRespuesta of laPregunta?.respuestas">
                                        <div style="padding-left: 32px;">
                                            <span>{{laRespuesta?.texto}}</span>
                                        </div>
                                    </div>
                                </dl> -->
                            <div *ngIf="laPregunta?.id_tipo_pregunta_fk == '2'">
                                <table class="table table-striped">
                                    <caption></caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" style="color: #000 !important;">Respuestas Abiertas:</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let laRespuesta of laPregunta?.respuestas">
                                        <tr>
                                            <td>{{laRespuesta?.texto}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- </div> -->
                            <div *ngIf="laPregunta?.id_tipo_pregunta_fk == '4'">
                                <ng-container>
                                    <ng-container *ngFor="let laRespuesta of laPregunta?.respuestas">
                                        <!-- <div *ngFor="let laRespuestaAbierta of laPregunta?.respuestasAbiertas">
                                            <ng-container
                                            *ngIf="laRespuesta.id_respuesta_pk == laRespuestaAbierta.id_respuesta_fk">
                                            <div style="padding-left: 32px;">
                                                <span>{{laRespuestaAbierta.texto}}</span>
                                            </div>
                                        </ng-container>
                                    </div> -->
                                        <table class="table table-striped">
                                            <caption></caption>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Respuestas Complementarias De Respuesta:
                                                        {{laRespuesta?.respuesta}}</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let laRespuestaAbierta of laPregunta?.respuestasAbiertas">
                                                <ng-container
                                                    *ngIf="laRespuesta.id_respuesta_pk == laRespuestaAbierta.id_respuesta_fk">
                                                    <tr>
                                                        <td>{{laRespuestaAbierta.texto}}</td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <br>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-template #impresion>
                    <div *ngFor="let laPregunta of resultado?.preguntas; let i = index;"
                        style="display: inline-block; width: 100%; margin-bottom: 10px;">

                        <p>
                            {{i+1}}.- {{laPregunta?.descripcion}}
                        </p>
                        <!-- <br> -->
                        <div *ngIf="code==200" style="display: inline-block; width:100%;">
                            <ng-container *ngIf="laPregunta?.id_tipo_pregunta_fk != '2'">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                    id="respuesta{{laPregunta?.id_pregunta_pk}}"
                                    ngModel="respuesta{{laPregunta?.id_pregunta_pk}}"
                                    [ngClass]="{other: laPregunta?.id_tipo_pregunta_fk == '3'}">
                                    <ng-container *ngFor="let laRespuesta of resultado?.respuestas; let indice =index;">
                                        <ng-container *ngIf="laRespuesta?.id_pregunta_fk == laPregunta?.id_pregunta_pk">
                                            <br>
                                            <!-- <br *ngIf="indice !== 0"> -->
                                            <!-- <br *ngIf="laRespuesta?.id_pregunta_fk !== laPregunta?.id_pregunta_pk"> -->
                                            <mat-radio-button class="example-radio-button" disabled>
                                                {{laRespuesta?.descripcion}}
                                            </mat-radio-button>
                                            <br>

                                            <mat-form-field appearance="fill"
                                                *ngIf="laPregunta?.id_tipo_pregunta_fk == '4'" style="width: 100%;">
                                                <mat-label>Explique</mat-label>
                                                <input type="text" matInput [(ngModel)]="texto" disabled />
                                            </mat-form-field>
                                            <!-- <mat-form-field appearance="fill" *ngIf="laPregunta?.id_tipo_pregunta_fk == '3'" style="width: 100%;">
                                                <mat-label>¿Cual?</mat-label>
                                                <input type="text" matInput [(ngModel)]="texto" disabled/>
                                            </mat-form-field> -->
                                        </ng-container>

                                    </ng-container>

                                </mat-radio-group>
                            </ng-container>
                            <ng-container *ngIf="laPregunta?.id_tipo_pregunta_fk == '3'">
                                <!-- <mat-form-field appearance="fill" style="display: flex; position: fixed; left: 120px;"> -->
                                <mat-form-field appearance="fill" class="mat-otra">
                                    <mat-label>¿Cual?</mat-label>
                                    <!-- <input type="text" matInput [(ngModel)]="texto" disabled style="position: absolute;top: -56px;left: 70px;"/> -->
                                    <input type="text" matInput [(ngModel)]="texto" disabled />
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="laPregunta?.id_tipo_pregunta_fk == '2'">
                                <mat-form-field appearance="fill" style="width: 100%;">
                                    <mat-label>Escribe tu respuesta</mat-label>
                                    <textarea matInput [(ngModel)]="texto" disabled></textarea>
                                </mat-form-field>
                            </ng-container>
                            <br>
                        </div>
                    </div>
                </ng-template>
            </mat-card-content>
        </mat-card>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" mat-dialog-close>Cerrar</button>
    <button mat-flat-button color="primary" (click)="imprimir()"  *ngIf="!data.excel">Imprimir</button>
</mat-dialog-actions>