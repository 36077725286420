<br><br>
<div class="card-container encuesta">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Inventario de encuestas</mat-card-title>
            <mat-card-subtitle>Gestión y control de encuestas</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <form autocomplete="no" (ngSubmit)="buscador(0)" [formGroup]="formulario">
                <mat-form-field appearance="fill" class="bt col-md-6" >
                    <mat-label>Buscar</mat-label>
                    <input matInput placeholder="Nombre de encuesta" formControlName="busqueda">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                
                &nbsp;
                <button type="button" mat-flat-button color="primary" [routerLink]="['new']" disabled>Nueva encuesta</button>
            </form>
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
                <caption></caption>
                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
                
                <ng-container matColumnDef="position">
                    <th id="position" mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element;let i=index"> {{i+1}} </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="publico">
                  <th id="publico" mat-header-cell *matHeaderCellDef> Publico </th>
                  <td mat-cell *matCellDef="let element"> {{element.publico}} </td>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="nombre">
                    <th id="nombreEncuesta" mat-header-cell *matHeaderCellDef> Nombre encuesta </th>
                    <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                </ng-container>                

                <!-- Symbol Column -->
                <ng-container matColumnDef="editar">
                    <th id="editar" mat-header-cell *matHeaderCellDef> Editar </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="primary" [routerLink]="['edit']" [queryParams]="{id:element.id_aplicacion_pk}" disabled>
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="imprimir">
                    <th id="imprimir" mat-header-cell *matHeaderCellDef> Imprimir </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="primary" (click)="imprimir(element.id_encuesta_pk)">
                            <mat-icon>print</mat-icon>
                        </button>
                    </td>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="eliminar">
                    <th id="eliminar" mat-header-cell *matHeaderCellDef> Eliminar </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="warn" (click)="delete(element.id_aplicacion_pk)" disabled>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>            
        </mat-card-content>
        <mat-card-actions>
            <mat-paginator [length]="length"
               [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions"
               (page)="page($event)" showFirstLastButtons>
            </mat-paginator>
        </mat-card-actions>
    </mat-card>
</div>