import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray,FormBuilder, FormGroup,FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DesitionBoxComponent } from 'src/app/modales/desition-box/desition-box.component';
import { Grupos } from 'src/app/model/grupos';
import {NivelEducativo} from 'src/app/model/nivel_educativo';
import { AlumnsService } from 'src/app/services/alumns-service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
import { SessionServiceService } from 'src/app/services/session-service.service';
import { ParametrosService } from 'src/app/services/parametros.service';


const ELEMENT_DATA: any[] = [
];

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-intranet',
  templateUrl: './intranet.component.html',
  styleUrls: ['./intranet.component.scss']
})
export class IntranetComponent implements OnInit {

  public form1: FormGroup;
  public form2: FormGroup;
  public form3: FormGroup;
  public form4: FormGroup;
  public cuentas: any;
  public estado: number;
  public mensaje: string;
  public alumnos: any;
  public pagina_act: number;
  public id_perfil_fk: any;
  public isLoading: boolean = false;
  public id: number = -1;
  public id_cuenta: any;
  public id_seccion: number = -1;


  constructor(
    private fb: FormBuilder,
    private _router:Router,
    private _alumn:AlumnsService,
    public dialog: MatDialog, 
    private config:ConfigurationService,
    public _session:SessionServiceService,
    private params:ParametrosService,
    private activatedRoute:ActivatedRoute,
  ) {
    this.estado = 0;
    this.mensaje = ''


    /* forms para el modal */
    this.form1 = this.fb.group({
      alumno: new FormControl(-1, [Validators.required]),
      cuenta: new FormControl(-1, [Validators.required]),
    });

    this.form2 = this.fb.group({
      correo: new FormControl('1', [Validators.required, Validators.email]),
      nombre_obligado: new FormControl('', [Validators.required]),
      curp: new FormControl('', [Validators.required]),
      contrasena: new FormControl('1', [Validators.required]),
      cuenta: new FormControl(-1, [Validators.required]),
    });

    this.form3 = this.fb.group({
      correo: new FormControl('', [Validators.required, Validators.email]),
      nombre_obligado: new FormControl('', [Validators.required]),
      curp: new FormControl('', [Validators.required]),
      contrasena: new FormControl('', [Validators.required]),
      alumno: new FormControl(-1, [Validators.required]),
    });

    this.form4 = this.fb.group({
      tipo_busqueda: new FormControl('4', []),
      buscador: new FormControl('', []),
    });
    /* fin forms para modals */

    this.activatedRoute.queryParams.subscribe(params=>{
      
        this.id = +params["id"];
        this.id_cuenta = +params["id_cuenta"];
        this.oneditAlumno(this.id, this.id_cuenta);
     
    });

    //this.setIdUsuarioFk();

   }


  
  



  ngOnInit(): void {
    this.setIdUsuarioFk();
    this.getConsultarCuentas();
    this.postBusquedaCuentas();
  }

  public seccion(id: number){
    this.id_seccion = id;
  }

  setIdUsuarioFk(){
    console.log(this._session.getSession());
    //this.id_perfil_fk = this._session.getSession().getUsuario().id_perfil_fk;
    //this.oneditAlumno(element.id_alumno_pk, element.id_cuenta_tutor_fk);
  }


  /*intranet*/
  public getConsultarCuentas(u: string = ''){
    this.isLoading = true;
    this._alumn.getConsultarCuentas().subscribe(
      response => {
        console.log(response);
        this.estado = response.estado;
        this.mensaje = response.mensaje;
        this.isLoading = false;
          //this.spinner.hide();
          
          if(this.estado == 200){
            this.cuentas = response.data;
            
            

            if(u == 'ultimo'){
              let lastElement = this.cuentas[this.cuentas.length - 1];
              var index = this.cuentas.findIndex(ct => ct.id_cuenta_pk == this.cuentas[this.cuentas.length - 1].id_cuenta_pk);
              this.id_cuenta = this.cuentas[this.cuentas.length - 1].id_cuenta_pk;
              console.log(this.cuentas);
              console.log(index);
              var cuenta = this.cuentas[index];
              console.log(index);
              this.form2.patchValue({ 
                correo: cuenta.correo,
                nombre_obligado: cuenta.nombre_obligado,
                curp: cuenta.curp,
                contrasena: '',
                cuenta: this.cuentas[this.cuentas.length - 1].id_cuenta_pk

              });

            }
              
            


          }else{
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: this.mensaje, icon:"error_outline", href:null}});
          }
        },
        error => {
        //console.log(<any>error);
        this.isLoading = false;
        this.estado = error.estado;
        this.mensaje = error.mensaje;
      });
  }

  public oneditAlumno(id_alumno: any, id_cuenta: any){
    console.log(id_cuenta);
    this.form1 = this.fb.group({
      alumno: new FormControl(id_alumno, [Validators.required]),
      cuenta: new FormControl(-1, [Validators.required]),
    });
    this.existeAlumnoForm2(id_cuenta);
    
    this.form3 = this.fb.group({
      curp: new FormControl('', [Validators.required]),
      nombre_obligado: new FormControl('', [Validators.required]),
      correo: new FormControl('', [Validators.required, Validators.email]),
      contrasena: new FormControl('', [Validators.required]),
      alumno: new FormControl(id_alumno, [Validators.required]),
    });
  }

  public existeAlumnoForm2(id_cuenta: any){
    if(id_cuenta == null){
      this.form2 = this.fb.group({
        nombre_obligado: new FormControl('', [Validators.required]),
        curp: new FormControl('', [Validators.required]),
        correo: new FormControl('No hay cuenta asociada', [Validators.required, Validators.email]),
        contrasena: new FormControl('', [Validators.required]),
        cuenta: new FormControl(-1, [Validators.required]),
      });
    }else{
      console.log(id_cuenta);
      this.isLoading = true;
      this._alumn.getConsultarCuentas().subscribe(
        response => {
          console.log(response);
          this.estado = response.estado;
          this.mensaje = response.mensaje;
          this.isLoading = false;
          //this.spinner.hide();
          if(this.estado == 200){
            this.cuentas = response.data;
            var index = this.cuentas.findIndex(ct => ct.id_cuenta_pk == id_cuenta);
            console.log(this.cuentas);
            console.log(index);
            var cuenta = this.cuentas[index];
            console.log(index);
            this.form1.patchValue({
              cuenta: +id_cuenta
            })
            this.form2.patchValue({ 
              correo: cuenta.correo,
              nombre_obligado: cuenta.nombre_obligado,
              curp: cuenta.curp,
              contrasena: '',
              cuenta: id_cuenta

            });
            console.log(this.form2.value);
          }else{
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: this.mensaje, icon:"error_outline", href:null}});
          }
        },
        error => {
        //console.log(<any>error);
        this.isLoading = false;
        this.estado = error.estado;
        this.mensaje = error.mensaje;
      });
    }
  }




  postAsociarAlumno(){
    this.isLoading = true;
    this.form1.patchValue({
      alumno: +this.form1.value.alumno,
      cuenta: +this.form1.value.cuenta,
    });
    console.log(this.form1);

    this._alumn.postAsociarAlumno(this.form1.value).subscribe(
      response => {
        console.log(response);
        this.estado = response.estado;
        this.mensaje = response.mensaje;
        this.isLoading = false;
        if(this.estado == 200){

          //this.cuentas = response.data;
            var index = this.cuentas.findIndex(ct => ct.id_cuenta_pk == +this.form1.value.cuenta);
            console.log(this.cuentas);
            console.log(index);
            var cuenta = this.cuentas[index];
            console.log(index);
            this.form2.patchValue({ 
              correo: cuenta.correo,
              nombre_obligado: cuenta.nombre_obligado,
              curp: cuenta.curp,
              contrasena: '',
              cuenta: +this.form1.value.cuenta

            });

            this.id_cuenta = this.form1.value.cuenta;
            console.log(this.id_cuenta);
              this._router.navigate(['control-escolar/expediente-alumno/edit'], 
              { queryParams:
                {id_cuenta: this.id_cuenta, id: this.id}
              });

          /*this.form2.patchValue({
            correo: new FormControl('1', [Validators.required, Validators.email]),
            contrasena: new FormControl('1', [Validators.required]),
            cuenta: new FormControl(-1, [Validators.required]),




            alumno: +this.form1.value.alumno,
            cuenta: +this.form1.value.cuenta,
          });*/

          this.getConsultarCuentas();
          //this.filtro(this.pagina_act, this.pageSize);
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: this.mensaje, icon:"done", href:null}});
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: this.mensaje, icon:"error_outline", href:null}});
        }
      },
      error => {
        //console.log(<any>error);
        this.isLoading = false;
        this.estado = error.estado;
        this.mensaje = error.mensaje;
      });
  }

  postModificarCuentaExpediente(){
    this.isLoading = true;
    this._alumn.postModificarCuentaExpediente(this.form2.value).subscribe(
      response => {
        console.log(response);
        this.estado = response.estado;
        this.mensaje = response.mensaje;
        this.isLoading = false;
        if(this.estado == 200){
          this.getConsultarCuentas();
          //this.filtro(this.pagina_act, this.pageSize);
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: this.mensaje, icon:"done", href:null}});
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: this.mensaje, icon:"error_outline", href:null}});
        }
      },
      error => {
        //console.log(<any>error);
        this.isLoading = false;
        this.estado = error.estado;
        this.mensaje = error.mensaje;
      });
  }

  public postRegistroCuentaExpediente(){
    this.isLoading = true;
    console.log(this.form3.value);
    this._alumn.postRegistroCuentaExpediente(this.form3.value).subscribe(
      response => {
        console.log(response);
        this.estado = response.estado;
        this.mensaje = response.mensaje;
        this.isLoading = false;
        this.form3.patchValue({
          correo: '',
          contrasena: '',
          nombre_obligado: '',
          curp: '',
        })
        if(this.estado == 200){
          this.getConsultarCuentas('ultimo');
          //this.filtro(this.pagina_act, this.pageSize);
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: this.mensaje, icon:"done", href:null}});
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: this.mensaje, icon:"error_outline", href:null}});
        }
        
      },
      error => {
        //console.log(<any>error);
        this.isLoading = false;
        this.estado = error.estado;
        this.mensaje = error.mensaje;
      });
  }


  public postRegistrarCuenta(){
    this.isLoading = true;
    
    this._alumn.postRegistrarCuenta(this.form3.value).subscribe(
      response => {
        console.log(response);

        this.estado = response.estado;
        this.isLoading = false;
        this.form3.patchValue({
          correo: '',
          contrasena: '',
          nombre_obligado: '',
          curp: '',
        })
        if(response.estado == 200){
          
          this.getConsultarCuentas();
          //this.filtro(this.pagina_act, this.pageSize);
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: response.mensaje, icon:"done", href:null}});
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
        }
        
      },
      error => {
        //console.log(<any>error);
        this.isLoading = false;
        this.estado = error.estado;
        this.mensaje = error.mensaje;
      });
  }

  /* fin intranet */





  public postBusquedaCuentas(){
    this.isLoading = true;
    var fm = new FormData();
    if(this.form4.value.tipo_busqueda != 4){
      fm.set('tipo_busqueda', this.form4.value.tipo_busqueda.toString());
      fm.set('buscador',  this.form4.value.buscador.toString());
    }else{
      fm.set('tipo_busqueda', this.form4.value.tipo_busqueda.toString());
    }
    
    this._alumn.postBusquedaCuentas(fm).subscribe(
      response => {
        console.log(response);

        this.estado = response.estado;
        this.isLoading = false;
        if(response.estado == 200){
          this.cuentas = response.data;
          //this.getConsultarCuentas();
          //this.filtro(this.pagina_act, this.pageSize);
          //this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: response.mensaje, icon:"done", href:null}});
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
        }
        
      },
      error => {
        //console.log(<any>error);
        this.isLoading = false;
        this.estado = error.estado;
        this.mensaje = error.mensaje;
      });
  }




}
