//General
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, FormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { ValidacionPagosService } from 'src/app/services/validacion-pagos.service';
import { ControlEscolarService } from 'src/app/services/control-escolar.service';
//Tabla
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//Fin Tabla
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios
import { ReciboComponent } from 'src/app/modales/recibo/recibo.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { TtrSesionService } from 'src/app/services/tutores/ttr-sesion.service';
import { iif } from 'rxjs';
import { SessionServiceService } from 'src/app/services/session-service.service';



@Component({
  selector: 'app-ttr-ac-validacion-pagos',
  templateUrl: './ttr-ac-validacion-pagos.component.html',
  styleUrls: ['./ttr-ac-validacion-pagos.component.scss'],
  providers: [ ValidacionPagosService, ControlEscolarService, TtrSesionService ]
})
export class TtrAcValidacionPagosComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher;
  public form1: FormGroup = this.fb.group({
    subtotal:new UntypedFormControl(0, []),
    descuento:new UntypedFormControl(0, []),
    total:new UntypedFormControl(0, [Validators.required]),
    comentarios:new UntypedFormControl('', []),
    numero_estado:new UntypedFormControl(1, []),
    alumno:new UntypedFormControl(-1, []),
    usuario:new UntypedFormControl(-1, []),
    numero_ticket:new UntypedFormControl('', []),
    ciclo:new UntypedFormControl('', []),
    nivel:new UntypedFormControl('', []),
    grado:new UntypedFormControl('', []),
  });


  public form2: FormGroup = this.fb.group({
    concepto:new UntypedFormControl(0, []),
    precio_unitario:new UntypedFormControl(0, []),
    recargos:new UntypedFormControl(0, []),
    subtotal:new UntypedFormControl(0, []),
    total:new UntypedFormControl(0, []),
    descuento:new UntypedFormControl(0, []),
    numero_ticket:new UntypedFormControl(0, []),
    usuario:new UntypedFormControl(3, []),
    nivel:new UntypedFormControl(1, []),
    grado:new UntypedFormControl('', []),
  });
 
  //Alerta
  public isLoading: boolean = false;
  public id_alumno: number = -1;
  public id_ciclo: number = -1;
  public id_ticket: number = -1;
  public dataSJP: any;
  public accion: string = '';
  public t: any;
  public laravelapiStorage: any;
  public nombre_ciclo: string = '';
  public nivel: string = ''
  public grado: string = '';
  


  constructor(
    private fb: FormBuilder,
    public  _dialog: MatDialog,
    private _InfFinEdqService: ValidacionPagosService,
    private _TtrSesionService: TtrSesionService,
    public controlEscolarService:ControlEscolarService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdRef:ChangeDetectorRef,
    private session:SessionServiceService
  ) { 
    //General
    this.laravelapiStorage = this._InfFinEdqService.getLaravelApiStorageTutores();

    this.matcher = new MyErrorStateMatcher();
    this.id_alumno = +localStorage.getItem('alumno');
      this.form1.patchValue({
        alumno: this.id_alumno,
        
    });
    


    this._route.queryParams.subscribe(params => {
      this.id_ciclo = params['ciclo'];
      this.id_ticket = params['id_ticket'];
      this.nombre_ciclo = params['nombre_ciclo'];
      this.nivel = params['nivel'];
      this.grado = params['grado'];
      this.form1.patchValue({
        ciclo: this.id_ciclo,
        numero_ticket: this.id_ticket,
        nivel: +this.nivel,
        grado: +this.grado
      });
      console.log(this.id_ticket);
      console.log(this.id_ciclo);
      if(this.id_ticket){
        this.form2.patchValue({
          //concepto:new UntypedFormControl(0, []),
          //precio_unitario:new UntypedFormControl(0, []),
          //recargos:new UntypedFormControl(0, []),
          //subtotal:new UntypedFormControl(0, []),
          //total:new UntypedFormControl(0, []),
          //descuento:new UntypedFormControl(0, []),
          numero_ticket: this.id_ticket,
          usuario: 3
        });


        this.postConsultaTicketSolicitudPapa();
      }else{
        this.form1.patchValue({
          numero_ticket: this.generarTicket()
        })
      }
     
    });

  }

  ngOnInit(): void {
    let url_list = this._router.url.split("/");
    this.accion = url_list[2]?.split("?")[0];
    console.log(this.accion);

    this.getIdUsuario();
  }

  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    
  }

  generarTicket() {
    // Generar número aleatorio de 10 dígitos
    const numeroAleatorio = Math.floor(Math.random() * 10000000000);
  
    // Combinar con la palabra "ticket"
    const ticket = `ticket-${numeroAleatorio}`;
  
    return ticket;
  }

  getIdUsuario(){
    //this.user = this.form.value;
    this._TtrSesionService.getDatosSesion().subscribe(
      (response: any) => {console.log(response);
          if(response.estado == 200){
            this.dataSJP = response.data;
            this.form1.patchValue({
              usuario: 3 //this.dataSJP?.id_cuenta_pk
            });
          }
        },
      (error: any) => {
        
      } 
    );
  }

  ngOnChanges(){
    console.log(localStorage.getItem('alumno'));
    if(+localStorage.getItem('alumno') != this.id_alumno){
      this.id_alumno = +localStorage.getItem('alumno');
      this.form1.patchValue({
        alumno: this.id_alumno
      });
    }
  }

  public postCrearTicketPapa(){
    console.log(this.form1.value);
    this.isLoading = true;
    this._InfFinEdqService.postCrearTicketPapa(this.form1.value).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._router.navigate(['tutores/dashboard/gt-validacion-pagos']);
        }
      },
      (error: any) => {
        this.isLoading = false;
        //console.log(<any>error);
      });
  }



  public conceptos: any = [];
  public getConceptos(){
    this.controlEscolarService.getDeudas(this.id_alumno,this.id_ciclo,this.data_ticket?.nivel?.id_nivel_pk,this.data_ticket?.grados?.id_grado_pk).subscribe(result=>{
      console.log(result);
      if(result.code == 200){
        this.conceptos = result.datos.porpagar;

        this.controlEscolarService.getConceptosCobro(this.id_alumno,this.id_ciclo, this.data_ticket?.nivel?.id_nivel_pk,this.data_ticket?.grados?.id_grado_pk).subscribe(resultado=>{
          console.log("getConceptosCobro result: ",resultado);

          //agregarConceptos(cuentasPorCobrar,otrosConceptos){
          //  console.info(cuentasPorCobrar);
            for(let i=0;i<resultado?.datos?.porpagar?.length;i++){
              this.conceptos.push(resultado?.datos?.porpagar[i]);
            }
          //this.dataSource = cuentasPorCobrar;
          //  console.info(cuentasPorCobrar);
          //}
  
          //this.conceptosCobro = resultado.datos.porpagar;
          //console.log("conceptosCobro: ", this.conceptosCobro);
  
          //this.agregarConceptos(result.datos.porpagar,resultado.datos.porpagar);
        })


      }
    });
  }
  


  /*consultar */
  public data_ticket: any;
  public postConsultaTicketSolicitudPapa(){
    console.log(this.form1.value);
    this.isLoading = true;
    this._InfFinEdqService.postConsultaTicketSolicitudPapa({numero_ticket: this.id_ticket}).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.data_ticket = response.data;
          this.getConceptos();
        }
      },
      (error: any) => {
        this.isLoading = false;
        //console.log(<any>error);
      });
  }
  /*fin consultar*/

  /*registrar concepto*/
  public postRegistrarConceptoTicketPapa(){
    console.log(this.form1.value);
    this.isLoading = true;
    this._InfFinEdqService.postRegistrarConceptoTicketPapa(this.form2.value).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.postConsultaTicketSolicitudPapa();
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: response.mensaje, icon:"done", href:null}});
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
        }
      },
      error => {
        this.isLoading = false;
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', metextnsaje: 'Error con el Servicio.', icon:'error_outline'}});
        //console.log(<any>error);
      });
  }


  public postModificarConceptoTicketPapa(item: any){
    console.log(item);
    this.isLoading = true;
    this._InfFinEdqService.postModificarConceptoTicketPapa({
      concepto: item.id_concepto_fk,
      precio_unitario: 0,
      recargos: 0,
      subtotal: 0,
      total: +item.total,
      descuento: 0,
      numero_ticket: this.id_ticket?.toString(),
      usuario: 3,
      id_concepto_soli_pk:  item.id_concepto_fk,
    }).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.postConsultaTicketSolicitudPapa();
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: response.mensaje, icon:"done", href:null}});
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
        }
      },
      error => {
        this.isLoading = false;
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', metextnsaje: 'Error con el Servicio.', icon:'error_outline'}});
        //console.log(<any>error);
      });
  }


  public postEliminarConceptoTicketPapa(id: number){
    console.log(id);
    this.isLoading = true;
    this._InfFinEdqService.postEliminarConceptoTicketPapa({id_concepto_soli_pk: id}).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.postConsultaTicketSolicitudPapa();
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: response.mensaje, icon:"done", href:null}});
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
        }
      },
      error => {
        this.isLoading = false;
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', metextnsaje: 'Error con el Servicio.', icon:'error_outline'}});
        //console.log(<any>error);
      });
  }
  /*fin registrar concepto*/


  /*documentos*/
  public file_registrar: any;
  public onchangeArchivo(event: any) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);

      // just checking if it is an image, ignore if you want
      if (file.type.startsWith('application/pdf')) {//application/pdf
        this.file_registrar = file;
      }else{
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', metextnsaje: 'Inválido formato.', icon:'error_outline'}});
      }
    }
  }


  public postRegistrarModificarFileTicket(){
    const formData = new FormData();
    formData.set('archivo', this.file_registrar);
    formData.set('numero_ticket', this.id_ticket.toString());
    this.isLoading = true;
    this._InfFinEdqService.postRegistrarModificarFileTicket(formData).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.postConsultaTicketSolicitudPapa();
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: response.mensaje, icon:"done", href:null}});
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
        }
      },
      error => {
        this.isLoading = false;
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', metextnsaje: 'Error con el Servicio.', icon:'error_outline'}});
        //console.log(<any>error);
      });
  }


  /*public postModificarFileTicket(){
    const formData = new FormData();
    formData.set('archivo', this.file_registrar);
    formData.set('numero_ticket', this.id_ticket.toString());
    this.isLoading = true;
    this._InfFinEdqService.postRegistrarModificarFileTicket(formData).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.postConsultaTicketSolicitudPapa();
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: response.mensaje, icon:"done", href:null}});
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
        }
      },
      error => {
        this.isLoading = false;
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', metextnsaje: 'Error con el Servicio.', icon:'error_outline'}});
        //console.log(<any>error);
      });
  }*/

  public postEliminarArchivoComprobante(id: number){
    this.isLoading = true;
    this._InfFinEdqService.postEliminarArchivoComprobante({numero_documento: id}).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.postConsultaTicketSolicitudPapa();
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: response.mensaje, icon:"done", href:null}});
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
        }
      },
      error => {
        this.isLoading = false;
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', metextnsaje: 'Error con el Servicio.', icon:'error_outline'}});
        //console.log(<any>error);
      });
  }


  public postModificar(event: any, id: number){
    //console.log(this.form.value);

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);

      this.isLoading = true;
    const formData = new FormData();
    formData.set('archivo', file);
    formData.set('numero_documento', id.toString());
    formData.set('numero_ticket', this.id_ticket.toString());


    this._InfFinEdqService.postRegistrarModificarFileTicket(formData).subscribe(
      (response: any) => {console.log(response);
      //console.log(this.form1.value);
      this.isLoading = false;
          if(response.estado == 200){
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: response.mensaje, icon:"done", href:null}});
            this.postConsultaTicketSolicitudPapa();
          }else{
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
          }
        },
        error => {console.log(<any>error);
          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', metextnsaje: 'Error con el Servicio.', icon:'error_outline'}});
         this.isLoading = false;
        });




    }
    
  }


  public element: any;
  public clickChangeFile(id:number){
    console.log(id);
    this.element = document.getElementById('file'+id) as HTMLElement;
    console.log(this.element);
    this.element.click();
    //this.document.getElementById('file'+id).click();
  }





  public postCambiarEstado(estado: number){
    console.log(estado);
    this.isLoading = true;
    this._InfFinEdqService.postCambiarEstado({estado: estado, numero_ticket: this.id_ticket}).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          //this.postConsultaTicketSolicitudPapa();
         
          if(estado == 2){
            this._router.navigate(['tutores/dashboard/gt-validacion-pagos']);
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: 'Se ha enviado su solicitud', icon:"done", href:null}});
            //this.postConsultaTicketSolicitudPapa();
          }
          
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
        }
      },
      error => {
        this.isLoading = false;
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', metextnsaje: 'Error con el Servicio.', icon:'error_outline'}});
        //console.log(<any>error);
      });
  }


  public comentario: string = '';

  public postCambiarEstadoAdmin(estado: number){
    var usuario = this.session.getSession().getUsuario().id_usuario_pk
    console.log(estado, usuario);
    this.isLoading = true;
    this._InfFinEdqService.postCambiarEstadoAdmin({estado: estado, numero_ticket: this.id_ticket, usuario: usuario}).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          //this.postConsultaTicketSolicitudPapa();
         
          if(estado == 1){
            this.postAgregarComentarioMalo();
          }else if(estado == 11){
            this.postAgregarComentario();
          }
          //if(estado == 2){
          //  this._router.navigate(['tutores/dashboard/gt-validacion-pagos']);
            this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: 'Se ha enviado su solicitud', icon:"done", href:null}});
            
          //}
          
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
        }
      },
      error => {
        this.isLoading = false;
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', metextnsaje: 'Error con el Servicio.', icon:'error_outline'}});
        //console.log(<any>error);
      });
  }

  public postAgregarComentario(){
    var usuario = this.session.getSession().getUsuario().id_usuario_pk
    this.isLoading = true;
    this._InfFinEdqService.postAgregarComentario({comentarios: this.comentario, numero_ticket: this.id_ticket, usuario: usuario}).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          //sonar
          this._router.navigate(['/validacion-pagos-tesoreria']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
        }
      },
      error => {
        this.isLoading = false;
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', metextnsaje: 'Error con el Servicio.', icon:'error_outline'}});
        //console.log(<any>error);
      });
  }

  public postAgregarComentarioMalo(){
    var usuario = this.session.getSession().getUsuario().id_usuario_pk
    this.isLoading = true;
    this._InfFinEdqService.postAgregarComentarioMalo({comentario_rechazo: this.comentario, numero_ticket: this.id_ticket, usuario: usuario}).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          //sonar
          this._router.navigate(['/validacion-pagos-tesoreria']);
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
        }
      },
      error => {
        this.isLoading = false;
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', metextnsaje: 'Error con el Servicio.', icon:'error_outline'}});
        //console.log(<any>error);
      });
  }


  





  /*fin documentos*/

  /* 
  getDeudas(idAlumno:number, idCiclo:number, idNivel:number, idGrado:number):Observable<any>{
    console.log("getDeudas set: ");

    */
}
