<br *ngIf="accion == 'consultar'">
<br *ngIf="accion == 'consultar'">
<br *ngIf="accion == 'consultar'">
<br *ngIf="accion == 'consultar'">

<div class="container-fluid mt-5" *ngIf="id_ticket == 0 || id_ticket == -1 || !id_ticket">
	<mat-card class="p-5">
		<mat-card-title>Solicitud de validación de pago: {{nombre_ciclo}}</mat-card-title>
		<mat-card-subtitle></mat-card-subtitle>
        
		<mat-card-actions align="start">
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <form novalidate [formGroup]="form1">
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w" >
                            <mat-label>Número del ticket</mat-label>
                            <input readonly="true" type="text" matInput formControlName="numero_ticket" >
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w" >
                            <mat-label>Nivel</mat-label>
                            <mat-select formControlName="nivel" disabled>
                              <mat-option  [value]="1">Preescolar</mat-option>
                              <mat-option  [value]="2">Primaria</mat-option>
                              <mat-option  [value]="3">Secundaria</mat-option>
                              <mat-option  [value]="4">Preparatoria</mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>


                    <div class="col" *ngIf="form1.value.nivel == 1">
                        <mat-form-field appearance="fill"  class="input-full-w" >
                            <mat-label>Preescolar</mat-label>
                            <mat-select formControlName="grado" disabled>
                              <mat-option  [value]="1">1ro</mat-option>
                              <mat-option  [value]="2">2do</mat-option>
                              <mat-option  [value]="3">3ro</mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col" *ngIf="form1.value.nivel == 2">
                        <mat-form-field appearance="fill"  class="input-full-w" >
                            <mat-label>Primaria</mat-label>
                            <mat-select formControlName="grado" disabled>
                              <mat-option  [value]="4">1ro</mat-option>
                              <mat-option  [value]="5">2do</mat-option>
                              <mat-option  [value]="6">3ro</mat-option>
                              <mat-option  [value]="7">4to</mat-option>
                              <mat-option  [value]="8">5to</mat-option>
                              <mat-option  [value]="9">6to</mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col" *ngIf="form1.value.nivel == 3">
                        <mat-form-field appearance="fill"  class="input-full-w" >
                            <mat-label>Secundaria</mat-label>
                            <mat-select formControlName="grado" disabled>
                              <mat-option  [value]="10">1ro</mat-option>
                              <mat-option  [value]="11">2do</mat-option>
                              <mat-option  [value]="12">3ro</mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>


                    <div class="col" *ngIf="form1.value.nivel == 4">
                        <mat-form-field appearance="fill"  class="input-full-w" >
                            <mat-label>Preparatoria</mat-label>
                            <mat-select formControlName="grado" disabled>
                              <mat-option  [value]="13">1ro</mat-option>
                              <mat-option  [value]="14">2do</mat-option>
                              <mat-option  [value]="15">3ro</mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>

                    
    
                    <!--<div class="col">
                        <mat-form-field appearance="fill" class="input-full-w" formControlName="numero_ticket">
                            <mat-label>Razón social</mat-label>
                            <input readonly="true" type="text" matInput >
                        </mat-form-field>
                    </div>-->
                </div>
            </form>

            <form novalidate [formGroup]="form1" (ngSubmit)="postCrearTicketPapa()" >
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-small btn-primary"> 
                        Registrar
                    </button>
                </div>
            </form>
            



        </mat-card-actions>
    </mat-card>
</div>


<div class="container-fluid nt-5" *ngIf="id_ticket > 0">
	<mat-card class="p-5">
		<mat-card-title>Solicitud de validación de pago: {{data_ticket?.numero_ticket}}</mat-card-title>
		<mat-card-subtitle></mat-card-subtitle>
        
		<mat-card-actions align="start">
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <div class="d-flex justify-content-end">
                <div class="alert alert-dark" role="alert" *ngIf="data_ticket?.id_estado_fk == 1"> 
                    Estatus: Borrador
                </div>
                <div class="alert alert-warning" role="alert" *ngIf="data_ticket?.id_estado_fk == 2"> 
                    Estatus: Enviado
                </div>
                <div class="alert alert-success" role="alert" *ngIf="data_ticket?.id_estado_fk == 11"> 
                    Estatus: Concluido
                </div>
               
              
                
            </div>
            <form novalidate [formGroup]="form2" (submit)="postRegistrarConceptoTicketPapa()">
                <div class="row">
                    <div class="col">
                        <span class="fw-bold">
                            Agregue los pagos a validar
                        </span>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill"  class="input-full-w" >
                          <mat-label>Concepto</mat-label>
                          <mat-select formControlName="concepto">
                            <mat-option *ngFor="let c of conceptos" [value]="c.id_concepto_pk">{{c.concepto}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="fill" class="input-full-w" >
                            <mat-label>Monto</mat-label>
                            <input type="text" matInput formControlName="total" >
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <button *ngIf="data_ticket?.id_estado_fk == 1 && accion != 'consultar'" type="submit" class="btn btn-small btn-primary"> 
                            <mat-icon>add_shopping_cart</mat-icon>
                        </button>
                    </div>
    
                    <!--<div class="col">
                        <mat-form-field appearance="fill" class="input-full-w" formControlName="numero_ticket">
                            <mat-label>Razón social</mat-label>
                            <input readonly="true" type="text" matInput >
                        </mat-form-field>
                    </div>-->
                </div>
            </form>

            <div class="row" *ngFor="let a of data_ticket?.conceptos_ticket">
                <div class="col">

                </div>
                <div class="col">
                    <mat-form-field appearance="fill"  class="input-full-w" >
                        <mat-label>Concepto</mat-label>
                        <mat-select [(ngModel)]="a.id_concepto_fk">
                          <mat-option *ngFor="let c of conceptos" [value]="+c.id_concepto_pk">{{c?.concepto}}</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <!--<mat-form-field appearance="fill" class="input-full-w" >
                        <mat-label>Colegiatura</mat-label>
                        <input  type="text" matInput [(ngModel)]="a.concepto?.nombre" >
                    </mat-form-field>-->
                </div>
                <div class="col">
                    <mat-form-field appearance="fill" class="input-full-w" >
                        <mat-label>Fecha</mat-label>
                        <input readonly="true" type="text" matInput [(ngModel)]="a.updated_at.split('T')[0]" >
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="fill" class="input-full-w" >
                        <mat-label>Monto</mat-label>
                        <input  type="text" matInput [(ngModel)]="a.total" >
                    </mat-form-field>
                </div>
                <div class="col">
                    <button *ngIf="data_ticket?.id_estado_fk == 1 && accion != 'consultar'" type="button" class="btn btn-small btn-primary" (click)="postModificarConceptoTicketPapa(a)"> 
                        Modificar
                    </button>
                </div>
                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                    <button *ngIf="data_ticket?.id_estado_fk == 1 && accion != 'consultar'" type="button" class="btn btn-small btn-danger" (click)="postEliminarConceptoTicketPapa(a.id_concepto_soli_pk)"> 
                        x
                    </button>
                </div>
            </div>



            <div class="row mt-5">
                <div class="col">
                    <span class="fw-bold">
                        Adjunte comprobante de pago
                    </span>
                </div>
                <div class="col">
                    <input *ngIf="data_ticket?.id_estado_fk == 1 && accion != 'consultar'" type="file" (change)="onchangeArchivo($event)" class="form-control form-control-sm" >
					
                </div>
                <div class="col">
                    <button *ngIf="data_ticket?.id_estado_fk == 1 && accion != 'consultar'" type="button" class="btn btn-small btn-primary" (click)="postRegistrarModificarFileTicket()"> 
                        Agregar
                    </button>
                </div>
            </div>
            <div class="row mt-2"  *ngFor="let a of data_ticket?.documentos; let i = index">
                
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                    <mat-form-field appearance="fill" class="input-full-w" >
                        <mat-label>Documento {{i}}</mat-label>
                        <input disabled readonly="true" type="text" matInput [value]="a.nombre" >
                    </mat-form-field>
                </div>
                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                    <button *ngIf="data_ticket?.id_estado_fk == 1 && accion != 'consultar'" type="button" class="btn btn-small btn-primary"  (click)="clickChangeFile(a.id_documentos_ticket_pk)">
                        <a target="_blank" rel="noopener">
                            <span class="material-icons  text-white">
                                file_present
                            </span>
                        </a>
                    </button>
                </div>
                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                    &nbsp;
                    <div class="input-group mb-1" style="display: none;">             
                        <input type="file" (change)="postModificar($event, a.id_documentos_ticket_pk)" class="form-control" id="file{{a.id_documentos_ticket_pk}}" type="file" >
                    </div>


                    <button type="button" class="btn btn-small btn-primary">
                        <a href="{{laravelapiStorage+'/'+a?.ruta+'?t='+t}}" target="_blank" rel="noopener">
                            <span class="material-icons  text-white">
                                file_download
                            </span>
                        </a>
                    </button>
                </div>
                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                    <button *ngIf="data_ticket?.id_estado_fk == 1 && accion != 'consultar'" type="button" class="btn btn-small btn-danger" (click)="postEliminarArchivoComprobante(a.id_documentos_ticket_pk)"> 
                        x
                    </button>
                </div>

                <div class="col">

                </div>
                
            </div>


          
            <div class="row alert-warning p-4"  *ngIf="data_ticket?.id_estado_fk == 1 || data_ticket?.id_estado_fk == 2">
                <span>
                    Comentarios: {{data_ticket?.comentarios_rechazo}}
                </span>
            </div>

            <div class="row alert-success p-4"  *ngIf="data_ticket?.id_estado_fk == 11">
                <span>
                    Comentarios: {{data_ticket?.comentarios}}
                </span>
            </div>

            <div class="row mt-5" *ngIf="data_ticket?.id_estado_fk == 2 && accion == 'consultar'">
                <mat-form-field appearance="fill" class="input-full-w" >
                    <mat-label>Introduce un nuevo comentario</mat-label>
                    <textarea  type="text" matInput [(ngModel)]="comentario" ></textarea>
                </mat-form-field>
            </div>

            <div class="mt-5 d-flex justify-content-center gap-2">
                <form *ngIf="data_ticket?.id_estado_fk == 1 && accion != 'consultar'" novalidate [formGroup]="form2" (submit)="postCambiarEstado(2)"> <!--enviado-->
                    <button type="submit" class="btn btn-small btn-primary" >
                        Enviar solicitud
                    </button>
                </form>

                <form *ngIf="data_ticket?.id_estado_fk == 2 && accion == 'consultar'" novalidate [formGroup]="form2" (submit)="postCambiarEstadoAdmin(1)"> 
                    <button type="submit" class="btn btn-small btn-primary" >
                        Rechazar
                    </button>
                </form>
               
            
                <form *ngIf="data_ticket?.id_estado_fk == 2 && accion == 'consultar'" novalidate [formGroup]="form2" (submit)="postCambiarEstadoAdmin(11)"> 
                    <button type="submit" class="btn btn-small btn-primary" >
                        Aprobar
                    </button>
                </form>

                <!--<form  novalidate [formGroup]="form2" (submit)="postCambiarEstadoAdmin(1)"> 
                    <button type="submit" class="btn btn-small btn-primary" >
                        Prueba
                    </button>
                </form>-->
            </div>
            



        </mat-card-actions>
    </mat-card>
</div>
