import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.scss']
})
export class EncuestaComponent implements OnInit {

  token:String;
  constructor(private _router:Router,private route:ActivatedRoute) { }

  form = new FormGroup({
    token:new FormControl("",[Validators.required])
  });
  ngOnInit(): void {
    // This is intentional
  }

  sendForm(){
    console.info(this.token);
    if(this.form.valid){
      this._router.navigate(["/encuesta", this.form.controls["token"].value]);
    }else{

    }
  }

}
