import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { DesitionBoxComponent } from 'src/app/modales/desition-box/desition-box.component';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
import { ControlEscolarService } from 'src/app/services/control-escolar.service';
const ELEMENT_DATA: any[] = [
];
@Component({
  selector: 'app-control-escolar',
  templateUrl: './control-escolar.component.html',
  styleUrls: ['./control-escolar.component.scss']
})
export class ControlEscolarComponent implements OnInit {
  length = 150;
  hayProcesoPendiente = false;
  procesoControlEscolarInicializado = false;
  pageSize = 50;
  pageSizeOptions: number[] = [50, 100, 150];
  id=0;
  // MatPaginator Output
  pageEvent: PageEvent;
  
  isLoading = false;
  displayedColumns: string[] = ['ciclo','fechaInscripcion', 'inscripcion', 'cursando', 'evaluacion', 'cierre'];
  dataSource = ELEMENT_DATA;
  constructor(private activatedRoute:ActivatedRoute,private control_escolar_service:ControlEscolarService, private dialog:MatDialog) { }

  ngOnInit(): void {
    this.cargaInicial();

    console.log("this.hayProcesoPendiente: ", this.hayProcesoPendiente);
    
  }
  cargaInicial(){
    this.activatedRoute.queryParams.subscribe(params => {
      this.id =params['id'];
      // alert(this.id);
      this.control_escolar_service.getCiclosByAlumno(params['id']).subscribe(result=>{
        console.log("getCiclosByAlumno result: ", result);
        if(result.code == 200){
          for(let cont=0; cont<result.datos.ciclos.length;cont++){
            if(result.datos.ciclos[cont].step=="0"){
              this.procesoControlEscolarInicializado = true;
            }
          }
        }else{
          this.procesoControlEscolarInicializado = false;
        }
        this.dataSource = result.datos.ciclos;
        // console.log(this.dataSource);
        if (this.dataSource){
          for(let i=0;i<this.dataSource.length;i++){
            if(this.dataSource[i].step==null || this.dataSource[i].step==0){
              this.hayProcesoPendiente = true;
              return 0;
            }
          }
        }
        this.hayProcesoPendiente = false;
      });
    });
  }
  procesarBaja(idControl:number){
    const dialogRef = this.dialog.open(DesitionBoxComponent, {
      width: '500px',
      data: {title: "Confirmación de baja", text: "¿Está seguro que desea proceder con la baja de este alumno?", icon:"help_outline"},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.info(result)
      if(result){
        this.darBaja(idControl);
      }
    });
    
  }
  darBaja(idControl:number){
    this.control_escolar_service.bajaAlumno({id:idControl,idAlumno:this.id}).subscribe(result=>{

      const dialogRef = this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {title: "Baja Exitosa", text: "El alumno se dio de baja correctamente", icon:"done"},
      });
      dialogRef.afterClosed().subscribe(result=>{
        this.cargaInicial();
      })

    },error=>{

    });
  }

}
