<br><br>
<div class="card-container mt-5">
	<mat-card class="example-card">
		<mat-card-header>
			<mat-card-title>{{accion}} documento médico</mat-card-title>
			<mat-card-subtitle></mat-card-subtitle>
		</mat-card-header>

		<mat-card-content>
			<div class="spinner" *ngIf="isLoading">
				<mat-spinner color="accent"></mat-spinner>
			</div>
			<form novalidate [formGroup]="form">
				<div class="row">
					<mat-form-field appearance="fill" class="input-full-w">
						<mat-label>Nombrel del documento</mat-label>
						<input type="text" matInput maxlength="250" #nombre formControlName="nombre">
						<mat-hint align="end">{{nombre.value.length}} / 250</mat-hint>
						<mat-error *ngIf="form.controls['nombre'].hasError('maxlength')">
							El maximo tamaño es de 250 caracteres
						</mat-error>
					</mat-form-field>
				</div>
				<div class="row">
					<mat-form-field appearance="fill" class="input-full-w">
						<mat-label>Categorías</mat-label>
						<mat-select formControlName="numero_categoria" >
							<mat-option *ngFor="let a of categorias" [value]="a.id_categoria_biblioteca_pk">{{a?.categoria}}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="row">
					<mat-form-field appearance="fill">
						<mat-label>Adjuntar documento médico</mat-label>
						<input matInput readonly matInput placeholder="Adjuntar PDF Médico">
						<button mat-icon-button type="button" (click)="file.click()" matSuffix>
							<mat-icon>attach_file</mat-icon>
						</button>
						<input type="file" #file class="hide"  (change)="onchangeArchivo_org($event)"/>
					</mat-form-field>
				</div>
			</form>

			<div class="mt-4 d-flex justify-content-center">
				<div *ngIf="form.invalid || !file">
					<span class="text-danger">
						Faltan campos por llenar
					</span>
				</div>
			</div>

			<div class="d-flex justify-content-center gap-2">

				<button class="flat" color="primary" mat-flat-button [routerLink]="['/medico/biblioteca-general']" >
					Regresar
				</button>

				<form novalidate [formGroup]="form" *ngIf="accion == 'registrar'" (ngSubmit)="postRegistrarDocGeneral()">
					<button *ngIf="file" class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
						Guardar
					</button>
				</form>
				<form novalidate [formGroup]="form" *ngIf="accion == 'modificar'" (ngSubmit)="postModificarDocGeneral()" >
					<button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
						Modificar
					</button>
				</form>

			</div>
		</mat-card-content>
	</mat-card>
</div>
