import { Component } from '@angular/core';

@Component({
  selector: 'app-evaluacion-psico-pedagogica',
  templateUrl: './evaluacion-psico-pedagogica.component.html',
  styleUrls: ['./evaluacion-psico-pedagogica.component.scss']
})
export class EvaluacionPsicoPedagogicaComponent  {

  
}
