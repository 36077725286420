<div class="container-fluid {{esFicha()  ? 'card-container encuesta':''}}">
  <mat-card class="example-card {{esFicha() ? '':'px-0'}}">
    <div id="htmlData">
      <mat-card-header *ngIf="esFicha()">
        <mat-card-title>Ficha Informativa</mat-card-title>
        <mat-card-subtitle>Información general del alumno</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <div class="container-fluid px-0">
          <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
          <form autocomplete="off" [formGroup]="datosAlumno" class="row">
            <div class="col-lg-2" style="background-image: url({{endpoint}}/php/{{foto}}); height: 250px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;">

            </div>
            <div class="col-lg-10" style="vertical-align:top">
              
              <mat-toolbar class="mb-2" color="primary">
                <mat-icon>badge</mat-icon>
                <span>&nbsp;Datos básicos</span>
              </mat-toolbar>
              
              <div class="row">
                <div class="col-lg-3">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Matrícula</mat-label>
                    <input matInput placeholder="" formControlName="matricula" readonly>
                  </mat-form-field>
                </div>
                <div class="col-lg-9">
                  
                </div>
              </div>
              <div class="row">

                <div class="col-lg-9">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Nombre Completo</mat-label>
                    <input matInput placeholder="" formControlName="nombre" readonly>
                  </mat-form-field>
                </div>
                <div class="col-lg-3">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Genero</mat-label>
                    <input matInput placeholder="" formControlName="genero" readonly>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Fecha de Nacimiento</mat-label>
                    <input matInput placeholder="" formControlName="fecha" readonly>
                  </mat-form-field>
                </div>
                <div class="col-lg-2">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Edad</mat-label>
                    <input matInput placeholder="" formControlName="edad" readonly>
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>CURP</mat-label>
                    <input matInput placeholder="" formControlName="curp" readonly>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Programa</mat-label>
                    <input matInput placeholder="" readonly>
                  </mat-form-field>
                </div>
                <div class="col-lg-3">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Grado</mat-label>
                    <input matInput placeholder="" readonly>
                  </mat-form-field>
                </div>
                <div class="col-lg-3">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Grupo</mat-label>
                    <input matInput placeholder="" readonly>
                  </mat-form-field>
                </div>

              </div>
              <div class="row" *ngIf="observaciones">
                
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Observaciones</mat-label>
                    <input matInput placeholder="" readonly [value]="observaciones">
                  </mat-form-field>
                
              </div>
              
              
              
              
              
              
              <div class="row" *ngIf="contactos?.length>0">
                <div class="container">
                  <mat-toolbar color="primary">
                    <mat-icon>contact_phone</mat-icon>
                    <span>&nbsp;Contáctos</span>
                  </mat-toolbar>
                </div>
                <mat-list class="py-0">
                  <mat-list-item *ngFor="let contacto of contactos; let i = index">
                    <span mat-list-icon>{{i+1}}</span>
                    <div mat-line><span class="fw-bold">({{contacto.descripcion}})</span> {{contacto.nombre + ' '+ contacto.apellidop + ' ' + contacto.apellidom}}</div>
                    <div mat-line *ngIf="contacto.celular.length>0"><mat-icon class="fs-6">phone_iphone</mat-icon>{{contacto.celular}}</div>
                    <div mat-line *ngIf="contacto.trabajo.length>0"><mat-icon class="fs-6">work</mat-icon>{{contacto.trabajo}}</div>
                    <div mat-line *ngIf="contacto.telefono_movil.length>1"><mat-icon class="fs-6">house</mat-icon>{{contacto.telefono_movil}}</div>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </mat-list>
                <div class="container d-none" *ngFor="let contacto of contactos; let i = index">
                  <div class="row">
                    <div class="col-lg-3">
                      <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Parentesco</mat-label>
                        <input matInput placeholder="" readonly [value]="contacto.descripcion">
                      </mat-form-field>
                      
                    </div>
                    <div class="col-lg-9">
                      <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Nombre</mat-label>
                        <input matInput placeholder="" readonly
                          [value]="contacto.nombre + ' '+ contacto.apellidop + ' ' + contacto.apellidom">
                      </mat-form-field>
                    </div>
                    
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Teléfono móvil</mat-label>
                        <input matInput placeholder="" readonly [value]="contacto.celular">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                      <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Teléfono trabajo</mat-label>
                        <input matInput placeholder="" readonly [value]="contacto.trabajo">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                      <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Teléfono casa</mat-label>
                        <input matInput placeholder="" readonly [value]="contacto.telefono_movil">
                      </mat-form-field>
                    </div>
                    
                    
                    
                    <hr *ngIf="i+1<contactos.length">
                  </div>
                </div>
              </div>
              

              
            </div>
          </form>
        </div>

      </mat-card-content>
    </div>
    <mat-card-actions *ngIf="esFicha()">
      <button type="button" mat-flat-button color="primary" (click)="downloadPDF()">Descargar Ficha</button>&nbsp;
      <button mat-stroked-button color="primary" type="submit" [routerLink]="['/control-escolar']">Cancelar</button>
    </mat-card-actions>

  </mat-card>
</div>