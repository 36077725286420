import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AlumnsService } from 'src/app/services/alumns-service';
import { ConfigurationService } from 'src/app/services/configuration.service';
export interface DialogData {
  id: number;
  nombre: string;
}
const ELEMENT_DATA: any[] = [
];
@Component({
  selector: 'app-lupa',
  templateUrl: './lupa.component.html',
  styleUrls: ['./lupa.component.scss']
})
export class LupaComponent implements OnInit {

  constructor(private _alumn:AlumnsService,
    public dialogRef: MatDialogRef<LupaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private config:ConfigurationService) { 
      dialogRef.disableClose = true;
    }

  
  length = 0;
  pageSize = this.config.getConfiguration().paginator.defaultPageSize;
  pageSizeOptions: number[] = this.config.getConfiguration().paginator.pageSizeOptions;

  // MatPaginator Output
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  isLoading = false;
  displayedColumns: string[] = ['matricula','nombre', 'estatus', 'nivel', 'grado','seleccionar'];
  dataSource = ELEMENT_DATA;

  formulario = new FormGroup({
    busqueda : new FormControl("",[]),
    //nivel: new FormControl("",[])
  });
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto');

  ngOnInit(): void {
    this.buscar();
  }

  buscar(){
    this.filtro(0,this.pageSize);
  }

  filtro(index:number, pageSize:number){
 //   console.info(this.formulario.controls["busqueda"].value);
    this.isLoading = true;
    this._alumn.findAlumnFiltro(this.formulario.controls["busqueda"].value,index,pageSize).subscribe(result2=>{
      console.log("esto es la primera",result2);
      this.isLoading = false;
      if(result2.code==200){
        console.info(result2);
        if(index==0){
          this.paginator.pageIndex = 0;
        }
        this.length = result2.size;
        this.dataSource = result2.datos.alumnos;
      }else{
        this.length = 0;
        this.dataSource = [];
      }
    },error=>{
      this.length = 0;
      this.isLoading = true;
      console.info(error);
      this.dataSource = [];
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  pageEvent(event){
    console.info(event);
    this.filtro(event.pageSize * event.pageIndex,event.pageSize);
  }
}
