import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ciclo-escolar',
  templateUrl: './ciclo-escolar.component.html',
  styleUrls: ['./ciclo-escolar.component.scss']
})
export class CicloEscolarComponent  {

 

}
