import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/pages/encuesta/contestar/contestar.component';

@Component({
  selector: 'app-desition-box',
  templateUrl: './desition-box.component.html',
  styleUrls: ['./desition-box.component.scss']
})
export class DesitionBoxComponent {

  constructor(
    public dialogRef: MatDialogRef<DesitionBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
