import { Estados } from './estados';
import { NivelEducativo } from './nivel_educativo';
import { Grado } from './grado';
import { EstadoCivil } from './estado_civil';
import { Ocupaciones } from './ocupaciones';
import { Seguro } from './seguro';
import { GrupoSanguineo } from './grupo_sanguineo';
import { Familiar } from './familiar';
import { Credencial } from './credencial';

export class Datos{
    private estado: Estados[];
    private nivel: NivelEducativo[];
    private grado: Grado[];
    private edoCivil: EstadoCivil[];
    private ocupacion: Ocupaciones[];
    private seguro: Seguro[];
    private grupo_sanguineo: GrupoSanguineo[];
    private familiar: Familiar[];
    private credencial: Credencial[];

    getEstados():Estados[]{
        return this.estado;
    }
    setEstados(estado:Estados[]):void{
        this.estado = estado;
    }
    getNivel():NivelEducativo[]{
        return this.nivel;
    }
    setNivel(nivel:NivelEducativo[]):void{
        this.nivel = nivel;
    }
    getGrado():Grado[]{
        return this.grado;
    }
    setGrado(grado:Grado[]):void{
        this.grado = grado;
    }
    getEdoCivil():EstadoCivil[]{
        return this.edoCivil;
    }
    setEdoCivil(edoCivil:EstadoCivil[]):void{
        this.edoCivil = edoCivil;
    }
    getOcupacion():Ocupaciones[]{
        return this.ocupacion;
    }
    setOcupacion(ocupacion:Ocupaciones[]):void{
        this.ocupacion = ocupacion;
    }
    getSeguro():Seguro[]{
        return this.seguro;
    }
    setSeguro(seguro:Seguro[]):void{
        this.seguro = seguro;
    }
    getGrupoS():GrupoSanguineo[]{
        return this.grupo_sanguineo;
    }
    setGrupoS(grupo_sanguineo:GrupoSanguineo[]):void{
        this.grupo_sanguineo = grupo_sanguineo;
    }
    getFamiliar():Familiar[]{
        return this.familiar;
    }
    setFamiliar(familiar:Familiar[]):void{
        this.familiar = familiar;
    }
    getCredencial():Credencial[]{
        return this.credencial;
    }
    setCredencial(credencial:Credencial[]):void{
        this.credencial = credencial;
    }

}