<div class="card-container encuesta" >
    <mat-card class="example-card">
        <div id="htmlData">
            <mat-card-header>
                <mat-card-title>Gestor de evaluaciones</mat-card-title>
                <mat-card-subtitle>Evaluaciones autorizadas</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                
            

                <table mat-table matTableExporter [dataSource]="dataSource" class="mat-elevation-z0" #exporter="matTableExporter">
                    <!--<caption *ngIf="!dataSource">No hay estudios socioeconómicos pendientes</caption>-->
                    <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->
                    
                    <!-- Position Column -->
                    <caption></caption>
                    <ng-container matColumnDef="matricula">
                        <th mat-header-cell *matHeaderCellDef scope="col"> MATRICULA </th>
                        <td mat-cell *matCellDef="let element"> 
                            <!--00{{element.id_alumno_fk}}-->
                            {{getMatricula(element.id_alumno_fk)}}
                            <!--{{'0000'.substring(0, '0000'.length - +element.id_alumno_fk.length) + element.id_alumno_fk}}-->
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="nombre">
                        <th mat-header-cell *matHeaderCellDef scope="col"> NOMBRE </th>
                        <td mat-cell *matCellDef="let element"> {{element?.alumno?.apellidop}} {{element?.alumno?.apellidom}} {{element?.alumno?.nombre}}</td>
                    </ng-container>
                    
                    <ng-container matColumnDef="eval_academica">
                        <th mat-header-cell *matHeaderCellDef scope="col"> EVALUACIÓN ACADÉMICA </th>
                        <td mat-cell *matCellDef="let element"> 
                            <div *ngIf="element.evaluacion_academica; else elseEA">
                                {{element?.evaluacion_academica}}
                            </div>
                            <ng-template #elseEA>
                                -
                            </ng-template>
                           

                        </td>
                    </ng-container>

                    <ng-container matColumnDef="eval_psico">
                        <th mat-header-cell *matHeaderCellDef scope="col"> EVALUACIÓN FAMILIAR </th>
                        <td mat-cell *matCellDef="let element"> 
                            <div *ngIf="element.evaluacion_psicometrica; else elseEP">
                                {{element?.evaluacion_psicometrica}}
                            </div>
                            <ng-template #elseEP>
                                -
                            </ng-template>
                            


                        </td>
                    </ng-container>

                    <ng-container matColumnDef="sugerencia">
                        <th mat-header-cell *matHeaderCellDef scope="col"> SUGERENCIA </th>
                        <td mat-cell *matCellDef="let element"> {{element?.sugerencia}}</td>
                    </ng-container>
                    
                    <ng-container matColumnDef="decision">
                        <th mat-header-cell *matHeaderCellDef scope="col"> DECISIÓN </th>
                        <td mat-cell *matCellDef="let element"> {{element?.decision}}</td>
                    </ng-container>

                    <ng-container matColumnDef="cancelar">
                        <th scope="col" mat-header-cell *matHeaderCellDef> CANCELAR </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button color="primary" (click)="getEvaluacionesMod(element.id_resultado_evaluaciones_pk,0)">
                                <mat-icon>approval</mat-icon>
                            </button>
                        </td>
                    </ng-container>
    
                    <!-- Symbol Column -->
                    <tr mat-header-row *matHeaderRowDef="gdhei_displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: gdhei_displayedColumns;"></tr>
                </table>
                
                
            </mat-card-content>
            <mat-card-actions>
                <mat-paginator [length]="gdhei_length"
                   [pageSize]="gdhei_pageSize"
                   [pageSizeOptions]="gdhei_pageSizeOptions"
                   (page)="pageEvent($event)">
                </mat-paginator>
            </mat-card-actions>
        </div>
    </mat-card>
</div>