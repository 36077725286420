//General
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { ValidacionPagosService } from 'src/app/services/validacion-pagos.service';
import { ControlEscolarService } from 'src/app/services/control-escolar.service';
//Tabla
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//Fin Tabla
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios
import { ReciboComponent } from 'src/app/modales/recibo/recibo.component';
import {animate, state, style, transition, trigger} from '@angular/animations';


@Component({
  selector: 'app-ttr-gt-validacion-pagos',
  templateUrl: './ttr-gt-validacion-pagos.component.html',
  styleUrls: ['./ttr-gt-validacion-pagos.component.scss'],
  providers: [ ValidacionPagosService, ControlEscolarService ]
})
export class TtrGtValidacionPagosComponent implements OnInit {

  //public idAlumno: number = +localStorage.getItem('alumno');
  //public idCiclo: number = 3;

  /*tabla*/
  public length: number = 100;;
  public pageSize: number = 10;
  public pageSizeOptions: Array<number> = [5, 10, 25, 100];
  public page: number = 1;
  public displayedColumns: any =[
    'id', 
    'nombre',
    'gradoynivel',
    //'grupo',
    'f_s',
    //'t_p',
    'estado',
    'v_solicitud'
    //'eliminar'
  ];
  public dataSource: any;
  /*fin tabla*/
  public isLoading: boolean = false;
  public laravelapiStorage: any;
  public t: any;


  @Input() id_alumno: number = +localStorage.getItem('alumno');
  @Input() id_ciclo: number = 3;

  public id_alumno_pro: number = -1;
  public id_ciclo_pro: number = -1;
  public nombre_ciclo: string = '';
  public nivel: string = '';
  public grado: string = '';

  public ciclos: any = [];

  public expandedElement: any;
  public isExpansionDetailRow: any;


  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _ValidacionPagosService: ValidacionPagosService,
    private _ControlEscolarService: ControlEscolarService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdRef:ChangeDetectorRef,

    private dialog:MatDialog,
  ){ 
    this.getConsultarCiclos();
    this.postConsultaTicketSolicitudesPapaAlumnos(1);
    
    //this.laravelapiStorage = this._ValidacionPagosService.getLaravelApiStorageTutores();
  }

  ngOnInit(): void {
    console.log(localStorage.getItem('alumno')  );
  }

  ngDoCheck(){
    console.log('aqui');
    for(let i = 0; i < this.ciclos.length; i++){
      if(this.ciclos[i].ciclo.id_ciclo_pk == this.id_ciclo){
        this.nombre_ciclo = this.ciclos[i].ciclo.nombre;
        this.nivel = this.ciclos[i].id_nivel_fk;
        this.grado = this.ciclos[i].id_grado_fk;
      }
    }
  }

  public postConsultaTicketSolicitudesPapaAlumnos(page: number){
    this.page = page;
    this.isLoading = true;
    this._ValidacionPagosService.postConsultaTicketSolicitudesPapaAlumnos(this.page, this.pageSize, this.id_alumno_pro, this.id_ciclo_pro).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.length = response.data.total;
          this.dataSource = response?.data?.data;
        }
      },
      (error: any) => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public getConsultarCiclos(){
    this.isLoading = true;
    this._ControlEscolarService.getConsultarCiclos().subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          //this.ciclos = response.data;
          this.postConsultaInformacionAlumno(this.id_alumno_pro);
        }
      },
      (error: any) => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public postConsultaInformacionAlumno(id: number){
    this.isLoading = true;
    this._ValidacionPagosService.postConsultaInformacionAlumno({alumno: id}).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.ciclos = response.data.control_escolar;
          console.log(this.ciclos);
        }
      },
      (error: any) => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  /*public postEliminarDocumentosOrg(id: number){
    this.isLoading = true;
    this._ControlEscolarService.postEliminarDocumentosOrg({numero_documento: id}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.postConsultaTicketSolicitudesPapaAlumnos();
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }*/

  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    

    this.id_alumno = +localStorage.getItem('alumno');

    if(this.id_alumno != this.id_alumno_pro || this.id_ciclo != this.id_ciclo_pro){
      console.log('cambios');
      this.id_alumno_pro = this.id_alumno;
      this.id_ciclo_pro = this.id_ciclo;
      this.postConsultaTicketSolicitudesPapaAlumnos(1);

    }


  }

  public pageEvent(event: any){
    console.info(event);
    this.pageSize = event.pageSize;
    this.postConsultaTicketSolicitudesPapaAlumnos(event.pageIndex + 1);
  }

  public consultandoTicket(data: any){
      
        
          
          const dialogRef = this.dialog.open(ReciboComponent, {
            width: '30em',
            data: data
          });

          dialogRef.afterClosed().subscribe(result => {
            // This is intentional
          });
        
      
    
  }

}
