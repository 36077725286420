import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment'; 
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class EncuestaService {
  private base_url = environment.base_url;
  private laravelapi = "";
  public endpoint_2 = '';
  endpoint ="";
  constructor(private _http:HttpClient, private _config:ConfigurationService) { 
    this.endpoint = _config.getConfiguration().hostapi;
    this.laravelapi = this._config.getConfiguration().laravelapi;
    this.endpoint_2 = _config.getConfiguration().laravelapi;
  }

  public getEndPoint2(){
    return this.endpoint_2;
  }

  getEncuestaById(id:string):Observable<any>{
    return this._http.get(this.endpoint+"/php/getEncuesta.php?id="+id);
    // return this._http.get("http://localhost/ng-san-juan-pablo/php/getEncuesta.php?id="+id);
  }
  getReportEncuesta(id:string,idAplicacion:string):Observable<any>{
    return this._http.get(this.endpoint+"/php/getEncuesta.php?id="+id+"&aplicacion="+idAplicacion);
  }

  getEncuesta(token:string):Observable<any>{
    return this._http.get(this.endpoint+"/php/getPreguntas.php?token="+token);
    // return this._http.get("http://localhost/ng-san-juan-pablo/php/getPreguntas.php?token="+token);
  }
  saveEncuesta(json:any):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  let options = { headers: headers };
    return this._http.post(this.endpoint+"/php/encuestas-service.php?opc=saveResults",json,options);
    // return this._http.post("http://localhost/ng-san-juan-pablo/php/encuestas-service.php?opc=saveResults",json,options);
  }

  public postExcelReporteEncuestas(data: any){    
    return this._http.post(this.endpoint_2+'ExcelReporteEncuestas', data);
  }


}
