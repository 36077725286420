<br><br>
<div class="card-container encuesta">
    <mat-card class="example-card">
        <!--<mat-card-header>
            <mat-card-title>Gestor de ciclos de encuestas</mat-card-title>
            <mat-card-subtitle>Gestión y control de aplicaciones de encuestas</mat-card-subtitle>
        </mat-card-header>-->
        <mat-card-content>
            <div class="container">
                <div class="row">
                    <div class="col"></div>
                    <div class="col-8">
                        <div class="row row-cols-1 row-cols-md-3 g4">
                            <div class="col mb-4" *ngFor="let item of menu">
                                <div class="card rounded-0" [routerLink]="[item.href]">                            
                                    <img class="card-img-top rounded-0" style="background-image: url(/assets/img/{{item.icon}});" src="/assets/img/transparent.png" alt="{{item.text}}">
                                    <div class="card-body">
                                        <h3 class="card-title">{{item.text}}</h3>
                                        <!--<p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>-->
                                    </div>
                                </div>                          
                            </div>
                        </div>
                    </div>
                    <div class="col"></div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
