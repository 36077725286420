//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { BibliotecaGeneralService } from 'src/app/services/biblioteca-general.service';
//Tabla
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//Fin Tabla
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios

@Component({
  selector: 'app-ttr-biblioteca',
  templateUrl: './ttr-biblioteca.component.html',
  styleUrls: ['./ttr-biblioteca.component.scss'],
  providers: [ BibliotecaGeneralService ]
})
export class TtrBibliotecaComponent implements OnInit {

  /*tabla*/
  public length: number = 100;;
  public pageSize: number = 100;
  public pageSizeOptions: Array<number> = [5, 10, 25, 100];
  public page: number = 1;
  public displayedColumns: any =[
    'id', 
    'n_d',
    'categoria',
    'consultar',
    'modificar',
    //'eliminar'
  ];
  public dataSource: any;
  /*fin tabla*/
  public isLoading: boolean = false;
  public laravelapiStorage: any = this._BibliotecaGeneralService.getLaravelApiStorageTutores();;
  public t: any;
  public all: any = [];

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _BibliotecaGeneralService: BibliotecaGeneralService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdRef:ChangeDetectorRef,
  ){ 
    this.getConsultarCategoriasBibliotecaTodas();
  }




  ngOnInit(): void {
  }

  public getConsultarCategoriasBibliotecaTodas(){
    this.isLoading = true;
    this._BibliotecaGeneralService.getConsultarCategoriasBibliotecaTodas().subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.all = response?.data;
        }
      },
      error => {console.log(<any>error);
        this.isLoading = false;
      });
  }

 

  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    
  }


}
