import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import $ from "jquery";
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
import { Alumnos } from 'src/app/model/alumnos';
import { Datos } from 'src/app/model/datos';
import { AlumnsService } from 'src/app/services/alumns-service';
import { ControlEscolarService } from 'src/app/services/control-escolar.service';
import { EstudioSocioeconomicoService } from 'src/app/services/estudio-socioeconomico.service';
import { EvaluacionesService } from 'src/app/services/evaluaciones.service';
import { ParametrosService } from 'src/app/services/parametros.service';


@Component({
  selector: 'app-inscripcion',
  templateUrl: './inscripcion.component.html',
  styleUrls: ['./inscripcion.component.scss']
})
export class InscripcionComponent implements OnInit {
  hayPago=false;
  alumno: Alumnos;
  isLoading = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isEditable = false;
  ciclos = [];
  niveles = [];
  oferta=[];
  grados = [];
  idAlumno: number;
  idProceso: number = null;
  step = 0;
  public general_alumno: any;
  public url: any;
  
  constructor(
    private router:Router,
    private dialog: MatDialog,
    private _alumnosService:AlumnsService,
    private _estudioSocioEconomicoService: EstudioSocioeconomicoService,
    private _evaluacionesService: EvaluacionesService,
    private control_escolar_service: ControlEscolarService,
    private _formBuilder: FormBuilder,
    private parametrosService: ParametrosService,
    private _activatedRoute: ActivatedRoute) {

    this.url =  this.control_escolar_service.getEndPoint2();


    this.firstFormGroup = this._formBuilder.group({
      alumno: ['', []],
      ciclo: ['', Validators.required],
      nivel: ['', Validators.required],
      grado: ['', Validators.required],
      observaciones: ['', []],
      requiere_estudio_socioeconomico: ['true',[]]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this._activatedRoute.queryParams.subscribe(params => {
      console.log("params: ", params);
      this.idAlumno = params.id; // es 61
      this.firstFormGroup.controls["alumno"].setValue(params.id);
      this.idProceso = params.key; //1 1 2
      if (this.idProceso != undefined) {
        let arreglo = this.idProceso.toString().split("-");
        this.isLoading = true;
        this.control_escolar_service.findByKey(this.idAlumno, parseInt(arreglo[0]), parseInt(arreglo[1]), parseInt(arreglo[2])).subscribe(result => {
          console.log("result findByKey: ", result);
          this.general_alumno = result.datos;
          this.step=this.convertidorNull(result.datos.step);
          let proceso = result.datos;
          this.firstFormGroup.controls["ciclo"].setValue(proceso.id_ciclo_fk);
          this.firstFormGroup.controls["nivel"].setValue(proceso.id_nivel_fk);
          this.firstFormGroup.controls["nivel"].disable();
          this.firstFormGroup.controls["ciclo"].disable();
          this.firstFormGroup.controls["grado"].setValue(proceso.id_grado_fk);
          this.firstFormGroup.controls["observaciones"].setValue(proceso.observaciones);
          //this.firstFormGroup.controls["requiere_estudio_socioeconomico"].setValue(Boolean(Number(parseInt(proceso.requiere_estudio_socioeconomico))));
          this.isLoading = false;
          console.log("this.firstFormGroup.controls: ", this.firstFormGroup.controls);
          this.cambiaNivel();

          //this.validarRequisitos();

        }, () => {
          this.isLoading = false;
        });

      }
      this.parametrosService.findAll("ciclos").subscribe(result => {
        console.log('esto swon los cilo' + result.datos.ciclos);
        this.ciclos = result.datos.ciclos
      });
      this.parametrosService.findAll("niveles").subscribe(result => {
        this.niveles = result.datos.niveles
      });
      this.parametrosService.findAll("ofertaEducativa").subscribe(result => {
        console.log('esta es la oferta educativa ' + result.datos.oferta);
        this.oferta = result.datos.oferta
      });
    });
  }
  ngOnInit() {
    console.info("Iniciando componente inscripcion");

  }


  setAlumno(alumno) {
    console.log("setAlumno alumno: ", alumno);
    this.alumno = alumno;
    console.log("setAlumno this.alumno: ", this.alumno);
  };
  traduceEstatus(id) {
    return this._estudioSocioEconomicoService.traduceEstatus(id);
  }
  estudioSocioEconomico = [];
  porcentajeBeca = 0;
  estudioSocioEconomicoEstatus = 0;
  pasoestudiosocioeconomico = false;
  cartaDeNoAdeudo = true
  reqEstudioSocEstatus = 0;
  evaluacion: any = { estatus: 0, resultado: "" };
  porPagar: any = [];
  cambiaNivel() {
    //this.firstFormGroup.controls["grado"].setValue(null);
    this.isLoading = true;
    this.parametrosService.findAllDinamic("grados", this.firstFormGroup.controls["nivel"].value).subscribe(result => {
      this.grados = result.datos.grados;
      this.isLoading = false;
    });
  }
  validarOfertaEducativa(ciclo,nivel,grado){
    let respuesta=false;
    this.oferta.forEach(element => {
      if(element.id_ciclo_fk==ciclo && element.id_nivel_fk == nivel && element.id_grado_fk==grado){
        respuesta =true;
        return true
      }
    });
    return respuesta;
  }
  iniciarProceso() {

    if (this.firstFormGroup.valid) {
      if(this.validarOfertaEducativa(this.firstFormGroup.controls["ciclo"].value,
      this.firstFormGroup.controls["nivel"].value,
      this.firstFormGroup.controls["grado"].value)){
        this.isLoading = true;
        console.log("this.firstFormGroup.getRawValue: ", this.firstFormGroup.getRawValue());
        // return;
        this.reqEstudioSocEstatus = this.firstFormGroup.value.requiere_estudio_socioeconomico;
        console.log("this.reqEstudioSocEstatus: ", this.reqEstudioSocEstatus);
        if (this.idProceso != null) {
          // let arreglo = this.idProceso.toString().split("-");
          // console.log("areglo: ", arreglo);
          // return;
          this.control_escolar_service.modificarInscripcion(this.firstFormGroup.getRawValue()).subscribe(result => {
            this.isLoading = false;
            if (result.code == 200) {

              document.getElementById("siguiente1").click();

            }
          });
        } else {
          this.control_escolar_service.iniciarInscripcion(this.firstFormGroup.getRawValue()).subscribe(result => {
            console.log("iniciarInscripcion result: ",result);
            if (result.code == 200) {

              this.isLoading = false;
              this.idProceso = result.datos[0].datos;
              document.getElementById("siguiente1").click();


            }else if(result.code == 406){
              console.log("El alumno ya cuenta con una inscripcion en ese ciclo escolar");
              this.dialog.open(MessageBoxComponent, { disableClose: true, data: { title: "El alumno ya cuenta con registro", text: result.message, icon: "error_outline", href: "/control-escolar", parametros: { } } });
            }else{
              console.log("Ocurrio un problema");
              this.dialog.open(MessageBoxComponent, { disableClose: true, data: { title: "Error en el servicio", text: "Ocurrio un problema en el servicio, intentalo nuevamente", icon: "error_outline", href: "/control-escolar", parametros: { } } });
            }
          });
        }
      }else{
        this.dialog.open(MessageBoxComponent, { width:'40vw',disableClose: true, data: { title: "Atención", text: "El nivel <b>"+this.getGrado(this.firstFormGroup.controls["grado"].value) +" de "+this.getNivel(this.firstFormGroup.controls["nivel"].value)+"</b> no está dentro de la oferta educativa para el ciclo escolar <b>"+this.getCiclo(this.firstFormGroup.controls["ciclo"].value)+"</b>. Si crees que esto es un error, solicita el alta con el administrador del sistema.", icon: "notification_important", parametros: { } } });
      }
    }

  }
  getNivel(nivel){
    let descripcion="";
    this.niveles.forEach(element=>{
      if(element.id_nivel_pk==nivel){
        descripcion= element.descripcion;
        return true;
      }
    })
    return descripcion;
  }
  getGrado(grado){
    let descripcion="";
    this.grados.forEach(element=>{
      if(element.id_grado_pk==grado){
        descripcion= element.descripcion;
        return true;
      }
    })
    return descripcion;
  }
  getCiclo(ciclo){
    let descripcion="";
    this.ciclos.forEach(element=>{
      if(element.id_ciclo_pk==ciclo){
        descripcion= element.nombre;
        return true;
      }
    })
    return descripcion;
  }

  validarRequisitos() {
    if (this.idProceso) {
      this.iniciarProceso();
      this.isLoading = true;
      let arreglo = this.idProceso.toString().split("-");
      this.cartaDeNoAdeudo = true;
      this.pasoestudiosocioeconomico = false;
      this.control_escolar_service.getDeudas(this.idAlumno, this.firstFormGroup.controls["ciclo"].value, this.firstFormGroup.controls["nivel"].value, this.firstFormGroup.controls["grado"].value).subscribe(result => {
        this.responseGetDeudas(result);
        console.log("this.porPagar: ", this.porPagar);
        for(let i = 0; i < this.porPagar.length; i++){
          if(this.porPagar[i].id_c_p_c_fk == null){
            this.porPagar[i].condonacion_val = false;
            this.porPagar[i].pago_pendiente = (this.porPagar[i].precio_unitario) - (this.porPagar[i].pagado) - (this.porPagar[i].descuento) + (this.porPagar[i].recargos*(this.porPagar[i].precio_unitario-this.porPagar[i].descuento));
          }else{
            this.porPagar[i].condonacion_val = true;
            this.porPagar[i].pago_pendiente = (this.porPagar[i].precio_unitario) - (this.porPagar[i].pagado) - (this.porPagar[i].descuento);
            
          }
        }

        this._estudioSocioEconomicoService.findByKey(this.idAlumno, this.firstFormGroup.controls["ciclo"].value, this.firstFormGroup.controls["nivel"].value, this.firstFormGroup.controls["grado"].value).subscribe(result => {
          this.estudioSocioEconomico = result.datos;
          console.info("inmformacion del result", result);


          this.porcentajeBeca =this.convertidorNull(result.datos.beca);

          this.estudioSocioEconomicoEstatus = result.datos.estatus;
          

          this._evaluacionesService.findByKey(this.idAlumno, this.firstFormGroup.controls["ciclo"].value, this.firstFormGroup.controls["nivel"].value, this.firstFormGroup.controls["grado"].value).subscribe(result => {
            this.isLoading = false;
            console.log("_evaluacionesService.findByKey result: ", result);
            this.evaluacion.estatus = result.datos.estatus;
            this.evaluacion.evaluacion_academica = result.datos.evaluacion_academica;
            this.evaluacion.evaluacion_psicometrica = result.datos.evaluacion_psicometrica;
            this.evaluacion.decision = result.datos.decision;
            console.log("result.datos: ", result.datos);
            console.log("this.firstFormGroup.controls: ", this.firstFormGroup.controls);
            console.log("this.evaluacion: ", this.evaluacion);
            
            
            let pasoevaluacion = this.verificarEvaluacion();
            
            this.conditionPasoEstudioSocioE();
            console.log("pasoestudiosocioeconomico: ", this.pasoestudiosocioeconomico);
            console.log("this.firstFormGroup.controls sal: ", this.firstFormGroup.controls);
            console.log("this.evaluacion sal: ", this.evaluacion);

            this.cartaDeNoAdeudo = this.emitirCartaDeNoAdeudo();
            console.log(this.cartaDeNoAdeudo);
            this.continuarProceso(pasoevaluacion, this.cartaDeNoAdeudo, this.pasoestudiosocioeconomico);
          });
        });
      });




    }
  }

  responseGetDeudas(result: any){
    if (result.code == 200) {
      this.porPagar = result.datos.porpagar;
    } else {
      this.porPagar = [];
      this.cartaDeNoAdeudo = false;
    }
    this.porPagar.forEach(element => {
      if(element.pago_pendiente>0==false){
        this.hayPago=true;
      }
    });
  }

  conditionPasoEstudioSocioE(){
    if(this.reqEstudioSocEstatus == 0){
      this.pasoestudiosocioeconomico = true;
    }else{
      this.pasoestudiosocioeconomico = this.estudioSocioEconomicoEstatus != 3 ? false : true;
    }
  }

  verificarEvaluacion() {
    if (this.firstFormGroup.controls["nivel"].value == 1) {
      return true;
    }
    return this.evaluacion?.estatus == 0 || this.evaluacion?.decision == 'No Aceptado' ? false : true;
  }
  public pagarUno: any = [];
  emitirCartaDeNoAdeudo() {
    /*for (let i = 0; i < this.porPagar.length; i++) {
      if (this.porPagar[i].pago_pendiente > 0 && this.porPagar[i].vencido == 1) {
        return false;
      } //antes
    }*/

    const currentMonth = new Date().getMonth() + 1;  // getMonth() devuelve un valor entre 0 y 11, así que se suma 1 para que sea entre 1 y 12
    console.log(currentMonth);
    this.pagarUno = this.porPagar.filter(date => new Date(date.fecha_limite).getMonth() + 1 === currentMonth);
    console.log(this.pagarUno);
    if (this.pagarUno[0]?.pago_pendiente > 0 && this.pagarUno[0]?.vencido == 1) {
      return false;
    }

    console.log("FOR this.porPagar: ", this.porPagar);
    return true;
  }
  continuarProceso(pasoevaluacion, cartaDeNoAdeudo, pasoestudiosocioeconomico) {

    if (pasoevaluacion && cartaDeNoAdeudo && pasoestudiosocioeconomico) {
      this.secondFormGroup.controls['secondCtrl'].setValue("TRUE");
    } else {
      this.secondFormGroup.controls['secondCtrl'].setValue(null);
    }
  }
  triggerClick(e) {
    if (e.selectedIndex == 1) {
      this.validarRequisitos();
    }
  }


  descargarFicha() {
    console.log("descargarFicha this.alumno: ", this.alumno);
    this.modalTitle = "Ficha de inscripción " + this.alumno.nombre + " " + this.alumno.apellidop + " " + this.alumno.apellidom;
    let ruta = "/php/pdf-generator/index.php?dt=" + new Date().getTime() + "&doc=1" + this.getFecha()+"&idAlumno="+this.alumno.id_alumno_pk;
    // let ruta = "http://localhost/ng-san-juan-pablo/php/pdf-generator/index.php?dt=" + new Date().getTime() + "&doc=1" + this.getFecha()+"&idAlumno="+this.alumno.id_alumno_pk;
    console.log("ruta: ", ruta);
    this.abrirPdf(ruta);
  }
  descargarReglamento() {
    this.modalTitle = "Reglamento " + this.alumno.nombre + " " + this.alumno.apellidop + " " + this.alumno.apellidom;
    let ruta = "/php/pdf-generator/index.php?dt=" + new Date().getTime() + "&doc=2" + this.getFecha();
    // let ruta = "http://localhost/ng-san-juan-pablo/php/pdf-generator/index.php?dt=" + new Date().getTime() + "&doc=2" + this.getFecha();
    this.abrirPdf(ruta);
  }
  abrirPdf(ruta: string) {
    console.info(ruta);
    document.getElementById("lauchModal").click();
    let html = '<embed id="viewer" src="/php/pdf-generator/index.php' + ruta + '" type="application/pdf" width="100%" style="height: calc(100% - 1rem);"/>';
    $("#mypdf").html(html);
  }
  cerrarDialog() {
    $("#viewer").remove();
  }
  pdf = "";
  concluirProceso() {
    console.log()
    this.isLoading = true;
    let arreglo = this.idProceso.toString().split("-");

    arreglo[0]=this.firstFormGroup.controls["ciclo"].value;
    if(arreglo[1]==undefined){arreglo[1]=this.firstFormGroup.controls["nivel"].value}
    if(arreglo[2]==undefined){arreglo[2]=this.firstFormGroup.controls["grado"].value}

    this.control_escolar_service.concluirIncripcion({ alumno: this.idAlumno, ciclo: parseInt(arreglo[0]), nivel: parseInt(arreglo[1]), grado: parseInt(arreglo[2]) }).subscribe(result => {
      this.isLoading = false;
      console.info(result);
      if (result.code == 200) {
        this.dialog.open(MessageBoxComponent, { disableClose: true, data: { title: "Inscripción concluida", text: "¡Felicidades! el proceso de inscripción ha finalizado con éxito", icon: "done", parametros: { id: this.idAlumno } } });
        this.router.navigate(['/control-escolar/control'],{queryParams:{id:this.idAlumno}});
      }
    });
  }
  getFecha() {
    let fecha = new Date();

    let ruta = "&dia=" + fecha.getDate() + "&mes=" + this.meses[fecha.getMonth()] + "&anio=" + fecha.getFullYear();
    return ruta;

  }

  getFechaLarv() {
    let fecha = new Date();

    let ruta = "&day=" + fecha.getDate() + "&month=" + this.meses[fecha.getMonth()] + "&year=" + fecha.getFullYear();
    return ruta;

  }

  convertidorNull(datos:any){
    var conversion:any;
    if(datos==null || datos==0){
      conversion=0;
    }else{
      conversion=datos;
    }
    return conversion;
  }
  modalTitle = "Visor de pdf";
  ruta = "";
  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
}
