<div class="card-container encuesta" >
    <mat-card class="example-card">
        <div id="htmlData">
            <mat-card-header>
                <mat-card-title>Estudio Socioeconómico</mat-card-title>
                <mat-card-subtitle>Bandeja de entrada de estudio socioeconómico</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                
                <form autocomplete="no" [formGroup]="formulario" (ngSubmit)="buscador(0)">
                    <div class="row">
                        <div class="col-lg-4">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Buscar</mat-label>
                                <input matInput placeholder="Nombre del alumno" formControlName="nombre" autocomplete="off" #buscar>
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Nivel</mat-label>
                                <mat-select formControlName="nivel" (selectionChange)="getGrados($event.value)">
                                    <mat-option value="">-- Ninguno -- </mat-option>
                                    <mat-option value="{{nivel.id_nivel_pk}}" *ngFor="let nivel of niveles">{{nivel.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Grado</mat-label>
                                <mat-select formControlName="grado">
                                    <mat-option value="">-- Ninguno --</mat-option>
                                    <mat-option value="{{grupo.id_grado_pk}}" *ngFor="let grupo of grupos">{{grupo.descripcion}}</mat-option>
                                    
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-auto col-md-auto col-sm-auto">
                            <button mat-mini-fab color="accent" (click)="print(buscar.value)"><mat-icon>search</mat-icon></button>&nbsp;&nbsp;
                            <button type="button" mat-flat-button color="primary">Reporte Excel</button>
                        </div>
                        
                        
                        &nbsp;
                        &nbsp;&nbsp;
                    </div>
                    
                </form>

                <table mat-table matTableExporter [dataSource]="dataSource" class="mat-elevation-z0" #exporter="matTableExporter" hiddenColumns="[6][7][8]">
                    <caption *ngIf="!dataSource">No hay estudios socioeconómicos pendientes</caption>
                    <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->
                    
                    <!-- Position Column -->
                    <ng-container matColumnDef="matricula">
                        <th mat-header-cell *matHeaderCellDef scope="col"> Matricula </th>
                        <td mat-cell *matCellDef="let element"> {{element.matricula}}</td>
                    </ng-container>
                    
                    <!-- Name Column -->
                    <ng-container matColumnDef="nombre">
                        <th mat-header-cell *matHeaderCellDef scope="col"> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{element.nombre}} {{element.apellidop}} {{element.apellidom}} </td>
                    </ng-container>
                    
                    <!-- Weight Column -->
                    <ng-container matColumnDef="proceso">
                        <th mat-header-cell *matHeaderCellDef scope="col"> Proceso </th>
                        <td mat-cell *matCellDef="let element"> 
                        <mat-chip-list>
                            <mat-chip color="primary">Inscripción</mat-chip>
                        </mat-chip-list>
                            
                        </td>
                    </ng-container>
                    
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="nivel">
                        <th mat-header-cell *matHeaderCellDef scope="col"> Programa </th>
                        <td mat-cell *matCellDef="let element"> {{element.nivel}} </td>
                        </ng-container>
    
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="grado">
                        <th mat-header-cell *matHeaderCellDef scope="col"> Grado </th>
                        <td mat-cell *matCellDef="let element"> {{element.grado}} </td>
                    </ng-container>
    
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="estatus">
                        <th mat-header-cell *matHeaderCellDef scope="col"> Estatus </th>
                        <td mat-cell *matCellDef="let element">
                            {{parseEstatus(element.estatus)}}
                        </td>
                        </ng-container>
    
                        <!-- Symbol Column -->
                    <ng-container matColumnDef="becaActual">
                        <th mat-header-cell *matHeaderCellDef scope="col">Beca actual </th>
                        <td mat-cell *matCellDef="let element">
                            {{   element?.becas_all?.split(',')[element?.becas_all?.split(',')?.length-1] | number: '1.2-2'  }}%
                        </td>
                    </ng-container>
                
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="cuotaActual">
                        <th mat-header-cell *matHeaderCellDef  scope="col"> Cuota actual </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.precio_unitario | currency:"MXN"}}
                        </td>
                    </ng-container>
    
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="becaSistema">
                        <th mat-header-cell *matHeaderCellDef  scope="col"> Beca sistema </th>
                        <td mat-cell *matCellDef="let element">
                            0%
                        </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="cuotaSistema">
                    <th mat-header-cell *matHeaderCellDef  scope="col"> Cuota sistema </th>
                    <td mat-cell *matCellDef="let element">
                        $0,000.00
                    </td>
                    </ng-container>

                    
                    
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="becaAutorizada">
                    <th mat-header-cell *matHeaderCellDef  scope="col"> Beca autorizada </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-form-field class="mx-1"><!--antes value="{{element.beca | number: '1.2-2'}}"-->
                            <input disabled class="text-center" matInput autocomplete="off" #beca maxlength="200" value="{{element.beca | number: '1.2-2'}}" (change)="actualizar('beca',$event.target.value,element.id_ciclo_fk,element.id_nivel_fk,element.id_grado_fk,element.id_alumno_fk)">
                            <span matSuffix>%</span>
                            <div style="display: none;" matPrefix id="spin_beca_{{element.id_ciclo_fk}}_{{element.id_nivel_fk}}_{{element.id_grado_fk}}_{{element.id_alumno_fk}}">
                                <span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            
                        </mat-form-field>
                    </td>
                    </ng-container>



                    <ng-container matColumnDef="descuento">
                        <th mat-header-cell *matHeaderCellDef  scope="col"> Descuento </th>
                        <td mat-cell *matCellDef="let element">
                            
                            <mat-form-field class="mx-1" >
                                <span matPrefix>$</span>
                                
                                <input disabled class="text-center" matInput autocomplete="off" #descuento maxlength="200" value="{{(element.precio_unitario * (element.beca/100)).toFixed(2)}}" (change)="actualizar('beca',100 - (((element.precio_unitario-$event.target.value)*100)/element.precio_unitario),element.id_ciclo_fk,element.id_nivel_fk,element.id_grado_fk,element.id_alumno_fk)">
                                <div style="display: none;" matPrefix id="spin_beca_{{element.id_ciclo_fk}}_{{element.id_nivel_fk}}_{{element.id_grado_fk}}_{{element.id_alumno_fk}}">
                                    <span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                
                            </mat-form-field>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="cuotaAutorizada">
                        <th mat-header-cell *matHeaderCellDef scope="col"> Cuota autorizada </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-form-field class="mx-1 bg-light">
                                <span matPrefix>$</span>
                                <input type="text" class="text-center" matInput autocomplete="off" maxlength="200" [value]="(element.precio_unitario - (element.precio_unitario * (element.beca/100))).toFixed(2)" (change)="actualizar('beca',100 - ($event.target.value * 100 /element.precio_unitario),element.id_ciclo_fk,element.id_nivel_fk,element.id_grado_fk,element.id_alumno_fk)">
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="autorizar">
                        <th mat-header-cell *matHeaderCellDef  scope="col"> Autorizar </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button color="primary" (click)="autorizar('estatus',3,element.id_ciclo_fk,element.id_nivel_fk,element.id_grado_fk,element.id_alumno_fk)">
                                <mat-icon>approval</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="verEstudio">
                        <th mat-header-cell *matHeaderCellDef  scope="col"> Ver Estudio </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button color="primary">
                                <mat-icon>find_in_page</mat-icon>
                            </button>
                        </td>
                        </ng-container>
    
                    <!-- Symbol Column -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                
                
            </mat-card-content>
            <mat-card-actions>
                <mat-paginator [length]="length"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   (page)="pageEvent = $event">
                </mat-paginator>
            </mat-card-actions>
        </div>
    </mat-card>
</div>