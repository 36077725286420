<div class="container-fluid" >
	<mat-card class="p-5">
		<mat-card-title>Solicitud de validación de pago</mat-card-title>
		<mat-card-subtitle></mat-card-subtitle>
        <mat-card-content>
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" class="input-full-w">
                        <mat-label>Ciclo</mat-label>
                        <mat-select [(ngModel)]="id_ciclo">
                            <mat-option *ngFor="let p of ciclos" [value]="p?.ciclo?.id_ciclo_pk">{{p?.ciclo?.nombre}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <div class="d-flex justify-content-end">
                        <button type="button" class="btn btn-small btn-primary" [routerLink]="['/tutores/dashboard/gt-validacion-pagos/registrar']" [queryParams]="{ciclo: id_ciclo, nombre_ciclo: nombre_ciclo, nivel: nivel, grado: grado}">
                        
                            Nueva solicitud
                        
                        </button>
                    </div>
                </div>
            </div>

             <!-- tabla -->
 <div class="row mt-3 mat-elevation-z8">
                    
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <caption><!--Parrilla--></caption>
        <!-- contenido -->

        <ng-container matColumnDef="id">
            <th id="id" mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element">{{element?.id_ticket_solictud_pk}}</td>
        </ng-container>

        <ng-container matColumnDef="nombre">
            <th id="nombre" mat-header-cell *matHeaderCellDef> NOMBRE </th>
            <td mat-cell *matCellDef="let element">{{element?.numero_ticket}}</td>
        </ng-container>

        <ng-container matColumnDef="gradoynivel">
            <th id="gradoynivel" mat-header-cell *matHeaderCellDef> GRADO Y NIVEL </th>
            <td mat-cell *matCellDef="let element">{{element?.nivel?.descripcion}} {{element?.grados?.descripcion}}</td>
        </ng-container>

        <!--<ng-container matColumnDef="grado">
            <th id="grado" mat-header-cell *matHeaderCellDef> GRADO </th>
            <td mat-cell *matCellDef="let element">{{element?.id_operacion_pk}}</td>
        </ng-container>

        <ng-container matColumnDef="grupo">
            <th id="grupo" mat-header-cell *matHeaderCellDef> GRUPO </th>
            <td mat-cell *matCellDef="let element">{{element?.id_operacion_pk}}</td>
        </ng-container>-->

        <ng-container matColumnDef="f_s">
            <th id="f_s" mat-header-cell *matHeaderCellDef> FECHA DE SOLICITUD </th>
            <td mat-cell *matCellDef="let element">{{element?.created_at?.split('T')[0]}}</td>
        </ng-container>

        <!--<ng-container matColumnDef="t_p">
            <th id="t_p" mat-header-cell *matHeaderCellDef> TIPO DE PAGO </th>
            <td mat-cell *matCellDef="let element">{{element?.id_operacion_pk}}</td>
        </ng-container>-->

        <ng-container matColumnDef="estado">
            <th id="estado" mat-header-cell *matHeaderCellDef> ESTADO </th>
            <td mat-cell *matCellDef="let element">{{element?.estado?.estado}}</td>
        </ng-container>

        <ng-container matColumnDef="v_solicitud">
            <th id="v_solicitud" mat-header-cell *matHeaderCellDef> VER SOLICITUD </th>
            <td mat-cell *matCellDef="let element">

                <a class="text-white"  mat-flat-button color="primary" rel="noopener" [routerLink]="['/tutores/dashboard/gt-validacion-pagos/registrar']" [queryParams]="{ciclo: id_ciclo, id_ticket: element?.id_ticket_solictud_pk}"> <!--href="{{getBaseUrl()}}ExcelReporteEncuestas?numero_encuesta={{element.id_encuesta_fk}}&numero_app={{element.id_aplicacion_pk}}" {{element?.id_ticket_solictud_pk}}-->
                    <mat-icon>open_in_new</mat-icon>
                </a>


            </td>
        </ng-container>

      
    
        <!-- fin contenido -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<!-- fin tabla -->


            
        </mat-card-content>


    </mat-card>
</div>
<div class="container-fluid mt-5">
   
</div>



