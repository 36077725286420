import { Component, OnInit, Inject } from '@angular/core';
import { FormArray,FormBuilder, FormGroup,FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';

import { Router, ActivatedRoute, Params } from '@angular/router'; 
import { TtrSesionService } from 'src/app/services/tutores/ttr-sesion.service';

import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-ttr-sesion',
  templateUrl: './ttr-sesion.component.html',
  styleUrls: ['./ttr-sesion.component.scss'],
  providers: [ TtrSesionService ]
})
export class TtrSesionComponent implements OnInit {

  public form: FormGroup;
   public msj: string;
   public std: number;
   public isLoading: boolean;
   public matcher: MyErrorStateMatcher;

  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _ttrSesionService: TtrSesionService,
    public dialog: MatDialog
  ) { 
    this.msj = '';
    this.std = 0;
    this.isLoading = false;
    /* forms para el modal */
    this.form = this.fb.group({
      correo: new FormControl('', [Validators.required]),
      contrasena: new FormControl('', [Validators.required]),
    });

  }



  ngOnInit(): void {
  }

  //public postIniciarSesion(){
    //this._router.navigate(['tutores/dashboard']);
  //}

  public postIniciarSesion(){
    this.isLoading = true;
    this._ttrSesionService.postIniciarSesion(this.form.value).subscribe(
        (response: any)=> {
          console.log(response);
          this.isLoading = false;
          if(response.estado == 200){
            console.log('bien');
            sessionStorage.setItem('token', response.token);
            this._router.navigate(['tutores/dashboard']);
          }else{
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: this.msj, icon:"error_outline", href:null}});
          }
        },
        (error: any) => {
            this.isLoading = false;
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Problema con el servicio.', icon:"error_outline", href:null}});
      } 
    );
  }

  public postRecuperarCuenta(){
    this.isLoading = true;
    this._ttrSesionService.postRecuperarCuenta(this.form.value).subscribe(
      (response: any) => {
          console.log(response);
          this.isLoading = false;
          if(response.estado == 200){
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: this.msj, icon:"done", href:null}});
          }else{
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: this.msj, icon:"error_outline", href:null}});
          }
        },
      (error: any) => {
        this.isLoading = false;
        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Problema con el servicio.', icon:"error_outline", href:null}});
      } 
    );
  }

  public clearForm(){
    this.form.patchValue({
        correo: '',
        contrasena: '',
    });
  }

}
