//module
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms'; //its for formBuilder
import { HttpClientModule } from '@angular/common/http';
import { TutoresRoutingModule } from './tutores-routing.module';
//import { MomentModule } from 'angular2-moment';

import { TutoresComponent } from 'src/app/pages/tutores/tutores.component';
//import { TtrSesionComponent } from './sesion/ttr-sesion/ttr-sesion.component';
//import { TtrIniciarSesionComponent } from 'src/app/pages/tutores/sesion/ttr-iniciar-sesion/ttr-iniciar-sesion.component';
import { TtrDashboardComponent } from 'src/app/pages/tutores/ttr-dashboard/ttr-dashboard.component';
import { TtrInicioComponent } from 'src/app/pages/tutores/ttr-dashboard/ttr-inicio/ttr-inicio.component';
import { TtrMiCuentaComponent } from 'src/app/pages/tutores/ttr-dashboard/ttr-mi-cuenta/ttr-mi-cuenta.component';
//import { TtrStdSocioeconomicoComponent } from 'src/app/pages/tutores/ttr-dashboard/ttr-std-socioeconomico/ttr-std-socioeconomico.component';
//import { TtrAtenderStdSocioeconomicoComponent } from 'src/app/pages/tutores/ttr-dashboard/ttr-std-socioeconomico/ttr-atender-std-socioeconomico/ttr-atender-std-socioeconomico.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';



import {MatTabsModule} from '@angular/material/tabs';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatTreeModule} from '@angular/material/tree';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatStepperModule} from '@angular/material/stepper';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';

//Guards tutores
import { TtrSesionService } from 'src/app/services/tutores/ttr-sesion.service'; 
import { TtrUserGuard } from 'src/app/pages/tutores/ttr-user.guard';
import { TtrUserNotGuard } from 'src/app/pages/tutores/ttr-user-not.guard';
import { TtrBibliotecaComponent } from './ttr-dashboard/ttr-biblioteca/ttr-biblioteca.component';
import { TtrTesoreriaComponent } from './ttr-dashboard/ttr-tesoreria/ttr-tesoreria.component';
import { TtrAcValidacionPagosComponent } from './ttr-dashboard/ttr-validacion-pagos/ttr-ac-validacion-pagos/ttr-ac-validacion-pagos.component';
import { TtrGtValidacionPagosComponent } from './ttr-dashboard/ttr-validacion-pagos/ttr-gt-validacion-pagos/ttr-gt-validacion-pagos.component';
//import { HistorialTicketsDePagosComponent } from 'src/app/pages/tesoreria/historial-tickets-de-pagos/historial-tickets-de-pagos.component';


//ngModule
@NgModule({
    declarations: [
        TutoresComponent,
        //TtrSesionComponent,
        //TtrIniciarSesionComponent,
        TtrDashboardComponent,
        TtrInicioComponent,
        TtrMiCuentaComponent,
        TtrBibliotecaComponent,
        TtrTesoreriaComponent,
        TtrAcValidacionPagosComponent,
        TtrGtValidacionPagosComponent,
        
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TutoresRoutingModule,

        HttpClientModule,

        //Material
        
        MatTabsModule,
        MatInputModule,
        MatButtonModule,
        MatToolbarModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatTreeModule,
        MatMenuModule,
        MatSelectModule,
        MatTableModule,
        MatCheckboxModule,
        MatRadioModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatTableExporterModule,
        MatPaginatorModule,
        MatCardModule


    ],
    exports: [
        TutoresComponent,
        TtrDashboardComponent,
        TtrInicioComponent,
        TtrMiCuentaComponent,
        TtrTesoreriaComponent
        
        //TtrStdSocioeconomicoComponent,
        //TtrAtenderStdSocioeconomicoComponent,
        //TtrSesionComponent,
        //TtrIniciarSesionComponent,
    ],
    providers: [ 
    MatStepperModule,
    TtrSesionService,
    TtrUserGuard,
    TtrUserNotGuard
    ],
    schemas: [],
})
export class TutoresModule {}