<br>
<br>
<div class="card-container mt-5">
    <mat-card class="example-card mt-5">
        <mat-card-header>
            <mat-card-title>Parametrización ciclo escolar</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>


<div class="container-fluid mt-5">
    <form novalidate [formGroup]="form1" autocomplete="off" class="mt-5">
        <h2>Registrar ciclo</h2>
        <div class="row mt-1">
                <div class="col">
                    <mat-form-field class="input-full-w" appearance="outline">
                        <mat-label>Nombre</mat-label>
                        <input type="text" matInput formControlName="nombre" [errorStateMatcher]="matcher">
                        <mat-error *ngIf="form1.controls['nombre'].hasError('required')">
                            El campo es obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="input-full-w" appearance="outline">
                        <mat-label>Fecha de inicio</mat-label>
                        <input type="date" matInput formControlName="fecha_inicio" [errorStateMatcher]="matcher">
                        <mat-error *ngIf="form1.controls['fecha_inicio'].hasError('required')">
                            El campo es obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="input-full-w" appearance="outline">
                        <mat-label>Fecha de fin</mat-label>
                        <input type="date" matInput formControlName="fecha_fin" [errorStateMatcher]="matcher">
                        <mat-error *ngIf="form1.controls['fecha_fin'].hasError('required')">
                            El campo es obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <form novalidate [formGroup]="form1" (ngSubmit)="postRegistrarCiclo()">
                        <button type="submit" [disabled]="form1.invalid" class="btn btn-primary">Registrar</button>
                    </form>
                </div>
        </div>
    </form>


    <h2>Ciclos registrados</h2>
    <div class="row" *ngFor="let c of ciclos;let i = index">
        <div class="col">
            <mat-form-field class="input-full-w" appearance="outline">
                <mat-label>Nombre</mat-label>
                <input type="text" matInput [(ngModel)]="c.nombre" [errorStateMatcher]="matcher">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field class="input-full-w" appearance="outline">
                <mat-label>Fecha de inicio</mat-label>
                <input type="date" matInput [(ngModel)]="c.fecha_inicio" [errorStateMatcher]="matcher">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field class="input-full-w" appearance="outline">
                <mat-label>Fecha de inicio</mat-label>
                <input type="date" matInput [(ngModel)]="c.fecha_fin" [errorStateMatcher]="matcher">
            </mat-form-field>
        </div>
        <div class="col">
            <form (ngSubmit)="postModificarCiclos(i, ciclos[i].activo)">
                <button type="submit" *ngIf="c.nombre != '' && c.fecha_incio != '' && c.fecha_fin != ''" class="btn btn-primary">Modificar</button>
            </form>
        </div>
        <div class="col">
            <button *ngIf="ciclos[i].activo == 1" type="button" (click)="postModificarCiclos(i, 0)" class="btn btn-success">Estado: Activado</button>
            <button *ngIf="ciclos[i].activo == 0" type="button" (click)="postModificarCiclos(i, 1)" class="btn btn-danger">Estado: Desactivado</button>
        </div>
    </div>


    <div class="row" *ngIf="index != -1">
        <h2 *ngIf="index != -1">
            Ciclo activo actual: {{ciclos[index]?.nombre}}
        </h2>
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <div class="row mb-3">
                            <span class="fw-bold fs-6">Niveles:</span>
                        </div>
                        <div class="row" *ngFor="let n of niveles; let i = index">
                            <span class="fw-bolder">&nbsp;{{n.descripcion}}</span>
                            <div  class="row" *ngFor="let g of grados; let j = index">
                                <div *ngIf="n.id_nivel_pk == g.id_nivel_fk" class="mt-3 row"> 
                                    <div class="col">
                                        <mat-form-field class="input-full-w" appearance="outline">
                                            <mat-label>Grado</mat-label>
                                            <input readonly="true" type="text" matInput value="{{g.descripcion}}" [errorStateMatcher]="matcher">
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <button (click)="postRegistrarOfertaEducativa(ciclos[index]?.id_ciclo_pk, n.id_nivel_pk, g.id_grado_pk)" type="button"  class="btn btn-primary">Asociar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row" *ngFor="let oF of ofertaEducativa; let g = index">
                    <div *ngIf="ciclos[index].id_ciclo_pk == oF.id_ciclo_fk" class="row">
                        <div class="col">
                            <mat-form-field appearance="fill">
                                <mat-label>{{oF.nivel.descripcion}}</mat-label>
                                <mat-select [(ngModel)]="oF.id_nivel_fk">
                                    <mat-option *ngFor="let nee of niveles" [value]="nee.id_nivel_pk">{{nee.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field class="input-full-w" appearance="outline">
                                <mat-label>{{oF.nivel.descripcion}}</mat-label>
                                <mat-select [(ngModel)]="oF.id_grado_fk">
                                    <mat-option *ngFor="let g of gradosFiltrados(oF.id_nivel_fk)"  [value]="g.id_grado_pk">{{g.descripcion}}</mat-option>
                                </mat-select>
                                <!--<input readonly="true" type="text" matInput value="{{oF.grado.descripcion}}" [errorStateMatcher]="matcher">-->
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <button (click)="postModificarOfertaEducativa(ciclos[index]?.id_ciclo_pk, oF.id_nivel_fk, oF.id_grado_fk, oF.nivel_antiguo, oF.grado_antiguo)" type="button"  class="btn btn-primary">Modificar</button>
                        </div>

                    </div>
                    <!--<div *ngIf="g != ofertaEducativa?.length - 1">
                        
                        <div class="row mt-5" *ngIf="+oF.id_nivel_fk < +ofertaEducativa[g + 1].id_nivel_fk ">
                            <br>
                        </div>
                    </div>-->
                    
                </div>
            </div>
        </div>


    </div>



    <div class="row" *ngIf="index != -1">
       
       
            <h2>No registrados</h2>
            <div class="row mt-2" *ngFor="let c of noRegistrados;let i = index">
                <div class="col">
                    <mat-form-field class="input-full-w" appearance="outline">
                        <mat-label>Nombre</mat-label>
                        <input readonly="true" type="text" matInput [(ngModel)]="c.nombre" [errorStateMatcher]="matcher">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="input-full-w" appearance="outline">
                        <mat-label>Precio unitario</mat-label>
                        <input  type="number" matInput [(ngModel)]="c.precio_unitario" [errorStateMatcher]="matcher">
                    </mat-form-field>
                </div>
                <div class="col">
                    <button type="button" (click)="postRegistrarConceptoTesoreriaCiclo(i)" class="btn btn-primary">+</button>
                </div>
            </div>
        
            <h2>Registrados</h2>
            <div class="row mt-2" >

                <div class="accordion" id="accordionExample">
                    <div class="accordion-item" *ngFor="let c of registrados; let i = index">
                      <h2 class="accordion-header" id="heading{{i}}">
                        <button (click)="getConsultarCuentasPorCobrar(i)" class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
                            <button type="button" (click)="postEliminarConceptoTesoreriaCiclo(i)" class="btn btn-danger">x</button> &nbsp; {{c.concepto.nombre}} - {{c.precio_unitario}}
                        </button>

                        
                      </h2>
                      <div [id]="'collapse' + i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div class="row" *ngIf="cuentas_por_cobrar"> <!--modificar-->
                                <div class="col" *ngIf="c.concepto.nombre.indexOf('Estudio Socioeconómico') !== -1">
                                    <mat-form-field appearance="outline" class="input-full-w">
                                        <mat-label>Nivel</mat-label>
                                        <mat-select [(ngModel)]="cuentas_por_cobrar.id_nivel_fk" >
                                            <mat-option [value]="1" >Preescolar</mat-option>
                                            <mat-option [value]="2" >Primaria</mat-option>
                                            <mat-option [value]="3" >Secundaria</mat-option>
                                            <mat-option [value]="4" >Preparatoria</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field class="input-full-w" appearance="outline">
                                        <mat-label>Fecha de inicio</mat-label>
                                        <input [(ngModel)]="cuentas_por_cobrar.fecha_inicio"  type="date" matInput  >
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field class="input-full-w" appearance="outline">
                                        <mat-label>Fecha limite</mat-label>
                                        <input [(ngModel)]="cuentas_por_cobrar.fecha_limite" type="date" matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="outline" class="input-full-w">
                                        <mat-label>Aplica recargo</mat-label>
                                        <mat-select [(ngModel)]="cuentas_por_cobrar.aplica_recargo" >
                                            <mat-option [value]="0" >No</mat-option>
                                            <mat-option [value]="1" >Si</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <button (click)="postModificarConceptoPorCobrar(i)" type="button" class="btn btn-primary">Modificar</button>
                                </div>
                                <div class="col">
                                    <button (click)="postEliminarConceptoPorCobrar()" type="button" class="btn btn-danger">x</button>
                                </div>
                            </div>

                            <div class="row" *ngIf="!cuentas_por_cobrar"> <!--registrar-->
                                <form novalidate [formGroup]="form2" autocomplete="off" class="mt-5">
                                    <div class="col" *ngIf="c.concepto.nombre.indexOf('Estudio Socioeconómico') !== -1">
                                        <mat-form-field appearance="outline" class="input-full-w">
                                            <mat-label>Nivel</mat-label>
                                            <mat-select formControlName="nivel" >
                                                <mat-option [value]="1" >Preescolar</mat-option>
                                                <mat-option [value]="2" >Primaria</mat-option>
                                                <mat-option [value]="3" >Secundaria</mat-option>
                                                <mat-option [value]="4" >Preparatoria</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="input-full-w" appearance="outline">
                                            <mat-label>Fecha de inicio</mat-label>
                                            <input formControlName="fecha_inicio"  type="date" matInput  >
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="input-full-w" appearance="outline">
                                            <mat-label>Fecha limite</mat-label>
                                            <input formControlName="fecha_limite" type="date" matInput>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field appearance="outline" class="input-full-w">
                                            <mat-label>Aplica recargo</mat-label>
                                            <mat-select formControlName="aplica_recargo" >
                                                <mat-option [value]="0" >No</mat-option>
                                                <mat-option [value]="1" >Si</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <form novalidate [formGroup]="form2" (ngSubmit)="postRegistrarConceptoPorCobrar(i)">
                                            <button type="submit" [disabled]="form2.invalid" class="btn btn-primary">Registrar</button>
                                        </form>
                                    </div>
                                </form>
                                
                                
                            </div>








                        </div>
                      </div>
                    </div>
                  </div>
                  

               
            </div>
            <!--<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                <mat-tab label="Preescolar">Content 1</mat-tab>
                <mat-tab label="Primaria">Content 2</mat-tab>
                <mat-tab label="Secundaria">Content 3</mat-tab>
                <mat-tab label="Preparatoria">Content 4</mat-tab>
              </mat-tab-group>-->
        
    </div>


</div>



</mat-card-content>

</mat-card>
</div>
