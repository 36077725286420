<div mat-dialog-title class="container">
    <div class="row">
        <div class="col">
            <h1>
                <mat-icon>person_search</mat-icon> Lupa de Alumnos
            </h1>
        </div>
    </div>    
</div>
<div mat-dialog-content>
    
    <nav class="navbar sticky-top navbar-light" style="background-color: white;">
        <div class="container-fluid text-center">
            <div class="col-md-12">
                
            </div>
          
        </div>
        
        <div class="container-fluid">            
                <div class="col-md-12">
                    <div class="row">
                        <div class="col">
                            <form autocomplete="no" (ngSubmit)="buscar()" [formGroup]="formulario">
                                <mat-form-field appearance="fill" class="w-100">
                                    <mat-label>Buscar</mat-label>
                                    <input matInput placeholder="Por nombre, grado, grupo" formControlName="busqueda" autocomplete="off">
                                    <!--<mat-icon matSuffix>search</mat-icon>-->
                                </mat-form-field>
                            </form>
                        </div>
                        <div class="col-lg-auto col-md-auto">
                            <button mat-stroked-button color="primary" (click)="buscar()">
                                <mat-icon>search</mat-icon>
                                Buscar
                            </button>
                        </div>
                    </div>
                </div>
                
            
        </div>
    </nav>
    
    <div class="container position-relative">
        <div class="spinner" *ngIf="isLoading">
            <mat-spinner mode="indeterminate"></mat-spinner>
        </div>
        <table mat-table matTableExporter [dataSource]="dataSource" class="mat-elevation-z0" #exporter="matTableExporter" hiddenColumns="[6][7][8]">
            <caption *ngIf="length<=0">No hay resultados para sus parámetros de búsqueda actuales</caption>
            <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
            
            <ng-container matColumnDef="matricula">
                <th scope="col" mat-header-cell *matHeaderCellDef> Matrícula </th>
                <td mat-cell *matCellDef="let element"> {{element.matricula}} </td>
            </ng-container>

            <!-- Position Column -->
            <ng-container matColumnDef="nombre">
                <th scope="col" mat-header-cell *matHeaderCellDef> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.nombre}} {{element.apellidop}} {{element.apellidom}}</td>
            </ng-container>
            
            <!-- Name Column -->
            <ng-container matColumnDef="proceso">
                <th scope="col" mat-header-cell *matHeaderCellDef> Proceso </th>
                <td mat-cell *matCellDef="let element"> {{element.proceso}} </td>
            </ng-container>
            
            <!-- Weight Column -->
            <ng-container matColumnDef="estatus">
                <th scope="col" mat-header-cell *matHeaderCellDef> Estatus </th>
                <td mat-cell *matCellDef="let element"> 
                    <mat-chip-list aria-label="Fish selection">
                        <mat-chip color="default" *ngIf="element.estado=='Prospecto'">{{element.estado}}</mat-chip>
                        <mat-chip color="accent" *ngIf="element.estado=='Alumno'" selected>{{element.estado}}</mat-chip>
                    </mat-chip-list>
                    
                </td>
            </ng-container>
            
            <!-- Symbol Column -->
            <ng-container matColumnDef="nivel">
                <th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Nivel </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.control_escolar.split("|")[1]}} </td>
                </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="grado">
                <th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Grado </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.control_escolar.split("|")[2]}} </td>
            </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="grupo">
                <th scope="col" mat-header-cell *matHeaderCellDef> Grupo </th>
                <td mat-cell *matCellDef="let element"> {{element.grupo}} </td>
                </ng-container>

                <!-- Symbol Column -->
            <ng-container matColumnDef="ficha">
                <th scope="col" mat-header-cell *matHeaderCellDef> Ficha </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button color="warn" [routerLink]="['ficha']" [queryParams]="{id:element.id_alumno_pk}">
                        <mat-icon>list_alt</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="expediente">
                <th scope="col" mat-header-cell *matHeaderCellDef> Expediente </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button color="primary" [routerLink]="['edit']" [queryParams]="{id:element.id_alumno_pk}">
                        <mat-icon>note_alt</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="seleccionar">
                <th scope="col" mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    <button mat-icon-button color="primary" [mat-dialog-close]="{nombre:element.nombre+' '+element.apellidop+' '+element.apellidom,id:element.id_alumno_pk }">
                        <mat-icon>send</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Symbol Column -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
<div mat-dialog-actions align="end">
    <mat-paginator [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent($event)">
    </mat-paginator>
  <button mat-flat-button color="accent" (click)="onNoClick()">Cerrar</button>&nbsp;
  <!--<button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Sí</button>-->
</div>