<div class="card-container encuesta">
  <mat-card class="example-card">
    <div class="spinner" *ngIf="isLoading">
      <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
    </div>
    <mat-card-header>
      <mat-card-title>Inscripción del alumno</mat-card-title>
      <mat-card-subtitle>Complete los pasos para realizar la inscripción del alumno</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

      <app-ficha-informativa (alumnoSeleccionado)="setAlumno($event)"></app-ficha-informativa>
      <!--<button mat-raised-button (click)="isEditable = !isEditable">
              {{!isEditable ? 'Enable edit mode' : 'Disable edit mode'}}
            </button>-->

      <mat-horizontal-stepper [linear]="true" #stepper (selectionChange)="triggerClick($event)">
        <mat-step [stepControl]="firstFormGroup" *ngIf="step==0">
          <form [formGroup]="firstFormGroup" (ngSubmit)="iniciarProceso()">
            <ng-template matStepLabel>Solicitud</ng-template>
            <div class="container">

            
            <div class="row">
              <div class="col-6">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Ciclo escolar</mat-label>
                  <mat-select required formControlName="ciclo" #elCiclo>
                    <mat-option>-- Ninguno --</mat-option>
                    <mat-option *ngFor="let ciclo of ciclos" [value]="ciclo.id_ciclo_pk">{{ciclo.activo==1 ? '(Activo) ':''}}{{ciclo.nombre}}</mat-option>
                  </mat-select>
                  <!--<input matInput formControlName="firstCtrl" placeholder="Last name, First name" required>-->
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Tramite</mat-label>
                  <input matInput readonly [value]="'Inscripción'">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-md-3">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Sección</mat-label>
                  <mat-select #elNivel required formControlName="nivel" (selectionChange)="cambiaNivel()">
                    <mat-option >-- Ninguno --</mat-option>
                    <mat-option *ngFor="let nivel of niveles" [value]="nivel.id_nivel_pk">{{nivel.descripcion}}
                    </mat-option>
                  </mat-select>
                  <!--<input matInput formControlName="firstCtrl" placeholder="Last name, First name" required>-->
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-md-3">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Grado</mat-label>
                  <mat-select required formControlName="grado">
                    <mat-option >-- Ninguno --</mat-option>
                    <mat-option *ngFor="let grado of grados" [value]="grado.id_grado_pk">{{grado.descripcion}}
                    </mat-option>
                  </mat-select>
                  <!--<input matInput formControlName="firstCtrl" placeholder="Last name, First name" required>-->
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field appearance="fill" class="bt col-md-12">
                  <mat-label>Observaciones</mat-label>
                  
                  <textarea matInput formControlName="observaciones"></textarea>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <section class="example-section d-none">
                  <mat-checkbox class="example-margin" formControlName="requiere_estudio_socioeconomico">Requiere estudio socioeconómico</mat-checkbox>
                </section>
              </div>

              <div style="text-align: right;">
                <button mat-button type="button" matStepperNext style="display: none;"
                  id="siguiente1">Siguiente</button>
                <!--                        <button mat-stroked-button color="primary" type="button" [routerLink]="['../']" [queryParams]={id:idAlumno}>Cancelar</button>&nbsp;-->
                <button mat-flat-button color="accent">Siguiente</button>

              </div>
            </div>
          </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" [editable]="isEditable" (select)="validarRequisitos()"
          *ngIf="step==0">
          <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>Prerrequisitos</ng-template>
            <h3>1. Evaluación inicial</h3>
            <mat-list *ngIf="firstFormGroup.controls['nivel'].value!=1">
              <mat-list-item
                class="{{evaluacion?.estatus == 0 || evaluacion?.decision == 'No Aceptado' ? 'bgRed':'bgGreen'}}">
                <mat-icon mat-list-icon
                  color="{{evaluacion?.estatus == 0 || evaluacion?.decision == 'No Aceptado' ? 'warn':'primary'}}">
                  {{evaluacion.estatus == 0 || evaluacion?.decision == 'No Aceptado' || evaluacion?.estatus == undefined ? 'highlight_off':'verified'}}
                </mat-icon>
                <div mat-line>
                  <span *ngIf="evaluacion?.estatus == 0 || evaluacion?.estatus == undefined">El proceso de evaluación
                    esta en curso</span>
                  <span *ngIf="evaluacion?.estatus != 0 && evaluacion?.decision == 'No Aceptado'">El resultado de la
                    evaluación inicial no fue satisfactorio.</span>
                  <span
                    *ngIf="evaluacion?.estatus != 0 && evaluacion?.decision != 'No Aceptado' && evaluacion?.estatus != undefined">La
                    evaluación ha finalizado satisfactoriamente</span>
                </div>
                <!--<div mat-line *ngIf="(item.debe != null ? item.debe: item.precio_unitario)>0">ADEUDO {{item.debe != null ? item.debe: item.precio_unitario | currency: 'MXN' }} de {{item.precio_unitario | currency: 'MXN'}}</div>
                      <div mat-line *ngIf="(item.debe != null ? item.debe: item.precio_unitario)<=0">PAGADO</div>-->
              </mat-list-item>
            </mat-list>

            <mat-list *ngIf="firstFormGroup.controls['nivel'].value==1">
              <mat-list-item class="bgGreen">
                <mat-icon mat-list-icon color="primary">verified</mat-icon>
                <div mat-line>
                  <span>¡Felicidades! para este nivel, no es necesario realizar una evaluación inicial.</span>
                </div>
                <!--<div mat-line *ngIf="(item.debe != null ? item.debe: item.precio_unitario)>0">ADEUDO {{item.debe != null ? item.debe: item.precio_unitario | currency: 'MXN' }} de {{item.precio_unitario | currency: 'MXN'}}</div>
                      <div mat-line *ngIf="(item.debe != null ? item.debe: item.precio_unitario)<=0">PAGADO</div>-->
              </mat-list-item>
            </mat-list>



            <div *ngIf="firstFormGroup.controls['nivel'].value!=1">
              <div class="row">
                <div class="col">
                  <ng-container *ngIf="evaluacion?.decision">
                    <mat-form-field appearance="fill">
                      <mat-label>Resultado</mat-label>
                      <input matInput type="text" readonly [value]="evaluacion?.decision" />
                    </mat-form-field>
                  </ng-container>
                </div>
              </div>
            </div>

            <hr>
            <h3>2. Carta de no adeudo</h3>
            <mat-list>
              <ng-container *ngFor="let item of pagarUno">
                
                <!--{{((element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado))}}-->
                <ng-container *ngIf="item.condonacion_val == true; else noCon">
                  <mat-list-item *ngIf="item.vencido==1" class="{{ item.pago_pendiente>0 ? 'bgRed':'bgGreen'}}">
                    <mat-icon mat-list-icon color="{{ item.pago_pendiente>0 ? 'warn':'primary'}}">{{ item.pago_pendiente>0
                      ? 'highlight_off':'verified'}}</mat-icon>
                    <div mat-line>{{item.concepto}}</div>
                    <div mat-line *ngIf="item.pago_pendiente>0">ADEUDO 
                      {{ (item.precio_unitario - item.descuento) + (-(item.precio_unitario - item.descuento)*item.recargos)-item.pagado | currency: 'MXN' }} de
                      {{ (item.precio_unitario - item.descuento) + (-(item.precio_unitario - item.descuento)*item.recargos) | currency: 'MXN'}}</div>
                    <div mat-line *ngIf="item.pago_pendiente<=0">PAGADO</div>
                  </mat-list-item>
              </ng-container>
              <ng-template #noCon>
                <mat-list-item *ngIf="item.vencido==1" class="{{ item.pago_pendiente>0 ? 'bgRed':'bgGreen'}}">
                  <mat-icon mat-list-icon color="{{ item.pago_pendiente>0 ? 'warn':'primary'}}">{{ item.pago_pendiente>0
                    ? 'highlight_off':'verified'}}</mat-icon>
                  <div mat-line>{{item.concepto}}</div>
                  <div mat-line *ngIf="item.pago_pendiente>0">ADEUDO 
                    {{ (item.precio_unitario - item.descuento) + ((item.precio_unitario - item.descuento)*item.recargos)-item.pagado | currency: 'MXN' }} de
                    {{ (item.precio_unitario - item.descuento) + ((item.precio_unitario - item.descuento)*item.recargos) | currency: 'MXN'}}</div>
                  <div mat-line *ngIf="item.pago_pendiente<=0">PAGADO</div>
                </mat-list-item>
              </ng-template>
              
              </ng-container>

              <!--<div mat-subheader>Notes</div>
                      <mat-list-item *ngFor="let note of notes">
                        <mat-icon mat-list-icon>note</mat-icon>
                        <div mat-line>{{note.name}}</div>
                        <div mat-line> {{note.updated | date}} </div>
                      </mat-list-item>-->
            </mat-list>
            <hr>
            <h3>3. Estudio socioeconómico</h3>
            <div *ngIf="reqEstudioSocEstatus == 0; else elseBlock">
              <mat-list>
                <mat-list-item class="bgGreen">
                  <mat-icon mat-list-icon color="primary">
                    verified
                  </mat-icon>
                  <div mat-line>
                    <span>No se requiere estudio socioeconómico</span>
                  </div>
                </mat-list-item>
              </mat-list>
            </div>
            <ng-template #elseBlock>

              <mat-list>
                <mat-list-item class="{{estudioSocioEconomicoEstatus != 3 ? 'bgRed':'bgGreen'}}">
                  <mat-icon mat-list-icon color="{{estudioSocioEconomicoEstatus != 3 ? 'warn':'primary'}}">
                    {{estudioSocioEconomicoEstatus != 3 ? 'highlight_off':'verified'}}
                  </mat-icon>
                  <div mat-line>
                    <span *ngIf="estudioSocioEconomicoEstatus != 3">El estudio socioeconómico no ha concluido</span>
                    <span *ngIf="estudioSocioEconomicoEstatus == 3">¡Felicidades!, El estudio socioeconómico ha finalizado
                      ya puedes visualizar tu porcentaje de beca.</span>
                  </div>
                  <!--<div mat-line *ngIf="(item.debe != null ? item.debe: item.precio_unitario)>0">ADEUDO {{item.debe != null ? item.debe: item.precio_unitario | currency: 'MXN' }} de {{item.precio_unitario | currency: 'MXN'}}</div>
                    <div mat-line *ngIf="(item.debe != null ? item.debe: item.precio_unitario)<=0">PAGADO</div>-->
                </mat-list-item>
              </mat-list>
            

              <mat-form-field appearance="fill">
                <mat-label>Estatus</mat-label>
                <input matInput type="text" readonly [value]="traduceEstatus(estudioSocioEconomicoEstatus)" />
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Porcentaje de beca aprobado</mat-label>
                <input matInput type="text" readonly [value]="porcentajeBeca + '%'" />
              </mat-form-field>
            </ng-template>
            <div style="text-align: right;">
              <button mat-stroked-button matStepperPrevious color="accent">Atras</button>&nbsp;
              <button mat-flat-button matStepperNext color="accent"
                [disabled]="secondFormGroup.invalid">Siguiente</button>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Cierre</ng-template>
          <p>Imprime la siguiente documentación</p>

          <mat-list>
            <mat-list-item>
              <mat-icon mat-list-icon>description</mat-icon>
              <div mat-line>
                <span>Ficha de inscripción</span>
              </div>
              <div mat-line>
                <!--  (click)="descargarFicha()" -->
                <button mat-flat-button color="accent" >
                  <mat-icon>download</mat-icon>
                  <a *ngIf="general_alumno?.id_nivel_fk == '1'" target="_blank"  rel="noopener" href="{{url+'FichaInscripcionPreescolarDocPDF?idAlumno='+alumno?.id_alumno_pk+getFechaLarv()+'&matricula='+general_alumno?.id_alumno_fk+'&ciclo='+general_alumno?.id_ciclo_fk+'&grado='+general_alumno?.id_grado_fk}}" class="text-decoration-none text-white">
                    Descargar preescolar
                  </a>
                  <a *ngIf="general_alumno?.id_nivel_fk == '2'" target="_blank"  rel="noopener" href="{{url+'FichaInscripcionPrimariaDocPDF?idAlumno='+alumno?.id_alumno_pk+getFechaLarv()+'&matricula='+general_alumno?.id_alumno_fk+'&ciclo='+general_alumno?.id_ciclo_fk+'&grado='+general_alumno?.id_grado_fk}}" class="text-decoration-none text-white">
                    Descargar primaria
                  </a>
                  <a *ngIf="general_alumno?.id_nivel_fk == '3'" target="_blank"  rel="noopener" href="{{url+'FichaInscripcionSecundariaDocPDF?idAlumno='+alumno?.id_alumno_pk+getFechaLarv()+'&matricula='+general_alumno?.id_alumno_fk+'&ciclo='+general_alumno?.id_ciclo_fk+'&grado='+general_alumno?.id_grado_fk}}" class="text-decoration-none text-white">
                    Descargar secundaria
                  </a>
                  <a *ngIf="general_alumno?.id_nivel_fk == '4'" target="_blank"  rel="noopener" href="{{url+'FichaInscripcionPreparatoriaDocPDF?idAlumno='+alumno?.id_alumno_pk+getFechaLarv()+'&matricula='+general_alumno?.id_alumno_fk+'&ciclo='+general_alumno?.id_ciclo_fk+'&grado='+general_alumno?.id_grado_fk}}" class="text-decoration-none text-white">
                    Descargar preparatoria
                  </a>
                </button>
              </div>
            </mat-list-item>
            <mat-list-item>
              <mat-icon mat-list-icon>description</mat-icon>
              <div mat-line>
                <span>Reglamento</span>
              </div>
              <div mat-line>
                <!-- (click)="descargarReglamento()" -->
                <button mat-flat-button color="accent" >
                  <mat-icon>download</mat-icon>
                  <a *ngIf="general_alumno?.id_nivel_fk == '1'" target="_blank"  rel="noopener" href="{{url+'ReglamentoPreescolar?idAlumno='+alumno?.id_alumno_pk+getFechaLarv()+'&contenido=null'+'&matricula='+general_alumno?.id_alumno_fk+'&ciclo='+general_alumno?.id_ciclo_fk+'&grado='+general_alumno?.id_grado_fk}}" class="text-decoration-none text-white">
                    Descargar preescolar
                  </a>
                  <a *ngIf="general_alumno?.id_nivel_fk == '2'" target="_blank"  rel="noopener" href="{{url+'ReglamentoPrimaria?idAlumno='+alumno?.id_alumno_pk+getFechaLarv()+'&contenido=null'+'&matricula='+general_alumno?.id_alumno_fk+'&ciclo='+general_alumno?.id_ciclo_fk+'&grado='+general_alumno?.id_grado_fk}}" class="text-decoration-none text-white">
                    Descargar primaria
                  </a>
                  <a *ngIf="general_alumno?.id_nivel_fk == '3'" target="_blank"  rel="noopener" href="{{url+'ReglamentoSecundaria?idAlumno='+alumno?.id_alumno_pk+getFechaLarv()+'&contenido=null'+'&matricula='+general_alumno?.id_alumno_fk+'&ciclo='+general_alumno?.id_ciclo_fk+'&grado='+general_alumno?.id_grado_fk}}" class="text-decoration-none text-white">
                    Descargar secundaria
                  </a>
                  <a *ngIf="general_alumno?.id_nivel_fk == '4'" target="_blank"  rel="noopener" href="{{url+'ReglamentoPreparatoria?idAlumno='+alumno?.id_alumno_pk+getFechaLarv()+'&contenido=null'+'&matricula='+general_alumno?.id_alumno_fk+'&ciclo='+general_alumno?.id_ciclo_fk+'&grado='+general_alumno?.id_grado_fk}}" class="text-decoration-none text-white">
                    Descargar preparatoria
                  </a>
                </button>
                <!--<button id="lauchModal" type="button" class="btn btn-primary d-none" data-bs-toggle="modal"
                  data-bs-target="#exampleModal">
                  Launch demo modal
                </button>-->
              </div>
            </mat-list-item>
          </mat-list>

          <div style="text-align: right;">

            <button mat-flat-button color="accent" (click)="concluirProceso()">Finalizar</button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>


    </mat-card-content>
  </mat-card>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="cerrarDialog()"></button>
      </div>
      <div class="modal-body" id="mypdf"
        style="background-image: url(/assets/img/732.gif); background-position: center; background-repeat: no-repeat; background-size: auto;">
      </div>
      <div class="modal-footer">
        <button type="button" mat-flat-button color="accent" data-bs-dismiss="modal"
          (click)="cerrarDialog()">Cerrar</button>
      </div>
    </div>
  </div>
</div>