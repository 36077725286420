import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DesitionBoxComponent } from 'src/app/modales/desition-box/desition-box.component';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
import { Grupos } from 'src/app/model/grupos';
import {NivelEducativo} from 'src/app/model/nivel_educativo';
import { AlumnsService } from 'src/app/services/alumns-service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ControlEscolarService } from 'src/app/services/control-escolar.service';
import { ParametrosService } from 'src/app/services/parametros.service';
import { SessionServiceService } from 'src/app/services/session-service.service';

const ELEMENT_DATA: any[] = [
];

@Component({
  selector: 'app-expedientes-alumnos',
  templateUrl: './expedientes-alumnos.component.html',
  styleUrls: ['./expedientes-alumnos.component.scss']
})
export class ExpedientesAlumnosComponent implements OnInit {
  constructor(private params:ParametrosService,private router:Router,private _alumn:AlumnsService,public dialog: MatDialog, private config:ConfigurationService, 
    public controlEscolarService:ControlEscolarService,
    public _session:SessionServiceService) { 
    this.displayedColumns= ['position','adeudo','matricula','nombre', 'proceso', 'estatus', 'nivel', 'grado','grupo','ficha','expediente','view','ctrlEscolar', 'eliminar'];
    if(this.router.url.indexOf("expedientes-psicopedagogicos")>-1){
      this.displayedColumns= ['position' ,'matricula','nombre','nivel','grado','grupo','ficha','estatus','psicologico','pedagogico'];
    }
    
  }
  ciclos=[];
  niveles=[];
  grados=[];
  grupos=[];
  length = 0;
  pageSize = this.config.getConfiguration().paginator.defaultPageSize;
  pageSizeOptions: number[] = this.config.getConfiguration().paginator.pageSizeOptions;

  // MatPaginator Output
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  isLoading = false;
  displayedColumns: string[] = ['position','matricula','nombre', 'proceso', 'estatus', 'nivel', 'grado','grupo','ficha','expediente','view','ctrlEscolar', 'eliminar'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  formulario = new FormGroup({
    busqueda : new FormControl("",[]),
    ciclo: new FormControl("",[]),
    nivel: new FormControl("",[]),
    grado: new FormControl("0",[]),
    grupo: new FormControl("0",[]),
    adeudos: new FormControl("0",[]),
    inscritos: new FormControl("0",[]),
  });
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto');
  public id_perfil: any;
  public mesActual: number = new Date().getMonth();
  public id_perfil_fk: any;

  ngOnInit(): void {
    //this.buscar();
    this.getCatalogos();
    console.log(this.getSesion());
    this.id_perfil =  this.getSesion().id_perfil_fk;
    this.id_perfil_fk = this._session.getSession().getUsuario().id_perfil_fk;

    console.log(this.id_perfil);
    if(this.id_perfil == 5){
      this.formulario.patchValue({ nivel: '0' });
    }else if(this.id_perfil == 11){
      this.formulario.patchValue({ nivel: '1' });
    }else if(this.id_perfil == 12){
      this.formulario.patchValue({ nivel: '3' });
    }else if(this.id_perfil == 6){
      this.formulario.patchValue({ nivel: '1' });
    }else if(this.id_perfil == 7){
      this.formulario.patchValue({ nivel: '2' });
    }else if(this.id_perfil == 8){
      this.formulario.patchValue({ nivel: '3' });
    }else if(this.id_perfil == 9){
      this.formulario.patchValue({ nivel: '4' });
    }
    this.buscar();
    
  }

  getSesion(){
    return this._session.getSession().getUsuario();
  }

  public activo: number = 0;
  getCatalogos(){
    this.params.findAll("ciclos").subscribe(result=>{
      console.log(result);
      this.ciclos = result.datos.ciclos;
      for(let i = 0; i < this.ciclos?.length; i++){
        if(this.ciclos[i]?.activo == '1'){
            this.activo = this.ciclos[i]?.id_ciclo_pk;
        }
      }
    },error=>{

    });

    this.params.findAll("niveles").subscribe(result=>{
      this.niveles = result.datos.niveles;
    },error=>{

    });

    this.params.findAll("grupos").subscribe(result=>{
      this.grupos = result.datos.grupos;
    },error=>{

    });
  }
  getGrados(event){
    this.formulario.controls["grado"].setValue("0");
    this.params.findAllDinamic("grados",event.value).subscribe(result=>{
      this.grados = result.datos.grados;
    },error=>{

    });
  }
  buscar(){
    
    this.filtro(0,this.pageSize);
  }

  filtro(index:number,size:number){
    this.pagina_act = index;
    console.info(this.formulario.controls["busqueda"].value);
    this.isLoading = true;
    this._alumn.findAlumnFiltro2(this.formulario.getRawValue(),index,size).subscribe(result2=>{
      console.log("result2: ", result2);
      this.isLoading = false;
      if(result2.code==200){
        
        if(index==0){
          this.paginator.pageIndex=0;
        }
        console.info(result2);
        this.length = result2.size;
        this.dataSource = new MatTableDataSource<any>(result2.datos.alumnos);
        
      }else{
        this.length = 0;
        this.dataSource = new MatTableDataSource<any>([]);
        
      }
    },error=>{
      this.isLoading = false;
      this.length = 0;
      console.info(error);
      this.dataSource = new MatTableDataSource<any>([]);
      
    },()=>{
      this.isLoading = false;
    });
  }
  pageEvent(event){
    console.info(event);
    this.filtro(event.pageSize * event.pageIndex,event.pageSize);
  }

  public pagina_act: number;
  public postEliminarCuentaAlumno(id: number, nombre: string){
    

    const dialogRef = this.dialog.open(DesitionBoxComponent, {
      //width: '250px',
      data: {title:"ATENCIÓN", text:"Está por eliminar el registro del Alumno: " +nombre+ " ¿Desea continuar?",icon:"help_outline"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);  


      if(result){
        console.log(id);
        this.isLoading = true;
        this._alumn.postEliminarCuentaAlumno({alumno: id}).subscribe(result =>{
          console.log(result);
          this.isLoading = false;
          if(result.estado==200){
            this.filtro(this.pagina_act,this.pageSize);
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: "El registro del alumno se ha eliminado.", icon:"done", href:null}});
          }else{
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas", text: "Algo salio mal con el servidor, intentelo más tarde", icon:"error_outline", href:null}});
          }
          
        },error=>{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas", text: "Algo salio mal con el servidor, intentelo más tarde", icon:"error_outline", href:null}});
          this.isLoading = false;
          console.info(error);
        });
        
      }
    });
  }

  
  exportar(){

    this.isLoading=true;
    this._alumn.export().subscribe(response=>{
      this.isLoading=false;
      this.downLoadFile(response, "application/ms-excel")
    });
  }
  /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
   downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    /*let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert( 'Please disable your Pop-up blocker and try again.');
    }*/

    let downloadLink = document.createElement('a');
          downloadLink.href = url;
          downloadLink.setAttribute('download', "export-alumnos-"+new Date().toJSON().split("T")[0].replace("-","").replace("-","")+new Date().toJSON().split("T")[1].replace(':','').replace(':','').split('.')[0]+".xls");
          document.body.appendChild(downloadLink);
          downloadLink.click();
    }












    public conceptos: any = [];
  public getConceptos(e: any){
    this.controlEscolarService.getDeudas(e?.id_alumno_pk, this.activo, null, null).subscribe(result=>{
      console.log(result);
      var texto = '';
      var all = result?.datos?.porpagar;
      if(result.code == 200){

        for(let i = 0; i < all?.length; i++){
          if(all[i].descuento == null){
            all[i].descuento = 0;
          }
          
            if(all[i]?.motivo != null){
              console.log('a', -all[i].recargos*(all[i].precio_unitario-all[i].descuento)+(all[i].precio_unitario-all[i].descuento) - all[i].pagado );
              if(   (all[i].vencido == "1" && (-all[i].recargos*(all[i].precio_unitario-all[i].descuento)+(all[i].precio_unitario-all[i].descuento) - all[i].pagado)>0)     ){
                texto =  texto + ' - ' + all[i]?.concepto;
              }
                
            }else{
              console.log('b', all[i].recargos, all[i].precio_unitario, all[i].descuento, all[i].pagado );
              if(   (all[i].vencido == "1" && (all[i].recargos*(all[i].precio_unitario-all[i].descuento)+(all[i].precio_unitario-all[i].descuento) - all[i].pagado)>0)     ){
                texto =  texto + ' - ' + all[i]?.concepto;
              }
            }
        }


        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Adeudos", text: texto,  href:null}});


      }
    });
  }

  getMonNumber(fecha_limite: any){
    //console.log(new Date(fecha_limite).getMonth(), this.mesActual);
    return new Date(fecha_limite).getMonth();
  }








  
}
