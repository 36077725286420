import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { config } from 'rxjs';
import { AlumnsService } from 'src/app/services/alumns-service';
import { ConfigurationService } from 'src/app/services/configuration.service';
const ELEMENT_DATA: any[] = [
];
@Component({
  selector: 'app-expedientes-psicopedagogicos',
  templateUrl: './expedientes-psicopedagogicos.component.html',
  styleUrls: ['./expedientes-psicopedagogicos.component.scss']
})
export class ExpedientesPsicopedagogicosComponent implements OnInit {

  constructor(private _alumn:AlumnsService,private config:ConfigurationService) { 
      
    }

  
  length = 0;
  pageSize = this.config.getConfiguration().paginator.defaultPageSize;
  pageSizeOptions: number[] = this.config.getConfiguration().paginator.pageSizeOptions;

  // MatPaginator Output
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  isLoading = false;
  displayedColumns: string[] = ['nombre', 'proceso', 'estatus', 'nivel', 'grado','grupo','seleccionar'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);;

  formulario = new FormGroup({
    busqueda : new FormControl("",[]),
    //nivel: new FormControl("",[])
  });
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto');

  ngOnInit(): void {
    this.buscar();
  }

  buscar(){
    this.filtro(0,this.pageSize);
  }

  filtro(index:number,pageSize:number){
    console.info(this.formulario.controls["busqueda"].value);
    this.isLoading = true;
    this._alumn.findAlumnFiltro(this.formulario.controls["busqueda"].value,index,pageSize).subscribe(result2=>{
      this.isLoading = false;
      if(result2.code==200){
        console.info(result2);
        if(index==0){
          this.paginator.pageIndex=0;
        }
        this.length = result2.size;
        this.dataSource = new MatTableDataSource<any>(result2.datos.alumnos);
      }else{
        this.length = 0;
        this.dataSource = new MatTableDataSource<any>([]);
      }
    },error=>{
      this.length = 0;
      this.isLoading = true;
      console.info(error);
      this.dataSource = new MatTableDataSource<any>([]);
    });
  }
  pageEvent(event){
    console.info(event);
    this.filtro(event.pageSize * event.pageIndex,event.pageSize);
  }
}
