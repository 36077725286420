import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { MatCardActions } from '@angular/material/card';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
//import { ResponseGenerico } from '../model/response-generico';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class BibliotecaGeneralService {

  headers = new HttpHeaders();
  public laravelapi;
  public laravelapiStorageTutores;
  constructor(
    protected _http:HttpClient,
    private _config:ConfigurationService
  ){
      this.laravelapi = _config.getConfiguration().laravelapi;
      this.laravelapiStorageTutores = _config.getConfiguration().laravelapiStorageTutores;
      console.log(this.laravelapi);
      console.log(this.laravelapiStorageTutores);
  }
  public getLaravelApiStorageTutores(){
    return this.laravelapiStorageTutores;
  }
  

  public getLaravelApi(){
    return this.laravelapi;
  }

  public getDatosClinicosForm():any{
    return this._http.get(this.laravelapi+"DatosClinicosForm");
  }


  public postModificarDocGeneral(datos: any):any{
    return this._http.post(this.laravelapi+"RegistrarModificarArchivoBiblioteca", datos, {});
  }

  public postRegistrarDocGeneral(datos: any):any{
    return this._http.post(this.laravelapi+"RegistrarModificarArchivoBiblioteca", datos, {});
  }

  public getConsultarDocumentosGeneral(p: number, r: number):any{
    return this._http.get(this.laravelapi+"ConsultarDocumentosBibliotecaPaginador?page="+p+'&filas='+r);
  }

  





  



  public postRegistrarCategoriaBiblioteca(datos: any):any{
    return this._http.post(this.laravelapi+"RegistrarCategoriaBiblioteca", datos, {});
  }

  public postModificarCategoriaBiblioteca(datos: any):any{
    return this._http.post(this.laravelapi+"ModificarCategoriaBiblioteca", datos, {});
  }

  public getConsultarCategoriaBiblioteca(id: number):any{
    return this._http.get(this.laravelapi+"ConsultarCategoriaBiblioteca?numero_categoria="+id);
  }


  public getConsultarCategoriasBibliotecaPaginador(p: number, r: number):any{
    return this._http.get(this.laravelapi+"ConsultarCategoriasBibliotecaPaginador?page="+p+'&filas='+r);
  }
  


  public getConsultarCategoriasBibliotecaTodas():any{
    return this._http.get(this.laravelapi+"ConsultarCategoriasBibliotecaTodas");
  }

  

  


}