<form autocomplete="off" (ngSubmit)="enviar()" [formGroup]="formulario">
    <h1 mat-dialog-title>
        <mat-icon>email</mat-icon>
    </h1>
    <div mat-dialog-content>
        <br>
        <h1>Recuperación de contraseña</h1>
        <p>Escriba su correo electrónico para recuperar su contraseña de acceso.</p>
        
        <mat-form-field appearance="outline">
            <mat-label>Correo</mat-label>
            <input matInput type="text" formControlName="correo">
        </mat-form-field>
    </div>
    <div mat-dialog-actions align="center">
        <button mat-flat-button color="accent">Envíar</button>&nbsp;
        <button type="button" mat-stroked-button (click)="onNoClick()">Cancelar</button>&nbsp;
    </div>
</form>