<br><br>
<div class="lightbox">

</div>
<div class="card-container encuesta">
  <mat-card class="example-card">
    <div class="spinner" *ngIf="isLoading">
      <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
    </div>

    <mat-card-header>
      <mat-card-title>Alta de Alumnos</mat-card-title>
      <mat-card-subtitle>Complemente la información solicitada para el expediente del alumno.</mat-card-subtitle>
    </mat-card-header>

    <mat-horizontal-stepper [linear]="tipoDeStepper" #stepper>
      <mat-step [stepControl]="formularioAlumno">
        <form autocomplete="off" [formGroup]="formularioAlumno" (ngSubmit)="saveAlumno()">
          <ng-template matStepLabel>Datos Generales</ng-template>
          <mat-card-subtitle>1. DATOS DEL ALUMNO</mat-card-subtitle>

          <div class="bt col-md-12">
            <ng-container *ngFor="let foto of fotos; let i= index">
              <div class="bt col-md-2 thumbnail" *ngIf="i==0">
                <img src="/assets/img/transparent.png" style="background-image:url({{endpoint}}/php/{{foto.ruta}})"
                  alt="Foto{{i+1}}" width="100%" height="auto" />
              </div>
              <div class="bt col-md-1 thumbnail" *ngIf="i>0">
                <img src="/assets/img/transparent.png" style="background-image:url({{endpoint}}/php/{{foto.ruta}})"
                  alt="Foto{{i+1}}" width="100%" height="auto" />
              </div>
            </ng-container>

          </div>

          <div class="bt col-md-2" *ngIf="preview">
            <img [src]="preview" alt="" width="200px">
          </div>
          <mat-form-field appearance="fill" class="bt col-md-4">
            <mat-label>Foto del alumno</mat-label>
            <input matInput readonly [value]="fotoSelector.value" formControlName="url" #url>
            <button mat-icon-button type="button" matSuffix (click)="fotoSelector.click()">
              <mat-icon>attach_file</mat-icon>
            </button>
          </mat-form-field>
          <button mat-mini-fab type="button" (click)="cargarFoto()" [disabled]="url.value==''" *ngIf="this.id">
            <mat-icon>file_upload</mat-icon>
          </button>

          <div class="bt col-md-2" style="display: none;">
            <input #fotoSelector [disabled]="cargaFotos" type='file' accept='.png, .jpg, .jpeg'
              (change)="onFileSelect($event)">
          </div>

          <br><br><br>
          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="" formControlName="nombre" maxlength="100">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Apellido paterno</mat-label>
            <input matInput placeholder="" formControlName="apellidop" maxlength="100">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Apellido materno</mat-label>
            <input matInput placeholder="" formControlName="apellidom" maxlength="100">
          </mat-form-field>
          <section class="bt col-md-3">
            <mat-radio-group formControlName="genero">&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="1">Niño</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="2">Niña</mat-radio-button>
            </mat-radio-group>
          </section>
          <mat-card-subtitle></mat-card-subtitle>
          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Fecha de nacimiento</mat-label>
            <input type="date" matInput  formControlName="fecha_nacimiento" 
              >
            
            
          </mat-form-field>
          <mat-form-field class="bt col-md-1">
            <mat-label>Edad</mat-label>
            <input matInput value="{{calculaEdad()}}">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-4">
            <mat-label>Lugar de nacimiento</mat-label>
            <mat-select formControlName="estado">
              <mat-option *ngFor="let edo of estados" [value]="edo.id_estado_pk">{{edo.nombre}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-4" *ngIf="formularioAlumno.value.estado == '33'">
            <mat-label>Nombre del lugar extranjero</mat-label>
            <input matInput placeholder="nombre del lugar extranjero" formControlName="nombre_extranjero" maxlength="50">
          </mat-form-field>

          <mat-form-field appearance="fill" class="bt col-md-4">
            <mat-label>CURP</mat-label>
            <input matInput placeholder="" formControlName="curp" maxlength="18">
          </mat-form-field>
          <!--<mat-card-subtitle></mat-card-subtitle>
                    <mat-label>Grado al que desea ingresar:</mat-label>&nbsp;&nbsp;
                    <mat-form-field appearance="fill" class="bt col-md-2">
                      <mat-label>Nivel</mat-label>
                      <mat-select formControlName="nivel_educativo">
                        <mat-option *ngFor="let level of nivelValues" [value]="level.id_nivel_educativo_pk" (click)="traegrado(level.id_nivel_educativo_pk)">{{level.nivel}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-2">
                        <mat-label>Grado</mat-label>
                        <mat-select formControlName="grado">
                          <mat-option *ngFor="let grade of gradoValues" [value]="grade.id_grado_pk">{{grade.grado}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <br>-->
          <mat-card-subtitle>2. DOMICILIO</mat-card-subtitle>
          <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Colonia</mat-label>
            <!--<input matInput placeholder="" formControlName="colonia" maxlength="100">-->
            <mat-select formControlName="colonia" (selectionChange)="cambioComboColonia()">
              <mat-option *ngFor="let colonia of colonias" [value]="colonia.id_colonia_pk">{{colonia.descripcion}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Otra colonia</mat-label>
            <input matInput placeholder="" formControlName="colonia2" maxlength="100">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-4">
            <mat-label>Calle</mat-label>
            <input matInput placeholder="" formControlName="calle" maxlength="100">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-2">
            <mat-label>Num EXT</mat-label>
            <input matInput placeholder="" formControlName="numExt" maxlength="15">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-2">
            <mat-label>Num INT</mat-label>
            <input matInput placeholder="" formControlName="numInt" maxlength="15">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-2">
            <mat-label>Código postal</mat-label>
            <input matInput placeholder="" formControlName="codigo" pattern="^[0-9]+" maxlength="5">
          </mat-form-field>

          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Víve con</mat-label>
            <mat-select formControlName="viveCon" (selectionChange)="cambioComboViveCon()">
              <mat-option *ngFor="let s of vivecon" [value]="s.id_vivecon_pk">{{s.nombre}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Otro</mat-label>
            <input matInput placeholder="" formControlName="viveConOtro" maxlength="100">
          </mat-form-field>
          <br>
          <!--<mat-card-subtitle>3. Datos familiares</mat-card-subtitle>
                    <mat-label>Miembros de la familia:</mat-label>&nbsp;&nbsp;
                      <mat-form-field appearance="fill" class="bt col-md-1">
                        <input matInput placeholder="" type="number" formControlName="familia" pattern="^[0-9]+" maxlength="2">
                      </mat-form-field>
                      <br>
                    <mat-card-subtitle>Información de mamá</mat-card-subtitle>
                      <mat-form-field appearance="fill" class="bt col-md-4" >
                        <mat-label>Nombre</mat-label>
                        <input matInput placeholder="" formControlName="nombre_mama" maxlength="100">
                      </mat-form-field>
                      <mat-form-field appearance="fill" class="bt col-md-4" >
                        <mat-label>Apellido paterno</mat-label>
                        <input matInput placeholder="" formControlName="apellidop_mama" maxlength="100">
                      </mat-form-field>
                      <mat-form-field appearance="fill" class="bt col-md-4" >
                        <mat-label>Apellido materno</mat-label>
                        <input matInput placeholder="" formControlName="apellidom_mama" maxlength="100">
                      </mat-form-field>
                      <mat-form-field appearance="fill" class="bt col-md-4" >
                        <mat-label>Estado civil</mat-label>
                        <mat-select formControlName="estado_civil_mama">
                          <mat-option *ngFor="let edoC of civilValues" [value]="edoC.id_estado_civil_pk">{{edoC.estado_civil}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill" class="bt col-md-4" >
                        <mat-label>Grado de estudios</mat-label>
                        <mat-select formControlName="nivel_mama">
                          <mat-option *ngFor="let lvl of nivelpValues" [value]="lvl.id_nivel_educativo_pk">{{lvl.nivel}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill" class="bt col-md-4" >
                        <mat-label>Ocupación</mat-label>
                        <mat-select formControlName="ocupacion_mama">
                          <mat-option *ngFor="let op of ocupacionValues" [value]="op.id_ocupacion_pk">{{op.ocupacion}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill" class="bt col-md-4" >
                        <mat-label>Correo electrónico</mat-label>
                        <input matInput placeholder="" type="email" formControlName="correo_mama" maxlength="100">
                      </mat-form-field>
                      <mat-form-field appearance="fill" class="bt col-md-2" >
                        <mat-label>Teléfono fijo</mat-label>
                        <input matInput placeholder="" type="number" formControlName="telefono_mama" pattern="^[0-9]+" maxlength="10">
                      </mat-form-field>
                      <mat-form-field appearance="fill" class="bt col-md-2" >
                        <mat-label>Teléfono movil</mat-label>
                        <input matInput placeholder="" type="number" formControlName="celular_mama" pattern="^[0-9]+" maxlength="10">
                      </mat-form-field>
                      <br>
                    <mat-card-subtitle>Información de papá</mat-card-subtitle>
                    <mat-form-field appearance="fill" class="bt col-md-4" >
                      <mat-label>Nombre</mat-label>
                      <input matInput placeholder="" formControlName="nombre_papa" maxlength="100">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-4" >
                      <mat-label>Apellido paterno</mat-label>
                      <input matInput placeholder="" formControlName="apellidop_papa" maxlength="100">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-4" >
                      <mat-label>Apellido materno</mat-label>
                      <input matInput placeholder="" formControlName="apellidom_papa" maxlength="100">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-4" >
                      <mat-label>Estado civil</mat-label>
                      <mat-select formControlName="estado_civil_papa">
                        <mat-option *ngFor="let edoC of civilValues" [value]="edoC.id_estado_civil_pk">{{edoC.estado_civil}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-4" >
                      <mat-label>Grado de estudios</mat-label>
                      <mat-select formControlName="nivel_papa">
                        <mat-option *ngFor="let lvl of nivelpValues" [value]="lvl.id_nivel_educativo_pk">{{lvl.nivel}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-4" >
                      <mat-label>Ocupación</mat-label>
                      <mat-select formControlName="ocupacion_papa">
                        <mat-option *ngFor="let op of ocupacionValues" [value]="op.id_ocupacion_pk">{{op.ocupacion}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-4" >
                      <mat-label>Correo electrónico</mat-label>
                      <input matInput placeholder="" type="email" formControlName="correo_papa" maxlength="100">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-2" >
                      <mat-label>Teléfono fijo</mat-label>
                      <input matInput placeholder="" type="number" formControlName="telefono_papa" pattern="^[0-9]+" maxlength="10">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-2" >
                      <mat-label>Teléfono movil</mat-label>
                      <input matInput placeholder="" type="number" formControlName="celular_papa" pattern="^[0-9]+" maxlength="10">
                    </mat-form-field> -->
          <br><br>
          <div class="text-align-center">
            <!--<button mat-flat-button color="primary" type="submit">Crear Registro</button>&nbsp;-->
            <!--<button mat-stroked-button color="primary" type="submit" >Guardar</button>&nbsp;-->
            <!--<button mat-stroked-button color="primary" type="submit" [routerLink]="['/control-escolar/expediente-alumno']">Cancelar</button>-->
            <button mat-flat-button color="primary" matStepperNext *ngIf="tipoDeStepper">Siguiente</button>
            <button mat-flat-button color="primary" *ngIf="!tipoDeStepper">Guardar</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="formularioEscolar" label="Datos Complementarios">
        <form autocomplete="off" [formGroup]="formularioEscolar">
          <mat-card-subtitle>1. PROCEDENCIA ACADÉMICA</mat-card-subtitle>
          <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Escuela de procedencia</mat-label>
            <input matInput placeholder="" formControlName="procedencia" maxlength="300">
          </mat-form-field>
          <section class="bt col-md-5">
            <mat-radio-group formControlName="escuela">&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="1">Pública</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="2">Privada</mat-radio-button>
            </mat-radio-group>
          </section>
          <mat-form-field appearance="fill" class="bt col-md-12">
            <mat-label>Motivo del cambio</mat-label>
            <input matInput placeholder="" formControlName="cambio" maxlength="2500">
          </mat-form-field>
          <br><br>
          <mat-card-subtitle>2. CONSIDERACIONES MÉDICAS</mat-card-subtitle>
          <section class="bt col-md-6">
            <mat-label>Alergias</mat-label>
            <mat-radio-group formControlName="alergias" (change)="cambioAlergias()">&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="1">No</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="2">Si</mat-radio-button>
            </mat-radio-group>
          </section>
          <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Cual</mat-label>
            <input matInput placeholder="" formControlName="alergiaWhich" maxlength="2500">
          </mat-form-field>
          <section class="bt col-md-6">
            <mat-label>Enfermedad crónica</mat-label>
            <mat-radio-group formControlName="enfermedad" (change)="cambioEnfermedad()">&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="1">No</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="2">Si</mat-radio-button>
            </mat-radio-group>
          </section>
          <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Cual</mat-label>
            <input matInput placeholder="" formControlName="enfermedadWhich" maxlength="2500">
          </mat-form-field>
          <div class="bt col-md-6"></div>
          <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Grupo sanguineo</mat-label>
            <mat-select formControlName="grupo_sanguineo">
              <mat-option *ngFor="let gs of grupo_sangineo" [value]="gs.id_grupo_sanguineo_pk">{{gs.descripcion}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <section class="bt col-md-6">
            <mat-label>Seguro médico</mat-label>
            <mat-radio-group formControlName="seguro" (change)="cambioSeguroMedico()">&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="1">No</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="2">Si</mat-radio-button>
            </mat-radio-group>
          </section>
          <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Cual</mat-label>
            <input matInput placeholder="" formControlName="seguroWhich" maxlength="150">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Enfermedades familiares</mat-label>
            <mat-select formControlName="enfermedadesFamiliares" (selectionChange)="cambioEnfermedadesFamiliares()">
              <mat-option *ngFor="let enfermedad of enfermedades" [value]="enfermedad.id_enfermedades_pk">
                {{enfermedad.descripcion}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Otra</mat-label>
            <input matInput placeholder="" formControlName="otraenfermedad" maxlength="150">
          </mat-form-field>

          <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Doctor de confianza</mat-label>
            <input matInput placeholder="" formControlName="doctorDeConfianza" maxlength="150">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Teléfono</mat-label>
            <input matInput placeholder="" formControlName="telDoctorDeConfianza" maxlength="10">
          </mat-form-field>
          <br><br>
          <mat-card-subtitle>3. RELIGIÓN</mat-card-subtitle>
          <mat-form-field appearance="fill" class="bt col-md-6">
            <mat-label>Religión que profesa</mat-label>
            <input matInput placeholder="" formControlName="religion" maxlength="100">
          </mat-form-field>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <section class="bt col-md-12"></section>
          <section class="bt col-md-3">
            <mat-label>Bautizado</mat-label>
            <mat-radio-group formControlName="bautizo">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="1">No</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="2">Si</mat-radio-button>
            </mat-radio-group>
          </section>
          <section class="bt col-md-12"></section>
          <section class="bt col-md-3">
            <mat-label>Confirmado</mat-label>
            <mat-radio-group formControlName="confirmado">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="1">No</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="2">Si</mat-radio-button>
            </mat-radio-group>
          </section>
          <section class="bt col-md-12"></section>
          <section class="bt col-md-3">
            <mat-label>Primera comunión</mat-label>
            <mat-radio-group formControlName="primeraComunion">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="1">No</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="2">Si</mat-radio-button>
            </mat-radio-group>
          </section>
          <br><br>
          <div>
            <!--<button mat-stroked-button matStepperPrevious color="primary">Anterior</button>-->
            &nbsp;
            <!--<button mat-stroked-button color="primary" type="submit" [routerLink]="['/control-escolar/expediente-alumno']">Guardar</button>&nbsp;
                  <button mat-stroked-button color="primary" type="submit" [routerLink]="['/control-escolar/expediente-alumno']">Cancelar</button>-->
            <button mat-flat-button matStepperNext color="primary" (click)="saveDatosComplementarios()"
              *ngIf="tipoDeStepper">Siguiente</button>
            <button mat-flat-button color="primary" *ngIf="!tipoDeStepper"
              (click)="saveDatosComplementarios()">Guardar</button>
          </div>
        </form>
      </mat-step>

      <mat-step label="Documentos">
        <form [formGroup]="formularioDocumentos" (ngSubmit)="addDocument()">


          <mat-card-subtitle>1. DOCUMENTOS DE EXPEDIENTE</mat-card-subtitle>
          <mat-form-field appearance="fill" class="bt col-md-4">
            <mat-label>Tipo de documento</mat-label>
            <mat-select formControlName="tipoDocto">
              <mat-option *ngFor="let tipo of tipo_documento" [value]="tipo.id_tipo_documento_pk">{{tipo.nombre}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-4">
            <mat-label>Adjuntar documento</mat-label>
            <input matInput readonly [value]="documentoSelector.value" formControlName="documento" accept="text/*">
            <button mat-icon-button type="button" matSuffix (click)="documentoSelector.click()">
              <mat-icon>attach_file</mat-icon>
            </button>
          </mat-form-field>



          <div class="bt col-md-" style="display: none;">
            <mat-label></mat-label>
            <input #documentoSelector type="file" (change)="onFileSelection($event)"
              accept="image/jpg,image/jpeg,application/pdf">
          </div>
          <div class="bt col-md-2">
            <button mat-mini-fab color="primary" [disabled]="formularioDocumentos.invalid">
              <mat-icon>add</mat-icon>
            </button>
          </div>

          <div class="bt col-md-12">
            <mat-list>
              <div mat-subheader>Documentos</div>
              <mat-list-item *ngFor="let documento of documentos">
                <mat-icon mat-list-icon>description</mat-icon>
                <div mat-line>{{documento.nombre}}</div>
                <div mat-line> {{documento.fecha_alta | date}} </div>
                <div mat-line>
                  <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                    <mat-icon>download</mat-icon>
                  </button>
                  <a href="/php/{{documento.ruta_descarga}}" download="{{documento.nombre}}"
                    target="_self">Descargar</a>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
          <button type="reset" id="btnReset" style="display: none;">reset</button>
        </form>
        <div>

          <!--<button mat-stroked-button color="primary" matStepperPrevious>Anterior</button>&nbsp;-->
          <button mat-flat-button color="primary" matStepperNext type="button">Siguiente</button>
        </div>

      </mat-step>

      <mat-step [stepControl]="formularioContacto" label="Contactos">
        <form [formGroup]="formularioContacto">
          <mat-card-subtitle>1. CONTACTOS</mat-card-subtitle>
          <mat-form-field appearance="fill" class="bt col-md-4">
            <mat-label>Parentesco</mat-label>
            <mat-select formControlName="parentesco">
              <mat-option *ngFor="let p of parentescos" [value]="p.id_parentesco_pk">{{p.descripcion}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-card-subtitle></mat-card-subtitle>
          <mat-form-field appearance="fill" class="bt col-md-4">
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="" formControlName="nombre" maxlength="100">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-4">
            <mat-label>Apellido paterno</mat-label>
            <input matInput placeholder="" formControlName="apaterno" maxlength="100">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-4">
            <mat-label>Apellido materno</mat-label>
            <input matInput placeholder="" formControlName="amaterno" maxlength="100">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Calle</mat-label>
            <input matInput formControlName="calle" maxlength="100">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Número</mat-label>
            <input matInput formControlName="numero" maxlength="45">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Colonia</mat-label>
            <input matInput formControlName="colonia" maxlength="100">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Código postal</mat-label>
            <input matInput formControlName="codigopostal" maxlength="5">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Celular</mat-label>
            <input matInput formControlName="celular" pattern="^[0-9]+" maxlength="10">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Trabajo</mat-label>
            <input matInput formControlName="trabajo" pattern="^[0-9]+" maxlength="10">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Casa</mat-label>
            <input matInput formControlName="telefono" pattern="^[0-9]+" maxlength="10">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Correo electrónico</mat-label>
            <input matInput placeholder="" type="email" formControlName="correo" maxlength="100">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-4">
            <mat-label>Escolaridad</mat-label>
            <mat-select formControlName="escolaridad">
              <mat-option *ngFor="let esc of escolaridades" [value]="esc.id_escolaridad_pk">{{esc.descripcion}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Ocupación</mat-label>
            <input matInput placeholder="" type="text" formControlName="ocupacion" maxlength="100">
          </mat-form-field>
          <mat-form-field appearance="fill" class="bt col-md-3">
            <mat-label>Oficios que domina</mat-label>
            <input matInput placeholder="" type="text" formControlName="oficios" maxlength="100">
          </mat-form-field>
          <section class="bt col-md-12">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <mat-label>Tipo de contacto</mat-label>
            <input matInput placeholder="" maxlength="100" #tipo style="display: none;">
            <mat-radio-group formControlName="tipo">&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="1">1er contacto</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="2">2do contacto</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button class="example-margin" value="3">3er contacto</mat-radio-button>
            </mat-radio-group>
          </section>
          <!-- <section class="bt col-md-7">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <mat-label>Tipo de contacto</mat-label>
                  <input matInput placeholder=""  maxlength="100" #tipo style="display: none;" [value]="contacto.tipo">
                  
                  <mat-radio-group  [value]="contacto.tipo" (change)="tipo.value= $event.value">&nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-radio-button class="example-margin" value="1">1er contacto</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-radio-button class="example-margin" value="2">2do contacto</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-radio-button class="example-margin" value="3">3er contacto</mat-radio-button>
                  </mat-radio-group>
                </section> -->
          <mat-form-field appearance="fill" class="bt col-md-4" style="display: none;">
            <mat-label>Credencial</mat-label>
            <mat-select formControlName="credencial">
              <mat-option *ngFor="let c of credenciales" [value]="c.id_credenciales_pk">{{c.nombre}}</mat-option>
            </mat-select>
          </mat-form-field>
          <br><br>
          <button mat-flat-button color="accent" (click)="saveContacto()">
            <mat-icon>add</mat-icon>
            Agregar contacto
          </button>
        </form>
        <br><br>

        <div class="bt col-md-12">

          <mat-accordion>
            <mat-expansion-panel *ngFor="let contacto of contactos">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>account_circle</mat-icon>
                  &nbsp;{{contacto.nombre}} {{contacto.apellidop}} {{contacto.apellidom}}
                </mat-panel-title>
                <mat-panel-description>
                  {{contacto.descripcion}}
                </mat-panel-description>
              </mat-expansion-panel-header>

              <ng-container *ngIf="contacto.edit==true">
                <mat-form-field appearance="fill" class="bt col-md-4">
                  <mat-label>Parentesco</mat-label>
                  <mat-select [value]="contacto.id_parentesco_pk" #parentesco>
                    <mat-option *ngFor="let p of parentescos" [value]="p.id_parentesco_pk">{{p.descripcion}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-card-subtitle></mat-card-subtitle>
                <mat-form-field appearance="fill" class="bt col-md-4">
                  <mat-label>Nombre</mat-label>
                  <input matInput placeholder="" maxlength="100" [value]="contacto.nombre" #nombre>
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-4">
                  <mat-label>Apellido Paterno</mat-label>
                  <input matInput placeholder="" maxlength="100" [value]="contacto.apellidop" #apaterno>
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-4">
                  <mat-label>Apellido Materno</mat-label>
                  <input matInput placeholder="" maxlength="100" [value]="contacto.apellidom" #amaterno>
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-3">
                  <mat-label>Calle</mat-label>
                  <input matInput maxlength="100" [value]="contacto.calle" #calle>
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-3">
                  <mat-label>Número</mat-label>
                  <input matInput maxlength="45" [value]="contacto.numero" #numero>
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-3">
                  <mat-label>Colonia</mat-label>
                  <input matInput maxlength="100" [value]="contacto.colonia" #colonia>
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-3">
                  <mat-label>Código postal</mat-label>
                  <input matInput maxlength="5" [value]="contacto.codigo_postal" #codigopostal>
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-3">
                  <mat-label>Celular</mat-label>
                  <input matInput pattern="^[0-9]+" maxlength="10" [value]="contacto.celular" #celular>
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-3">
                  <mat-label>Trabajo</mat-label>
                  <input matInput [value]="contacto.trabajo" pattern="^[0-9]+" maxlength="10" #trabajo>
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-3">
                  <mat-label>Casa</mat-label>
                  <input matInput [value]="contacto.telefono_movil" pattern="^[0-9]+" maxlength="10" #telefono>
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-3">
                  <mat-label>Correo electrónico</mat-label>
                  <input matInput placeholder="" type="email" maxlength="100" [value]="contacto.correo" #correo>
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-4">
                  <mat-label>Escolaridad</mat-label>
                  <mat-select [value]="contacto.id_escolaridad_pk" #escolaridad>
                    <mat-option *ngFor="let esc of escolaridades" [value]="esc.id_escolaridad_pk">{{esc.descripcion}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-4">
                  <mat-label>Ocupación</mat-label>
                  <input matInput placeholder="" type="text" [value]="contacto.ocupacion" maxlength="100" #ocupacion>
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-4">
                  <mat-label>Oficios que domina</mat-label>
                  <input matInput placeholder="" type="text" [value]="contacto.oficios" maxlength="100" #oficios>
                </mat-form-field>

                <section class="bt col-md-12">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <mat-label>Tipo de contacto</mat-label>
                  <input matInput placeholder="" maxlength="100" style="display: none;" [value]="contacto.tipo" #tipo>

                  <mat-radio-group [value]="contacto.tipo" (change)="tipo.value= $event.value">&nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-radio-button class="example-margin" value="1">1er contacto</mat-radio-button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-radio-button class="example-margin" value="2">2do contacto</mat-radio-button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-radio-button class="example-margin" value="3">3er contacto</mat-radio-button>
                  </mat-radio-group>

                </section>
                <br><br>
                <mat-action-row>
                  <button mat-stroked-button type="button" color="default" (click)="editarContacto(contacto.id_familiar_pk,{
                      'parentesco':parentesco.value,
                      'nombre':nombre.value,
                      'apaterno':apaterno.value,
                      'amaterno':amaterno.value,
                      'celular':celular.value,
                      'trabajo':trabajo.value,
                      'telefono':telefono.value,
                      'correo':correo.value,
                      'tipo':tipo.value,
                      'escolaridad':escolaridad.value,
                      'ocupacion':ocupacion.value,
                      'oficios':oficios.value,
                      'calle':calle.value,
                      'numero':numero.value,
                      'colonia':colonia.value,
                      'codigopostal':codigopostal.value
                    })">
                    <mat-icon>save</mat-icon>
                    Guardar
                  </button>&nbsp;
                  <button mat-flat-button type="button" color="warn" (click)="contacto.edit=false">
                    <mat-icon>close</mat-icon>
                    Cancelar
                  </button>
                </mat-action-row>
              </ng-container>
              <ng-container *ngIf="contacto.edit==false">
                <div class="bt col-md-12">
                  <div class="bt col-md-12">
                    <mat-icon>map</mat-icon>&nbsp;
                    <strong>Dirección:&nbsp;</strong><span>{{contacto.calle}} {{contacto.numero}} {{contacto.colonia}}
                      {{contacto.codigo_postal}}</span>
                  </div>
                  <div class="bt col-md-4">
                    <mat-icon>smartphone</mat-icon>&nbsp;
                    <strong>Celular:&nbsp;</strong><span>{{contacto.celular}}</span>
                  </div>
                  <div class="bt col-md-4">
                    <mat-icon>business</mat-icon>&nbsp;
                    <strong>Trabajo:&nbsp;</strong><span>{{contacto.trabajo}}</span>
                  </div>
                  <div class="bt col-md-4">
                    <mat-icon>phone</mat-icon>&nbsp;
                    <strong>Casa:&nbsp;</strong><span>{{contacto.telefono_movil}}</span>
                  </div>
                  <div class="bt col-md-4">
                    <mat-icon>email</mat-icon>&nbsp;
                    <strong>Correo:&nbsp;</strong><span>{{contacto.correo}}</span>
                  </div>
                  <div class="bt col-md-4">
                    <mat-icon>school</mat-icon>&nbsp;
                    <strong>Escolaridad:&nbsp;</strong><span>{{contacto.escolaridad}}</span>
                  </div>
                  <div class="bt col-md-4">
                    <mat-icon>work</mat-icon>&nbsp;
                    <strong>Ocupación:&nbsp;</strong><span>{{contacto.ocupacion}}</span>
                  </div>
                  <div class="bt col-md-4">
                    <mat-icon>handyman</mat-icon>&nbsp;
                    <strong>Oficios que domina:&nbsp;</strong><span>{{contacto.oficios}}</span>
                  </div>


                </div>


                <!--<p>
                          <mat-icon>badge</mat-icon>&nbsp;
                          <strong>Credencial:&nbsp;</strong><span>{{contacto.nombre_credencial}}</span>
                        </p>-->

                <mat-action-row>
                  <button mat-stroked-button type="button" color="default" (click)="contacto.edit=true">
                    <mat-icon>edit</mat-icon>
                    Editar
                  </button>&nbsp;
                  <button mat-flat-button type="button" color="warn" (click)="eliminar(contacto.id_familiar_pk)">
                    <mat-icon>delete</mat-icon>
                    Eliminar
                  </button>
                </mat-action-row>
              </ng-container>

            </mat-expansion-panel>
          </mat-accordion>

        </div>
        <div>
          <button mat-flat-button matStepperNext color="primary" [routerLink]="['../']">Finalizar</button>
        </div>

      </mat-step>
      <mat-step label="Intranet">
        <ng-template matStepLabel>Intranet</ng-template>
        <mat-card-subtitle>1. Intranet</mat-card-subtitle>
        <app-intranet></app-intranet>
      </mat-step>


    </mat-horizontal-stepper>
  </mat-card>
</div>