<div class="card-container encuesta">
    
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Pagos Tesorería</mat-card-title>
            <mat-card-subtitle>Administrador de pagos</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            
            
                <div class="row">
                    <form autocomplete="no" [formGroup]="formulario" class="container-fluid">
                    <mat-form-field appearance="fill" class="col-md-6 p-1" >
                        <mat-label>Alumno</mat-label>
                        <input readonly matInput placeholder="Por nombre y correo" formControlName="alumno">
                        <button type="button" mat-icon-button matSuffix (click)="openLupa()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="col-md-6 p-1">
                        <mat-label>Ciclo escolar</mat-label>
                        <mat-select formControlName="ciclo" (selectionChange)="getData()">
                            <mat-option *ngFor="let ciclo of ciclosAlumno" value="{{ciclo.id_ciclo_pk}}"> {{ciclo.activo==1 ? '(Activo)':''}} {{ciclo.nombre}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    </form>


                    <!--<div class="row">
                        <div class="col">

                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <mat-form-field appearance="fill"  >
                                        <mat-label>Saldo disponible</mat-label>
                                        <input matInput placeholder="Saldo disponible" [(ngModel)]="saldo_disponible">
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <button mat-stroked-button color="primary" (click)="postModificarSaldoAlumno()">
                                        Modificar saldo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>-->
                    
                    <div class="container">
                        <div class="row pb-4">
                            <div class="col-md-12 position-relative"  *ngIf="this.idAlumno>0">
                                <div class="spinner" *ngIf="isLoading">
                                    <mat-spinner mode="indeterminate"></mat-spinner>
                                </div>
                                <mat-toolbar color="default">
                                    <mat-icon>payments</mat-icon>
                                    <span class="fs-6">&nbsp;Cuentas por cobrar</span>
                                </mat-toolbar>
                                <div class="container-fluid p-0 overflow-auto border" style="height: 25em;">
                                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" multiTemplateDataRows>
                                        <caption class="d-none"></caption>
                                        <ng-container matColumnDef="check">
                                            <th scope="col" mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell *matCellDef="let element">
                                                <mat-checkbox color="primary" class="example-margin"></mat-checkbox>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="estatus">
                                            <th scope="col" mat-header-cell *matHeaderCellDef class="text-left"> Estatus </th>
                                            <td mat-cell *matCellDef="let element" class="text-left"> 
                                                <!--<span data-bs-toggle="tooltip" data-bs-placement="right" title="Pagado" class="badge bg-success rounded-pill" >-->
                                                    <!--{{((element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado))}}-->
                                                    <ng-container *ngIf="element.condonacion_val == true; else noCon">
                                                        
                                                        <mat-icon class="btn-outline-success" *ngIf="(element.vencido == 1 || element.vencido == 0) && ((-element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado)<=0)">check</mat-icon>
                                                
                                                        <mat-icon class="btn-outline-danger" *ngIf="(element.vencido == 1 && (-element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado)>0)">event_busy</mat-icon>
                                                    
                                                        <mat-icon class="btn-outline-secondary" *ngIf="(element.vencido == 0 && (-element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado)>0)">event</mat-icon>
                                                    </ng-container>
                                                    <ng-template #noCon>
                                                        
                                                        <mat-icon class="btn-outline-success" *ngIf="(element.vencido == 1 || element.vencido == 0) && ((element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado)<=0)">check</mat-icon>
                                                
                                                        <mat-icon class="btn-outline-secondary" *ngIf="(element.vencido == 1 && (element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado)>0) && getMonNumber(element.fecha_limite) != mesActual && !element?.concepto?.includes('Inscripción') ">event_busy</mat-icon>
                                                        <mat-icon class="btn-outline-danger" *ngIf="(element.vencido == 1 && (element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado)>0) && (getMonNumber(element.fecha_limite) == mesActual ||  element?.concepto?.includes('Inscripción'))">event_busy</mat-icon>
                                                    
                                                        <mat-icon class="btn-outline-secondary" *ngIf="(element.vencido == 0 && (element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado)>0)">event</mat-icon>
                                                    </ng-template>
                                                    
                                                
                                        </ng-container>
                                        <ng-container matColumnDef="fecha_limite">
                                            <th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Fecha límite </th>
                                            <td mat-cell *matCellDef="let element" class="text-center"> {{element.fecha_limite | date : 'dd/MM/yyyy'}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="concepto">
                                            <th scope="col" mat-header-cell *matHeaderCellDef> Concepto </th>
                                            <td mat-cell *matCellDef="let element"> {{element.concepto}}</td>
                                        </ng-container>
                
                                        <ng-container matColumnDef="total">
                                            <th scope="col" mat-header-cell *matHeaderCellDef class="text-end">Precio sin Dct/Rec </th>
                                            <td mat-cell *matCellDef="let element" class="text-end"> {{element.precio_unitario | currency: 'MXN'}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="recargo">
                                            <th scope="col" mat-header-cell *matHeaderCellDef class="text-end"> Recargos </th>
                                            <td mat-cell *matCellDef="let element" class="text-end fw-bold " style="color: red;"> 
                                                <!--<ng-container *ngIf="element.recargos>0">
                                                    <mat-icon>trending_up</mat-icon>{{element.recargos*(element.precio_unitario-element.descuento) | currency : 'MXN'}} 
                                                </ng-container>
                                                &nbsp;-->
                                                <!--<ng-container>
                                                    <mat-icon>trending_up</mat-icon>{{element.recargos_axuliar_0 | currency : 'MXN'}} 
                                                </ng-container>-->
                                                <!--<ng-container *ngIf="element.id_c_p_c_fk != null || element.recargo_val<=0; else elseRecargos" >
                                                    <ng-container>
                                                        <mat-icon>trending_up</mat-icon>{{0 | currency : 'MXN'}} 
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #elseRecargos>
                                                    <ng-container>
                                                        <mat-icon>trending_up</mat-icon>{{element.recargos*(element.precio_unitario-element.descuento) | currency : 'MXN'}} 
                                                    </ng-container>
                                                </ng-template>-->

                                                <ng-container>
                                                    <mat-icon>trending_up</mat-icon>{{element.recargos*(element.precio_unitario-element.descuento) | currency : 'MXN'}} 
                                                </ng-container>
                                                

                                                <!--<ng-container *ngIf="element.suma_recargos>0">
                                                    <mat-icon>trending_up</mat-icon>{{element.suma_recargos | currency : 'MXN'}} 
                                                </ng-container>--->
                                                

                                                
                                                <!--0 sin recargo-->
                                            </td>
                                        </ng-container>


                                        <ng-container matColumnDef="condonacion">
                                            <th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Condonación <br> de recargos </th>
                                            <td mat-cell *matCellDef="let element" class="text-center" >

                                                <ng-container *ngIf="element.condonacion_val == true">
                                                    Sí
                                                </ng-container>
                                                <ng-container *ngIf="element.condonacion_val == false">
                                                    No
                                                </ng-container>
                                                &nbsp;

                                                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="setModalCondonacion(element.motivo, element.condonacion_val)">
                                                    Ver
                                                </button>

                                                <!--<mat-checkbox  class="example-margin" [(ngModel)]="element.condonacion_val" (change)="postEliminaCon2(element.condonacion_val)"></mat-checkbox>-->

                                                <!--<button *ngIf="element.recargos > 0" type="button" mat-mini-fab color="warn" (click)="postEliminaCon(d.id_concepto_fk, d.id_ciclo_fk, d.precio_unitario, 0, d.motivo, d.id_operacion_pk)">
                                                    <mat-icon class="material-icons" >
                                                        delete
                                                    </mat-icon>
                                                </button>-->




                                            </td>
                                            <!--<td mat-cell *matCellDef="let element" class="text-end">{{0*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado | currency : 'MXN'}}</td> sin recargo-->
                                        </ng-container>

                                        <!--<ng-container matColumnDef="descuento">
                                            <th scope="col" mat-header-cell *matHeaderCellDef>Descuento </th>
                                            <td mat-cell *matCellDef="let element">
                                                <mat-form-field appearance="fill" class="col-md-6" >
                                                    <mat-label></mat-label>
                                                    <input type="number" matInput [value]="element.descuento">
                                                </mat-form-field>
                                            </td>
                                        </ng-container>-->



                                        <ng-container matColumnDef="adeudo">
                                            <th scope="col" mat-header-cell *matHeaderCellDef class="text-end"> Pendiente por pagar </th>
                                            <td mat-cell *matCellDef="let element" class="text-end" >

                                                <span *ngIf="element.condonacion_val == true">
                                                    {{element.porpagar_auxiliar + -element.recargos*(element.precio_unitario-element.descuento)  | currency : 'MXN'}}
                                                    <!--<ng-container *ngIf="element.id_c_p_c_fk != null || element.recargo_val<=0; else elseppp" >
                                                        {{element.porpagar_auxiliar + -element.recargos*(element.precio_unitario-element.descuento)  | currency : 'MXN'}}
                                                    </ng-container>
                                                    <ng-template #elseppp>
                                                        {{element.porpagar_auxiliar + -element.recargos*(element.precio_unitario-element.descuento)  | currency : 'MXN'}}
                                                    </ng-template>-->

                                                   
                                                </span>
                                                <span *ngIf="element.condonacion_val == false">

                                                    {{element.porpagar_auxiliar | currency : 'MXN'}}
                                                </span>
                                            </td>

                                            <!--<td mat-cell *matCellDef="let element" class="text-end">{{0*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado | currency : 'MXN'}}</td> sin recargo-->
                                        </ng-container>
                
                                        <ng-container matColumnDef="pagado">
                                            <th scope="col" mat-header-cell *matHeaderCellDef class="text-end"> Pagado </th>
                                            <td mat-cell *matCellDef="let element" class="text-end"> {{element.pagado | currency : 'MXN'}} </td>
                                        </ng-container>
                                        
                                       
                                        <ng-container matColumnDef="add">
                                            <th scope="col" mat-header-cell *matHeaderCellDef>  </th>
                                            <td mat-cell *matCellDef="let element" class="text-end">
                                                <ng-container *ngIf="element.condonacion_val == true; else noCon">
                                                        
                                                    <!--<mat-icon class="btn-outline-success" *ngIf="(element.vencido == 1 || element.vencido == 0) && ((-element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado)<=0)">check</mat-icon>
                                            
                                                    <mat-icon class="btn-outline-danger" *ngIf="(element.vencido == 1 && (-element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado)>0)">event_busy</mat-icon>
                                                
                                                    <mat-icon class="btn-outline-secondary" *ngIf="(element.vencido == 0 && (-element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado)>0)">event</mat-icon>-->

                                                    <button mat-icon-button class="btn-outline-primary" *ngIf="!(-element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado<=0) && carrito.indexOf(element)==-1" (click)="agregarCarrito(element)">
                                                        <mat-icon>add_shopping_cart</mat-icon>
                                                    </button>
                                                    <button mat-icon-button class="btn-outline-success" *ngIf="carrito.indexOf(element)!=-1">
                                                        <mat-icon class="">
                                                            shopping_cart_checkout
                                                        </mat-icon> 
                                                    </button>

                                                </ng-container>
                                                <ng-template #noCon>
                                                    
                                                    <!--<mat-icon class="btn-outline-success" *ngIf="(element.vencido == 1 || element.vencido == 0) && ((element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado)<=0)">check</mat-icon>
                                            
                                                    <mat-icon class="btn-outline-danger" *ngIf="(element.vencido == 1 && (element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado)>0)">event_busy</mat-icon>
                                                
                                                    <mat-icon class="btn-outline-secondary" *ngIf="(element.vencido == 0 && (element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado)>0)">event</mat-icon>-->

                                                    <button mat-icon-button class="btn-outline-primary" *ngIf="!(element.recargos*(element.precio_unitario-element.descuento)+(element.precio_unitario-element.descuento) - element.pagado<=0) && carrito.indexOf(element)==-1" (click)="agregarCarrito(element)">
                                                        <mat-icon>add_shopping_cart</mat-icon>
                                                    </button>
                                                    <button mat-icon-button class="btn-outline-success" *ngIf="carrito.indexOf(element)!=-1">
                                                        <mat-icon class="">
                                                            shopping_cart_checkout
                                                        </mat-icon> 
                                                    </button>
                                                </ng-template>
                                                
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="apagar">
                                            <th scope="col" mat-header-cell *matHeaderCellDef> A pagar </th>
                                            <td mat-cell *matCellDef="let element">
                                                <mat-form-field appearance="fill" class="col-md-6" >
                                                    <mat-label></mat-label>
                                                    <input type="number" matInput [value]="element.precio_unitario">
                                                    <mat-icon matPrefix>attach_money</mat-icon>
                                                </mat-form-field>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="beca">
                                            <th scope="col" mat-header-cell *matHeaderCellDef class="text-end"> Descuento (Beca) </th>
                                            <td mat-cell *matCellDef="let element" class="text-end"> 
                                                <ng-container *ngIf="element.aplica_beca==1">
                                                    <span>{{element.descuento | currency : 'MXN'}}</span>&nbsp;
                                                    <span class="badge bg-danger rounded-pill">{{element.aplica_equivalencia == "1" ? element.equivalencia:element.beca | number:'1.2-2'}}%</span>
                                                </ng-container>
                                                <ng-container *ngIf="element.aplica_beca==0">
                                                    <span class="badge bg-secondary rounded-pill">No aplica beca</span>
                                                </ng-container>
                                            </td>
                                        </ng-container>


                                        <ng-container matColumnDef="expand">
                                            <th id="espand" mat-header-cell *matHeaderCellDef aria-label="expand row" (click)="expandedElement = element">VER</th>
                                            <td mat-cell *matCellDef="let element">
                                                <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                                                    <mat-icon *ngIf="expandedElement !== element" (click)="getObtenerMovimientosAlumno(element?.id_concepto_pk , element?.id_ciclo_pk ,idAlumno, element?.id_cuenta_por_cobrar_pk)">keyboard_arrow_down</mat-icon>
                                                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                                                    
                                                </button>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="expandedDetail" >
                                            <td mat-cell *matCellDef="let element; let i = index"  [attr.colspan]="displayedColumns.length">
                                                <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden" >
                                                   
                                                    <div class="row">
                                                        
                                                    </div>
                                                    <table class="table">
                                                        <caption><!--Parrilla--></caption>
                                                        <thead>
                                                          <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Concepto</th>
                                                            <th scope="col">Recargos</th>
                                                            <th scope="col">Precio unitario</th>
                                                            <!--<th scope="col">Motivo</th>-->
                                                            <th scope="col">Eliminar</th>
                                                          </tr>
                                                        </thead>

                                                        

                                                        <tbody>
                                                            <tr *ngFor="let d of parciales; let g = index">
                                                                <td>{{d?.id_operacion_pk}}</td>
                                                                <td>{{d?.conceptos?.nombre}}</td>
                                                                <td>
                                                                    <!--<input type="number" [(ngModel)]="d.recargos" >-->
                                                                    <span>
                                                                        {{d?.recargos}} 
                                                                    </span>
                                                                    <!--&nbsp;
                                                                    <button *ngIf="d.recargos > 0" type="button" mat-mini-fab color="warn" (click)="postModificarMovimientoTesoreria(d.id_concepto_fk, d.id_ciclo_fk, d.precio_unitario, 0, '-', d.id_operacion_pk)">
                                                                        <mat-icon class="material-icons" >
                                                                            delete
                                                                        </mat-icon>
                                                                    </button>-->
                                                                </td>
                                                                <td>
                                                                    <!--<input type="number" [(ngModel)]="d.precio_unitario">-->
                                                                    {{+d.precio_unitario | currency: 'MXN'}}
                                                                </td>
                                                                <!--<td>
                                                                    <textarea readonly="true" rows="4" type="text" [(ngModel)]="d.motivo"></textarea>
                                                                </td>-->
                                                                <td>
                                                                    <!--(click)="postEliminaCon(d.id_concepto_fk, d.id_ciclo_fk, d.precio_unitario, d.recargos, d.motivo, d.id_operacion_pk)"-->
                                                                    <button type="button" mat-mini-fab color="warn" (click)="postEliminarMovimientosAlumno(d.id_operacion_pk)">
                                                                        <mat-icon class="material-icons" >
                                                                            delete
                                                                        </mat-icon>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>

                                                    </table>


                                                    
                                                </div>
                                            </td>
                                        </ng-container>



                
                                        <!-- Symbol Column -->
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                                        class="example-element-row"
                                        [class.example-expanded-row]="expandedElement === element"
                                        (click)="expandedElement = expandedElement === element ? null : element"
                                        (click)="getObtenerMovimientosAlumno(element?.id_concepto_pk , element?.id_ciclo_pk ,idAlumno, element?.id_cuenta_por_cobrar_pk)"
                                        >

                                        <!-- Extra row to show detail content column -->
					                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];"  class="example-detail-row" ></tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="false">
                            <div class="col" *ngIf="conceptosCobro.length>0">
                                <mat-toolbar>
                                    <mat-icon>sell</mat-icon>&nbsp; <span class="fs-6">Otros conceptos</span> 
                                </mat-toolbar>
                                
                                <div class="col-md-12">
                                    <mat-list>
                                        
                                        <ng-container *ngFor="let concepto of conceptosCobro">
                                            <mat-list-item *ngIf="carrito.indexOf(concepto)==-1">
                                                <mat-icon mat-list-icon>sell</mat-icon>
                                                <button type="button" mat-icon-button class="btn-primary" (click)="carrito.push(concepto)">
                                                    <mat-icon>
                                                        add_shopping_cart
                                                    </mat-icon>
                                                </button>
                                                  
                                                
                                                <div mat-line>{{concepto.nombre}}</div>
                                                <div mat-line> {{concepto.precio_unitario | currency: 'MXN'}} </div>
                                            </mat-list-item>
                                            <hr *ngIf="carrito.indexOf(concepto)==-1"/>
                                        </ng-container>
                                    
                                    </mat-list>
                                </div>
                            </div>
                        </div>
                    
                    </div>

                    

                
                    
                    <div class="col">
                        <mat-toolbar color="default" class="d-flex justify-content-between">
                            
                                <span class="fs-6"><mat-icon>point_of_sale</mat-icon>&nbsp; Caja</span> 

                                <span class="fs-6">Saldo disponible: {{getTotalCost().saldo_carrito}}</span> 
                            
                            
                        </mat-toolbar>
                        
                        <div class="row">
                            
                            
                            <div class="col">
                                <div class="container-fluid p-0 border" style="min-height: 10em;">

                                    <table class="mat-table cdk-table mat-elevation-z0" *ngIf="carrito.length>0">
                                        <caption class="d-none"></caption>
                                        <thead>
                                            <tr class="mat-header-row cdk-header-row sticky-top shadow-sm ng-star-inserted sticky-top" style="z-index: 1;">
                                                <th scope="col" class="mat-header-cell cdk-header-cell text-left cdk-column-estatus mat-column-estatus ng-star-inserted">#</th>
                                                <th scope="col" class="mat-header-cell cdk-header-cell text-left cdk-column-estatus mat-column-estatus ng-star-inserted">Concepto</th>
                                                <th scope="col" class="mat-header-cell cdk-header-cell text-left cdk-column-estatus mat-column-estatus ng-star-inserted">Precio sin Dct/Rec</th>

                                                <!--<th scope="col" class="mat-header-cell cdk-header-cell text-left cdk-column-estatus mat-column-estatus ng-star-inserted">Recargos</th>-->
                                                <th scope="col" class="mat-header-cell cdk-header-cell text-left cdk-column-estatus mat-column-estatus ng-star-inserted">Descuento</th>


                                                <th scope="col" class="mat-header-cell cdk-header-cell text-left cdk-column-estatus mat-column-estatus ng-star-inserted">Pendiente por pagar</th>
                                                <th scope="col" class="mat-header-cell cdk-header-cell text-left cdk-column-estatus mat-column-estatus ng-star-inserted">Monto a pagar</th>
                                                <th scope="col" class="mat-header-cell cdk-header-cell text-left cdk-column-estatus mat-column-estatus ng-star-inserted">Uso de saldo disponible</th>
                                                <th scope="col" class="mat-header-cell cdk-header-cell text-left cdk-column-estatus mat-column-estatus ng-star-inserted">Saldo por pagar</th>
                                                <th scope="col" class="mat-header-cell cdk-header-cell text-left cdk-column-estatus mat-column-estatus ng-star-inserted"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of carrito; let i = index;" class="mat-row cdk-row ng-star-inserted">
                                                <td class="mat-cell cdk-cell cdk-column-concepto mat-column-concepto ng-star-inserted">{{i+1}}</td>
                                                <td class="mat-cell cdk-cell cdk-column-concepto mat-column-concepto ng-star-inserted">{{item.concepto}}</td>
                                                <td class="mat-cell cdk-cell cdk-column-concepto mat-column-concepto ng-star-inserted">
                                                    {{item.precio_unitario | currency: 'MXN'}}
                                                </td>
                                                <!--<td class="mat-cell cdk-cell cdk-column-concepto mat-column-concepto ng-star-inserted"  > -->
                                                    <!--+ {{(item.precio_unitario - item.descuento)*item.recargos | currency: 'MXN'}} antes-->
                                                    <!---{item.recargos_axuliar_0?.toFixed(2)}}-->
                                                    
                                                    <!--<input type="number" [(ngModel)]="item.recargos_axuliar"  >-->
                                                <!--</td>-->
                                                <td class="mat-cell cdk-cell cdk-column-concepto mat-column-concepto ng-star-inserted" *ngIf="item.descuento>0"> - {{item.descuento | currency: 'MXN'}} </td>
                                                <td class="mat-cell cdk-cell cdk-column-concepto mat-column-concepto ng-star-inserted" *ngIf="item.descuento<=0"> 0 </td>


                                                <td class="mat-cell cdk-cell cdk-column-concepto mat-column-concepto ng-star-inserted">
                                                    {{+item.precio_unitario_auxiliar_0 | currency: 'MXN'}}
                                                    
                                                </td>
                                                <td class="mat-cell cdk-cell cdk-column-concepto mat-column-concepto ng-star-inserted">
                                                    <input type="number" [(ngModel)]="item.precio_unitario_auxiliar">
                                                </td>



                                               
                                                <!--<td class="mat-cell cdk-cell cdk-column-concepto mat-column-concepto ng-star-inserted" *ngIf="item.recargos>0">+ {{(item.precio_unitario_auxiliar - item.descuento)*item.recargos | currency: 'MXN'}} </td> -->
                                                

                                                
                                                <!--<td class="mat-cell cdk-cell cdk-column-concepto mat-column-concepto ng-star-inserted">{{item.precio_unitario_auxiliar - item.descuento + ((item.precio_unitario_auxiliar - item.descuento)*item.recargos)| currency: 'MXN'}}</td>-->
                                                <td class="mat-cell cdk-cell cdk-column-concepto mat-column-concepto ng-star-inserted" >
                                                    <input type="number" [(ngModel)]="item.saldo_carrito">

                                                </td>


                                                <!--despues el total-->
                                                <td class="mat-cell cdk-cell cdk-column-concepto mat-column-concepto ng-star-inserted" *ngIf="(item.precio_unitario_auxiliar_0 - +item.precio_unitario_auxiliar) <= 0">
                                                   0

                                                </td>
                                                <td class="mat-cell cdk-cell cdk-column-concepto mat-column-concepto ng-star-inserted" *ngIf="(item.precio_unitario_auxiliar_0 - +item.precio_unitario_auxiliar) > 0">
                                                    {{(item.precio_unitario_auxiliar_0 - +item.precio_unitario_auxiliar - +item.saldo_carrito ) | currency: 'MXN'}}

                                                </td>

                                                <td class="mat-cell cdk-cell cdk-column-concepto mat-column-concepto ng-star-inserted text-end">
                                                    <button mat-icon-button class="btn-outline-danger" (click)="carrito.splice(i, 1)">
                                                        <mat-icon>remove_shopping_cart</mat-icon>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-list *ngIf="false">
                                        
                                        <ng-container *ngFor="let item of carrito; let i = index;">
                                            <mat-list-item >
                                            <mat-icon mat-list-icon>sell</mat-icon>
                                                <button type="button" mat-mini-fab color="warn" (click)="carrito.splice(i, 1)">
                                                    <mat-icon>
                                                        remove_shopping_cart
                                                    </mat-icon>
                                                </button>
                                                <div mat-line>{{item.nombre}}</div>
                                                
                                                <div mat-line *ngIf="item.aplica_beca==1" class="text-end">{{item.precio_unitario_auxiliar | currency: 'MXN'}} &nbsp;&nbsp;</div>
                                                <div mat-line *ngIf="item.aplica_beca==1" class="text-end"> - {{item.descuento | currency: 'MXN'}}  &nbsp;&nbsp;</div>
                                                <div mat-line class="text-end"> {{item.costo_con_descuento | currency: 'MXN'}}  &nbsp;&nbsp;</div>
                                            </mat-list-item>
                                            <hr *ngIf="carrito.indexOf(concepto)==-1"/>
                                        </ng-container>
                                    </mat-list>
                                </div>
                            </div>

                            
                            <div class="col-12">
                                <div class="alert alert-secondary text-end rounded-0 border-0 mb-0" role="alert" >
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="txt-subtotal">Precio</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="txt-subtotal">{{getTotalCost().subtotal | currency: 'MXN'}}</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="txt-subtotal">Descuentos</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="txt-subtotal">{{getTotalCost().descuentos | currency: 'MXN'}}</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="txt-subtotal">Recargos</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="txt-subtotal">{{getTotalCost().recargos | currency: 'MXN'}}</div>
                                        </div>
                                    </div>
                                    
                                    
                                   
                                </div>
                                <div class="alert alert-danger text-end rounded-0 border-0 mb-0 pt-2 pb-2" role="alert">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <span class="txt-subtotal">Adeudo total</span>
                                        </div>
                                        <div class="col-md-6">
                                            <span class="txt-subtotal">{{getTotalCost().precio_unitario_auxiliar_0 | currency: 'MXN'}}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="alert alert-success text-end rounded-0 border-0 mb-0 pt-2 pb-2" role="alert">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <span class="txt-subtotal">Monto a pagar total</span>
                                        </div>
                                        <div class="col-md-6">
                                            <span class="txt-subtotal">{{getTotalCost().pendiente_por_pagar_0 + getTotalCost().total_saldo | currency: 'MXN'}}</span>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div class="alert alert-warning text-end rounded-0 border-0 pt-2 pb-2" role="alert">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <span class="txt-subtotal">Saldo a favor</span>
                                        </div>
                                        <div class="col-md-6">
                                            <span class="txt-subtotal" *ngIf="getTotalCost().pendiente_por_pagar_0 - getTotalCost().precio_unitario_auxiliar_0 > 0">{{getTotalCost().pendiente_por_pagar_0 - getTotalCost().precio_unitario_auxiliar_0 | currency: 'MXN'}}</span>
                                            <span class="txt-subtotal" *ngIf="getTotalCost().pendiente_por_pagar_0 - getTotalCost().precio_unitario_auxiliar_0 <= 0">{{0 | currency: 'MXN'}}</span>
                                        </div>
                                    </div>
                                    
                                </div>

                                <!--<div class="alert alert-success text-end rounded-0 border-0" role="alert">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <span class="txt-total">Total</span>
                                        </div>
                                        <div class="col-md-6">
                                            <span class="txt-total">{{getTotalCost().total | currency: 'MXN'}}</span>
                                        </div>
                                    </div>
                                    
                                </div>-->
                                
                            </div>
                        </div>
                    </div>
                </div>


                
               

                <form autocomplete="no" [formGroup]="formulario" >
                <div class="row">
                    
                    <div class="col-md-6">
                        <mat-form-field appearance="fill" class="input-full-w" >
                            <mat-label>Forma de pago</mat-label>
                            <mat-select formControlName="formaDePago">
                                <mat-option value="1">Efectivo</mat-option>
                                <mat-option value="2">Transferencia</mat-option>
                                <mat-option value="3">Deposito</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="fill" *ngIf="formulario.controls.formaDePago.value!='1'" class="input-full-w" >
                            <mat-label>Referencia</mat-label>
                            <input type="text" matInput formControlName="referencia" >
                            
                        </mat-form-field>
                    </div>
                              
                </div>
            </form> 
            

            <div class="row">
                <div class="col">
                    <form autocomplete="no" [formGroup]="formulario" >
                    <mat-form-field appearance="fill"  *ngIf="formulario.controls.formaDePago.value!='1'" class="input-full-w" >
                        <mat-label>Fecha de pago</mat-label>

                        <input matInput [matDatepicker]="picker" readonly [max]="maxDate" formControlName="fechaDePago">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker disabled="false"></mat-datepicker>
                        
                    </mat-form-field>
                    </form> 
                </div>
                <div class="col">
                    <!--<div class="row">
                        <div class="col">
                            <mat-form-field appearance="fill"  >
                                <mat-label>Saldo disponible</mat-label>
                                <input matInput placeholder="Saldo disponible" [(ngModel)]="saldo_disponible">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <button mat-stroked-button color="primary" (click)="postModificarSaldoAlumno()">
                                Modificar saldo
                            </button>
                        </div>
                    </div>-->
                    
                </div>
            </div>
            

            <!--<div class="row">
                <div class="col">
                    <div class="row">
                        
                    </div>
                </div>
            </div>-->


            <!--<div class="alert alert-secondary text-end rounded-0 border-0 mb-0">
                <div class="row">
                    <div class="col-md-6">
                        <div class="txt-subtotal">
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Monto a pagar total
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="txt-subtotal"> 
                            <input type="number" [(ngModel)]="pagando" readonly="true">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="txt-subtotal">
                            <div class="form-check">
                                
                                <label class="form-check-label" for="flexCheckDefault">
                                    Aplicar saldo disponible
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="txt-subtotal"> 
                            <input  class="form-check-input" type="checkbox" [(ngModel)]="aplicar_saldo_disponible" (change)="viewAplicarSaldoDisponible()">
                            &nbsp;
                            <input type="number" [(ngModel)]="saldo_disponible_uso" >
                        </div>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-md-6">
                        <div class="txt-subtotal">
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Saldo restante a favor
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="txt-subtotal">
                            <span *ngIf="-(final_saldo_favor) <= 0">
                                0
                            </span>
                            <span *ngIf="-(final_saldo_favor) > 0">
                                {{-(final_saldo_favor) | currency: 'MXN'}}
                            </span>

                                
                          
                        </div>
                    </div>
                </div>
            </div>-->
            <div class="alert alert-success text-end rounded-0 border-0 pt-2 pb-2" role="alert">
                <div class="row">
                    <div class="col-md-6">
                        <span class="txt-subtotal">Total pagado</span>
                    </div>
                    <div class="col-md-6">
                        <span class="txt-subtotal" *ngIf="aplicar_saldo_disponible == false">{{pagando + getTotalCost().total_saldo | currency: 'MXN'}}</span>
                        <span class="txt-subtotal" *ngIf="aplicar_saldo_disponible == true">{{pagando + saldo_disponible_uso + getTotalCost().total_saldo | currency: 'MXN'}}</span>
                    </div>
                </div>
                
            </div>
                

                

                
            
            
        </mat-card-content>
        <mat-card-actions align="end">
            <form autocomplete="no" (ngSubmit)="registraPago()" [formGroup]="formulario" class="container-fluid">
            <div fxFlex></div>
            <button type="submit" mat-flat-button color="accent">
                Procesar pago
            </button>
            </form>
        </mat-card-actions>
    </mat-card>
    
    <br>
    <br>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Condonación</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <mat-checkbox  class="example-margin" [(ngModel)]="con_box"> Valida condonación </mat-checkbox> <!-- [(ngModel)]="element.condonacion_val" (change)="postEliminaCon2(element.condonacion_val)"-->
            </div>
            <div class="row mt-3">
                <mat-form-field appearance="fill">
                    <mat-label>Motivo</mat-label>
                    <textarea rows="5" matInput [(ngModel)]="con_motivo"></textarea>
                </mat-form-field>
            </div>
            






        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          <button *ngIf="con_val == true" type="button" class="btn btn-danger" (click)="postCondonacion(2, id_cuentas_cobrar)" data-bs-dismiss="modal">Eliminar</button>
          <button *ngIf="con_val == false && con_box == true && con_motivo != ''" type="button" class="btn btn-primary" (click)="postCondonacion(1, id_cuentas_cobrar, con_motivo)" data-bs-dismiss="modal">Guardar</button>
        </div>
      </div>
    </div>
  </div>




<app-historial-tickets-de-pagos [id_alumno]="idAlumno" [id_ciclo]="formulario.value.ciclo"></app-historial-tickets-de-pagos>

<!--<app-historial-de-pagos [id_alumno]="idAlumno" [id_ciclo]="formulario.value.ciclo"></app-historial-de-pagos>-->