<div class="container-fluid">
    <div *ngIf="id_cuenta; else no_cuenta">
        <div class="alert alert-success" role="alert">
            Cuenta asociada:

            <form novalidate [formGroup]="form2" >

                <div class="row">
                    <div class="col">
                        
                        <mat-form-field class="input-full-width" appearance="fill">
                            <mat-label>Correo</mat-label>
                            <input  formControlName="correo"  type="email" matInput readonly="true">
                        </mat-form-field>
                       
                    </div>
                    <div class="col">
    
                    </div>
                </div>
               
    
            </form>


        </div>
    </div>
    <ng-template #no_cuenta>
        <div class="alert alert-warning" role="alert">
            No tiene una cuenta para INTRANET
        </div>
    </ng-template>

    <div class="row">
        <div class="d-flex-justify-content start gap-2">
            <button type="button" class="btn btn-outline-primary  btn-small  mt-3 " (click)="seccion(1)">
                Registrar y asociar cuenta
            </button>
            &nbsp;
            <button type="button" class="btn btn-outline-primary  btn-small  mt-3 " (click)="seccion(2)">
                Asociar con una cuenta existente
            </button>
            &nbsp;
            <button *ngIf="id_cuenta" type="button" class="btn btn-outline-primary  btn-small  mt-3 " (click)="seccion(3)">
                Modificar
            </button>
        </div>
    </div>
    

    <div class="row mt-5" *ngIf="id_seccion == 1">
        <form novalidate [formGroup]="form3">
            <div class="row">
                <div class="col">
                    <mat-form-field class="input-full-width" appearance="fill">
                        <mat-label>Correo</mat-label>
                        <input  formControlName="correo"  type="email" matInput >
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="input-full-width" appearance="fill">
                        <mat-label>Nombre obligado</mat-label>
                        <input  formControlName="nombre_obligado"  type="text" matInput >
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <mat-form-field class="input-full-width" appearance="fill">
                        <mat-label>Curp</mat-label>
                        <input  formControlName="curp"  type="text" matInput >
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="input-full-width" appearance="fill">
                        <mat-label>Contraseña</mat-label>
                        <input  formControlName="contrasena"  type="password" matInput >
                    </mat-form-field>
                </div>
            </div>
        </form>

        <div class="d-flex d-flex-justify-content center">
            <form novalidate [formGroup]="form3" (ngSubmit)="postRegistroCuentaExpediente()">
                <button mat-flat-button color="primary" type="submit" [disabled]="form3.invalid">
                    Registrar y asociar cuenta
                </button>
            </form>
        </div>


    </div>

    <div class="row mt-5" *ngIf="id_seccion == 2">
        <div class="alert alert-secondary" role="alert">
            <strong>Nota: </strong> Al seleccionar una cuenta existente le otorgará los permisos para entrar a la intranet, si el alumno ya tenía una cuenta 
            entonces la cuenta seleccionada sustituirá la cuenta actual.
        </div>
        <form novalidate [formGroup]="form4" (ngSubmit)="postBusquedaCuentas()">

            <div class="row">
                <div class="col">
                    <mat-form-field appearance="fill" class="input-full-width">
                        <mat-label>Tipo</mat-label>
                        <mat-select  formControlName="tipo_busqueda">
                            <mat-option  value="1">Correo</mat-option>
                            <mat-option  value="2">Nombre obligado</mat-option>
                            <mat-option  value="3">Curp</mat-option>
                            <mat-option  value="4">Todo</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="input-full-width" appearance="fill">
                        <mat-label>Buscador</mat-label>
                        <input  formControlName="buscador"  type="text" matInput >
                    </mat-form-field>
                </div>
                <div class="col">
                    <button mat-flat-button color="primary" type="submit" [disabled]="form4.invalid">
                        Buscar
                    </button>
                </div>
            </div>
           
        </form>


        <form novalidate [formGroup]="form1" (ngSubmit)="postAsociarAlumno()">

            <div class="row mt-5">
                <div class="col">
                    <mat-form-field appearance="fill" class="input-full-width">
                        <mat-label>Cuentas</mat-label>
                        <mat-select  formControlName="cuenta">
                            <mat-option *ngFor="let c of cuentas" value="{{c.id_cuenta_pk}}">{{c.correo}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">

                </div>
            </div>
           

            <button mat-flat-button color="primary" type="submit" [disabled]="form1.invalid">Asociar</button>
        </form>
    </div>

    <div class="row" *ngIf="id_seccion == 3">
        <form novalidate [formGroup]="form2" (ngSubmit)="postModificarCuentaExpediente()">
            <hr>
            <h3>Modificar cuenta asociada</h3>
            <div class="row">
                <mat-form-field class="input-full-width" appearance="fill">
                    <mat-label>Correo</mat-label>
                    <input  formControlName="correo"  type="email" matInput readonly="true">
                </mat-form-field>
            </div>

            <div class="row">
                <mat-form-field class="input-full-width" appearance="fill">
                    <mat-label>Nombre completo</mat-label>
                    <input  formControlName="nombre_obligado"  type="text" matInput >
                </mat-form-field>
            </div>

            <div class="row">
                <mat-form-field class="input-full-width" appearance="fill">
                    <mat-label>Curp</mat-label>
                    <input  formControlName="curp"  type="text" matInput >
                </mat-form-field>
            </div>

            <div class="row">
                <mat-form-field class="input-full-width" appearance="fill">
                    <mat-label>Contraseña</mat-label>
                    <input  formControlName="contrasena"  type="password" matInput >
                </mat-form-field>
            </div>

            <button data-bs-dismiss="modal" mat-flat-button color="primary" type="submit" [disabled]="form2.invalid">Modificar cuenta</button>

        </form>
    </div>











            <!--<div class="modal-header">
                <h2 class="modal-title" id="modal_{{ element.id_alumno_pk }}">Alumno {{ element.id_alumno_pk }}: 
                    {{element.apellidop}} 
                    {{element.apellidom}} 
                    {{element.nombre}} 
                </h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>-->
<!--
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <h3>Seleccione con la cuenta que desea asociar</h3>
                <form novalidate [formGroup]="form1" (ngSubmit)="postAsociarAlumno()">

                    <mat-form-field appearance="fill">
                        <mat-label>Cuentas</mat-label>
                        <mat-select  formControlName="cuenta">
                            <mat-option *ngFor="let c of cuentas" value="{{c.id_cuenta_pk}}">{{c.correo}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button data-bs-dismiss="modal" mat-flat-button color="primary" type="submit" [disabled]="form1.invalid">Asociar alumno</button>
                </form>
                <form novalidate [formGroup]="form2" (ngSubmit)="postModificarCuentaExpediente()">
                    <hr>
                    <h3>Modificar cuenta asociada</h3>
                    <div class="row">
                        <mat-form-field class="input-full-width" appearance="fill">
                            <mat-label>Correo</mat-label>
                            <input  formControlName="correo"  type="email" matInput readonly="true">
                        </mat-form-field>
                    </div>

                    <div class="row">
                        <mat-form-field class="input-full-width" appearance="fill">
                            <mat-label>Nombre completo</mat-label>
                            <input  formControlName="nombre_obligado"  type="text" matInput >
                        </mat-form-field>
                    </div>

                    <div class="row">
                        <mat-form-field class="input-full-width" appearance="fill">
                            <mat-label>Curp</mat-label>
                            <input  formControlName="curp"  type="text" matInput >
                        </mat-form-field>
                    </div>

                    <div class="row">
                        <mat-form-field class="input-full-width" appearance="fill">
                            <mat-label>Contraseña</mat-label>
                            <input  formControlName="contrasena"  type="password" matInput >
                        </mat-form-field>
                    </div>

                    <button data-bs-dismiss="modal" mat-flat-button color="primary" type="submit" [disabled]="form2.invalid">Modificar cuenta</button>

                </form>


                <hr>
                <h3>Nueva cuenta</h3>
                <form novalidate [formGroup]="form3">

                    <div class="row">
                        <mat-form-field class="input-full-width" appearance="fill">
                            <mat-label>Correo</mat-label>
                            <input  formControlName="correo"  type="email" matInput >
                        </mat-form-field>
                    </div>

                    <div class="row">
                        <mat-form-field class="input-full-width" appearance="fill">
                            <mat-label>Nombre completo</mat-label>
                            <input  formControlName="nombre_obligado"  type="text" matInput >
                        </mat-form-field>
                    </div>

                    <div class="row">
                        <mat-form-field class="input-full-width" appearance="fill">
                            <mat-label>Curp</mat-label>
                            <input  formControlName="curp"  type="text" matInput >
                        </mat-form-field>
                    </div>

                    <div class="row">
                        <mat-form-field class="input-full-width" appearance="fill">
                            <mat-label>Contraseña</mat-label>
                            <input  formControlName="contrasena"  type="password" matInput >
                        </mat-form-field>
                    </div>
                </form>

                <div class="row">
                    <div class="col">
                        <form novalidate [formGroup]="form3" (ngSubmit)="postRegistroCuentaExpediente()">
                            <button data-bs-dismiss="modal" mat-flat-button color="primary" type="submit" [disabled]="form3.invalid">
                                Registrar y asociar cuenta
                            </button>
                        </form>
                    </div>


                    <div class="col">
                       <form novalidate [formGroup]="form3" (ngSubmit)="postRegistrarCuenta()">
                        <button data-bs-dismiss="modal" mat-flat-button color="primary" type="submit" [disabled]="form3.invalid">
                            Registrar cuenta sin asociar
                        </button>
                    </form>
                </div>
            </div>
        </div>
       
    </div>
</div>
-->
 <!--<div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>-->












</div>