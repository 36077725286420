import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionServiceService } from './services/session-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router : Router,
    public _session:SessionServiceService
    ){}
  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
    let url: string = state.url;
    
    return this.verifyLogin(url);
  }

  verifyLogin(url) : boolean{
    
    if(!this.isLoggedIn()){
        this.router.navigate(['/security/login']);
        return false;
    }else{
      if(this.tienePermiso(url)){
        return true;
      }else{
        this.router.navigate(['/error/no-grant']);
      }
    }
  }

  public isLoggedIn(): boolean{
    let status = false;
    if(this._session.getStatus()){
      status = true;
    }
    else{
      status = false;
    }
    return status;
  }

  tienePermiso(url:String){
    //console.info(url);
    let acciones = this._session.getSession().getAcciones();
    for( let i = 0; i<acciones.length; i++){
      if(url == acciones[i].url){
        return true;
      }else if(url.indexOf("?")>-1){
        return this.tienePermiso(url.split("?")[0]);
      }else{
        //console.info(acciones[i].url);
        //console.error("none");
      }
    }
    return false;
  }
}
