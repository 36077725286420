import { Domicilio } from "./domicilio";
import { EstadoCivil } from "./estado_civil";
import { Estados } from "./estados";
import { Genero } from "./genero";
import { Ocupaciones } from "./ocupaciones";
//import { Padres } from "./padres";

export class Alumnos{
    public id_alumno_pk:Number;
    public nombre: String;
    public apellidop: String;
    public apellidom: String;
    public fecha_nacimiento: Date;
    public carrusel:String;
    public edad: Number;
    public id_genero_fk:Number;
    public curp: String;
    public familia: Number;
    public id_proceso_fk:Number;
    public id_estatus_fk:Number;
    public id_estado_fk:Number;
    public id_vivecon_fk:Number;
    public vive_con_otro:String;
    public nombre_extranjero:String;

    domicilio:Domicilio;
    edo_civil:EstadoCivil;
    estados:Estados;
    genero:Genero;
    ocupacion:Ocupaciones;
    //padre:Padres;

    constructor(){
        this.domicilio = new Domicilio();
        this.edo_civil = new EstadoCivil();
        this.estados = new Estados();
        this.genero = new Genero();
        this.ocupacion = new Ocupaciones();
        //this.padre = new Padres();
    }

    getDomicilio():Domicilio{
        return this.domicilio;
    }
    setDomicilio(domicilio:Domicilio):void{
        this.domicilio = domicilio;
    }
    getEdoCivil():EstadoCivil{
        return this.edo_civil;
    }
    setEdoCivil(edo_civil:EstadoCivil):void{
        this.edo_civil = edo_civil;
    }
    getEstados():Estados{
        return this.estados;
    }
    setEstados(estados:Estados):void{
        this.estados = estados;
    }
    getGenero():Genero{
        return this.genero;
    }
    setGenero(genero:Genero):void{
        this.genero = genero;
    }
    
    getOcupacion():Ocupaciones{
        return this.ocupacion;
    }
    setOcupacion(ocupacion:Ocupaciones):void{
        this.ocupacion = ocupacion;
    }
    setNombreExtranjero(nombre: String):void{
        this.nombre_extranjero = nombre;
    }
   /* getPadre():Padres{
        return this.padre;
    }
    setPadre(padre:Padres):void{
        this.padre = padre;
    }*/
}