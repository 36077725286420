import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-creacion-grupos',
  templateUrl: './creacion-grupos.component.html',
  styleUrls: ['./creacion-grupos.component.scss']
})
export class CreacionGruposComponent {

  

}
