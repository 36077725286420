<mat-icon>{{data.icon}}</mat-icon>
<div class="text-center pt-4" mat-dialog-title>
  <h1 >{{data.title}}</h1>
</div>

<div mat-dialog-content>
  <p class="text-center" [innerHTML]="data.text"></p>
</div>
<div mat-dialog-actions align="center">
  <button mat-flat-button color="primary" mat-dialog-close (click)="cerrar()">Cerrar</button>
</div>
