<header class="header">
    <mat-toolbar class="header" *ngIf="validaRuta()==false">
        <img src="assets/img/LOGOTIPO.png" class="logo" [routerLink]="['/']" alt="logo-SJP">
        <span class="example-spacer"></span>
        <button mat-button class="example-icon" aria-label="Example icon-button with share icon" *ngIf="_session.getStatus()">
            <mat-icon>person</mat-icon>&nbsp;Hola, {{nombreUsuario}}
        </button>
        <button (click)="salir()" mat-button class="example-icon" aria-label="Example icon-button with share icon">
            Salir&nbsp;<mat-icon>launch</mat-icon>
        </button>
    </mat-toolbar>
    <mat-toolbar class="submenu" *ngIf="_session.getStatus()">
        <button color="primary" [matMenuTriggerFor]="menu" mat-mini-fab class="example-icon" aria-label="Example icon-button with menu icon">
            <mat-icon>apps</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <mat-divider></mat-divider>
            <button mat-menu-item [matMenuTriggerFor]="controlEscolar">
                <mat-icon>bar_chart</mat-icon>
                Control escolar
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [matMenuTriggerFor]="tesoreria">
                <mat-icon>insights</mat-icon>
                Tesorería 
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [matMenuTriggerFor]="resultados">
                <mat-icon>bar_chart</mat-icon>
                Medición de resultados 
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [matMenuTriggerFor]="indicadores">
                <mat-icon>insights</mat-icon>
                Reportería 
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [matMenuTriggerFor]="administracion">
                <mat-icon>admin_panel_settings</mat-icon>
                Administración
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['/estudio-socioeconomico']">
                <mat-icon>assignment</mat-icon>
                Estudio Socioeconómico
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [matMenuTriggerFor]="evaluaciones" >
                <mat-icon>sort</mat-icon>
                Evaluaciones
            </button>
        </mat-menu>

        <mat-menu #controlEscolar="matMenu">
            <button mat-menu-item [routerLink]="['/control-escolar']">
                <mat-icon>today</mat-icon>
                Control Escolar
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['/control-escolar/asignacion/grupos']">
                <mat-icon>assignment</mat-icon>
                Asignacion de grupos
            </button>
            
        </mat-menu>

        <mat-menu #tesoreria="matMenu">
            <button mat-menu-item [routerLink]="['/tesoreria']">
                <mat-icon>today</mat-icon>
                Tesoreria
            </button>
            <mat-divider></mat-divider>
        </mat-menu>

        <mat-menu #resultados="matMenu">
            <button mat-menu-item [routerLink]="['/medicion-resultados/ciclos-mannager']">
                <mat-icon>today</mat-icon>
                Ciclos de encuestas
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['/medicion-resultados/encuestas-mannager']">
                <mat-icon>fact_check</mat-icon>
                Inventario de encuestas
            </button>
        </mat-menu>
        <mat-menu #indicadores="matMenu">
            <button mat-menu-item [routerLink]="['/reporteria/indicadores']">
                <mat-icon>bar_chart</mat-icon>De resultados
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item disabled>
                <mat-icon>multiline_chart</mat-icon>Indicadores
            </button>
        </mat-menu>
        <mat-menu #evaluaciones="matMenu">
            <button mat-menu-item [routerLink]="['/evaluaciones']">
                <mat-icon>bar_chart</mat-icon>Evaluaciones pendientes
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item  [routerLink]="['/evaluaciones/historial-ingreso']">
                <mat-icon>multiline_chart</mat-icon>Historial autorizadas
            </button>
        </mat-menu>
        <mat-menu #administracion="matMenu">
            <button mat-menu-item [routerLink]="['/administracion/user-mannager']">
                <mat-icon>people_alt</mat-icon>
                Usuarios
            </button>
        </mat-menu>
        <span class="subtitle" *ngIf="esControlEscolar()">Control Escolar</span>
        <span class="subtitle" *ngIf="esTesoreria()">Tesoreria</span>
        <span class="subtitle" *ngIf="esAdministracion()">Administración</span>
        <span class="subtitle" *ngIf="esReporteria()">Reportería</span>
        <span class="subtitle" *ngIf="esMedicionResultados()">Medición de resultados</span>
        <span class="subtitle" *ngIf="esEstudioSocioeconomico()">Estudio Socioeconómico</span>
        <span class="subtitle" *ngIf="esEvaluaciones()">Evaluaciones</span>
        


        <button *ngIf="esControlEscolar()" mat-button class="example-icon" [routerLink]="['/control-escolar']">
            Control escolar
        </button>
        <button *ngIf="esControlEscolar()" mat-button class="example-icon" [routerLink]="['/control-escolar/asignacion/grupos']">
            Asignación de grupos
        </button>
        <button *ngIf="esTesoreria()" mat-button class="example-icon" [routerLink]="['/tesoreria']">
            Tesorería
        </button>



        <button *ngIf="esTesoreria()" mat-button class="example-icon" [routerLink]="['/validacion-pagos-tesoreria']">
            Validación de pagos
        </button>


       
        <button *ngIf="esAdministracion()" mat-button class="example-icon" [routerLink]="['/administracion/user-mannager']">
            <!--<mat-icon>people_alt</mat-icon>-->
            Usuarios
        </button>
        <button  *ngIf="esMedicionResultados()" mat-button class="example-icon" [routerLink]="['/medicion-resultados/ciclos-mannager']">
            <!--<mat-icon>today</mat-icon>-->
            Ciclos de encuestas
        </button>
        <button *ngIf="esMedicionResultados()" mat-button class="example-icon" [routerLink]="['/medicion-resultados/encuestas-mannager']">
            <!--<mat-icon>fact_check</mat-icon>-->
            In>ventario de encuestas
        </button>
        <button *ngIf="esReporteria()" mat-button class="example-icon" [routerLink]="['/reporteria/indicadores']">
            <!--<mat-icon>bar_chart</mat-icon>-->
            Indicadores
        </button>
        <button disabled *ngIf="esReporteria()" mat-button class="example-icon" >
            <!--<mat-icon>multiline_chart</mat-icon>-->
            De resultados
        </button>
        <button *ngIf="esEstudioSocioeconomico()" mat-button class="example-icon" [routerLink]="['/estudio-socioeconomico']">
            Estudio Socioeconómico
        </button>
        <button *ngIf="esEvaluaciones()" mat-button class="example-icon" [routerLink]="['/evaluaciones']">
            Evaluaciones pendientes
        </button>
        <button *ngIf="esEvaluaciones()" mat-button class="example-icon" [routerLink]="['/evaluaciones/historial-ingreso']">
            Evaluaciones autorizadas
        </button>
    </mat-toolbar>
</header>