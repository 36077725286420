<mat-toolbar class="bg-nav-menu fixed-top pc">
    <!-- parte 1 -->
    <div mat-raised-button (click)="drawer.toggle()" class="text-white cl-a">
        <button class="btn btn-outline-primary cl-a">
            <mat-icon>view_headline</mat-icon>
        </button>
    </div>
    <div class="p-2 text-white fw-semibold">
        <span class="color-primario cl-a">BIENVENIDO: </span> 
        <span class="cl-a" *ngIf="dataSJP?.nombre_obligado; else elseNombreObligado">{{dataSJP?.nombre_obligado.toUpperCase()}}</span>
        <ng-template #elseNombreObligado>
            <span class="cl-a">Padre/Madre</span>
        </ng-template>
    </div>
    <!-- fin parte 1 -->
    <span class="example-spacer"></span>
    <!-- parte 2 -->
    <div class="p-2 text-white">
        <img src="assets/img/foto_user.jpg" height="45rem" width="45rem" class="img-fluid rounded-circle" alt="Imagen default de usuario">
    </div>
    <div class="p-2 text-white">
        <div class="d-inline" >
            <button class="btn btn-outline-primary cl-a" [routerLink]="['/tutores/dashboard/mi-cuenta']">
                {{dataSJP?.correo}}
            </button>
            &nbsp;
            <button class="btn btn-outline-primary cl-a" (click)="cerrarSesion()">
                <mat-icon>logout</mat-icon>
            </button>
        </div>
    </div>
    <!-- fin parte 2 -->
</mat-toolbar>







<mat-toolbar class="bg-nav-menu fixed-top mobile">
    <div mat-raised-button (click)="drawer.toggle()" class="text-white">
        <button class="btn btn-outline-primary cl-a">
            <mat-icon>view_headline</mat-icon>
        </button>
    </div>
    <div class="p-2 pt-4 text-white h6">
        Girasol <br> Bienvenido: 
        <span *ngIf="dataSJP?.nombre_obligado; else elseNombreObligado">{{dataSJP?.nombre_obligado}}</span>
        <ng-template #elseNombreObligado>
            <span>Padre/Madre</span>
        </ng-template>
    </div>
    <span class="example-spacer"></span>

    <div mat-raised-button class="text-white">
        <button [matMenuTriggerFor]="menu" class="btn btn-outline-primary">
            <mat-icon>more_vert</mat-icon>
        </button> 
        <mat-menu #menu="matMenu">
          <button mat-menu-item  [routerLink]="['/tutores/dashboard/mi-cuenta']">Mi pefil</button>
          <button mat-menu-item  (click)="cerrarSesion()" >Cerrar Sesión</button>
      </mat-menu>
  </div>
</mat-toolbar>











<mat-drawer-container class="example-container " >
  <mat-drawer #drawer mode="side" opened>

    <div class="card mb-3 border-0" style="background-color: #212121;">
        <div class="row g-0">
            <!--<div class="col-md-4 d-flex justify-content-center align-items-center mt-2 p-3">
                <img *ngIf="usuario.empleado" src="{{url_storage + usuario.empleado.url}}" class="img-fluid rounded-circle" alt="...">
            </div>
            <div class="col-md-8 mt-2">
                <div class="card-body">
                    <div *ngIf="usuario.empleado">
                        <h5 class="card-title text-white text-center">{{usuario.empleado.nombre}} {{usuario.empleado.apellido_paterno}} {{usuario.empleado.apellido_materno}}</h5>
                    </div>
                    <p class="card-text text-white text-center">{{usuario.email}}</p>
                    <p class="card-text text-white text-center"><small class="text-muted">Creado el {{usuario.created_at.updated_at | amLocale:'es' | amDateFormat: 'LL'}}</small></p>
                </div>
            </div>-->
        </div>
    </div>

    <hr style="color: white">

    

    <mat-selection-list #shoes [multiple]="false">



        <span>
            <div *ngIf="dataSJP?.alumnos.length == 0; else elsePrimerAlumno" class="alert alert-danger" role="alert">
                No hay alumnos asociados
            </div>
            <ng-template #elsePrimerAlumno>


                <mat-form-field appearance="fill" class="bg-white">
                    <mat-label>  <mat-icon>face</mat-icon> Alumnos </mat-label>
                    <mat-select [formControl]="alumno_fc" (ngModelChange)="cambiarAlumno()" >
                        <mat-option *ngFor="let al of dataSJP?.alumnos" value="{{al.id_alumno_pk}}">{{al.apellidop}} {{al.apellidom}} {{al.nombre}}</mat-option>   
                    </mat-select>
                </mat-form-field>
            </ng-template>
        </span>

        

        <mat-list-option [routerLink]="['/tutores/dashboard/inicio']" class="mt-3">
            <div class="d-inline text-white">
                <span><mat-icon>home</mat-icon></span>
                <span> Inicio</span>
            </div>
        </mat-list-option>

        <mat-list-option>
            <div class="d-inline text-white">
                <span><span class="material-icons">verified</span></span>
                <span > Calificaciones</span>
            </div>
        </mat-list-option>

        <mat-list-option >
            <div class="d-inline text-white">
                <span class="material-icons">admin_panel_settings</span>
                <span > Administración</span>
            </div>
        </mat-list-option>
        <mat-list-option>
            <div class="d-inline-flex ps-4" [routerLink]="['/tutores/dashboard/administracion']">
                <span class="material-icons">admin_panel_settings</span>
                Historia de pagos
            </div>
        </mat-list-option>
        <mat-list-option>
            <div class="d-inline-flex ps-4" [routerLink]="['/tutores/dashboard/gt-validacion-pagos']" >
                <span class="material-icons">admin_panel_settings</span>
                Comprobación de pagos
            </div>
        </mat-list-option>

        <mat-list-option [routerLink]="['/tutores/dashboard/std-socioeconomico']">
            <div class="d-inline text-white">
                <span class="material-icons">assignment</span>
                <span > Estudio socioeconómico</span>
            </div>
        </mat-list-option>

        <mat-list-option>
            <div class="d-inline text-white">
                <span class="material-icons">markunread_mailbox</span>
                <span > Buzón</span>
            </div>
        </mat-list-option>

        <mat-list-option [routerLink]="['/tutores/dashboard/biblioteca']">
            <div class="d-inline text-white">
                <span class="material-icons">shelves</span>
                <span > Biblioteca</span>
            </div>
        </mat-list-option>

        <mat-list-option>
            <div class="d-inline text-white ">
                <span class="material-icons">account_circle</span>
                <span > Mi cuenta</span>
            </div>
        </mat-list-option>
    </mat-selection-list>

    






</mat-drawer>
<mat-drawer-content>
    <div class="bg-secundario">
        
    </div>
    <router-outlet></router-outlet>
</mat-drawer-content>
</mat-drawer-container>
