import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DesitionBoxComponent } from 'src/app/modales/desition-box/desition-box.component';
import { EstudioSocioeconomicoService } from 'src/app/services/estudio-socioeconomico.service';
import { EvaluacionesService } from 'src/app/services/evaluaciones.service';
import { ParametrosService } from 'src/app/services/parametros.service';
const ELEMENT_DATA: any[] = [
];
@Component({
  selector: 'app-estudio-socioeconomico',
  templateUrl: './estudio-socioeconomico.component.html',
  styleUrls: ['./estudio-socioeconomico.component.scss']
})
export class EstudioSocioeconomicoComponent implements OnInit {

  length = 0;
  pageSize = 50;
  pageSizeOptions: number[] = [50, 100, 150];

  // MatPaginator Output
  pageEvent: PageEvent;
  
  isLoading = false;
  displayedColumns: string[] = ['matricula','nombre', 'proceso', 'nivel','grado', 'estatus', 'becaActual','cuotaActual','becaAutorizada', 'descuento','cuotaAutorizada','autorizar','verEstudio'];
  dataSource = ELEMENT_DATA;
  formulario = new FormGroup({
    nombre : new FormControl("",[]),
    nivel: new FormControl("",[]),
    grado: new FormControl("",[])
    //nivel: new FormControl("",[])
  });
  niveles=[];
  grupos=[];
  constructor(private _parametrosService:ParametrosService,private _estudioSocioeconomicoService:EstudioSocioeconomicoService,public dialog:MatDialog) {
    this._parametrosService.findAll("niveles").subscribe(result=>{
      this.niveles=result.datos.niveles;
    },()=>{
      console.info("");
    });
    
    
  }
  getGrados(id){
    this._parametrosService.findAllDinamic("grados",id).subscribe(result=>{
      this.grupos = result.datos.grados;
    });
  }
  ngOnInit(): void {
    this.buscador(0);
  }
  buscador(indice:number){
    this._estudioSocioeconomicoService.find(this.formulario.controls["nombre"].value,this.formulario.controls["nivel"].value,this.formulario.controls["grado"].value,0,this.pageSize).subscribe(result=>{
      this.dataSource = result.datos.evaluaciones;
      console.log("this.dataSource: ", this.dataSource);
    });
  }
  save(evaluacion,ciclo,nivel,grado,alumno,desicion){
    console.info(desicion);
  }
  print(info){
    console.info(info);
  }
  actualizarbeca(){
    
  }
  actualizar(campo:string,valor:string,ciclo,nivel,grado,alumno){
    console.info(valor);
    let json = {
      campo:campo,
      valor:valor,
      
      ciclo:ciclo,
      nivel:nivel,
      grado:grado,
      alumno:alumno
    }
    document.getElementById("spin_"+campo+"_"+ciclo+"_"+nivel+"_"+grado+"_"+alumno).style.display = "block";
    this._estudioSocioeconomicoService.update(json).subscribe(result=>{
      document.getElementById("spin_"+campo+"_"+ciclo+"_"+nivel+"_"+grado+"_"+alumno).style.display = "none";
      this.buscador(0);
    })
  }
  autorizar(campo:string,valor:string,ciclo,nivel,grado,alumno): void {
    const dialogRef = this.dialog.open(DesitionBoxComponent, {
      //width: '250px',
      data: {title:"Solicitud de confirmación", text:"¿Está seguro que desea autorizar este registro?",icon:"help_outline"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);  
      if(result){
        this.isLoading = true;
        let json = {
          campo:campo,
          valor:valor,
          
          ciclo:ciclo,
          nivel:nivel,
          grado:grado,
          alumno:alumno
        }
        console.log("json a enviar: ", json);
        this.isLoading = true;
        this._estudioSocioeconomicoService.update(json).subscribe(result=>{
          this.isLoading = false;
          this.buscador(0);
        })
        
      }
    });
  }
  parseEstatus(id){
    if(id==0){
      return "Sin iniciar";
    }else{
      return "Concluido";
    }

  }
}
