<h1 mat-dialog-title><mat-icon>{{data.icon2}}</mat-icon></h1>
<br>
<div mat-dialog-content>
  
  <h1>{{data.titlePass}}</h1>
  <p>{{data.mnsjPass}}</p>
</div>
<div mat-dialog-actions align="center">
  <button mat-stroked-button (click)="onNoClick()">No</button>&nbsp;
  <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Sí</button>
</div>