//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { BibliotecaGeneralService } from 'src/app/services/biblioteca-general.service';


@Component({
  selector: 'app-ac-categorias',
  templateUrl: './ac-categorias.component.html',
  styleUrls: ['./ac-categorias.component.scss'],
  providers: [ BibliotecaGeneralService ]
})
export class AcCategoriasComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    categoria: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)]),
    numero_categoria: new UntypedFormControl(1, []),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public id_doc: number = -1;
  public id_cat: number = -1;
  public archivo: any;
  public categorias: any = [];

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _BibliotecaGeneralService: BibliotecaGeneralService,
    private _router: Router,
    private _route: ActivatedRoute,
    ) { 
    
    
    //this.getConsultarCategoriasBibliotecaTodas();
    /*modificar*/ 
    this._route.queryParams.subscribe(
      params => {
        this.id_cat = +params['id_cat'];
        if(this.id_cat){
          this.form.patchValue({ numero_categoria: this.id_cat});
          this.getConsultarCategoriaBiblioteca(this.id_cat);
        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    //accion
    let url_list = this._router.url.split("/");
    this.accion = url_list[3].split("?")[0];
    console.log(this.accion);
  }

  public getConsultarCategoriaBiblioteca(id: number):any{
    const formData = new FormData();
    this.isLoading = true;
    this._BibliotecaGeneralService.getConsultarCategoriaBiblioteca(id).subscribe(
      response => {
        //this.res(response);
        console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.setForm(response.data);
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', text: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public setForm(data: any){
    this.form.patchValue({
      categoria: data?.categoria,
    });
  }

  public postRegistrarCategoriaBiblioteca():any{
    this.isLoading = true;
    this._BibliotecaGeneralService.postRegistrarCategoriaBiblioteca(this.form.value).subscribe(
      response => {
        this.res(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', text: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postModificarCategoriaBiblioteca():any{
    this.isLoading = true;
    this._BibliotecaGeneralService.postModificarCategoriaBiblioteca(this.form.value).subscribe(
      response => {
        this.res(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', text: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public res(response: any){
    this.isLoading = false;
    if(response.estado == 200){
      this._router.navigate(['biblioteca-categoria/biblioteca-categoria-general']);
      this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: 'Operación exitosa', icon:"done", href:null}});
    }else{
      this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Fallo en el servicio, vuelva intentarlo', icon:"error_outline", href:null}});
    }
  }

}
