import { Component } from '@angular/core';

@Component({
  selector: 'app-evaluacion-pedagogica',
  templateUrl: './evaluacion-pedagogica.component.html',
  styleUrls: ['./evaluacion-pedagogica.component.scss']
})
export class EvaluacionPedagogicaComponent {

  

}
