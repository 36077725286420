import { Component, OnInit } from '@angular/core';

export interface PeriodicElement {
  name: string;
  position: string;
  weight: string;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: "Sin Foto", name: '20/04/2021', weight: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced be by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.", symbol: 'Intervención'},
  {position: "Sin Foto", name: '20/05/2021', weight: 'El tratamiento y la evaluación han finalizado satisfactoriamente', symbol: 'Normal'},
  {position: "Sin Foto", name: '20/06/2021', weight: 'El tratamiento y la evaluación han finalizado satisfactoriamente', symbol: 'Normal'}
  
];
@Component({
  selector: 'app-expedientes-ppalumno',
  templateUrl: './expedientes-ppalumno.component.html',
  styleUrls: ['./expedientes-ppalumno.component.scss']
})
export class ExpedientesPPAlumnoComponent  {
  isLoading=false;
  displayedColumns: string[] = ['fecha','conclusiones','interpretacion','intervencion','evaluacion'];
  dataSource = ELEMENT_DATA;
  
  id=0;
  

}
