import { Component, Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SessionServiceService } from 'src/app/services/session-service.service';
export interface DialogData {
  titlePass: string;
  mnsjPass: string;
  icon2: string;
  href: string;
}
@Component({
  selector: 'app-pass-box',
  templateUrl: './pass-box.component.html',
  styleUrls: ['./pass-box.component.scss']
})
export class PassBoxComponent{
  constructor(
    private route:Router, 
    public _session:SessionServiceService,
    public dialogRef: MatDialogRef<PassBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData){ 
      dialogRef.disableClose = true;
     }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
