import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { PrintEncuestaComponent } from './modales/print-encuesta/print-encuesta.component';
import { CiclosMannagerComponent } from './pages/ciclos-mannager/ciclos-mannager.component';
import { NuevoCicloComponent } from './pages/ciclos-mannager/nuevo-ciclo/nuevo-ciclo.component';
import { ContestarComponent } from './pages/encuesta/contestar/contestar.component';
import { EncuestaComponent } from './pages/encuesta/encuesta.component';
import { EncuestasMannagerComponent } from './pages/encuestas-mannager/encuestas-mannager.component';
import { NoGrantComponent } from './pages/errors/no-grant/no-grant.component';
import { ExpedientesAlumnosComponent } from './pages/expedientes-alumnos/expedientes-alumnos.component';
import { ViewExpedienteComponent } from './pages/expedientes-alumnos/view-expediente/view-expediente.component'; 
import { FichaInformativaComponent } from './pages/expedientes-alumnos/new-expediente/ficha-informativa/ficha-informativa.component';
import { NewExpedienteComponent } from './pages/expedientes-alumnos/new-expediente/new-expediente.component';
import { LoginComponent } from './pages/login/login.component';
import { PrincipalComponent } from './pages/principal/principal.component';
import { NewUserComponent } from './pages/user-mannager/new-user/new-user.component';
import { UserMannagerComponent } from './pages/user-mannager/user-mannager.component';
import { ControlEscolarComponent } from './pages/expedientes-alumnos/control-escolar/control-escolar.component';
import { InscripcionComponent } from './pages/inscripcion/inscripcion.component';
import { GestorDeEvaluacionesComponent } from './pages/gestor-de-evaluaciones/gestor-de-evaluaciones.component';
import { EstudioSocioeconomicoComponent } from './pages/estudio-socioeconomico/estudio-socioeconomico.component';
import { TesoreriaComponent } from './pages/tesoreria/tesoreria.component';



import { RecuperaPassComponent } from './modales/recupera-pass/recupera-pass.component';
import {CicloEscolarComponent} from './pages/ciclo-escolar/ciclo-escolar.component';
import { PlanEstudiosComponent } from './pages/plan-estudios/plan-estudios.component';
import { CreacionGruposComponent } from './pages/creacion-grupos/creacion-grupos.component';
import { GestionAcademicaComponent } from './pages/gestion-academica/gestion-academica.component';
import { AsistenciaIncidenciaComponent } from './pages/asistencia-incidencia/asistencia-incidencia.component';
import { EvaluacionPsicologicaComponent } from './pages/evaluacion-psicologica/evaluacion-psicologica.component';
import { EvaluacionPedagogicaComponent } from './pages/evaluacion-pedagogica/evaluacion-pedagogica.component';
import { AdminCajaComponent } from './pages/admin-caja/admin-caja.component';
import { ExpedientesPsicopedagogicosComponent } from './pages/expedientes-psicopedagogicos/expedientes-psicopedagogicos.component';
import { ExpedientesPPAlumnoComponent } from './pages/expedientes-ppalumno/expedientes-ppalumno.component';
import { EvaluacionPsicoPedagogicaComponent } from './pages/evaluacion-psico-pedagogica/evaluacion-psico-pedagogica.component';
import { AsignacionDeGruposComponent } from './pages/asignacion-de-grupos/asignacion-de-grupos.component';
import { GestorDeHistorialEvaluacionIngresoComponent } from './pages/gestor-de-evaluaciones/gestor-de-historial-evaluacion-ingreso/gestor-de-historial-evaluacion-ingreso.component';


import { BibliotecaGeneralComponent } from './pages/biblioteca-general/biblioteca-general.component';
import { AccionesBibliotecaGeneralComponent } from './pages/biblioteca-general/acciones-biblioteca-general/acciones-biblioteca-general.component';
import { GtCategoriasComponent } from './pages/biblioteca-general/gt-categorias/gt-categorias.component';
import { AcCategoriasComponent } from './pages/biblioteca-general/ac-categorias/ac-categorias.component';
import { GtSolicitudesValidacionPagosComponent } from './pages/tesoreria/gt-solicitudes-validacion-pagos/gt-solicitudes-validacion-pagos.component';
import { TtrAcValidacionPagosComponent } from 'src/app/pages/tutores/ttr-dashboard/ttr-validacion-pagos/ttr-ac-validacion-pagos/ttr-ac-validacion-pagos.component';
// Tutores sesion
import { TtrSesionComponent } from 'src/app/pages/tutores/sesion/ttr-sesion/ttr-sesion.component';
// Fin tutores sesion
import { ParametrizacionCicloComponent } from './pages/control-escolar/parametrizacion-ciclo/parametrizacion-ciclo.component';
import { TtrGtValidacionPagosComponent } from 'src/app/pages/tutores/ttr-dashboard/ttr-validacion-pagos/ttr-gt-validacion-pagos/ttr-gt-validacion-pagos.component';

const routes: Routes = [
  { path: 'security/login', component: LoginComponent},
  { path: 'principal', component: PrincipalComponent, canActivate:[AuthGuard] },
  { path: 'tesoreria', component: TesoreriaComponent , canActivate:[AuthGuard] },
  { path: 'validacion-pagos-tesoreria', component: GtSolicitudesValidacionPagosComponent , canActivate:[AuthGuard] },
  { path: 'validacion-pagos-tesoreria/consultar', component: TtrAcValidacionPagosComponent , canActivate:[AuthGuard] },

  { path: 'control-escolar', component: ExpedientesAlumnosComponent , canActivate:[AuthGuard] },
  { path: 'control-escolar/view', component: ViewExpedienteComponent , canActivate:[AuthGuard] },
  { path: 'control-escolar/new', component: NewExpedienteComponent , canActivate:[AuthGuard] },
  { path: 'control-escolar/edit', component: NewExpedienteComponent , canActivate:[AuthGuard] },
  { path: 'control-escolar/ficha', component: FichaInformativaComponent , canActivate:[AuthGuard] },

  { path: 'control-escolar/asignacion/grupos', component: AsignacionDeGruposComponent , canActivate:[AuthGuard] },
  { path: 'control-escolar/control', component: ControlEscolarComponent , canActivate:[AuthGuard] },
  { path: 'control-escolar/control/inscripcion', component: InscripcionComponent , canActivate:[AuthGuard] },
  { path: 'login/encuesta', component: EncuestaComponent  },
  { path: 'encuesta/:id', component: ContestarComponent  },

  { path: 'estudio-socioeconomico', component: EstudioSocioeconomicoComponent , canActivate:[AuthGuard] },


  { path: 'evaluaciones', component: GestorDeEvaluacionesComponent , canActivate:[AuthGuard] },
  { path: 'evaluaciones/historial-ingreso', component: GestorDeHistorialEvaluacionIngresoComponent , canActivate:[AuthGuard] }, //pendiente
  



  { path: 'administracion/user-mannager', component: UserMannagerComponent , canActivate:[AuthGuard] },
  { path: 'administracion/user-mannager/new', component: NewUserComponent , canActivate:[AuthGuard] },
  { path: 'administracion/user-mannager/edit', component: NewUserComponent , canActivate:[AuthGuard] },
  { path: 'medicion-resultados/encuestas-mannager', component: EncuestasMannagerComponent , canActivate:[AuthGuard] },
  { path: 'medicion-resultados/ciclos-mannager', component: CiclosMannagerComponent , canActivate:[AuthGuard] },
  { path: 'medicion-resultados/ciclos-mannager/new', component: NuevoCicloComponent , canActivate:[AuthGuard] },
  { path: 'medicion-resultados/ciclos-mannager/edit', component: NuevoCicloComponent , canActivate:[AuthGuard] },
  { path: 'print/encuesta/:id', component: PrintEncuestaComponent  , canActivate:[AuthGuard] },
  { path: 'reporteria/indicadores', component: CiclosMannagerComponent , canActivate:[AuthGuard] },


  /*biblioteca*/
  { path: 'biblioteca/biblioteca-general',   component: BibliotecaGeneralComponent   , canActivate:[AuthGuard] },
  { path: 'biblioteca/biblioteca-general/registrar',   component: AccionesBibliotecaGeneralComponent   , canActivate:[AuthGuard] },
  { path: 'biblioteca/biblioteca-general/consultar',   component: AccionesBibliotecaGeneralComponent   , canActivate:[AuthGuard] },
  { path: 'biblioteca/biblioteca-general/modificar',   component: AccionesBibliotecaGeneralComponent   , canActivate:[AuthGuard] },
  /*categorias*/
  { path: 'biblioteca-categoria/biblioteca-categoria-general',   component: GtCategoriasComponent   , canActivate:[AuthGuard] },
  { path: 'biblioteca-categoria/biblioteca-categoria-general/registrar',   component: AcCategoriasComponent   , canActivate:[AuthGuard] },
  { path: 'biblioteca-categoria/biblioteca-categoria-general/consultar',   component: AcCategoriasComponent   , canActivate:[AuthGuard] },
  { path: 'biblioteca-categoria/biblioteca-categoria-general/modificar',   component: AcCategoriasComponent   , canActivate:[AuthGuard] },




  { path: 'parametrizacion', component: ParametrizacionCicloComponent , canActivate:[AuthGuard] },


  //tutores
  { path: 'tutores/sesion', component: TtrSesionComponent},
  



  { path: '',
    redirectTo: 'principal',
    pathMatch: 'full'
  },

  { path: 'error/no-grant', component: NoGrantComponent},
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
