import { User } from "./user";
import { Accion } from './accion';

export class Session {
    usuario:User;
    private active:boolean;
    private timeout:Number;
    private acciones:Accion[];
    
    constructor(){
        this.usuario = new User();
    }
    setActive(active:boolean):void{
        this.active = active;
    }
    getActive():boolean{
        return this.active;
    }
    getUsuario():User{
        return this.usuario;
    }
    setUsuario(usuario:User):void{
        this.usuario = usuario;
    }
    
    setTimeOut(timeout:Number):void{
        this.timeout = timeout;
    }
    getTimeout():Number{
        return this.timeout;
    }
    getAcciones():Accion[]{
        return this.acciones;
    }
    setAcciones(acciones:Accion[]):void{
        this.acciones = acciones;
    }

}
