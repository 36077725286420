<div class="container-fluid p-5">
    <div class="row">
        <div *ngFor="let r of all; let o = index" class="mt-5">
            <span class="fs-4 fw-semibold">Categoría: {{r?.categoria}}</span>
            <div *ngFor="let d of r?.biblioteca; let i = index" class="col-xs-12 col-sm-6 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-3">

                <div class="row no-gutters border p-3 bg-white">
                    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 p-1">
                        <span class="material-icons bi bi-files fs-1 fw-light">document_scanner</span>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                        <div class="d-flex justify-content-end">
                            <!--<h5 class="card-title fs-6 fw-light"></h5>-->
                            <p class="card-text web-color-primario">{{i + 1}}</p>
                        </div>
                        <p class="card-text text-break fw-light">{{d.nombre}}</p>
                        <p class="card-text"><small class="text-muted"></small></p>
                        <button type="button" class="btn btn-small btn-primary">
                            <a  target="_blank"  rel="noopener" href="{{laravelapiStorage+'/'+d?.ruta+'?t='+t}}">
                                <span class="material-icons text-white">
                                    file_present
                                </span>
                            </a>
                        </button>
                    </div>
                </div>
            </div>

        </div>


        
    </div>
</div>