<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Expediente Picopedagógico</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <app-ficha-informativa></app-ficha-informativa>
            
            <div>
                <button mat-flat-button color="accent" [routerLink]="['evaluacion']" [queryParams]="{id:id}">
                    <mat-icon>add</mat-icon>&nbsp;Nueva evaluación
                </button>
            </div>
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
                <caption></caption>
                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="interpretacion">
                  <th  scope="col" mat-header-cell *matHeaderCellDef> Interpretación de referencia </th>
                  <td mat-cell *matCellDef="let element">
                      <div class="container">
                          <div class="row">
                              <div class="col-md-12">
                                <div class="alert alert-danger" role="alert"></div>
                              </div>
                          </div>
                        
                      </div>
                    
                  </td>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="fecha">
                  <th  scope="col" mat-header-cell *matHeaderCellDef> Fecha </th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="conclusiones">
                  <th  scope="col" mat-header-cell *matHeaderCellDef> Conclusiones de la evaluación </th>
                  <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="intervencion">
                  <th  scope="col" mat-header-cell *matHeaderCellDef> Intervención </th>
                  <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="evaluacion">
                    <th  scope="col" mat-header-cell *matHeaderCellDef> Eval. </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-mini-fab color="primary">
                            <mat-icon>task</mat-icon>
                        </button>&nbsp;
                        <button mat-mini-fab color="primary">
                            <mat-icon>print</mat-icon>
                        </button>
                    </td>
                    
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="plan">
                    <th  scope="col" mat-header-cell *matHeaderCellDef> Plan </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-mini-fab color="accent">
                            <mat-icon>task</mat-icon>
                        </button>&nbsp;
                        <button mat-mini-fab color="accent">
                            <mat-icon>print</mat-icon>
                        </button>
                    </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              
        </mat-card-content>
    </mat-card>
</div>