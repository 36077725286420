<div class="spinner" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
<div class="container-fluid no-padding">
  <mat-card class="example-card">
    <mat-card-content>
      <div class="icon">
        <img class="logo" src="/assets/img/LOGOTIPO.png" alt="Logo SanJuanPablo" height="auto"/>
      </div>
      <div class="formulario">
        <span class="subtitle">Iniciar Sesión</span><br/><br>
        <form autocomplete="off" (ngSubmit)="login()" [formGroup]="formulario">
            <mat-form-field appearance="outline">
                <mat-label>Usuario</mat-label>
               <input matInput type="text" formControlName="usr">
                <!-- <input matInput type="text" formControlName="usr" [(ngModel)]="username"> -->
                <mat-icon matSuffix color="primary">person</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Contraseña</mat-label>
                <input matInput type="password" formControlName="pass">
                <!-- <input matInput type="password" formControlName="pass" [(ngModel)]="password"> -->
                <mat-icon matSuffix color="primary">lock</mat-icon>
            </mat-form-field>
            
            <button mat-flat-button color="primary" class="flat" type="submit">
              Entrar
            </button> &nbsp;
            <button mat-stroked-button color="primary" class="flat" [routerLink]="['/login/encuesta']" type="button">
              <mat-icon>poll</mat-icon>Encuestas
            </button>
        </form>
        <br><br>
        <a href="#" class="d-none">¿Olvido su contraseña?</a><br>
      </div>
    </mat-card-content>
    <mat-card-actions>
      
    </mat-card-actions>
  </mat-card>
</div>