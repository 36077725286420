import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {MatDialogRef,MatDialog} from '@angular/material/dialog';
import { UsersServiceService } from 'src/app/services/users-service.service';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';

@Component({
  selector: 'app-recupera-pass',
  templateUrl: './recupera-pass.component.html',
  styleUrls: ['./recupera-pass.component.scss']
})
export class RecuperaPassComponent {
  
  constructor(
    private _users:UsersServiceService,
    public dialogRef: MatDialogRef<RecuperaPassComponent>,
    private dialog:MatDialog
    ) { 
    dialogRef.disableClose = true;
  }

  formulario = new FormGroup({
    correo:new FormControl("",[Validators.required,Validators.email])
  });

  onNoClick(): void {
    this.dialogRef.close();
  }

  enviar(){
    if(this.formulario.valid){
      console.info(this.formulario.getRawValue());
      this._users.getCorreo(this.formulario.getRawValue()).subscribe(results=>{
        if(results.code==200){
          console.log(results.datos);
          this._users.sendCorreo(results.datos.usuario).subscribe(results=>{
            if(results.code==200){
              console.log(results);
              this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "El mensaje se envió exitosamente", text: "Revise su correo donde encotrará sus datos para inciar sesión.", icon:"done", href:"/"}});
            }
          });
          this.dialog.closeAll();
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "No se encontró el correo ingresado.", text: "Por favor, verifique su correo y vuelva a intentar.", icon:"error_outline", href:"/"}});
        }
      },error=>{
        console.info(error);
      });
    }
  }
}