import { Component } from '@angular/core';

@Component({
  selector: 'app-asistencia-incidencia',
  templateUrl: './asistencia-incidencia.component.html',
  styleUrls: ['./asistencia-incidencia.component.scss']
})
export class AsistenciaIncidenciaComponent  {

 

}
