import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Datos} from 'src/app/model/datos';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class AlumnsService {

  private _datos: Datos;

  public endpoint: string = "";
  public endpoint_2: string = '';
  constructor(
    private _http:HttpClient,
    private _config:ConfigurationService
  ) { 
    this._datos = new Datos();
    this.endpoint = _config.getConfiguration().hostapi;
    this.endpoint_2 = this._config.getConfiguration().laravelapi;
  }

  getDatos(json:any):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  let options = { headers: headers };
    return this._http.post(this.endpoint+"/php/datos-alumno-service.php?opc=datos",json,options);
  }

  muestraDatos(datos:Datos):void{
    this._datos.setEstados(datos.getEstados());
    this._datos.setNivel(datos.getNivel());
    this._datos.setGrado(datos.getGrado());
    this._datos.setEdoCivil(datos.getEdoCivil());
    //this._datos.set(datos.getGrupoAtencion());
    this._datos.setOcupacion(datos.getOcupacion());
    this._datos.setSeguro(datos.getSeguro());
    this._datos.setGrupoS(datos.getGrupoS());
    this._datos.setFamiliar(datos.getFamiliar());
    this._datos.setCredencial(datos.getCredencial());
    console.info(this._datos);
  }

  getAlumnoById(id:number):Observable<any>{
    return this._http.get(this.endpoint+"/php/alumnos-service.php?opc=findById&id="+id);
  }

  DeleteAlumnoById(id:number):Observable<any>{
    return this._http.get(this.endpoint+"/php/alumnos-service.php?opc=delete&id="+id);
  }

  findAlumnos(busqueda:string):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  let options = { headers: headers };
    return this._http.get(this.endpoint+"/php/alumnos-service.php?opc=find&busqueda="+busqueda,options);
  }


  findAlumnFiltro(busqueda:string,index:number,size:number):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  let options = { headers: headers };
  // console.log(this.endpoint+"/php/alumnos-service.php?opc=findWithFilter&busqueda="+busqueda+"&index="+index+"&size="+size,options);
    return this._http.get(this.endpoint+"/php/alumnos-service.php?opc=findWithFilter&busqueda="+busqueda+"&index="+index+"&size="+size,options);
    // return this._http.get("http://localhost/ng-san-juan-pablo/php/alumnos-service.php?opc=findWithFilter&busqueda="+busqueda+"&index="+index+"&size="+size,options);
  }
  findAlumnFiltro2(busqueda:any,index:number,size:number):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  let options = { headers: headers };
  // console.log(this.endpoint+"/php/alumnos-service.php?opc=findWithFilter&busqueda="+busqueda+"&index="+index+"&size="+size,options);
    return this._http.get(this.endpoint+"/php/alumnos-service.php?opc=findWithFilter&busqueda="+busqueda.busqueda+"&ciclo="+busqueda.ciclo+"&nivel="+busqueda.nivel+"&grado="+busqueda.grado+"&grupo="+busqueda.grupo+"&adeudos="+busqueda.adeudos+"&inscritos="+busqueda.inscritos+"&index="+index+"&size="+size,options);
    // return this._http.get("http://localhost/ng-san-juan-pablo/php/alumnos-service.php?opc=findWithFilter&busqueda="+busqueda+"&index="+index+"&size="+size,options);
  }


  altaAlumnos(json:any,usuario:Number):Observable<any>{
    return this._http.post(this.endpoint+"/php/alumnos-service.php?opc=alta&usuario="+usuario,json);
    // return this._http.post("http://localhost/ng-san-juan-pablo/php/alumnos-service.php?opc=alta&usuario="+usuario,json);
  }
  modificaAlumnos(json:any,usuario:Number,idAlumno:Number):Observable<any>{
    return this._http.post(this.endpoint+"/php/alumnos-service.php?opc=modifica&usuario="+usuario+"&idAlumno="+idAlumno,json);
    // return this._http.post("http://localhost/ng-san-juan-pablo/php/alumnos-service.php?opc=modifica&usuario="+usuario+"&idAlumno="+idAlumno,json);
  }
  altaActualizarDatosComplementarios(json:any,usuario:Number,idAlumno:Number):Observable<any>{
    return this._http.post(this.endpoint+"/php/alumnos-service.php?opc=altaActualizarComplementarios&idAlumno="+idAlumno+"&usuario="+usuario,json);
    // return this._http.post("http://localhost/ng-san-juan-pablo/php/alumnos-service.php?opc=altaActualizarComplementarios&idAlumno="+idAlumno+"&usuario="+usuario,json);
  }

  actualizarAlumnos(id:number,json:any):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });
  let options = { headers: headers };
    return this._http.post(this.endpoint+"/php/alumnos-service.php?opc=update&id="+id,json,options);
  }

  getGrados(nivel:String):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });
  let options = { headers: headers };
    return this._http.post(this.endpoint+"/php/datos-alumno-service.php?opc=grados&nivel="+nivel,options);
  }

  cargarFoto(file:any,idAlumno:any):Observable<any>{
    const formData = new FormData();
    let headers = new HttpHeaders({
      'Content-Type':'multipart/form-data'
    })
    let options = {headers:headers};
    formData.append('file',file);
    return this._http.post<any>(this.endpoint+"/php/cargapic.php?idAlumno="+idAlumno,formData);
    // return this._http.post<any>("http://localhost/ng-san-juan-pablo/php/cargapic.php?idAlumno="+idAlumno,formData);
  }
  altaDocumento(file:any,idAlumno:any,tipoDocto:any):Observable<any>{
    const formData = new FormData();
    let headers = new HttpHeaders({
      'Content-Type':'multipart/form-data'
    })
    let options = {headers:headers};
    formData.append('file',file);
    return this._http.post<any>(this.endpoint+"/php/documentos-service.php?tipoDocto="+tipoDocto+"&opc=alta&idAlumno="+idAlumno,formData);
    // return this._http.post<any>("http://localhost/ng-san-juan-pablo/php/documentos-service.php?tipoDocto="+tipoDocto+"&opc=alta&idAlumno="+idAlumno,formData);
  }
  consultaDocumentos(idAlumno:any):Observable<any>{
    
    return this._http.get<any>(this.endpoint+"/php/documentos-service.php?opc=consulta&idAlumno="+idAlumno);
  }
  altaContacto(json:any,idAlumno){
    return this._http.post<any>(this.endpoint+"/php/alumnos-service.php?opc=altaContacto&idAlumno="+idAlumno,json);
    // return this._http.post<any>("http://localhost/ng-san-juan-pablo/php/alumnos-service.php?opc=altaContacto&idAlumno="+idAlumno,json);
  }
  actualizaContacto(json:any,idContacto){
    return this._http.post<any>(this.endpoint+"/php/alumnos-service.php?opc=actualizaContacto&idContacto="+idContacto,json);
    // return this._http.post<any>("http://localhost/ng-san-juan-pablo/php/alumnos-service.php?opc=actualizaContacto&idContacto="+idContacto,json);
  }
  getContactos(idAlumno:String){
    return this._http.get<any>(this.endpoint+"/php/alumnos-service.php?opc=getContactos&idAlumno="+idAlumno);
    // return this._http.get<any>("http://localhost/ng-san-juan-pablo/php/alumnos-service.php?opc=getContactos&idAlumno="+idAlumno);
  }
  getFotos(idAlumno:Number){
    return this._http.get<any>(this.endpoint+"/php/alumnos-service.php?opc=getFotos&idAlumno="+idAlumno);
  }
  eliminarContacto(idContacto:Number){
    return this._http.delete<any>(this.endpoint+"/php/alumnos-service.php?opc=eliminarContacto&idContacto="+idContacto);
  }
  getCiclos(idAlumno:number):Observable<any>{
    return this._http.get(this.endpoint+"/php/alumnos-service.php?opc=ciclosByAlumno&idAlumno="+idAlumno)
    // return this._http.get("http://localhost/ng-san-juan-pablo/php/control-escolar-service.php?opc=findConceptosCobro&idAlumno="+idAlumno+"&ciclo="+idCiclo+"&nivel="+idNivel+"&grado="+idGrado)
  }
  export(){
    return this._http.get(this.endpoint+"/php/alumnos-service.php?opc=exportAlumnos",{
      responseType: 'arraybuffer'} );
  }

  /* intranet */
  getConsultarCuentas(){
    let headers = new HttpHeaders()
       .set('Accept', 'application/json');
    return this._http.get<any>(this.endpoint_2+"ConsultarCuentas", {headers: headers});
  }

  postAsociarAlumno(datos: any){
    let headers = new HttpHeaders()
       .set('Accept', 'application/json');
    return this._http.post<any>(this.endpoint_2+'AsociarAlumno', datos, {headers: headers});
  }

  postInformacionCuenta(datos: any){
    let headers = new HttpHeaders()
       .set('Accept', 'application/json');
    return this._http.post<any>(this.endpoint_2+'InformacionCuenta', datos, {headers: headers});
  }

  postModificarCuentaExpediente(datos: any){
    let headers = new HttpHeaders()
       .set('Accept', 'application/json');
    return this._http.post<any>(this.endpoint_2+'ModificarCuentaExpediente', datos, {headers: headers});
  }

  postRegistroCuentaExpediente(datos: any){
    let headers = new HttpHeaders()
       .set('Accept', 'application/json');
    return this._http.post<any>(this.endpoint_2+'RegistroCuentaExpediente', datos, {headers: headers});
  }

  postRegistrarCuenta(datos: any){
    let headers = new HttpHeaders()
       .set('Accept', 'application/json');
    return this._http.post<any>(this.endpoint_2+'RegistrarCuenta', datos, {headers: headers});
  }

  postBusquedaCuentas(datos: any){
    let headers = new HttpHeaders()
       .set('Accept', 'application/json');
    return this._http.post<any>(this.endpoint_2+'BusquedaCuentas', datos, {headers: headers});
  }
  

  /* fin intranet */

  public getEliminarFotoAlumno(n_f: number, url: string){
    return this._http.get<any>(this.endpoint_2+'EliminarFotoAlumno?numero_foto='+n_f+'&url='+url,{});
  }

  public postEliminarCuentaAlumno(datos: any){
    let headers = new HttpHeaders()
       .set('Accept', 'application/json');
    return this._http.post<any>(this.endpoint_2+'EliminarAlumno',datos, {headers: headers});
  }


}
