<br><br>
<div class="card-container mt-5">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Documentos</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <div class="bt col-md-12 text-align-right">
                <button class="flat" color="primary" mat-flat-button [routerLink]="['/biblioteca/biblioteca-general/registrar']" >
                    Registrar nuevo documento
                </button>
                &nbsp;
                <button class="flat" color="primary" mat-flat-button [routerLink]="['/biblioteca-categoria/biblioteca-categoria-general']" >
                    Gestor de categorías
                </button>
                <!--<button class="flat" color="primary" mat-flat-button>Comparativo</button>
                    <button class="flat" color="primary" mat-flat-button>Gráfica (Pizza)</button>-->
                </div>

                <!-- tabla -->
                <div class="row mt-5 mat-elevation-z8">
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <caption><!--Parrilla--></caption>
                        <!-- contenido -->

                        <ng-container matColumnDef="id">
                            <th id="id" mat-header-cell *matHeaderCellDef> ID </th>
                            <td mat-cell *matCellDef="let element">{{element?.id_biblioteca_padre_pk}}</td>
                        </ng-container>

                        <ng-container matColumnDef="n_d">
                            <th id="n_d" mat-header-cell *matHeaderCellDef> NOMBRE </th>
                            <td mat-cell *matCellDef="let element">{{element?.nombre}}</td>
                        </ng-container>

                        <ng-container matColumnDef="categoria">
                            <th id="categoria" mat-header-cell *matHeaderCellDef> CATEGORÍA </th>
                            <td mat-cell *matCellDef="let element">{{element?.categorias?.categoria}}</td>
                        </ng-container>
                    
                        <ng-container matColumnDef="consultar">
                            <th id="consultar" mat-header-cell *matHeaderCellDef> CONSULTAR </th>
                            <td mat-cell *matCellDef="let element">
                                <a mat-mini-fab color="primary" href="{{laravelapiStorage}}/{{element.ruta+'?t='+t}}" target="_blank" rel=noopener>
                                    <mat-icon class="material-icons">
                                        open_in_new
                                    </mat-icon>
                                </a>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="modificar">
                            <th id="modificar" mat-header-cell *matHeaderCellDef> MODIFICAR </th>
                            <td mat-cell *matCellDef="let element">
                                <button type="button" mat-mini-fab color="primary" >
                                    <mat-icon class="material-icons" [routerLink]="['/biblioteca/biblioteca-general/modificar']" [queryParams]="{id_doc:element.id_biblioteca_padre_pk, nombre:element.nombre, id_cat: element?.id_categoria_biblioteca_fk}">
                                        open_in_new
                                    </mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <!--<ng-container matColumnDef="eliminar">
                            <th id="eliminar" mat-header-cell *matHeaderCellDef> ELIMINAR </th>
                            <td mat-cell *matCellDef="let element">
                                <button type="button" mat-mini-fab color="warn" (click)="postEliminarDocumentosOrg(element.id_doc_org_pk)">
                                    <mat-icon class="material-icons">
                                        delete
                                    </mat-icon>
                                </button>
                            </td>
                        </ng-container>-->

                        <!-- fin contenido -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <!-- fin tabla -->

                <!--<div class="mt-5 d-flex justify-content-center">
                    <button class="flat" color="primary" mat-flat-button [routerLink]="['/rrhh/gestor-de-puestos-de-trabajo']" >
                        Regresar
                    </button>
                </div>-->

                
            </mat-card-content>
            <mat-card-actions align="start">
                <mat-paginator (page)="pageEvent($event)" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"  aria-label="Selecione una pagina">
                </mat-paginator>
            </mat-card-actions>
        </mat-card>
    </div>
