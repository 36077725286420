<br><br>
<div class="card-container encuesta">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Administrador de usuarios</mat-card-title>
            <mat-card-subtitle>Gestiona los usuarios del sistema</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <form autocomplete="no" (ngSubmit)="buscar()" [formGroup]="formulario">
                <mat-form-field appearance="fill" class="bt col-md-6" >
                    <mat-label>Buscar</mat-label>
                    <input matInput placeholder="Por nombre y correo" formControlName="busqueda">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <button type="button" mat-flat-button color="primary" [routerLink]="['new']">Nuevo usuario</button>
            </form>
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
                <caption></caption>
                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
                
                <ng-container matColumnDef="position">
                    <th id="position" mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element;let i=index"> {{i+1}} </td>
                </ng-container>
                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="nombre">
                  <th id="nombre" mat-header-cell *matHeaderCellDef> Nombre </th>
                  <td mat-cell *matCellDef="let element"> {{element.apellidop}} {{element.apellidom}} {{element.nombre}}</td>
                </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="licencia">
                  <th id="licencia" mat-header-cell *matHeaderCellDef> Licencia </th>
                  <td mat-cell *matCellDef="let element"> {{element.licencia}} </td>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="correo">
                  <th id="correo" mat-header-cell *matHeaderCellDef> Correo </th>
                  <td mat-cell *matCellDef="let element"> {{element.correo}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="editar">
                    <th id="editar" mat-header-cell *matHeaderCellDef> Editar </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="primary" [routerLink]="['edit']" [queryParams]="{id:element.id_usuario_pk}">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="eliminar">
                    <th id="eliminar" mat-header-cell *matHeaderCellDef> Eliminar </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="warn" (click)="delete(element.id_usuario_pk)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>            
        </mat-card-content>
        <mat-card-actions>
            <mat-paginator [length]="length"
               [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions"
               (page)="pageEvent = $event">
            </mat-paginator>
        </mat-card-actions>
    </mat-card>
</div>