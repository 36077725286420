<br><br>
<div class="card-container encuesta">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Programación de encuestas</mat-card-title>
            <mat-card-subtitle>Planifica o desplanifica la encuesta.</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
            <form autocomplete="off" (ngSubmit)="sendForm()" [formGroup]="formulario">
                <mat-form-field appearance="fill" class="bt col-md-6" >
                    <mat-label>Ciclo</mat-label>
                    <input matInput placeholder="" formControlName="ciclo">
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-6" >
                    <mat-label>Público</mat-label>
                    <mat-select formControlName="publico">
                        <mat-option [value]="publico" *ngFor="let publico of publicos">{{publico}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-6" >
                    <mat-label>Grado</mat-label>
                    <mat-select formControlName="grado">
                        <mat-option [value]="grado" *ngFor="let grado of grados">{{grado}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-6" >
                    <mat-label>Grupo</mat-label>
                    <mat-select formControlName="grupo">
                        <mat-option [value]="grupo" *ngFor="let grupo of grupos">{{grupo}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-6" >
                    <mat-label>Encuesta</mat-label>
                    <mat-select formControlName="encuesta">
                        <mat-option [value]="encuesta.id_encuesta_pk" *ngFor="let encuesta of encuestas">{{encuesta.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-6" >
                    <mat-label>Apertura</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="apertura">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="fill" class="bt col-md-6" >
                    <mat-label>Cierre</mat-label>
                    <input matInput [matDatepicker]="picker2" formControlName="cierre">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker2></mat-datepicker>
                </mat-form-field>
                <div>
                    <button mat-flat-button color="primary">Guardar</button>&nbsp;
                    <button mat-stroked-button type="button" color="primary" [routerLink]="['/medicion-resultados/ciclos-mannager']">Cancelar</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>