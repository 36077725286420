import { Component, OnInit, Inject } from '@angular/core';
import { FormArray,FormBuilder, FormGroup,FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';

import { Router, ActivatedRoute, Params } from '@angular/router'; 
import { TtrSesionService } from 'src/app/services/tutores/ttr-sesion.service';

import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-ttr-mi-cuenta',
  templateUrl: './ttr-mi-cuenta.component.html',
  styleUrls: ['./ttr-mi-cuenta.component.scss'],
  providers: [ TtrSesionService ]
})
export class TtrMiCuentaComponent implements OnInit {

  public form: FormGroup;
  public msj: string;
  public sta: number;
  public matcher: MyErrorStateMatcher;
  public isLoading: boolean;
  public dataGirasol: any;

  //unicos de esta cuenta
  public cuenta: any;

  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _ttrSesionService: TtrSesionService,
    private dialog:MatDialog,
    ){ 
    this.msj = '';
    this.sta = 0;
    this.isLoading = false;
    //this.dataGirasol = JSON.parse(this._ttrSesionService.getDataGirasol());

    this.form = this.fb.group({
      correo: new FormControl('', [Validators.required, Validators.email]),
      contrasena: new FormControl('', [Validators.required]),
      cuenta: new FormControl('', [Validators.required]),
      nombre_obligado: new FormControl('', [Validators.required]),
    });


    console.log(this.dataGirasol);
  }

  ngOnInit(): void {
   
    this.getDS();
  }

  getDS(){
    //this.user = this.form.value;
    this._ttrSesionService.getDatosSesion().subscribe(
      (response: any) => {console.log(response);
          if(response.estado == 200){
            this.dataGirasol = response.data;
            //this.val();
            //this._router.navigate(['tutores/dashboard']);
            this.postInformacionCuenta();
          }
        },
      (error: any) => {
        
      } 
    );
  }

  public postInformacionCuenta(){
    this.isLoading = true;
    this._ttrSesionService.postInformacionCuenta({cuenta: this.dataGirasol.id_cuenta_pk}).subscribe(
      (response: any) => {
        console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.cuenta = response.data;
          this.setForm();
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
        }
      },
      error => {
        //console.log(<any>error);
        this.isLoading = false;
      });
  }

  public setForm(){
    this.form = this.fb.group({
      correo: new FormControl(this.cuenta.correo, [Validators.required, Validators.email]),
      contrasena: new FormControl('', [Validators.required, Validators.maxLength(150)]),
      cuenta: new FormControl(this.cuenta.id_cuenta_pk, [Validators.required]),
      nombre_obligado: new FormControl(this.cuenta.nombre_obligado, [Validators.required]),
    });
  }

  public postModificarCuentaTutor(){
    console.log(this.form.value);
    this.isLoading = true;
    this._ttrSesionService.postModificarCuentaTutor(this.form.value).subscribe(
      (response: any) => {
        console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: response.mensaje, icon:"done", href:null}});
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
        }
      },
      (error: any) => {
        //console.log(<any>error);
        this.isLoading = false;
      });
  }

}
