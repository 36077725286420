//General
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { EvaluacionesService } from 'src/app/services/evaluaciones.service';



@Component({
  selector: 'app-gestor-de-historial-evaluacion-ingreso',
  templateUrl: './gestor-de-historial-evaluacion-ingreso.component.html',
  styleUrls: ['./gestor-de-historial-evaluacion-ingreso.component.scss'],
  providers: [ EvaluacionesService ]
})
export class GestorDeHistorialEvaluacionIngresoComponent implements OnInit {

  //General
  public gdhei_matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public gdhei_form: FormGroup = this.gdhei_fb.group({
    buscador: new FormControl('', []),
    //buscar: new FormControl('', []),
    //ruta: new FormControl('', []),
  });
  /*tabla*/
  public gdhei_length: number = 100;;
  public gdhei_pageSize: number = 25;
  public gdhei_pageSizeOptions: Array<number> = [5, 10, 25, 100];
  public gdhei_page: number = 1;
  //Matricula,	Nombre,	Proceso,	Programa,	Grado	
  public gdhei_displayedColumns: any =[
    'matricula',
    'nombre',
    'eval_academica',
    'eval_psico',
    'sugerencia',
    'decision',
    'cancelar'];
  public dataSource: any;
  /*fin tabla*/
  //All
  public gdhei_accion: string = '';
  public gdhei_isLoading: boolean = false;
  public gdhei_docs: any;
  public gdhei_laravelapi: any;
  public gdhei_laravelapiStorage: any;
  public gdhei_acciones: any;

  constructor(
    private gdhei_fb: FormBuilder,
    public  gdhei_dialog: MatDialog,
    private gdhei_evaluacionesService: EvaluacionesService,
    private gdhei_router: Router,
    private gdhei_route: ActivatedRoute,
    private dialog:MatDialog,
  ){
    this.getExpedienteConsultas(1);

  }

  ngOnInit(): void {
  }

  public getExpedienteConsultas(page: number){
    this.gdhei_isLoading = true;
    this.gdhei_page = page;
    this.gdhei_evaluacionesService.getEvaluaciones(this.gdhei_page, this.gdhei_pageSize, this.gdhei_form.value.buscador).subscribe(
      response => {console.log(response);
        this.gdhei_isLoading = false;
        if(response.estado == 200){
          this.gdhei_length = response.data.total;
          this.dataSource = response.data.data;
        }
      },
      error => {console.log(<any>error);
        this.gdhei_isLoading = false;
      });
  }

  public pageEvent(event: any){
    console.info(event);
    this.gdhei_pageSize = event.pageSize;
    this.getExpedienteConsultas(event.pageIndex + 1);
  }

  public getMatricula(matricula: any){
    var c = '0';
    var cNum = 5;
    var total = cNum - matricula.toString().length;
    for(let i = 0; i < total; i++){
      matricula = c + '' + matricula;
    }
    
    return matricula;
    //return '0000'.substring(0, '0000'.length - matricula.length) + matricula;
    //return ('0000'+matricula).substring(0, matricula.length);
    //{{('0000'+element.id_alumno_fk).substring(''+element.id_alumno_fk.length)}}
  }

  public getEvaluacionesMod(numero_evaluacion_resultado: number, estado: string){
    this.gdhei_isLoading = true;
    this.gdhei_evaluacionesService.getEvaluacionesMod(numero_evaluacion_resultado,estado).subscribe(
      response => {console.log(response);
        this.gdhei_isLoading = false;
        if(response.estado == 200){
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Se ha cancelado con éxito", text: "Se ha cancelado exitosamente este registro", icon:"done"}});
          this.getExpedienteConsultas(this.gdhei_page);
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al cancelar", text: "Problemas con el servicio", icon:"error_outline"}});
        }
      },
      error => {console.log(<any>error);
        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al cancelar", text: "Problemas con el servicio", icon:"error_outline"}});
        this.gdhei_isLoading = false;
      });
  }



}
