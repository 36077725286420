<br><br>
<div class="card-container mt-5">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Historial de tickets</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>

            <div class="d-flex justify-content-start" *ngIf="id_alumno_pro != -1 && id_ciclo_pro != -1 && id_alumno_pro != null && id_ciclo_pro != null">
                <button  type="button" mat-flat-button color="primary">
                    <a class="text-white" href="{{getBaseUrl()}}ObtenerTicketsAlumnoExcel?numero_alumno={{id_alumno_pro}}&numero_ciclo={{id_ciclo_pro}}" target="_blank" rel="noopener">
                        Excel
                    </a>
                </button>
            </div>
            
            

                <!-- tabla -->
                <div class="row mt-3 mat-elevation-z8">
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows>
                        <caption><!--Parrilla--></caption>
                        <!-- contenido -->

                        <ng-container matColumnDef="id">
                            <th id="id" mat-header-cell *matHeaderCellDef> ID </th>
                            <td mat-cell *matCellDef="let element">{{element?.id_ticket_pk}}</td>
                        </ng-container>

                        <ng-container matColumnDef="fecha">
                            <th id="fecha" mat-header-cell *matHeaderCellDef> FECHA DE PAGO </th>
                            <td mat-cell *matCellDef="let element">{{element?.fecha_alta}}</td>
                        </ng-container>

                        <ng-container matColumnDef="forma_de_pago">
                            <th id="forma_de_pago" mat-header-cell *matHeaderCellDef> FORMA DE PAGO </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element?.forma_de_pago == 1">
                                    EFECTIVO
                                </span>
                                <span *ngIf="element?.forma_de_pago == 2">
                                    TRANSFERENCIA
                                </span>
                                <span *ngIf="element?.forma_de_pago == 3">
                                    DEPOSITO
                                </span>

                            </td>
                        </ng-container>

                       

                        <ng-container matColumnDef="ver_ticket">
                            <th id="ver_ticket" mat-header-cell *matHeaderCellDef class="text-center p-1"> VER TICKET </th>
                            <td mat-cell *matCellDef="let element" class="text-center p-1">
                                <button type="button" mat-mini-fab color="primary" (click)="consultandoTicket(element)">
                                    <mat-icon class="material-icons" >
                                        open_in_new
                                    </mat-icon>
                                </button>


                            </td>
                        </ng-container>

                        <ng-container matColumnDef="expand">
                            <th id="espand" mat-header-cell *matHeaderCellDef aria-label="expand row" (click)="expandedElement = element">VER</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                                    <mat-icon *ngIf="expandedElement !== element" >keyboard_arrow_down</mat-icon>
                                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                                    <span>{{element?.tesoreria_movimientosd?.length}}</span>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail" >
                            <td mat-cell *matCellDef="let element; let i = index"  [attr.colspan]="displayedColumns.length">
                                <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden" >
                                   
                                    <table class="table">
                                        <caption><!--Parrilla--></caption>
                                        <thead>
                                          <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Concepto</th>
                                            <th scope="col">Recargos</th>
                                            <th scope="col">Precio unitario</th>
                                          </tr>
                                        </thead>

                                        

                                        <tbody>
                                            <tr *ngFor="let d of element?.tesoreria_movimientos">
                                                <td>{{d?.id_operacion_pk}}</td>
                                                <td>{{d?.conceptos?.nombre}}</td>
                                                <td>{{d?.recargos}}</td>
                                                <td>{{d?.precio_unitario}}</td>
                                              </tr>
                                        </tbody>

                                    </table>


                                    
                                </div>
                            </td>
                        </ng-container>
                    
                        <!-- Symbol Column -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                        class="example-element-row"
                        [class.example-expanded-row]="expandedElement === element"
                        (click)="expandedElement = expandedElement === element ? null : element"
                       
                        >
                         <!--(click)="getObtenerMovimientosAlumno(element?.id_concepto_pk , element?.id_ciclo_pk ,idAlumno)"-->
                        <!-- Extra row to show detail content column -->
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];"  class="example-detail-row" ></tr>
                    </table>
                </div>
                <!-- fin tabla -->

                
            </mat-card-content>
            <mat-card-actions align="start">
                <mat-paginator (page)="pageEvent($event)" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"  aria-label="Selecione una pagina">
                </mat-paginator>
            </mat-card-actions>
        </mat-card>
    </div>

