import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
import { EncuestaService } from 'src/app/services/encuesta.service';

export interface DialogData {
  title: string;
  text: string;
  icon: string;
  href: string;
}

@Component({
  selector: 'app-contestar',
  templateUrl: './contestar.component.html',
  styleUrls: ['./contestar.component.scss']
})
export class ContestarComponent implements OnInit {

  mensajeExito = "Gracias por el tiempo que has tomado para contestar nuestras preguntas, tus datos han sido guardados correctamente, ya pudes cerrar esta ventana.";
  mensajeError = "Lo sentimos, no pudimos localizar la encuesta a la cual estas intentado acceder, por favor revisa tu token e intenta nuevamente.";
  mensajeErrorGuardandoEncuesta = "Lo sentimos, ocurrio un error al guardar tus resultados. Intentalo nuevamente";
  tituloExito = "Encuesta envíada";
  tituloError = "Error";
  erroricon = "error";
  exitoicon = "send";

  response:string;
  nombreEncuesta;
  subtituloEncuesta;
  pregunta:any;
  index = 0;
  respuestas = [];
  resultado:any;
  texto:string;
  userResponse:any = {};
  code = 0;
  respuestaActual:any;
  idAplicacion:any;
  isLoading = false;
  constructor(public dialog: MatDialog,private _encuestaService:EncuestaService,private route:ActivatedRoute) { }

  ngOnInit(): void {
    let token = this.route.snapshot.paramMap.get('id');
    this._encuestaService.getEncuesta(token).subscribe(result=>{
      console.info(result);
      this.code=result.code;
      if(result.code == 200){
        this.resultado = result.datos;
        this.userResponse.respuestas = [];
        this.iniciarEncuesta(result.datos);
      }else if(result.code == 410){
        this.openDialog("Alerta",result.message,this.erroricon);
      }else if(result.code == 411){
          this.openDialog("Alerta",result.message,this.erroricon);
      }else if(result.code == 412){
          this.openDialog("Alerta",result.message,this.erroricon);
      }else if(result.code == 413){
        this.openDialog("Error al cargar preguntas","No se pudieron obtener las preguntas de la encuesta",this.erroricon);
      }else if(result.code == 414){
        this.openDialog("Error al cargar respuestas","No se pudieron obtener las respuestas de las preguntas",this.erroricon);
      }else{
        this.openDialog(this.tituloError,this.mensajeError,this.erroricon);
      }
    },error=>{
      console.info(error);
    })
  }
  

  iniciarEncuesta(datos){
    this.idAplicacion = datos.encuesta.id_aplicacion_pk;
    this.nombreEncuesta = datos.encuesta.nombre;
    this.subtituloEncuesta = datos.encuesta.descripcion;
    this.pregunta = datos.preguntas[this.index];
    this.respuestas = this.getRespuestasByPregunta(this.pregunta.id_pregunta_pk,datos.respuestas);
  }

  getRespuestasByPregunta(id,respuestas:any){
    let preguntas = [];
    for(let i=0; i<respuestas.length; i++){
      if(respuestas[i].id_pregunta_fk == id){
        preguntas.push(respuestas[i]);
      }
    }
    console.log("getRespuestasByPregunta preguntas: ",preguntas);
    return preguntas;
  }
  siguientePregunta(){
    this.guardarRespuesta();
    this.index = this.index + 1;
    this.pregunta = this.resultado.preguntas[this.index];
    this.respuestas = this.getRespuestasByPregunta(this.pregunta.id_pregunta_pk,this.resultado.respuestas);
  }

  terminarEncuesta(){
    this.isLoading=true;
    this.guardarRespuesta();
    this.userResponse.id_aplicacion = this.idAplicacion;
    console.log("A ENVIAR this.userResponse: ", this.userResponse);
    this._encuestaService.saveEncuesta(this.userResponse).subscribe(result=>{
      console.log("result final: ", result);
      if(result.code==200){
        this.isLoading=false;
        this.openDialog(this.tituloExito,this.mensajeExito,this.exitoicon);
      }else{
        this.isLoading=false;
        this.openDialogError(this.tituloError,this.mensajeErrorGuardandoEncuesta,this.erroricon);
      }
    },error=>{
      console.log("No se pudo consultar el servicio");
      this.isLoading=false;
      this.openDialogError(this.tituloError,this.mensajeErrorGuardandoEncuesta,this.erroricon);
    });
    
  }
  openDialog(title,text,icon) {
    this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: title, text: text, icon:icon, href: "/login/encuesta"}});
  }
  openDialogError(title,text,icon) {
    this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: title, text: text, icon:icon}});
  }
  redondear(num:number){
    return Math.round(num);
  }

  changeRespuesta(respuestaRecibida){
    console.log("respuestaRecibida: ",respuestaRecibida);
    this.respuestaActual = respuestaRecibida;
    this.texto = "";
  }

  guardarRespuesta(){
    if(this.texto!=''){
      // this.response = this.respuestas[0].id_respuesta_pk;
      this.response = this.respuestaActual;
    }
    console.info("guardarRespuesta Respuesta Id",this.response);
    let json = {
      "texto":this.texto,
      "id_pregunta_fk" : this.pregunta.id_pregunta_pk, 
      "id_respuesta_fk": this.response, 
      "id_aplicacion_fk":this.resultado.encuesta.id_aplicacion_pk
    };
    console.log("JSON a insertar: ", json);


    // if(this.userResponse.find(x =>{ x.id_pregunta_fk == json.id_pregunta_fk})){
    if(this.userResponse.respuestas.filter(x => x.id_pregunta_fk == json.id_pregunta_fk)[0]){
      // console.log("Ya existe");
      // console.log("al entrar this.userResponse: ", this.userResponse);
      var algo = this.userResponse.respuestas.filter(x => x.id_pregunta_fk == json.id_pregunta_fk)[0];
      // console.log("algo: ", algo);
      algo.id_respuesta_fk = json.id_respuesta_fk;
      // console.log("actualizando this.userResponse: ", this.userResponse);
    }else{
      // console.log("No existe");
      // this.userResponse.push(json);
      this.userResponse.respuestas.push(json);
    }
    // console.log("incrementando this.userResponse: ", this.userResponse);
    this.response = null;
    this.texto = null;
  }
}
