import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, LOCALE_ID} from '@angular/core';
import localeEs from '@angular/common/locales/es-MX'
import { registerLocaleData } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HeaderComponent } from './structure/header/header.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';



import { MatInputModule } from '@angular/material/input';

import { MatFormFieldModule } from '@angular/material/form-field';


import { MatListModule } from '@angular/material/list';
import { LoginComponent } from './pages/login/login.component';
import { PrincipalComponent } from './pages/principal/principal.component';
import { EncuestaComponent } from './pages/encuesta/encuesta.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContestarComponent } from './pages/encuesta/contestar/contestar.component';
import { HttpClientModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';


import { MatDialogModule } from '@angular/material/dialog';



import { MatPaginatorModule } from '@angular/material/paginator';

import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MessageBoxComponent } from './modales/message-box/message-box.component';
import { ConfigurationService } from './services/configuration.service';
import { SessionServiceService } from './services/session-service.service';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { getSpanishPaginatorIntl } from './spanish-paginator-intl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NoGrantComponent } from './pages/errors/no-grant/no-grant.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserMannagerComponent } from './pages/user-mannager/user-mannager.component';
import { NewUserComponent } from './pages/user-mannager/new-user/new-user.component';
import { MatSelectModule } from '@angular/material/select';
import { DesitionBoxComponent } from './modales/desition-box/desition-box.component';
import { EncuestasMannagerComponent } from './pages/encuestas-mannager/encuestas-mannager.component';
import { CiclosMannagerComponent } from './pages/ciclos-mannager/ciclos-mannager.component';
import { NuevoCicloComponent } from './pages/ciclos-mannager/nuevo-ciclo/nuevo-ciclo.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ChartsComponent } from './pages/charts/charts.component';
import { PrintEncuestaComponent } from './modales/print-encuesta/print-encuesta.component';
import { ExpedientesAlumnosComponent } from './pages/expedientes-alumnos/expedientes-alumnos.component';
import { NewExpedienteComponent } from './pages/expedientes-alumnos/new-expediente/new-expediente.component';
import { FichaInformativaComponent } from './pages/expedientes-alumnos/new-expediente/ficha-informativa/ficha-informativa.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import { ViewExpedienteComponent } from './pages/expedientes-alumnos/view-expediente/view-expediente.component';
import { ControlEscolarComponent } from './pages/expedientes-alumnos/control-escolar/control-escolar.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { InscripcionComponent } from './pages/inscripcion/inscripcion.component';
import * as $ from 'jquery';
import { EstudioSocioeconomicoComponent } from './pages/estudio-socioeconomico/estudio-socioeconomico.component';
import { GestorDeEvaluacionesComponent } from './pages/gestor-de-evaluaciones/gestor-de-evaluaciones.component';
import { TesoreriaComponent } from './pages/tesoreria/tesoreria.component';
import { LupaComponent } from './pages/lupa/lupa.component';
import { ReciboComponent } from './modales/recibo/recibo.component';





import { PassBoxComponent } from './modales/pass-box/pass-box.component';
import { RecuperaPassComponent } from './modales/recupera-pass/recupera-pass.component';
// import { MatFileUploadModule } from 'angular-material-fileupload';
import { CicloEscolarComponent } from './pages/ciclo-escolar/ciclo-escolar.component';
import { PlanEstudiosComponent } from './pages/plan-estudios/plan-estudios.component';
import { CreacionGruposComponent } from './pages/creacion-grupos/creacion-grupos.component';
import { GestionAcademicaComponent } from './pages/gestion-academica/gestion-academica.component';
import { AsistenciaIncidenciaComponent } from './pages/asistencia-incidencia/asistencia-incidencia.component';
import { EvaluacionPsicologicaComponent } from './pages/evaluacion-psicologica/evaluacion-psicologica.component';
import { EvaluacionPedagogicaComponent } from './pages/evaluacion-pedagogica/evaluacion-pedagogica.component';
import { AdminCajaComponent } from './pages/admin-caja/admin-caja.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { EvaluacionesComponent } from './pages/evaluaciones/evaluaciones.component';
import { ExpedientesPsicopedagogicosComponent } from './pages/expedientes-psicopedagogicos/expedientes-psicopedagogicos.component';
import { ExpedientesPPAlumnoComponent } from './pages/expedientes-ppalumno/expedientes-ppalumno.component';
import { EvaluacionPsicoPedagogicaComponent } from './pages/evaluacion-psico-pedagogica/evaluacion-psico-pedagogica.component';
import { PlanDeTrabajoComponent } from './pages/plan-de-trabajo/plan-de-trabajo.component';
import { AsignacionDeGruposComponent } from './pages/asignacion-de-grupos/asignacion-de-grupos.component';
import { GestorDeHistorialEvaluacionIngresoComponent } from './pages/gestor-de-evaluaciones/gestor-de-historial-evaluacion-ingreso/gestor-de-historial-evaluacion-ingreso.component';
import { IntranetComponent } from './pages/intranet/intranet.component';
import { BibliotecaGeneralComponent } from './pages/biblioteca-general/biblioteca-general.component';
import { AccionesBibliotecaGeneralComponent } from './pages/biblioteca-general/acciones-biblioteca-general/acciones-biblioteca-general.component';

// Tutores sesion
import { TtrSesionComponent } from 'src/app/pages/tutores/sesion/ttr-sesion/ttr-sesion.component';
import { TutoresModule } from 'src/app/pages/tutores/tutores.module';
import { GtCategoriasComponent } from './pages/biblioteca-general/gt-categorias/gt-categorias.component';
import { AcCategoriasComponent } from './pages/biblioteca-general/ac-categorias/ac-categorias.component';
import { HistorialDePagosComponent } from './pages/tesoreria/historial-de-pagos/historial-de-pagos.component';
import { HistorialTicketsDePagosComponent } from './pages/tesoreria/historial-tickets-de-pagos/historial-tickets-de-pagos.component';
import { ParametrizacionCicloComponent } from './pages/control-escolar/parametrizacion-ciclo/parametrizacion-ciclo.component';
import { GtSolicitudesValidacionPagosComponent } from './pages/tesoreria/gt-solicitudes-validacion-pagos/gt-solicitudes-validacion-pagos.component';
import { TtrGtValidacionPagosComponent } from 'src/app/pages/tutores/ttr-dashboard/ttr-validacion-pagos/ttr-gt-validacion-pagos/ttr-gt-validacion-pagos.component';
// Fin tutores sesion

export function initConfig(config: ConfigurationService, _session: SessionServiceService) {
  return () => config.load().then(
    value => {
      if (config.getConfiguration()) {
        console.info("**This is the configuration");
        console.info(config.getConfiguration());
      }
    }
  );
}
registerLocaleData(localeEs,'es-MX');
@NgModule({
  declarations: [

    ExpedientesAlumnosComponent,
    NewExpedienteComponent,
    FichaInformativaComponent,
    AppComponent,
    HeaderComponent,
    LoginComponent,
    PrincipalComponent,
    EncuestaComponent,
    ContestarComponent,
    MessageBoxComponent,
    NoGrantComponent,
    UserMannagerComponent,
    NewUserComponent,
    DesitionBoxComponent,
    EncuestasMannagerComponent,
    CiclosMannagerComponent,
    NuevoCicloComponent,
    ChartsComponent,
    PrintEncuestaComponent,
    ControlEscolarComponent,
    InscripcionComponent,
    ViewExpedienteComponent,
    EstudioSocioeconomicoComponent,
    GestorDeEvaluacionesComponent,
    TesoreriaComponent,
    LupaComponent,
    ReciboComponent,
    PassBoxComponent,
    RecuperaPassComponent,
    CicloEscolarComponent, 
    PlanEstudiosComponent,
    CreacionGruposComponent,
    GestionAcademicaComponent,
    AsistenciaIncidenciaComponent,
    EvaluacionPsicologicaComponent,
    AdminCajaComponent,
    EvaluacionesComponent,
    ExpedientesPsicopedagogicosComponent,
    ExpedientesPPAlumnoComponent,
    EvaluacionPsicoPedagogicaComponent,
    PlanDeTrabajoComponent,
    EvaluacionPedagogicaComponent,
    AsignacionDeGruposComponent,
    GestorDeHistorialEvaluacionIngresoComponent,
    IntranetComponent,
    BibliotecaGeneralComponent,
    AccionesBibliotecaGeneralComponent,

    //Tutores sesion
    TtrSesionComponent,
     GtCategoriasComponent,
     AcCategoriasComponent,
     HistorialDePagosComponent,
     HistorialTicketsDePagosComponent,
     ParametrizacionCicloComponent,
     GtSolicitudesValidacionPagosComponent,
     //TtrGtValidacionPagosComponent,
    //Fin tutores sesion

  ],
  imports: [
    MatSnackBarModule,
    MatExpansionModule,
    MatStepperModule,
    MatTableExporterModule,
    BrowserModule,
    AppRoutingModule,
    // MatFileUploadModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatListModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatRadioModule,
    MatDividerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTabsModule,

    TutoresModule
  ],
  providers: [
    {provide:LOCALE_ID,useValue:'es-MX'},
    ConfigurationService,
    SessionServiceService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [ConfigurationService, SessionServiceService],
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
