import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';




import { TtrDashboardComponent } from 'src/app/pages/tutores/ttr-dashboard/ttr-dashboard.component';
import { TtrInicioComponent } from 'src/app/pages/tutores/ttr-dashboard/ttr-inicio/ttr-inicio.component';
import { TtrMiCuentaComponent } from 'src/app/pages/tutores/ttr-dashboard/ttr-mi-cuenta/ttr-mi-cuenta.component';
//import { TtrStdSocioeconomicoComponent } from 'src/app/pages/tutores/ttr-dashboard/ttr-std-socioeconomico/ttr-std-socioeconomico.component';
//import { TtrAtenderStdSocioeconomicoComponent } from 'src/app/pages/tutores/ttr-dashboard/ttr-std-socioeconomico/ttr-atender-std-socioeconomico/ttr-atender-std-socioeconomico.component';


//import { IniciarSesionComponent } from 'src/app/Componentes/sesion/iniciar-sesion/iniciar-sesion.component';
//import { RecuperarSesionComponent } from 'src/app/Componentes/sesion/recuperar-sesion/recuperar-sesion.component';
//import { EsteticaAnyComponent } from 'src/app/Componentes/estetica-any/estetica-any.component';
//import { DashboardComponent } from 'src/app/Componentes/dashboard/dashboard.component';


//guards
import { TtrUserGuard } from 'src/app/pages/tutores/ttr-user.guard';
import { TtrUserNotGuard } from 'src/app/pages/tutores/ttr-user-not.guard';
import { TtrBibliotecaComponent } from './ttr-dashboard/ttr-biblioteca/ttr-biblioteca.component';
import { TtrTesoreriaComponent } from './ttr-dashboard/ttr-tesoreria/ttr-tesoreria.component';
import { TtrAcValidacionPagosComponent } from './ttr-dashboard/ttr-validacion-pagos/ttr-ac-validacion-pagos/ttr-ac-validacion-pagos.component';
import { TtrGtValidacionPagosComponent } from './ttr-dashboard/ttr-validacion-pagos/ttr-gt-validacion-pagos/ttr-gt-validacion-pagos.component';

const routes: Routes = [{
  path: 'tutores/dashboard', canActivate: [TtrUserGuard], component: TtrDashboardComponent,
  children:[
  { path: 'inicio', canActivate: [TtrUserGuard], component: TtrInicioComponent  },
  { path: 'mi-cuenta', canActivate: [TtrUserGuard], component: TtrMiCuentaComponent  },
  { path: 'biblioteca', canActivate: [TtrUserGuard], component: TtrBibliotecaComponent  },
  { path: 'administracion', canActivate: [TtrUserGuard], component: TtrTesoreriaComponent  },
  { path: 'gt-validacion-pagos', canActivate: [TtrUserGuard], component: TtrGtValidacionPagosComponent  },
  { path: 'gt-validacion-pagos/registrar', canActivate: [TtrUserGuard], component: TtrAcValidacionPagosComponent  },
  { path: 'gt-validacion-pagos/modificar', canActivate: [TtrUserGuard], component: TtrAcValidacionPagosComponent  },
  { path: 'gt-validacion-pagos/consultar', canActivate: [TtrUserGuard], component: TtrAcValidacionPagosComponent  },
  //{ path: 'std-socioeconomico', canActivate: [TtrUserGuard], component: TtrStdSocioeconomicoComponent  },
  //{ path: 'std-socioeconomico/atender', canActivate: [TtrUserGuard], component: TtrAtenderStdSocioeconomicoComponent }

  ]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class TutoresRoutingModule { 
}
