import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

export class TtrService {
    protected baseUrl: string = environment.apiUrl;
    protected datos: any;
    constructor(){
      // This is intentional
    }

    public configuracion(){
        var datos: any = {
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            'Accept': 'application/json',
        }
        var opciones = {
            headers: new HttpHeaders(datos),
        }
        return opciones;
    }


    
}