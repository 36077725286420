import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
import { Session } from 'src/app/model/session';
import { SessionServiceService } from 'src/app/services/session-service.service';
import { UsersServiceService } from 'src/app/services/users-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username="3demak";
  password="3demak1234";
  isLoading=false;
  title = "Usuario y/o contraseña incorrectos ";
  text = "Por favor, verifique su usuario y contraseña y vuelva a intentar.";
  icon = "error_outline";
  constructor(
    private router:Router, 
    private _users:UsersServiceService,
    private _session:SessionServiceService,
    private dialog:MatDialog
    ) { }

  formulario = new FormGroup({
    usr:new FormControl("",[Validators.required]),
    pass:new FormControl("",[Validators.required])
  });
  ngOnInit(): void {
    // This is intentional
  }

  entrar(){
    this.router.navigate(['/principal']);
  }
  login(){
    if(this.formulario.valid){
      this.isLoading = true;
      this._users.getUsuario(this.formulario.getRawValue()).subscribe(results=>{
        if(results.code==200){
          let session:Session = new Session();
          session.setUsuario(results.datos.usuario);
          session.setAcciones(results.datos.privilegios);
          this._session.sessionCreate(session,true);
          this.isLoading = false;
          this.router.navigate(['/']);
        }else{
          this.isLoading = false;
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,width:"400px",data: {title: this.title, text: this.text, icon:this.icon, href:"/"}});
        }

      },error=>{
        console.info(error);
      });
    }
  }
}
