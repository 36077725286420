import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DesitionBoxComponent } from 'src/app/modales/desition-box/desition-box.component';
import { User } from 'src/app/model/user';
import { UsersServiceService } from 'src/app/services/users-service.service';



const ELEMENT_DATA: any[] = [
];

@Component({
  selector: 'app-user-mannager',
  templateUrl: './user-mannager.component.html',
  styleUrls: ['./user-mannager.component.scss']
})
export class UserMannagerComponent implements OnInit {

  length = 100;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  // MatPaginator Output
  pageEvent: PageEvent;
  
  isLoading = false;
  displayedColumns: string[] = ['position','nombre', 'licencia', 'correo','editar','eliminar'];
  dataSource = ELEMENT_DATA;

  formulario = new FormGroup({
    busqueda : new FormControl("",[])
  });
  constructor(private _user:UsersServiceService,public dialog: MatDialog) { }

  ngOnInit(): void {
    this.buscar();
  }

  buscar(){
    console.info(this.formulario.controls["busqueda"].value);
    this.isLoading = true;
    this._user.findUsers(this.formulario.controls["busqueda"].value).subscribe(result=>{
      this.isLoading = false;
      if(result.code==200){
        console.info(result);
        this.dataSource = result.datos.usuarios;
      }else{
        this.dataSource = [];
      }
    },error=>{
      this.isLoading = true;
      console.info(error);
      this.dataSource = [];
    });
  }

  delete(id){
    this.openDialog(id);
  }

  openDialog(id): void {
    const dialogRef = this.dialog.open(DesitionBoxComponent, {
      //width: '250px',
      data: {title:"Solicitud de confirmación", text:"¿Está seguro que desea eliminar el usuario?",icon:"help_outline"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);
      if(result){
        this.isLoading = true;
        this._user.deleteUserById(id).subscribe(resulta2=>{
          if(resulta2.code==200){
            this.isLoading = false;
            this.buscar();
          }
        },error=>{
          console.info(error);
          this.isLoading = false;
        });
        
      }
    });
  }
}
