import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-caja',
  templateUrl: './admin-caja.component.html',
  styleUrls: ['./admin-caja.component.scss']
})
export class AdminCajaComponent  {

 

}
