<div class="card-container encuesta container-fluid">
    <div class="row">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Asignacion de grupos</mat-card-title>
                <mat-card-subtitle>Asignacion de alumnos a un grupo</mat-card-subtitle>
                <mat-card-subtitle class="fw-bold"><mat-icon>info</mat-icon> Nota: Para asignar un alumno a un grupo es necesario haber concluido el proceso de inscripción.</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form action="">
                    <div class="container-fluid px-0">
                        <div class="row">
                            <div class="col">
                                <mat-form-field appearance="fill" class="w-100">
                                    <mat-label>Ciclo escolar</mat-label>
                                    <mat-select (selectionChange)="obtenerNiveles($event.value); cbonivel.value=null;cbogrado.value=null;cbogrupo.value=null;" #ciclo>
                                      <mat-option *ngFor="let ciclo of ciclos" value="{{ciclo.id_ciclo_pk}}">{{ciclo.activo == "1" ? '⭐ ':''}}{{ciclo.nombre}}</mat-option>                                      
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="fill" class="w-100" >
                                    <mat-icon matPrefix class="spinner-border spinner-border-sm me-2" role="status" *ngIf="isLoadingNiveles"></mat-icon>
                                    <mat-label>Nivel</mat-label>
                                    <mat-select (selectionChange)="obntenerGrados($event.value,ciclo.value);cbogrado.value=null;cbogrupo.value=null;" #cbonivel [disabled]="ciclo.value==null">
                                      <mat-option *ngFor="let nivel of niveles" value="{{nivel.id_nivel_pk}}">{{nivel.descripcion}}</mat-option>
                                      
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="fill" class="w-100" >
                                    <mat-icon matPrefix class="spinner-border spinner-border-sm me-2" role="status" *ngIf="isLoadingGrados"></mat-icon>
                                    <mat-label>Grado</mat-label>
                                    <mat-select #cbogrado [disabled]="cbonivel.value==null" (selectionChange)="getAlumnos(ciclo.value,cbonivel.value,cbogrado.value); cbogrupo.value=null;">
                                      <mat-option *ngFor="let grado of grados" value="{{grado.id_grado_pk}}">{{grado.descripcion}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col">
                                <mat-card class="bg-light">
                                    <mat-card-header>
                                        <mat-icon mat-card-avatar>person</mat-icon>
                                        <mat-card-title>
                                            Alumnos
                                        </mat-card-title>
                                        <mat-card-subtitle>
                                            Sin grupo asignado
                                        </mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <mat-divider></mat-divider>
                                        <br>
                                        <div class="col text-end pb-2">
                                            <button id="actualizarAlumnos" mat-flat-button color="default" [disabled]="cbogrado.value==null" class=" btn-success ps-1 pe-2 d-none" (click)="getAlumnos(ciclo.value,cbonivel.value,cbogrado.value)">
                                                <mat-icon>refresh</mat-icon>
                                                Actualizar
                                            </button>
                                        </div>
                                        <table mat-table [dataSource]="alumnos">
                                            <caption>
                                                <div class="row" *ngIf="isLoading==true">
                                                    <div class="col text-center fs-5 p-5">
                                                        <mat-spinner align="center"></mat-spinner>
                                                    </div>
                                                </div>
                                                <div class="row" *ngIf="alumnos.length<=0 && isLoading==false">
                                                    <div class="col text-center fs-5 p-5 opacity-50">
                                                        No hay alumnos que cumplan sus criterios de búsqueda
                                                    </div>
                                                </div>
                                            </caption>
                                            <!--- Note that these columns can be defined in any order.
                                                  The actual rendered columns are set as a property on the row definition" -->
                                          
                                            <!-- Position Column -->
                                            <ng-container matColumnDef="matricula">
                                              <th th scope="col" mat-header-cell *matHeaderCellDef> Matrícula </th>
                                              <td mat-cell *matCellDef="let element"> {{element.matricula}} </td>
                                            </ng-container>
                                          
                                            <!-- Name Column -->
                                            <ng-container matColumnDef="nombre">
                                              <th th scope="col" mat-header-cell *matHeaderCellDef> Nombre </th>
                                              <td mat-cell *matCellDef="let element" class="text-uppercase"> {{element.apellidop}} {{element.apellidom}} {{element.nombre}} </td>
                                            </ng-container>
                                          
                                            <!-- Weight Column -->
                                            <ng-container matColumnDef="genero">
                                              <th th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Género </th>
                                              <td mat-cell *matCellDef="let element" class="text-center">
                                                  <mat-icon *ngIf="element.id_genero_pk=='1'" color="primary">male</mat-icon>
                                                  <mat-icon *ngIf="element.id_genero_pk=='2'" color="accent">female</mat-icon>
                                              </td>
                                            </ng-container>
                                          
                                            <!-- Symbol Column -->
                                            <ng-container matColumnDef="asignar" >
                                              <th th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Asignar </th>
                                              <td mat-cell *matCellDef="let element" class="text-center">
                                                  <button mat-icon-button class="btn-outline-success" *ngIf="cbogrupo.value!=null" (click)="asignarAlumno(element,cbogrupo.value)">
                                                      <mat-icon>arrow_forward</mat-icon>
                                                  </button>
                                              </td>
                                            </ng-container>
                                          
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                          </table>
                                          
                                          
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div class="col">
                                <div class="col">
                                    <mat-card class="shadow-sm bg-light">
                                        <mat-card-header>
                                            <mat-icon mat-card-avatar>groups</mat-icon>
                                            <mat-card-title>
                                                Alumnos 
                                            </mat-card-title>
                                            <mat-card-subtitle>
                                                Dentro del grupo {{cbogrupo.value}}
                                            </mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-content>
                                            <mat-divider></mat-divider>
                                            <br>
                                            <div class="col">
                                                <mat-form-field appearance="fill" class="w-100">
                                                    <mat-label>Grupo</mat-label>
                                                    <mat-select #cbogrupo [disabled]="cbogrado.value==null" (selectionChange)="getAlumnosGrupo(ciclo.value,cbonivel.value,cbogrado.value,cbogrupo.value)">
                                                      <mat-option *ngFor="let grupo of grupos" value="{{grupo.id_grupo_pk}}">{{grupo.nombre}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="row">
                                                <div class="col text-end">
                                                    <button id="actualizarGrupo" mat-flat-button color="default" [disabled]="cbogrado.value==null" class=" btn-success ps-1 pe-2 d-none" (click)="getAlumnosGrupo(ciclo.value,cbonivel.value,cbogrado.value,cbogrupo.value)">
                                                        <mat-icon>refresh</mat-icon>
                                                        Actualizar
                                                    </button>
                                                </div>
                                            </div>
                                            <table mat-table [dataSource]="alumnosDeGrupo">
                                                <caption>
                                                    <div class="row" *ngIf="isLoadingAlumnosDeGrupo==true">
                                                        <div class="col text-center fs-5 p-5">
                                                            <mat-spinner align="center"></mat-spinner>
                                                        </div>
                                                    </div>
                                                    <div class="row" *ngIf="alumnosDeGrupo?.length<=0 && isLoadingAlumnosDeGrupo==false">
                                                        <div class="col text-center fs-5 p-5 opacity-50">
                                                            No existen alumnos asignados a este grupo
                                                        </div>
                                                    </div>
                                                </caption>
                                                <!--- Note that these columns can be defined in any order.
                                                      The actual rendered columns are set as a property on the row definition" -->
                                              
                                                <!-- Position Column -->
                                                <ng-container matColumnDef="matricula">
                                                  <th th scope="col" mat-header-cell *matHeaderCellDef> Matrícula </th>
                                                  <td mat-cell *matCellDef="let element"> {{element.matricula}} </td>
                                                </ng-container>
                                              
                                                <!-- Name Column -->
                                                <ng-container matColumnDef="nombre">
                                                  <th th scope="col" mat-header-cell *matHeaderCellDef> Nombre </th>
                                                  <td mat-cell *matCellDef="let element" class="text-uppercase"> {{element.apellidop}} {{element.apellidom}} {{element.nombre}} </td>
                                                </ng-container>
                                              
                                                <!-- Weight Column -->
                                                <ng-container matColumnDef="genero">
                                                  <th th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Género </th>
                                                  <td mat-cell *matCellDef="let element" class="text-center">
                                                      <mat-icon *ngIf="element.id_genero_pk=='1'" color="primary">male</mat-icon>
                                                      <mat-icon *ngIf="element.id_genero_pk=='2'" color="accent">female</mat-icon>
                                                  </td>
                                                </ng-container>
                                              
                                                <!-- Symbol Column -->
                                                <ng-container matColumnDef="asignar">
                                                  <th th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Desasignar </th>
                                                  <td mat-cell *matCellDef="let element" class="text-center">
                                                      <button mat-icon-button class="btn-outline-danger" (click)="desasignarAlumno(element)">
                                                          <mat-icon>close</mat-icon>
                                                      </button>
                                                  </td>
                                                </ng-container>
                                              
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                              </table>
                                        </mat-card-content>
                                    </mat-card>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-card-content>
            <mat-card-actions>
                
            </mat-card-actions>
        </mat-card>
    </div>
</div>