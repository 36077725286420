<br><br>
<div class="card-container mt-5">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Historial de pagos</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>

                <!-- tabla -->
                <div class="row mt-3 mat-elevation-z8">
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <caption><!--Parrilla--></caption>
                        <!-- contenido -->

                        <ng-container matColumnDef="id">
                            <th id="id" mat-header-cell *matHeaderCellDef> ID </th>
                            <td mat-cell *matCellDef="let element">{{element?.id_operacion_pk}}</td>
                        </ng-container>

                        <ng-container matColumnDef="concepto">
                            <th id="concepto" mat-header-cell *matHeaderCellDef> CONCEPTO </th>
                            <td mat-cell *matCellDef="let element">{{element?.id_concepto_fk}}</td>
                        </ng-container>

                        <ng-container matColumnDef="monto">
                            <th id="monto" mat-header-cell *matHeaderCellDef> PRECIO UNITARIO </th>
                            <td mat-cell *matCellDef="let element">{{element?.precio_unitario}}</td>
                        </ng-container>
                    
                        <!-- fin contenido -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <!-- fin tabla -->

                
            </mat-card-content>
            <!--<mat-card-actions align="start">
                <mat-paginator (page)="pageEvent($event)" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"  aria-label="Selecione una pagina">
                </mat-paginator>
            </mat-card-actions>-->
        </mat-card>
    </div>

