//General
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { ControlEscolarService } from 'src/app/services/control-escolar.service';
//Tabla
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//Fin Tabla
//Detectar cambios de archivos
import { ChangeDetectorRef } from '@angular/core'; //Detectar cambios
import { ReciboComponent } from 'src/app/modales/recibo/recibo.component';
import {animate, state, style, transition, trigger} from '@angular/animations';


@Component({
  selector: 'app-historial-tickets-de-pagos',
  templateUrl: './historial-tickets-de-pagos.component.html',
  styleUrls: ['./historial-tickets-de-pagos.component.scss'],
  providers: [ ControlEscolarService ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class HistorialTicketsDePagosComponent implements OnInit {

  /*tabla*/
  public length: number = 100;;
  public pageSize: number = 10;
  public pageSizeOptions: Array<number> = [5, 10, 25, 100];
  public page: number = 1;
  public displayedColumns: any =[
    'id', 
    'fecha',
    'forma_de_pago',
    'ver_ticket',
    'expand'
    //'eliminar'
  ];
  public dataSource: any;
  /*fin tabla*/
  public isLoading: boolean = false;
  public laravelapiStorage: any;
  public t: any;


  @Input() id_alumno: number = -1;
  @Input() id_ciclo: number = -1;

  public id_alumno_pro: number = -1;
  public id_ciclo_pro: number = -1;

  public expandedElement: any;
  public isExpansionDetailRow: any;


  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _ControlEscolarService: ControlEscolarService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdRef:ChangeDetectorRef,

    private dialog:MatDialog,
  ){ 
    //this.getObtenerTicketsAlumno(1);
    //this.laravelapiStorage = this._ControlEscolarService.getLaravelApiStorageTutores();
  }

  ngOnInit(): void {
  }

  public getObtenerTicketsAlumno(page: number){
    this.page = page;
    this.isLoading = true;
    this._ControlEscolarService.getObtenerTicketsAlumno(this.page, this.pageSize, this.id_alumno_pro, this.id_ciclo_pro).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.length = response.data.total;
          this.dataSource = response?.data?.data;
        }
      },
      (error: any) => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  /*public postEliminarDocumentosOrg(id: number){
    this.isLoading = true;
    this._ControlEscolarService.postEliminarDocumentosOrg({numero_documento: id}).subscribe(
      response => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Operación Exitosa', mensaje: response.mensaje, icon:'correct'}});
          this.getObtenerTicketsAlumno();
        }else{
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {titulo: 'Error', mensaje: response.mensaje, icon:'error'}});
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {titulo: 'Error', mensaje: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }*/

  ngAfterContentChecked() {
    this.t = new Date().getTime();
    this._cdRef.detectChanges();    

    //console.log(this.id_alumno_pro);

    if(this.id_alumno != this.id_alumno_pro || this.id_ciclo != this.id_ciclo_pro){
      console.log('cambios');
      this.id_alumno_pro = this.id_alumno;
      this.id_ciclo_pro = this.id_ciclo;
      this.getObtenerTicketsAlumno(1);

    }


  }

  public pageEvent(event: any){
    console.info(event);
    this.pageSize = event.pageSize;
    this.getObtenerTicketsAlumno(event.pageIndex + 1);
  }

  public consultandoTicket(data: any){
      
        
          
          const dialogRef = this.dialog.open(ReciboComponent, {
            width: '30em',
            data: data
          });

          dialogRef.afterClosed().subscribe(result => {
            // This is intentional
          });
        
      
    
  }

  public getBaseUrl(){
    return this._ControlEscolarService.getBaseUrl();
  }
}
