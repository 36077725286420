import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ReciboComponent } from 'src/app/modales/recibo/recibo.component';
import { AlumnsService } from 'src/app/services/alumns-service';
import { ControlEscolarService } from 'src/app/services/control-escolar.service';
import { SessionServiceService } from 'src/app/services/session-service.service';
import { LupaComponent } from '../lupa/lupa.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
const ELEMENT_DATA: any[] = [
];
@Component({
  selector: 'app-tesoreria',
  templateUrl: './tesoreria.component.html',
  styleUrls: ['./tesoreria.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TesoreriaComponent  {

  public mesActual: number = new Date().getMonth();
  isLoading = false;
  formulario = new FormGroup({
    alumno:new FormControl("",[Validators.required]),
    formaDePago:new FormControl("",[Validators.required]),
    referencia:new FormControl("",[]),
    documentoSoporte:new FormControl("",[]),
    fechaDePago: new FormControl(null,[]),
    ciclo: new FormControl(null,[Validators.required])
    
  });

  length = 150;
  pageSize = 50;
  pageSizeOptions: number[] = [50, 100, 150];
  idAlumno=null;
  ciclosAlumno = [];
  requiere_estudio_socioeconomico=false;
  // MatPaginator Output
  pageEvent: PageEvent;
  
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto');
  displayedColumns: string[] = ['estatus','concepto','fecha_limite','total','beca','recargo', 'condonacion', 'adeudo','pagado','add', 'expand'];
  dataSource = ELEMENT_DATA;
  conceptosCobro :any=[];
  carrito = [];
  maxDate = new Date();
  public saldo_disponible: number = 0;
  public saldo_disponible_uso: number = 0;
  public aplicar_saldo_disponible: boolean = false;

  public pagando: number = 0;
  public final_saldo_favor: number = 0;
  


  constructor(private dialog:MatDialog,private alumnosService:AlumnsService,private controlEscolarService:ControlEscolarService,private session:SessionServiceService) {

   }



  public expandedElement: any;
  public isExpansionDetailRow: any;

  public parciales: any = [];

  

  send(){
    console.info("");
  }

  getMonNumber(fecha_limite: any){
    //console.log(new Date(fecha_limite).getMonth(), this.mesActual);
    return new Date(fecha_limite).getMonth();
  }

  openLupa(){
    const dialogRef = this.dialog.open(LupaComponent, {
      //width: '250px',
      minWidth: '80vw',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("dialogRef: ", dialogRef);
      console.log('The dialog was closed' + result);
      console.log('Dial' + result?.control_escolar);
      console.log('result.id' + result?.id);
      if(result){
        this.carrito = [];
        this.idAlumno = result.id;
        this.formulario.controls["alumno"].setValue(result.nombre);
        this.getCiclosByAlumno();
        
      }
    });
  }
  getCiclosByAlumno(){
    this.alumnosService.getCiclos(this.idAlumno).subscribe(result=>{
      this.ciclosAlumno=result.datos;
      this.formulario.controls["ciclo"].setValue(result.datos[0].id_ciclo_pk);
      this.getData();
      this.getConsultaPerfilSaldoAlumno(this.idAlumno);
    },error=>{
      this.ciclosAlumno=[];
    })
  }

  public perBeca: any = 0.0000;
  getData(){
    this.dataSource = [];
    this.isLoading = true;
    // this.controlEscolarService.getDeudas(this.idAlumno,2,2,9).subscribe(result=>{
    this.controlEscolarService.getDeudas(this.idAlumno,this.formulario.controls["ciclo"].value,2,9).subscribe(result=>{
      this.isLoading = false;
      console.info(result);
      if(result.code==200){
        //this.dataSource=result.datos.porpagar;
        for(let i = 0; i < result.datos.porpagar.length; i++){
          //result.datos.porpagar[i].porpagar_auxiliar = result.datos.porpagar[i].recargos*(result.datos.porpagar[i].precio_unitario-result.datos.porpagar[i].descuento)+(result.datos.porpagar[i].precio_unitario-result.datos.porpagar[i].descuento) - result.datos.porpagar[i].pagado; //antes
          let recargo = result.datos.porpagar[i].recargos*(result.datos.porpagar[i].precio_unitario-result.datos.porpagar[i].descuento); //antes
          //let recargo = result.datos.porpagar[i].recargos*(result.datos.porpagar[i].pago_pendiente);
          result.datos.porpagar[i].porpagar_auxiliar = recargo+(result.datos.porpagar[i].precio_unitario-result.datos.porpagar[i].descuento) - result.datos.porpagar[i].pagado;
          console.log(result.datos.porpagar[i].precio_unitario, result.datos.porpagar[i].descuento, (recargo), (result.datos.porpagar[i].pagado));
          console.log(result.datos.porpagar[i].suma_recargos);
          console.log(result.datos.porpagar[i].porpagar_auxiliar);


          

          if(result.datos.porpagar[i].id_c_p_c_fk == null){
            result.datos.porpagar[i].condonacion_val = false;
          }else{
            result.datos.porpagar[i].condonacion_val = true;
          }


          if(result.datos.porpagar[i].aplica_beca == '1'){ //sacando beca
            this.perBeca = result.datos.porpagar[i].beca;
            //this.perBeca = Number(this.perBeca.toFixed(2));
            console.info(this.perBeca);
          }

          
          //{{}}%


          result.datos.porpagar[i].condonacion_motivo = '';

          result.datos.porpagar[i].descuento_modificable = 0;
          //if(+result.datos.porpagar[i].suma_recargos <= 0){
            
          //  result.datos.porpagar[i].suma_recargos = recargo;
          //  console.log(result.datos.porpagar[i].suma_recargos);
          //}//else{
          //  result.datos.porpagar[i].suma_recargos = result.datos.porpagar[i].suma_recargos;
          //}



          //if(+result.datos.porpagar[i].suma_recargos <= 0){
          //  result.datos.porpagar[i].porpagar_auxiliar = result.datos.porpagar[i].precio_unitario-result.datos.porpagar[i].descuento + (recargo) - (result.datos.porpagar[i].pagado);
          //}else{
          //  result.datos.porpagar[i].porpagar_auxiliar = result.datos.porpagar[i].precio_unitario-result.datos.porpagar[i].descuento + (+result.datos.porpagar[i].suma_recargos) - (result.datos.porpagar[i].pagado);
          //}
          
          //recargo*(precio unitario - descuento)+(descuento)-pagado
        }
      }else{
        this.dataSource = [];
      }
      

      // this.controlEscolarService.getConceptosCobro(this.idAlumno,2,2,9).subscribe(resultado=>{
      this.controlEscolarService.getConceptosCobro(this.idAlumno,this.formulario.controls["ciclo"].value,2,9).subscribe(resultado=>{
        console.log("getConceptosCobro result: ",resultado);

        this.conceptosCobro = resultado.datos.porpagar;
        console.log("conceptosCobro: ", this.conceptosCobro);

        this.agregarConceptos(result.datos.porpagar,resultado.datos.porpagar);
      })
    });
  }

  agregarConceptos(cuentasPorCobrar,otrosConceptos){
    console.info(cuentasPorCobrar);
    for(let i=0;i<otrosConceptos?.length;i++){
      cuentasPorCobrar.push(otrosConceptos[i]);
    }
    this.dataSource = cuentasPorCobrar;
    console.info(cuentasPorCobrar);
  }
  getTotalCost(){
    let totales = {
      subtotal:0,
      descuentos:0,
      total:0,
      recargos:0,
      pendiente_por_pagar_0: 0,
      precio_unitario_auxiliar_0: 0,
      saldo: 0,
      saldo_carrito: this.saldo_disponible,
      total_saldo: 0,
    }
    let sumatoria:number = 0;
    //console.log(this.carrito)
    for(let i=0; i<this.carrito.length;i++){
      //this.carrito[i].recargos = 0; //borrar esta linea despues esto es sin recargo
      //antes totales.total= totales.total+ Number(this.carrito[i].precio_unitario_auxiliar - this.carrito[i].descuento)+((Number(this.carrito[i].precio_unitario_auxiliar)-Number(this.carrito[i].descuento))*Number(this.carrito[i].recargos));
      //ahora
      //totales.total= totales.total+ Number(this.carrito[i].pendiente_por_pagar_0 - this.carrito[i].descuento)+Number(this.carrito[i].recargos_axuliar); antes
      totales.total= totales.total+ Number(this.carrito[i].precio_unitario_auxiliar);
      //console.log(Number(this.carrito[i].precio_unitario_auxiliar), Number(this.carrito[i].descuento),Number(this.carrito[i].recargos_axuliar));
      
      totales.descuentos= totales.descuentos+ Number(this.carrito[i].descuento);
      //totales.subtotal= totales.subtotal+ Number(this.carrito[i].precio_unitario_auxiliar); //antes
      totales.subtotal= totales.subtotal+ Number(this.carrito[i].precio_unitario);
      
      //totales.recargos = totales.recargos+(Number(this.carrito[i].precio_unitario_auxiliar)-Number(this.carrito[i].descuento))*Number(this.carrito[i].recargos); //con recargo antes
      totales.recargos = totales.recargos+(Number(this.carrito[i].recargos_axuliar_0)); //despues
      //totales.recargos = 0; //sin recargo

      //totales.pendiente_por_pagar_0= totales.pendiente_por_pagar_0+ Number(this.carrito[i].precio_unitario_auxiliar) +this.carrito[i].recargos_axuliar; //antes
      totales.pendiente_por_pagar_0= totales.pendiente_por_pagar_0+ Number(this.carrito[i].precio_unitario_auxiliar); //+ this.carrito[i].pago_pendiente*(this.carrito[i].recargos);


      totales.precio_unitario_auxiliar_0= totales.precio_unitario_auxiliar_0+ Number(this.carrito[i].precio_unitario_auxiliar_0);


      totales.saldo = totales.saldo + (this.carrito[i].precio_unitario_auxiliar_0 - +this.carrito[i].precio_unitario_auxiliar);
      
      totales.saldo_carrito = totales.saldo_carrito - this.carrito[i].saldo_carrito;

      totales.total_saldo = totales.total_saldo + this.carrito[i].saldo_carrito;

    }
    if(this.saldo_disponible_uso == null){
      this.saldo_disponible_uso = 0;
    }
    //if(this.aplicar_saldo_disponible){ //saldo aplicado
      //totales.total =  totales.total - +this.saldo_disponible_uso; //antes
     // totales.total =  totales.total;
    //}
    //console.log(this.pagando );

    //console.log(this.saldo_disponible_uso );
    if(this.aplicar_saldo_disponible){
      this.final_saldo_favor = totales.total - +this.pagando - +this.saldo_disponible_uso;
    }else{
      this.final_saldo_favor = totales.total - +this.pagando - 0;
    }

    this.pagando = totales.pendiente_por_pagar_0;

    //console.log(totales);
    return totales;
  }
  registraPago(){
    if(this.carrito.length>0 && this.formulario.valid){
      let json:any = this.formulario.getRawValue();
      json.idAlumno = this.idAlumno;
      json.movimientos = this.carrito;
      json.movimientos.forEach(element => {
        //element.pago_pendiente=(element.precio_unitario_auxiliar-element.descuento)+(element.recargos_axuliar); //antes
        //element.pago_pendiente=(element.precio_unitario_auxiliar)+(element.recargos_axuliar); //antes
        element.pago_pendiente=(element.precio_unitario_auxiliar) + +element.saldo_carrito;
        
        
       
        if(element.recargos_axuliar == null){
          element.recargos_axuliar=0;
        }
      });
      console.log(json.movimientos);

      /*if(this.saldo_disponible_uso == null){
        this.saldo_disponible_uso = 0;
        json.movimientos[0].saldo_favor_aplicados = 0;
      }*/
      /*if(this.aplicar_saldo_disponible){ //saldo aplicado
       
        if(this.saldo_disponible_uso <= -this.getTotalCost().saldo){
          console.log('NO ENTRA', this.saldo_disponible_uso);
          json.saldo_favor_aplicados = this.saldo_disponible_uso;*/

          
         
         /* this.controlEscolarService.registrarPago(json).subscribe(result=>{
            if(result.code ==200){
              this.carrito = [];
              this.conceptosCobro = [];
              this.formulario.controls.formaDePago.setValue("");
              this.formulario.controls.referencia.setValue("");
              this.getData();
              this.getConsultaPerfilSaldoAlumno(this.idAlumno);
              console.log(result);
              const dialogRef = this.dialog.open(ReciboComponent, {
                width: '30em',
                data: result.datos.ticket
              });
    
              dialogRef.afterClosed().subscribe(result => {
                // This is intentional
              });
            }
          });*/


     /*   }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Asegurese que el saldo introducido es menor o igual al que tiene el alumno, o haya modificado su saldo', icon:"error_outline", href:null}});
          return false;
        }
      }else{
        json.saldo_favor_aplicados = 0;
      }*/

      json.saldo_favor_aplicados= this.getTotalCost().total_saldo;
      //console.log(this.getTotalCost().pendiente_por_pagar_0);
      //var saldo_restante_favor = -(this.getTotalCost().pendiente_por_pagar_0 - +this.pagando);
      //if( this.final_saldo_favor <= saldo_restante_favor){ //saldo a conservar, validando
        //todo bien por el momnento
      //}else{
      //  this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Asegurese que el saldo a conservar es menor a su saldo restante', icon:"error_outline", href:null}});
      //  return false;
      //}
     
      json.idUsuario = this.session.getSession().getUsuario().id_usuario_pk; //Aqui todo sigue normal
      console.info(json);
      this.controlEscolarService.registrarPago(json).subscribe(result=>{

        console.log(result);
        if(result.code ==200){
          

          


          let idAlumno = this.idAlumno;
          this.idAlumno = 0;

          
            setTimeout(() => {
              this.idAlumno = idAlumno;
              console.log(this.final_saldo_favor);
              /*if(this.final_saldo_favor <= 0){
                this.final_saldo_favor = -this.final_saldo_favor;
              }

              console.log(this.final_saldo_favor);*/
              console.log(this.getTotalCost().saldo_carrito, this.getTotalCost().pendiente_por_pagar_0, this.getTotalCost().precio_unitario_auxiliar_0);

              if(this.getTotalCost().pendiente_por_pagar_0 - this.getTotalCost().precio_unitario_auxiliar_0 > 0){
                let saldo = this.getTotalCost().saldo_carrito + (this.getTotalCost().pendiente_por_pagar_0 - this.getTotalCost().precio_unitario_auxiliar_0);
                this.cambiaSaldo(  saldo  );
              }else{
                this.cambiaSaldo( this.getTotalCost().saldo_carrito +   0    );
              }

              this.carrito = [];
              this.conceptosCobro = [];
              this.formulario.controls.formaDePago.setValue("");
              this.formulario.controls.referencia.setValue("");



              this.getData();
            }, 2000);
          


          /*if(this.aplicar_saldo_disponible){
            this.postModificarSaldoAlumno(1);
          }else{
            this.incrementarSaldo(this.saldo_disponible);
          }*/

          result.datos.ticket[0].saldo_favor_aplicados = this.saldo_disponible_uso;
          const dialogRef = this.dialog.open(ReciboComponent, {
            width: '30em',
            data: result.datos.ticket
          });

          dialogRef.afterClosed().subscribe(result => {
            // This is intentional
          });
        }
      });
    }
  }


  public id_cuentas_cobrar: number = 0;
  public setIdCuentasCobrar(id: number){
    this.id_cuentas_cobrar = id;
    console.log(this.id_cuentas_cobrar);
  }
  
  public getObtenerMovimientosAlumno(a: number, b: number, c: number, d: number){
    this.setIdCuentasCobrar(d);
    console.log(a,b,c);
    this.isLoading = true;
    this.controlEscolarService.getObtenerMovimientosAlumno(a, b, c).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.parciales = response?.data;
        }
      },
      (error: any) => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public getSumaParciales(){
    let totales = {
      recargos:0,
    }

    for(let i = 0; i < this.parciales?.length; i++){
      totales.recargos = this.parciales[i].recargos + totales.recargos;
    }

    return totales;


  }


  public agregarCarrito(element: any){
    console.log(element);
      if(!element.descuento || element.descuento == null){
        element.descuento = 0;
      }
      console.log(element.recargos, element.descuento, element.precio_unitario_auxiliar);

      if(element.condonacion_val == false){
        element.recargos_axuliar = element.recargos*(element.precio_unitario-element.descuento); //saul
        element.recargos_axuliar_0 = element.recargos*(element.precio_unitario-element.descuento); //saul
        console.log(element.recargos_axuliar, element.recargos_axuliar_0);
        console.log('AQUI',element.pago_pendiente,element.recargos_axuliar);
        element.precio_unitario_auxiliar_0 = +element.pago_pendiente + element.recargos*(element.precio_unitario-element.descuento);; //antes
        element.precio_unitario_auxiliar = +element.pago_pendiente + element.recargos*(element.precio_unitario-element.descuento); //+ +element.suma_recargos; //antes
      }else{
        element.recargos_axuliar = 0; //saul
        element.recargos_axuliar_0 = 0; //saul
        console.log(element.recargos_axuliar, element.recargos_axuliar_0);
        console.log('AQUI',element.pago_pendiente,element.recargos_axuliar);
        element.precio_unitario_auxiliar_0 = +element.pago_pendiente + 0;; //antes
        element.precio_unitario_auxiliar = +element.pago_pendiente + 0; //+ +element.suma_recargos; //antes
      }

      let suma = 0;
      for(let i = 0; i < this.parciales.length; i++){
        suma = suma + this.parciales[i].recargos;
      }
      if(suma > 0){
        element.recargos_axuliar = 0;
        element.recargos_axuliar_0 = 0;
      }

     
    element.saldo_carrito = 0;
    
    console.log(element.precio_unitario_auxiliar);
    this.carrito.push(element);
  }


  postModificarMovimientoTesoreria(id: number, ciclo: number, precio_unitario: number, rec: number, motivo: string, op: number){
    this.isLoading = true;
    this.controlEscolarService.postModificarMovimientoTesoreria({
      concepto: id,
      numero_ciclo: ciclo, 
      precio_unitario: precio_unitario,
      recargos: rec,
      motivo: motivo,
      numero_operacion: op
    }).subscribe(
      (response: any) => {
        console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.getData();
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: response.mensaje, icon:"done", href:null}});
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: response.mensaje, icon:"error_outline", href:null}});
        }
      },
      error => {
        //console.log(<any>error);
        this.isLoading = false;
      });
  }

  public postEliminarMovimientosAlumno(id: number){
    
    this.isLoading = true;
    this.controlEscolarService.postEliminarMovimientosAlumno({numero_mov: id}).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.getData();
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: 'Se ha eliminado correctamente', icon:"done", href:null}});
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Error, vuelva a intentarlo', icon:"error_outline", href:null}});
        }
      },
      (error: any) => {console.log(<any>error);
        this.isLoading = false;
        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Error en el servidor (saldo), intentelo más tarde', icon:"error_outline", href:null}});
      });
  }














  public getConsultaPerfilSaldoAlumno(id: number){
    this.isLoading = true;
    this.controlEscolarService.getConsultaPerfilSaldoAlumno(id).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.saldo_disponible = response?.data?.saldo_disponible;
          this.saldo_disponible_uso = response?.data?.saldo_disponible;
        }
      },
      (error: any) => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  public postModificarSaldoAlumno(caso: number = 0){
    var saldo = 0;
    if(caso == 0){
      saldo =  this.saldo_disponible;
      console.log(this.saldo_disponible);
    }else{
      saldo =  this.saldo_disponible - this.saldo_disponible_uso;
    }
    console.log(saldo);
    this.isLoading = true;
    this.controlEscolarService.postModificarSaldoAlumno({numero_alumno:this.idAlumno, saldo: saldo}).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.getConsultaPerfilSaldoAlumno(this.idAlumno);
          if(caso == 0){
            this.reiniciarDatosCaja();
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: 'Saldo modificado', icon:"done", href:null}});
          }else{
            this.incrementarSaldo(saldo);
          }
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Posiblemente no haya seleccionado un alumno o dato inválido', icon:"error_outline", href:null}});
        }
      },
      (error: any) => {console.log(<any>error);
        this.isLoading = false;
        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Error en el servidor, intentelo más tarde', icon:"error_outline", href:null}});
      });
  }

  public incrementarSaldo(saldo: number){
    var s = 0;
    s =  this.final_saldo_favor + saldo;
    
    console.log(s);
    this.isLoading = true;
    this.controlEscolarService.postModificarSaldoAlumno({numero_alumno:this.idAlumno, saldo: s}).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.getConsultaPerfilSaldoAlumno(this.idAlumno);
        }else{
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Problemas al ingresar el nuevo saldo', icon:"error_outline", href:null}});
        }
        this.reiniciarDatosCaja();
      },
      (error: any) => {console.log(<any>error);
        this.isLoading = false;
        this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Error en el servidor (saldo), intentelo más tarde', icon:"error_outline", href:null}});
      });
  }

  public cambiaSaldo(saldo: number){
    this.isLoading = true;
    this.controlEscolarService.postModificarSaldoAlumno({numero_alumno:this.idAlumno, saldo: saldo}).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        this.reiniciarDatosCaja();
        this.getConsultaPerfilSaldoAlumno(this.idAlumno);
      },
      (error: any) => {console.log(<any>error);
        this.isLoading = false;
      });
  }

  

  public viewAplicarSaldoDisponible(){
    console.log(this.aplicar_saldo_disponible);
    this.getTotalCost();
  }

  public reiniciarDatosCaja(){
    this.pagando = 0;
    //this.saldo_disponible_uso = 0;
    //this.final_saldo_favor = 0;
  }

  public postEliminaCon(id: number, ciclo: number, precio_unitario: number, rec: number, motivo: string, op: number){
    this.postCondonacion(2, this.id_cuentas_cobrar);
    this.postModificarMovimientoTesoreria(id, ciclo, precio_unitario, rec, motivo, op);
  }

  public postEliminaCon2(val: any){
    console.log(val);
    if(val){
      this.postCondonacion(1, this.id_cuentas_cobrar);
    }else{
      this.postCondonacion(2, this.id_cuentas_cobrar);
    }
  }

  public postCondonacion(caso: number, cp: number, motivo: string = 'Pruea'){
    var datos;
    if(caso == 2){
      datos = {
        opcion: 2,
        alumno: this.idAlumno,
        cuenta_por_cobrar: cp,
      }
    }else if(caso == 1){
      datos = {
        opcion: 1,
        alumno: this.idAlumno,
        cuenta_por_cobrar: cp,
        motivo: motivo, 
        usuario_alta: this.session.getSession().getUsuario().id_usuario_pk
      }
    }


    this.isLoading = true;
    this.controlEscolarService.postCondonacion(datos).subscribe(
      (response: any) => {console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          //this.reiniciarDatosCaja();
           this.getData();
        }else{
          //this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Problemas al ingresar el nuevo saldo', icon:"error_outline", href:null}});
        }
      },
      (error: any) => {console.log(<any>error);
        this.isLoading = false;
    });
  }





  public con_motivo: string = '';
  public con_box: boolean = false;
  public con_val: boolean = false;
  public setModalCondonacion(a: string, b: boolean){
    if(a == null){
      this.con_val = false;
    }else{
      this.con_val = true;
    }
    if(a == null){
      a = '...';
    }
    if(b == null){
      b = false;
    }
    this.con_motivo = a;
    this.con_box = b;
    console.log(this.con_box, this.con_motivo);
  }









  //roundToTwoDecimals(g: number) {
    //value = Math.round(value * 100) / 100;
    //console.log(value);
    //this.carrito[g].recargos_axuliar.toFixed(2)
  //}


}
