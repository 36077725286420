import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ValidacionPagosService {
  private ruta = "/php/evaluaciones-service.php";
  private endpoint = "";
  private laravelapi = "";
  public endpoint_2 = '';
  public laravelapiStorageTutores = '';

  constructor(private _http:HttpClient,private _configService:ConfigurationService) {
    this.endpoint = this._configService.getConfiguration().hostapi;
    this.laravelapi = this._configService.getConfiguration().laravelapi;
    this.endpoint_2 = _configService.getConfiguration().laravelapi;
    this.laravelapiStorageTutores = this._configService.getConfiguration().laravelapiStorageTutores;
  }

  public getLaravelApiStorageTutores(){
    return this.laravelapiStorageTutores;
  }
  

  /*postConsultaTicketSolicitudesPapaAlumnos():Observable<any>{
    return this._http.get(this.endpoint+this.ruta+"?opc=find&nombre="+nombre+"&nivel="+nivel+"&grado="+grado+"&i="+indice+"&r="+registros);
  }*/

  public getEndPoint2(){
    return this.endpoint_2;
  }

  public postConsultaTicketSolicitudesPapaAlumnos(page: number, filas: number, alumno: number, ciclo_escolar: number){    
    //console.log(this.configuracion());
    var datos = {
        ciclo_escolar: ciclo_escolar,
        filas: filas,
        alumno: alumno,
        page: page
    }
    return this._http.post(this.endpoint_2+'ConsultaTicketSolicitudesPapaAlumnos', datos);
  }


  public postCrearTicketPapa(data: any){    
    return this._http.post(this.endpoint_2+'CrearTicketPapa', data);
  }


  public postConsultaTicketSolicitudPapa(data: any){    
    return this._http.post(this.endpoint_2+'ConsultaTicketSolicitudPapa', data);
  }

  public postRegistrarConceptoTicketPapa(data: any){    
    return this._http.post(this.endpoint_2+'RegistrarConceptoTicketPapa', data);
  }

  public postModificarConceptoTicketPapa(data: any){    
    return this._http.post(this.endpoint_2+'ModificarConceptoTicketPapa', data);
  }

  public postEliminarConceptoTicketPapa(data: any){    
    return this._http.post(this.endpoint_2+'EliminarConceptoTicketPapa', data);
  }

  /*documentos*/
  public postRegistrarModificarFileTicket(data: any){    
    return this._http.post(this.endpoint_2+'RegistrarModificarFileTicket', data);
  }

  public postEliminarArchivoComprobante(data: any){    
    return this._http.post(this.endpoint_2+'EliminarArchivoComprobante', data);
  }

  public postModificarrFileTicket(data: any){    
    return this._http.post(this.endpoint_2+'ModificarrFileTicket', data);
  }

  /*fin documento*/

  public postCambiarEstado(data: any){    
    return this._http.post(this.endpoint_2+'CambiarEstado', data);
  }

  public postCambiarEstadoAdmin(data: any){    
    return this._http.post(this.endpoint_2+'CambiarEstadoAdmin', data);
  }



  public postAgregarComentario(data: any){    
    return this._http.post(this.endpoint_2+'AgregarComentario', data);
  }

  public postAgregarComentarioMalo(data: any){    
    return this._http.post(this.endpoint_2+'AgregarComentarioMalo', data);
  }

  public postConsultaInformacionAlumno(data: any){    
    return this._http.post(this.endpoint_2+'ConsultaInformacionAlumno', data);
  }


  public postConsultaTicketSolicitudesPapa(page: number, filas: number, alumno: number = 0, ciclo_escolar: number){    
    var datos = {
      ciclo_escolar: ciclo_escolar,
      filas: filas,
      alumno: alumno,
      page: page
  }
    return this._http.post(this.endpoint_2+'ConsultaTicketSolicitudesPapa', datos);
  }






}
