
<div class="card-container ciclo-escolar">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Gestor de ciclos asitencias</mat-card-title>
            <mat-card-subtitle>Gestión y control de asistencias e incidencias</mat-card-subtitle>
        </mat-card-header>
    </mat-card>
</div>

