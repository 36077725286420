import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService {

  endpoint = "";
  constructor(private _http:HttpClient, private _config:ConfigurationService) { 
    this.endpoint = _config.getConfiguration().hostapi;
  }
  findAll(tipoParametro:string):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  let options = { headers: headers };
  console.log('ekekek'+this.endpoint+"/php/parametros-service.php?opc="+tipoParametro,options);
    return this._http.get(this.endpoint+"/php/parametros-service.php?opc="+tipoParametro,options);
  }

  findAllDinamic(tipoParametro:string,id:number):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  let options = { headers: headers };
    return this._http.get(this.endpoint+"/php/parametros-service.php?opc="+tipoParametro+"&id="+id,options);
  }
  findAllDinamic2(tipoParametro:string,id1:number,id2:number):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  let options = { headers: headers };
    return this._http.get(this.endpoint+"/php/parametros-service.php?opc="+tipoParametro+"&id1="+id1+"&id2="+id2,options);
  }
}
