import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
import { EncuestaService } from 'src/app/services/encuesta.service';
import { EncuestasServiceService } from 'src/app/services/encuestas-service.service';

@Component({
  selector: 'app-nuevo-ciclo',
  templateUrl: './nuevo-ciclo.component.html',
  styleUrls: ['./nuevo-ciclo.component.scss']
})
export class NuevoCicloComponent implements OnInit {
  aplicacion:any;
  encuestas = [];
  isLoading = false;
  id;
  grados = ["1ro","2do","3ro","4to","5to","6to"];
  grupos = ["A","B","C","D"];
  publicos = ["Alumnos","Papás","Docentes"];
  constructor(private _encuestaService:EncuestasServiceService,
    private dialog:MatDialog,
    private activatedRoute:ActivatedRoute) { 

  this.activatedRoute.queryParams.subscribe(params => {
      this.id = params['id']; 
    });
  }

  formulario = new FormGroup({
    ciclo: new FormControl("",[Validators.required]),
    publico: new FormControl("",[Validators.required]),
    grado: new FormControl("",[Validators.required]),
    grupo: new FormControl("",[Validators.required]),
    encuesta: new FormControl("",[Validators.required]),
    apertura: new FormControl("",[Validators.required]),
    cierre: new FormControl("",[Validators.required])
  });

  get form(){
    return this.formulario.controls;
  }
  ngOnInit(): void {
    this._encuestaService.getEncuestas().subscribe(result=>{
      if(result.code==200){
        this.encuestas  = result.datos;
      }else{
        this.encuestas = [];
      }
    },error=>{
      this.encuestas = [];
    });


    if(this.id>0){
      this.isLoading=true;
      this._encuestaService.getEncuestaById(this.id).subscribe(result=>{
        if(result.code==200){
          this.aplicacion = result.datos;
          this.form.ciclo.setValue(this.aplicacion.ciclo);
          this.form.publico.setValue(this.aplicacion.publico);
          this.form.grado.setValue(this.aplicacion.grado);
          this.form.grupo.setValue(this.aplicacion.grupo);
          this.form.encuesta.setValue(this.aplicacion.id_encuesta_fk);
          this.form.apertura.setValue(this.aplicacion.fecha_inicio);
          this.form.cierre.setValue(this.aplicacion.fecha_fin);
          this.isLoading=false;

        }else{
          this.isLoading=false;
        }
      },error=>{
        console.log(error);
        this.isLoading=false;
      });
    }
  }

  sendForm(){
    if(this.formulario.valid){
      this.isLoading=true;
      if(this.id>0){
        this._encuestaService.updateAplicacion(this.id,this.formulario.getRawValue()).subscribe(result=>{
          this.isLoading=false;
          if(result.code==200){
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Encuesta actualizada", text: "La encuesta ha sido actualizada con éxito.", icon:"done", href:"/medicion-resultados/ciclos-mannager"}});
          }else{
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al modificar la encuesta", text: "No fué posible modificar la encuesta, si piensa que ésto es un error, contacte al administrador del sistema", icon:"error_outline", href:null}});
          }
        },error=>{
          this.isLoading=false;
          console.info(error);
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al modificar la encuesta", text: "No fué posible modificar la encuesta, si piensa que ésto es un error, contacte al administrador del sistema", icon:"error_outline", href:null}});
        });
      }else{
        this._encuestaService.saveAplicacion(this.formulario.getRawValue()).subscribe(result=>{
          this.isLoading=false;
          if(result.code==200){
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Encuesta programada", text: "La encuesta ha sido programada con éxito.", icon:"done", href:"/medicion-resultados/ciclos-mannager"}});
          }else{
            this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al realizar el alta", text: "No fué posible crear la encuesta, si piensa que ésto es un error, contacte al administrador del sistema", icon:"error_outline", href:null}});
          }
        },error=>{
          this.isLoading=false;
          console.info(error);
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al realizar el alta", text: "No fué posible crear la encuesta, si piensa que ésto es un error, contacte al administrador del sistema", icon:"error_outline", href:null}});
        });
      }
    }
  }
}
