import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../model/configuration';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ControlEscolarService {
  ruta = "/php/control-escolar-service.php";
  endpoint = ""
  endpoint_2 = '';
  constructor(private _http:HttpClient,private _configService:ConfigurationService) {
    this.endpoint = _configService.getConfiguration().hostapi;
    this.endpoint_2 = _configService.getConfiguration().laravelapi;

   /* this.endpoint="http://localhost/Proyectos/ng-san-juan-pablo";
    alert(this.endpoint);*/
  }

  getEndPoint2(){
    return this.endpoint_2;
  }



  getCiclosByAlumno(id:number):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // alert(this.endpoint+this.ruta+"?opc=findByAlumno&id="+id);
    let options = { headers: headers };
    return this._http.get(this.endpoint+this.ruta+"?opc=findByAlumno&id="+id);
  }
  // iniciarEvaluaciónAcademica(json:any):Observable<any>{
  //   // return this._http.post(this.endpoint+this.ruta+"?opc=iniciaEvaluacionAcademica",json);
  //   return this._http.post("http://localhost/ng-san-juan-pablo/php/control-escolar-service.php?opc=iniciaEvaluacionAcademica",json);
  // }
  iniciarInscripcion(json:any):Observable<any>{
    return this._http.post(this.endpoint+this.ruta+"?opc=iniciaInscripcion",json);
    // return this._http.post("http://localhost/ng-san-juan-pablo/php/control-escolar-service.php?opc=iniciaInscripcion",json);
  }
  concluirIncripcion(json:any):Observable<any>{
    return this._http.post(this.endpoint+this.ruta+"?opc=concluirInscripcion",json);
  }
  modificarInscripcion(json:any):Observable<any>{
    console.log("modificarInscripcion json: ",json);
    return this._http.post(this.endpoint+this.ruta+"?opc=modificaInscripcion",json);
    // return this._http.post("http://localhost/ng-san-juan-pablo/php/control-escolar-service.php?opc=modificaInscripcion",json);
       
  }
  getDatosInscripcion(id:number){
    return this._http.get(this.endpoint+this.ruta+"?opc=getInscripcion");
  }
  getDeudas(idAlumno:number, idCiclo:number, idNivel:number, idGrado:number):Observable<any>{
    console.log("getDeudas set: ");
    console.log("getDeudas idAlumno set: ", idAlumno);
    console.log("getDeudas idCiclo set: ",idCiclo);
    console.log("getDeudas idNivel set: ", idNivel);
    console.log("getDeudas idGrado set: ", idGrado);
    return this._http.get(this.endpoint+this.ruta+"?opc=findDeudas&idAlumno="+idAlumno+"&ciclo="+idCiclo+"&nivel="+idNivel+"&grado="+idGrado)
    // return this._http.get("http://localhost/ng-san-juan-pablo/php/control-escolar-service.php?opc=findDeudas&idAlumno="+idAlumno+"&ciclo="+idCiclo+"&nivel="+idNivel+"&grado="+idGrado)
  }
  getConceptosCobro(idAlumno:number, idCiclo:number, idNivel:number, idGrado:number):Observable<any>{
    return this._http.get(this.endpoint+this.ruta+"?opc=findConceptosCobro&idAlumno="+idAlumno+"&ciclo="+idCiclo+"&nivel="+idNivel+"&grado="+idGrado)
    // return this._http.get("http://localhost/ng-san-juan-pablo/php/control-escolar-service.php?opc=findConceptosCobro&idAlumno="+idAlumno+"&ciclo="+idCiclo+"&nivel="+idNivel+"&grado="+idGrado)
  }
  findByKey(idAlumno:number, idCiclo:number, idNivel:number, idGrado:number):Observable<any>{
    return this._http.get(this.endpoint+this.ruta+"?opc=findByKey&idAlumno="+idAlumno+"&ciclo="+idCiclo+"&nivel="+idNivel+"&grado="+idGrado)
  }
  registrarPago(json:any):Observable<any>{
    return this._http.post(this.endpoint+this.ruta+"?opc=registrarPago",json);
    // return this._http.post("http://localhost/ng-san-juan-pablo/php/control-escolar-service.php?opc=registrarPago",json);
  }
  
  getAlumnosByCicloNivelGradoGrupo(ciclo:number,nivel:number,grado:number,grupo:number):Observable<any>{
    return this._http.get(this.endpoint+this.ruta+"?opc=alumnosByCicloNivelGradoGrupo&ciclo="+ciclo+"&nivel="+nivel+"&grado="+grado+"&grupo="+grupo);
  }
  getAlumnosByCicloNivelGrado(ciclo:number,nivel:number,grado:number):Observable<any>{
    return this._http.get(this.endpoint+this.ruta+"?opc=alumnosByCicloNivelGrado&ciclo="+ciclo+"&nivel="+nivel+"&grado="+grado);
  }
  asignarAlumno(json:any):Observable<any>{
    return this._http.post(this.endpoint+this.ruta+"?opc=asignarGrupo",json);
    // return this._http.post("http://localhost/ng-san-juan-pablo/php/control-escolar-service.php?opc=registrarPago",json);
  }
  bajaAlumno(json:any){
    return this._http.put(this.endpoint+this.ruta+"?opc=bajaAlumno",json);
  }


  getConsultaPagosAlumno(p: number, r: number, numero_alumno: number, numero_ciclo: number){    
    //console.log(this.configuracion());
    return this._http.get(this.endpoint_2+'ConsultaPagosAlumno?page='+p+'&filas='+r+'&numero_alumno='+numero_alumno+'&numero_ciclo='+numero_ciclo);
  }

  getObtenerTicketsAlumno(p: number, r: number, numero_alumno: number, numero_ciclo: number){    
    //console.log(this.configuracion());
    return this._http.get(this.endpoint_2+'ObtenerTicketsAlumno?page='+p+'&filas='+r+'&numero_alumno='+numero_alumno+'&numero_ciclo='+numero_ciclo);
  }


  getObtenerMovimientosAlumno(num_op: number, num_ciclo: number, num_alu: number){    
    //console.log(this.configuracion());
    var json = {
      numero_alumno: num_alu,
      numero_operacion: num_op,
      numero_ciclo: num_ciclo
    }
    return this._http.post(this.endpoint_2+'ObtenerMovimientosAlumno', json);
  }



  getConsultarCiclos(){    
    //console.log(this.configuracion());
    return this._http.get(this.endpoint_2+'ConsultarCiclos');
  }







  /*parametrizacion ciclos*/
  public postRegistrarCiclo(data: any){    
    //console.log(this.configuracion());
    return this._http.post(this.endpoint_2+'RegistrarCiclo', data);
  }

  public postModificarCiclos(data: any){    
    //console.log(this.configuracion());
    return this._http.post(this.endpoint_2+'ModificarCiclos', data);
  }

  public getConsultarConceptosNoRegistrados(id: number){    
    //console.log(this.configuracion());
    return this._http.get(this.endpoint_2+'ConsultarConceptosNoRegistrados?numero_ciclo='+id);
  }

  public getConsultarConceptosRegistrados(id: number){    
    //console.log(this.configuracion());
    return this._http.get(this.endpoint_2+'ConsultarConceptosRegistrados?numero_ciclo='+id);
  }

  public postRegistrarConceptoTesoreriaCiclo(data: any){    
    //console.log(this.configuracion());
    return this._http.post(this.endpoint_2+'RegistrarConceptoTesoreriaCiclo', data);
  }

  public postEliminarConceptoTesoreriaCiclo(data: any){    
    //console.log(this.configuracion());
    return this._http.post(this.endpoint_2+'EliminarConceptoTesoreriaCiclo', data);
  }

  public postRegistrarConceptoPorCobrar(data: any){    
    //console.log(this.configuracion());
    return this._http.post(this.endpoint_2+'RegistrarConceptoPorCobrar', data);
  }

  public getConsultarCuentasPorCobrar(id: number, id2: number, id3: number){    
    //console.log(this.configuracion());
    return this._http.get(this.endpoint_2+'ConsultarCuentasPorCobrar?numero_ciclo='+id+'&nivel='+id2+'&numero_concepto='+id3);
  }

  public getConsultarCuentasPorCobrarDos(id: number, id2: number, id3: number){    
    //console.log(this.configuracion());
    return this._http.get(this.endpoint_2+'ConsultarCuentasPorCobrarDos?numero_ciclo='+id+'&nivel='+id2+'&numero_concepto='+id3);
  }

  public postModificarConceptoPorCobrar(data: any){    
    //console.log(this.configuracion());
    return this._http.post(this.endpoint_2+'ModificarConceptoPorCobrar', data);
  }

  public postEliminarConceptoPorCobrar(data: any){    
    //console.log(this.configuracion());
    return this._http.post(this.endpoint_2+'EliminarConceptoPorCobrar', data);
  }
  /*fin parametrizacion ciclos*/


  public getBaseUrl(){
    return this.endpoint_2;
  }


  public postModificarMovimientoTesoreria(data: any){    
    //console.log(this.configuracion());
    return this._http.post(this.endpoint_2+'ModificarMovimientoTesoreria', data);
  }






  public getConsultaNivel(){    
    //console.log(this.configuracion());
    return this._http.get(this.endpoint_2+'ConsultaNivel');
  }


  public getConsultaGrados(){    
    //console.log(this.configuracion());
    return this._http.get(this.endpoint_2+'ConsultarGrados');
  }

  public getConsultarOfertaEducativa(){    
    //console.log(this.configuracion());
    return this._http.get(this.endpoint_2+'ConsultarOfertaEducativa');
  }

  public postRegistrarOfertaEducativa(data: any){    
    //console.log(this.configuracion());
    return this._http.post(this.endpoint_2+'RegistrarOfertaEducativa', data);
  }


  public postModificarOfertaEducativa(data: any){    
    //console.log(this.configuracion());
    return this._http.post(this.endpoint_2+'ModificarOfertaEducativa', data);
  }





  /*creditos tesoreria*/
  public getConsultaPerfilSaldoAlumno(id: number){    
    //console.log(this.configuracion());
    return this._http.get(this.endpoint_2+'ConsultaPerfilSaldoAlumno?numero_alumno='+id);
  }

  public postModificarSaldoAlumno(data: any){    
    //console.log(this.configuracion());
    return this._http.post(this.endpoint_2+'ModificarSaldoAlumno', data);
  }


  public postCondonacion(data: any){    
    //console.log(this.configuracion());
    return this._http.post(this.endpoint_2+'Condonaciones', data);
  }



  public postEliminarMovimientosAlumno(data: any){    
    //console.log(this.configuracion());
    return this._http.post(this.endpoint_2+'EliminarMovimientosAlumno', data);
  }
  

}
