import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss']
})
export class PrincipalComponent implements OnInit {

  constructor(private config:ConfigurationService) { }
  menu:any;
  ngOnInit(): void {
    this.menu = this.config.getConfiguration().menu;
  }

}
