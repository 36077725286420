import { Component } from '@angular/core';

@Component({
  selector: 'app-plan-estudios',
  templateUrl: './plan-estudios.component.html',
  styleUrls: ['./plan-estudios.component.scss']
})
export class PlanEstudiosComponent  {

  

}
