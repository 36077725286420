import { Component } from '@angular/core';

@Component({
  selector: 'app-gestion-academica',
  templateUrl: './gestion-academica.component.html',
  styleUrls: ['./gestion-academica.component.scss']
})
export class GestionAcademicaComponent  {

  

}
