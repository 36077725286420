<div class="container-fluid bg-secundario m-0 mb-5">
	<span class="text-white fw-bold fs-2">
		<br>
		INICIO
		<br>
	</span>
</div>

<div class="container px-5">
	<div class="card bg-dark text-white border-0">
		<svg class="bd-placeholder-img bd-placeholder-img-lg card-img" width="100%" height="200" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Card image" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#DCDCDC"></rect><text x="50%" y="50%" fill="#dee2e6" dy=".3em">Card image</text></svg>
		<div class="card-img-overlay">
			<h2 class="card-title text-black">NOTIFICACIONES</h2>
			<p class="card-text text-black">(Sin funcionar)</p>
			<p class="card-text text-black">Last updated 3 mins ago</p>
		</div>
	</div>
</div>

<br>

<div class="container px-5">
	<div class="card bg-dark text-white border-0">
		<svg class="bd-placeholder-img bd-placeholder-img-lg card-img" width="100%" height="200" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Card image" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#DCDCDC"></rect><text x="50%" y="50%" fill="#dee2e6" dy=".3em">Card image</text></svg>
		<div class="card-img-overlay">
			<h2 class="card-title text-black">CORCHO</h2>
			<p class="card-text text-black">(Sin funcionar)</p>
			<p class="card-text text-black">Last updated 3 mins ago</p>
		</div>
	</div>
</div>

<br>

<div class="container px-5">
	<div class="card bg-dark text-white border-0">
		<svg class="bd-placeholder-img bd-placeholder-img-lg card-img" width="100%" height="200" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Card image" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#DCDCDC"></rect><text x="50%" y="50%" fill="#dee2e6" dy=".3em">Card image</text></svg>
		<div class="card-img-overlay">
			<h2 class="card-title text-black">NIÑOS QUE CUMPLEN AÑOS</h2>
			<p class="card-text text-black">(Sin funcionar)</p>
			<p class="card-text text-black">Last updated 3 mins ago</p>
		</div>
	</div>
</div>