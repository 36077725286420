<div class="container-fluid mt-5">
    <div class="card-container encuesta">
        <mat-card class="example-card">
            <mat-card-header>
                <mat-card-title>Gestor de expedientes de alumnos</mat-card-title>
                <mat-card-subtitle>Gestiona documentos del alumno</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="container-fluid">
                    <form autocomplete="no" (ngSubmit)="buscar()" [formGroup]="formulario">
                        <div class="row">

                            <div class="col-md-10">
                                <div class="row">

                                    <div class="col">
                                        <mat-form-field appearance="fill" class="w-100" >
                                            <mat-label>Buscar</mat-label>
                                            <input matInput placeholder="Por nombre del alumno" formControlName="busqueda"
                                            autocomplete="off">
                                            <mat-icon matPrefix>search</mat-icon>
                                        </mat-form-field>
                                        
                                    </div>
                                    <!--ciclo-->
                                    <div class="col">
                                        <mat-form-field appearance="fill" class="w-100" >
                                            <mat-label>Ciclo</mat-label>
                                            <mat-select  formControlName="ciclo">
                                                <mat-option value="">- Cualquiera -</mat-option>
                                                <mat-option *ngFor="let c of ciclos"  [value]="c.id_ciclo_pk">
                                                    {{c.nombre}} <span *ngIf="c.activo == '1'">(Activo)</span>
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <!--fin ciclo-->
                                    <!-- nivel -->
                                    <div class="col">
                                        <!-- control escolar -->
                                        <mat-form-field appearance="fill" class="w-100" *ngIf="id_perfil == 5 || id_perfil == 1 || id_perfil == 2">
                                            <mat-label>Nivel</mat-label>
                                            <mat-select #cboNivel (selectionChange)="getGrados($event)" formControlName="nivel">
                                                <mat-option value="0">- Cualquiera -</mat-option>
                                                <mat-option *ngFor="let nivel of niveles"  [value]="nivel.id_nivel_pk">
                                                    {{nivel.descripcion}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field appearance="fill" class="w-100" *ngIf="id_perfil == 11">
                                            <mat-label>Nivel</mat-label>
                                            <mat-select #cboNivel (selectionChange)="getGrados($event)" formControlName="nivel">
                                                <mat-option [value]="1"> Preescolar </mat-option>
                                                <mat-option [value]="2"> Primaria </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field appearance="fill" class="w-100" *ngIf="id_perfil == 12">
                                            <mat-label>Nivel</mat-label>
                                            <mat-select #cboNivel (selectionChange)="getGrados($event)" formControlName="nivel">
                                                <mat-option [value]="3"> Secundaria </mat-option>
                                                <mat-option [value]="4"> Preparatoria </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field appearance="fill" class="w-100" *ngIf="id_perfil == 6">
                                            <mat-label>Nivel</mat-label>
                                            <mat-select #cboNivel (selectionChange)="getGrados($event)" formControlName="nivel">
                                                <mat-option [value]="1"> Preescolar </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field appearance="fill" class="w-100" *ngIf="id_perfil == 7">
                                            <mat-label>Nivel</mat-label>
                                            <mat-select #cboNivel (selectionChange)="getGrados($event)" formControlName="nivel">
                                                <mat-option [value]="2"> Primaria </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field appearance="fill" class="w-100" *ngIf="id_perfil == 8">
                                            <mat-label>Nivel</mat-label>
                                            <mat-select #cboNivel (selectionChange)="getGrados($event)" formControlName="nivel">
                                                <mat-option [value]="3"> Secundaria </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field appearance="fill" class="w-100" *ngIf="id_perfil == 9">
                                            <mat-label>Nivel</mat-label>
                                            <mat-select #cboNivel (selectionChange)="getGrados($event)" formControlName="nivel">
                                                <mat-option [value]="4"> Preparatoria </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <!-- fin nivel -->
                                    <div class="col">
                                        <mat-form-field appearance="fill" class="w-100" >
                                            <mat-label>Grado</mat-label>
                                            <mat-select formControlName="grado">
                                                <mat-option value="0">- Cualquiera -</mat-option>
                                                <mat-option *ngFor="let grado of grados" [value]="grado.id_grado_pk">{{grado.descripcion}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>Grupo</mat-label>
                                            <mat-select formControlName="grupo">
                                                <mat-option value="0">- Cualquiera -</mat-option>
                                                <mat-option *ngFor="let grupo of grupos" [value]="grupo.id_grupo_pk">{{grupo.nombre}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col">
                                                <mat-form-field appearance="fill" class="w-100">
                                                    <mat-label>Situación en tesorería</mat-label>
                                                    <mat-select formControlName="adeudos">
                                                        <mat-option value="0">- Cualquiera -</mat-option>
                                                        <mat-option value="1">Con adeudos</mat-option>
                                                        <mat-option value="2">Sin adeudos</mat-option>
                                                        
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col">
                                                <mat-form-field appearance="fill" class="w-100">
                                                    <mat-label>Estatus</mat-label>
                                                    <mat-select formControlName="inscritos">
                                                        <mat-option value="0">- Cualquiera -</mat-option>
                                                        <mat-option value="1">Prospectos</mat-option>
                                                        <mat-option value="2">Inscritos</mat-option>
                                                        <mat-option value="3">Alumnos</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        
                                        
                                        
                                        <!--<mat-radio-group aria-label="Select an option">

                                            <mat-radio-button value="0" class="me-4">Cualquiera</mat-radio-button>
                                            <mat-radio-button value="1" class="me-4">Con adeudos</mat-radio-button>
                                            <mat-radio-button value="2" class="me-4">Sin adeudos</mat-radio-button>
                                            
                                        </mat-radio-group>
                                          
                                        <div class="form-check form-switch d-none">
                                            <input formControlName="adeudos" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked1">
                                            <label class="form-check-label" for="flexSwitchCheckChecked1">Solo con adeudos</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        
                                        <mat-radio-group aria-label="Select an option">
                                            <mat-radio-button value="0" class="me-4">Cualquiera</mat-radio-button>
                                            <mat-radio-button value="1" class="me-4">Prospectos</mat-radio-button>
                                            <mat-radio-button value="2" class="me-4">Inscritos</mat-radio-button>
                                            <mat-radio-button value="3" class="me-4">Ex-alumnos</mat-radio-button>
                                            
                                          </mat-radio-group>
                                        <div class="form-check form-switch d-none">
                                            <input formControlName="inscritos" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked2">
                                            <label class="form-check-label" for="flexSwitchCheckChecked2">Solo inscritos</label>
                                        </div>-->
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <button mat-stroked-button color="primary"><mat-icon>search</mat-icon> Buscar</button>&nbsp;
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col">
                                        <!-- <button type="button" mat-stroked-button color="primary" (click)="filtro()">Buscar</button>&nbsp; -->
                                        <!-- <button type="button" mat-stroked-button color="primary">Buscar</button>&nbsp; -->
                                        <!-- <button mat-button color="primary">Buscar</button>&nbsp; -->
                                        <button type="button" mat-flat-button color="primary"
                                            (click)="exportar()">
                                            Reporte excel</button>&nbsp;
                                        <button type="button" mat-flat-button color="primary" [routerLink]="['new']"><mat-icon>person_add</mat-icon> Nuevo
                                            
                                            expediente</button>
                                            &nbsp;
                                        <button type="button" mat-flat-button color="primary" [routerLink]="['/parametrizacion']"> Parametrizar </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                    <div class="row">
                        <div class="col position-relative">
                            <div class="spinner" *ngIf="isLoading">
                                <mat-spinner mode="indeterminate"></mat-spinner>
                            </div>
                            <table mat-table matTableExporter [dataSource]="dataSource" class="mat-elevation-z0"
                                #exporter="matTableExporter" hiddenColumns="[6][7][8]">
                                <caption *ngIf="length<=0">No hay resultados para sus parámetros de búsqueda actuales
                                </caption>
                                <!--- Note that these columns can be defined in any order.
                                    The actual rendered columns are set as a property on the row definition" -->

                                <!-- Position Column -->
                                <ng-container matColumnDef="position">
                                    <th id="position" mat-header-cell *matHeaderCellDef> # </th>
                                    <td mat-cell *matCellDef="let element;let i=index"> {{((paginator?.pageIndex * paginator?.pageSize)+i)+1}} </td>
                                </ng-container>


                                <ng-container matColumnDef="adeudo">
                                    <th scope="col" scope="col" mat-header-cell *matHeaderCellDef> Adeudo </th>
                                    <td mat-cell *matCellDef="let element">
                                        <button  mat-icon-button class="position-relative" *ngIf="element.has_adeudo>0 && element.estado != 'Prospecto'" (click)="getConceptos(element)" > <!--element.has_adeudo>0 && (click)="getConceptos(element)"-->
                                            <mat-icon color="warn">
                                                money_off
                                            </mat-icon>
                                            <span 
                                                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                {{element.has_adeudo}}
                                                <!-- requiere est:
                                                {{element.control_escolar.split("|")[3]}} -->
                                                <span class="visually-hidden">unread messages</span>
                                            </span>
                                        </button>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="matricula">
                                    <th id="matricula" mat-header-cell *matHeaderCellDef> Matricula</th>
                                    <td mat-cell *matCellDef="let element;let i=index"> {{element.matricula}} </td>
                                </ng-container>

                                <ng-container matColumnDef="nombre">
                                    <th scope="col" scope="col" mat-header-cell *matHeaderCellDef> Nombre </th>
                                    <td mat-cell *matCellDef="let element"> {{element.apellidop}} {{element.apellidom}}
                                        {{element.nombre}}</td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="proceso">
                                    <th scope="col" mat-header-cell *matHeaderCellDef> Proceso </th>
                                    <td mat-cell *matCellDef="let element"> {{element.proceso}} </td>
                                </ng-container>

                                <!-- Weight Column -->
                                <ng-container matColumnDef="estatus">
                                    <th scope="col" mat-header-cell *matHeaderCellDef> Estatus </th>
                                    <td mat-cell *matCellDef="let element">

                                        <mat-chip-list aria-label="Fish selection">
                                            <mat-chip class="red" *ngIf="element.resultado_evaluaciones=='No Aceptado'; else elseEstatus">
                                                No aceptado
                                            </mat-chip>
                                            <ng-template #elseEstatus>
                                                <mat-chip color="default" *ngIf="element.estado=='Prospecto'">{{element.estado}}</mat-chip>
                                                <mat-chip color="accent" *ngIf="element.estado=='Alumno'" selected>{{element.estado}}</mat-chip>
                                            </ng-template>
                                           
                                        </mat-chip-list>

                                    </td>
                                </ng-container>

                                <!-- Symbol Column -->
                                <ng-container matColumnDef="nivel">
                                    <th scope="col" mat-header-cell *matHeaderCellDef> Nivel </th>
                                    <td mat-cell *matCellDef="let element"> {{element.control_escolar.split("|")[1]}} </td>
                                </ng-container>

                                <!-- Symbol Column -->
                                <ng-container matColumnDef="grado">
                                    <th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Grado </th>
                                    <td mat-cell *matCellDef="let element" class="text-center">
                                        {{element.control_escolar.split("|")[2]}} </td>
                                </ng-container>

                                <!-- Symbol Column -->
                                <ng-container matColumnDef="grupo">
                                    <th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Grupo </th>
                                    <td mat-cell *matCellDef="let element" class="text-center"> {{element.grupo}} </td>
                                </ng-container>

                                <!-- Symbol Column -->
                                <ng-container matColumnDef="ficha">
                                    <th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Ficha </th>
                                    <td mat-cell *matCellDef="let element" class="text-center">
                                        <button mat-mini-fab class="btn-icon-small" color="accent" [routerLink]="['ficha']"
                                            [queryParams]="{id:element.id_alumno_pk}">
                                            <mat-icon class="icon-small">badge</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <!-- Symbol Column -->
                                <ng-container matColumnDef="expediente">
                                    <th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Expediente </th>
                                    <td mat-cell *matCellDef="let element" class="text-center">
                                        <button mat-mini-fab class="btn-icon-small" color="primary" [routerLink]="['edit']"
                                            [queryParams]="{id:element.id_alumno_pk, id_cuenta:element.id_cuenta_tutor_fk}">
                                            <mat-icon class="icon-small">folder</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="view">
                                    <th id="verExpediente" mat-header-cell *matHeaderCellDef>Ver Expediente </th>
                                    <td mat-cell *matCellDef="let element" class="text-center">
                                        <button mat-mini-fab class="btn-icon-small" color="accent" [routerLink]="['view']" 
                                            [queryParams]="{id:element.id_alumno_pk, id_cuenta:element.id_cuenta_tutor_fk}">
                                            <mat-icon class="icon-small">preview</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <!-- Symbol Column -->
                                <ng-container matColumnDef="ctrlEscolar">
                                    <th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Control escolar
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="text-center">
                                        <button mat-mini-fab class="btn-icon-small" color="primary" [routerLink]="['control']"
                                            [queryParams]="{id:element.id_alumno_pk}">
                                            <mat-icon class="icon-small">history_edu</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="eliminar">
                                    <th  scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Eliminar </th>
                                    <td mat-cell *matCellDef="let element" class="text-center"> <!--*ngIf="id_perfil_fk == 1 || id_perfil_fk == 11"-->
                                        <button *ngIf="id_perfil_fk == 1 || id_perfil_fk == 2" mat-mini-fab color="warn" (click)="postEliminarCuentaAlumno(element.id_alumno_pk, element.apellidop + ' ' + element.apellidom + ' ' + element.nombre)" >
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
                    

                                <ng-container matColumnDef="psicologico">
                                    <th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Psicológico </th>
                                    <td mat-cell *matCellDef="let element" class="text-center">
                                        <button mat-mini-fab class="btn-icon-small" color="accent" [routerLink]="['expediente/psicologico']"
                                            [queryParams]="{id:element.id_alumno_pk}">
                                            <mat-icon class="icon-small">folder</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="pedagogico">
                                    <th scope="col" mat-header-cell *matHeaderCellDef class="text-center"> Pedagógico </th>
                                    <td mat-cell *matCellDef="let element" class="text-center">
                                        <button mat-mini-fab class="btn-icon-small" color="accent" [routerLink]="['expediente/pedagogico']"
                                            [queryParams]="{id:element.id_alumno_pk}">
                                            <mat-icon class="icon-small">folder</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
                                <!-- Symbol Column -->
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent($event)">
                </mat-paginator>
            </mat-card-actions>
        </mat-card>
    </div>
</div>