<div class="spinner" *ngIf="isLoading">
    <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
</div>
<div class="card-container encuesta">
    <mat-card-header>
        <mat-card-title>{{nombreEncuesta}}</mat-card-title>
        <mat-card-subtitle>{{subtituloEncuesta}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card class="example-card">
        <mat-card-content>
            <p *ngIf="code==200">Agradecemos tu participación. Siéntete libre de llenar esta encuesta con las respuestas
                que mejor consideres.</p>
            <p *ngIf="code==200">Instrucciones para el llenado de encuestas.</p>
            <ol *ngIf="code==200">
                <li>Oprime el botón con la encuesta que desees llenar</li>
                <li>Cuando termines de llenar la encuesta oprime la opción terminar.</li>
            </ol>
            <br *ngIf="code==200">
            <br *ngIf="code==200">
            <br *ngIf="code==200">
            <mat-progress-bar *ngIf="code==200" mode="determinate"
                value="{{redondear(((index+1) *100 )/ resultado?.preguntas?.length)}}"></mat-progress-bar>
            <mat-progress-bar *ngIf="code==0" mode="indeterminate"></mat-progress-bar>
            <div style="text-align: right;" *ngIf="code==200">{{index + 1 }} de {{resultado?.preguntas?.length}}
                preguntas</div>
            <br>
            <br>
            <div *ngIf="code==200">
                <p>
                    {{index + 1 }}. {{pregunta?.descripcion}}
                </p>
            </div>
            <ng-container *ngIf="code==200">
                <div *ngIf="pregunta?.id_tipo_pregunta_fk == '1' || pregunta?.id_tipo_pregunta_fk == '3' || pregunta?.id_tipo_pregunta_fk == '4' ">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                        [(ngModel)]="response">
                        <ng-container *ngFor="let respuesta of respuestas; let i=index">
                            <mat-radio-button color="primary" class="example-radio-button"
                                [value]="respuesta?.id_respuesta_pk" (change)="changeRespuesta(respuesta?.id_respuesta_pk)">
                                {{respuesta?.descripcion}}
                            </mat-radio-button>
                            <mat-form-field appearance="fill"
                                *ngIf="pregunta?.id_tipo_pregunta_fk == '3' && i+1==respuestas.length"
                                style="width: 100%;">
                                <mat-label>¿Cual otro?</mat-label>
                                <input type="text" matInput [(ngModel)]="texto" />
                            </mat-form-field>
                            <ng-container *ngIf="respuestaActual == respuesta?.id_respuesta_pk">
                                <mat-form-field appearance="fill" *ngIf="pregunta?.id_tipo_pregunta_fk == '4'" style="width: 100%;">
                                    <mat-label>Explique</mat-label>
                                    <input type="text" matInput [(ngModel)]="texto" />
                                </mat-form-field>
                            </ng-container>
                        </ng-container>

                    </mat-radio-group>

                </div>
                <div>
                    <mat-form-field appearance="fill" *ngIf="pregunta?.id_tipo_pregunta_fk == '2'" style="width: 100%;">
                        <mat-label>Ingresa un texto</mat-label>
                        <textarea matInput [(ngModel)]="texto"></textarea>
                    </mat-form-field>
                </div>
            </ng-container>

        </mat-card-content>
        <mat-card-actions>
            <button mat-flat-button color="primary" (click)="siguientePregunta()"
                *ngIf="index +1 < resultado?.preguntas?.length && pregunta?.id_tipo_pregunta_fk == '3'"
                disabled="{{response==null && texto == null}}">Siguiente</button>
            <button mat-flat-button color="primary" (click)="siguientePregunta()"
                *ngIf="index +1 < resultado?.preguntas?.length && (pregunta?.id_tipo_pregunta_fk == '1' || pregunta?.id_tipo_pregunta_fk == '2')"
                disabled="{{response==null && texto == null}}">Siguiente</button>
            <button mat-flat-button color="primary" (click)="terminarEncuesta()"
                *ngIf="index + 1 == resultado?.preguntas?.length && pregunta?.id_tipo_pregunta_fk == '3'"
                disabled="{{response==null && texto == null}}">Terminar encuesta</button>
            <button mat-flat-button color="primary" (click)="terminarEncuesta()"
                *ngIf="index + 1 == resultado?.preguntas?.length && (pregunta?.id_tipo_pregunta_fk == '1' || pregunta?.id_tipo_pregunta_fk == '2' || pregunta?.id_tipo_pregunta_fk == '4')"
                disabled="{{response==null && texto == null}}">Terminar encuesta</button>
        </mat-card-actions>
    </mat-card>
</div>