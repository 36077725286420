import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment'; 
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  private base_url = environment.base_url;
  private laravelapi = "";
  public endpoint_2 = '';

  endpoint = "";
  constructor(private _http:HttpClient,private _config:ConfigurationService) { 
    this.endpoint = _config.getConfiguration().hostapi;
    this.endpoint_2 = _config.getConfiguration().laravelapi;
  }

  getReportEncuesta(idAplicacion:string):Observable<any>{
    
    return this._http.get(this.endpoint+"/php/getGenericReport.php?aplicacion="+idAplicacion);
    // return this._http.get("http://localhost/ng-san-juan-pablo/php/getGenericReport.php?aplicacion="+idAplicacion);
  }

  public getExcelReporteEncuestas(id1: any, id2: any){    
    return this._http.get(this.endpoint_2+'ExcelReporteEncuestas?numero_encuesta='+id1+'&numero_app='+id2);
  }

  public getEndPoint2(){
    return this.endpoint_2;
  }

  public getEndPoint(){
    return this.endpoint;
  }

  public postExcelReporteEncuestas(data: any){    
    return this._http.post(this.endpoint_2+'ExcelReporteEncuestas', data);
  }
}
