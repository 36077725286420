import { Component } from '@angular/core';

@Component({
  selector: 'app-plan-de-trabajo',
  templateUrl: './plan-de-trabajo.component.html',
  styleUrls: ['./plan-de-trabajo.component.scss']
})
export class PlanDeTrabajoComponent  {

  

}
