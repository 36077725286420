<br><br>
<div class="card-container mt-5">
	<mat-card class="example-card">
		<mat-card-header>
			<mat-card-title>{{accion}} categoría</mat-card-title>
			<mat-card-subtitle></mat-card-subtitle>
		</mat-card-header>

		<mat-card-content>
			<div class="spinner" *ngIf="isLoading">
				<mat-spinner color="accent"></mat-spinner>
			</div>
			<form novalidate [formGroup]="form">
				<div class="row">
					<mat-form-field appearance="fill" class="input-full-w">
						<mat-label>Nombre de la categoria</mat-label>
						<input type="text" matInput maxlength="250" #categoria formControlName="categoria">
						<mat-hint align="end">{{categoria.value.length}} / 250</mat-hint>
						<mat-error *ngIf="form.controls['categoria'].hasError('maxlength')">
							El maximo tamaño es de 250 caracteres
						</mat-error>
					</mat-form-field>
				</div>
			</form>

			<div class="mt-4 d-flex justify-content-center">
				<div *ngIf="form.invalid">
					<span class="text-danger">
						Faltan campos por llenar
					</span>
				</div>
			</div>

			<div class="d-flex justify-content-center gap-2">

				<button class="flat" color="primary" mat-flat-button [routerLink]="['/biblioteca-categoria/biblioteca-categoria-general']" >
					Regresar
				</button>

				<form novalidate [formGroup]="form" *ngIf="accion == 'registrar'" (ngSubmit)="postRegistrarCategoriaBiblioteca()">
					<button  class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
						Guardar
					</button>
				</form>
				<form novalidate [formGroup]="form" *ngIf="accion == 'modificar'" (ngSubmit)="postModificarCategoriaBiblioteca()" >
					<button class="flat" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
						Modificar
					</button>
				</form>

			</div>
		</mat-card-content>
	</mat-card>
</div>
