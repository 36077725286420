import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PrintEncuestaComponent } from 'src/app/modales/print-encuesta/print-encuesta.component';
import { EncuestasServiceService } from 'src/app/services/encuestas-service.service';
const ELEMENT_DATA: any[] = [
];
@Component({
  selector: 'app-encuestas-mannager',
  templateUrl: './encuestas-mannager.component.html',
  styleUrls: ['./encuestas-mannager.component.scss']
})
export class EncuestasMannagerComponent implements OnInit {
  length = 0;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  index = 0;

  // MatPaginator Output
  pageEvent: PageEvent;
  isLoading = false;
  formulario = new FormGroup({
    busqueda : new FormControl("",[])
  });
  displayedColumns: string[] = ['position','publico', 'nombre','editar','imprimir','eliminar'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  constructor(private dialog:MatDialog,private encuestasService:EncuestasServiceService) { }

  ngOnInit(): void {
    this.buscar(0);
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  buscar(index){
    this.isLoading = true;
    this.encuestasService.getInventario(this.formulario.controls["busqueda"].value,this.pageSize,index).subscribe(result=>{
      console.info(result);
      this.isLoading = false;
      if(result.code==200){
        this.dataSource = new MatTableDataSource<any>(result.datos);
        this.length = result.size;
      }else{
        this.dataSource = new MatTableDataSource<any>([]);
        this.length = 0;
      }
    },error=>{
      this.length = 0;
      this.isLoading = false;
      console.info(error);
      this.dataSource = new MatTableDataSource<any>([]);
    });
  }

  buscador(index:number){
    if(index == 0){
      this.paginator.pageIndex = 0;
    }
    this.buscar(index);
  }
  page(event:any){
    this.pageSize = event.pageSize;
    this.index = event.pageIndex * event.pageSize;
    this.buscar(this.index);
    //console.info(this.paginator);
  }

  imprimir(id:number){
    this.dialog.open(PrintEncuestaComponent,{ data: {id: id }});
  }
}