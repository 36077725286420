<div class="container mt-5">
	<h2>Mi cuenta</h2>

	<form novalidate [formGroup]="form" autocomplete="off" (ngSubmit)="postModificarCuentaTutor()">

		<mat-form-field class="example-full-width" appearance="fill">
			<mat-label>Nombre del obligado</mat-label>
			<input type="email" formControlName="nombre_obligado" matInput placeholder="Nombre del obligado" matInput [errorStateMatcher]="matcher">
			<mat-error *ngIf="form.controls['nombre_obligado'].hasError('required')">
				El campo es obligatorio
			</mat-error>
		</mat-form-field>

		<mat-selection-list [multiple]="false">
			<h3>Alumnos Asociados</h3>
			<mat-list-option *ngFor="let alm of dataGirasol?.alumnos" value="{{alm?.id_alumno_pk}}">
				{{alm.apellidop}} {{alm.apellidom}} {{alm.nombre}}
			</mat-list-option>

			<div *ngIf="dataGirasol?.alumnos?.length == 0" class="alert alert-danger" role="alert">
				No hay alumnos asociados
			</div>
		</mat-selection-list>
		<br>
		<br>
		<br>


		<mat-form-field class="example-full-width" appearance="fill">
			<mat-label>Correo o Usuario</mat-label>
			<input type="email" formControlName="correo" matInput placeholder="Correo" matInput [errorStateMatcher]="matcher">
			<mat-error *ngIf="form.controls['correo'].hasError('required')">
				El campo es obligatorio
			</mat-error>
		</mat-form-field>

		<mat-form-field class="example-full-width" appearance="fill">
			<mat-label>Contraseña</mat-label>
			<input type="password" formControlName="contrasena" matInput placeholder="Contraseña" matInput [errorStateMatcher]="matcher">
			<mat-error *ngIf="form.controls['contrasena'].hasError('required')">
				El campo es obligatorio
			</mat-error>
			<mat-error *ngIf="form.controls['contrasena'].hasError('maxlength')">
				El maximo tamaño de la justificación es 150 caracteres
			</mat-error>
		</mat-form-field>

		<div class="d-grid gap-2 mt-5">
			<button type="submit" mat-button color="primary">Modificar cuenta</button>
		</div>

	</form>




</div>