<br><br>
<div class="card-container encuesta">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Gestor de ciclos de encuestas</mat-card-title>
            <mat-card-subtitle>Gestión y control de aplicaciones de encuestas</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <form autocomplete="no" (ngSubmit)="buscar()" [formGroup]="formulario">
                <mat-form-field appearance="fill" class="bt col-md-6" >
                    <mat-label>Buscar</mat-label>
                    <input matInput placeholder="Nombre de encuesta" formControlName="busqueda">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <button *ngIf="!isReporteria" type="button" mat-stroked-button color="primary">Reporte excel</button>
                &nbsp;
                <button *ngIf="!isReporteria" type="button" mat-flat-button color="primary" [routerLink]="['new']">Nueva encuesta</button>
            </form>
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
                <caption></caption>
                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <ng-container matColumnDef="position">
                    <th id="position" mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element;let i=index"> {{i+1}} </td>
                </ng-container>
                <!-- Position Column -->
                <ng-container matColumnDef="periodo">
                  <th id="periodo" mat-header-cell *matHeaderCellDef> Periodo </th>
                  <td mat-cell *matCellDef="let element"> {{element.ciclo}}</td>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="publico">
                  <th id="publico" mat-header-cell *matHeaderCellDef> Publico </th>
                  <td mat-cell *matCellDef="let element"> {{element.publico}} </td>
                </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="grado">
                  <th id="grado" mat-header-cell *matHeaderCellDef> Grado </th>
                  <td mat-cell *matCellDef="let element"> {{element.grado}} </td>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="grupo">
                  <th id="grupo" mat-header-cell *matHeaderCellDef> Grupo </th>
                  <td mat-cell *matCellDef="let element"> {{element.grupo}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="nombre">
                    <th id="nombreEncuesta" mat-header-cell *matHeaderCellDef> Nombre encuesta </th>
                    <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="encuestas_contestadas">
                    <th id="encuestasContestadas" mat-header-cell *matHeaderCellDef> # Encuestas contestadas </th>
                    <!-- <td mat-cell *matCellDef="let element"> {{element.respuestas | number}} </td> -->
                    <td mat-cell *matCellDef="let element"> {{element.encuestas_contestadas}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="estatus">
                    <th id="estatus" mat-header-cell *matHeaderCellDef> Estatus </th>
                    <td mat-cell *matCellDef="let element"> {{element.estatus}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="token">
                    <th id="token" mat-header-cell *matHeaderCellDef> Token </th>
                    <td mat-cell *matCellDef="let element">
                        <a href="/encuesta/{{element.token}}" target="_blank">{{element.token}}</a>
                    </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="editar">
                    <th id="editar" mat-header-cell *matHeaderCellDef > Editar </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="primary" [routerLink]="['edit']" [queryParams]="{id:element.id_aplicacion_pk}">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="reporteria">
                    <th id="reporte" mat-header-cell *matHeaderCellDef > Reporte </th>
                    <td mat-cell *matCellDef="let element">
                        <button [disabled]="element.respuestas==0" mat-flat-button color="primary" (click)="openEncuesta(element.id_encuesta_fk,element.id_aplicacion_pk)">
                            <mat-icon>bar_chart</mat-icon> Ver reporte
                        </button>
                    </td>
                </ng-container>

                 <ng-container matColumnDef="reporte_excel">
                    <th id="reporte_excel" mat-header-cell *matHeaderCellDef > Reporte excel </th>
                    <td mat-cell *matCellDef="let element">

                        
                        <!--<a href="{{getBaseUrl()}}ExcelReporteEncuestas?numero_encuesta={{element.id_encuesta_fk}}&numero_app={{element.id_aplicacion_pk}}" class="text-white" [disabled]="element.respuestas==1" mat-flat-button color="primary" target="_blank" rel="noopener" (click)="enviarDatos(element.id_encuesta_fk,element.id_aplicacion_pk )"> href="{{getBaseUrl()}}ExcelReporteEncuestas?numero_encuesta={{element.id_encuesta_fk}}&numero_app={{element.id_aplicacion_pk}}" -->
                        <a href="{{getBaseUrl()}}ExcelReporteEncuestas?numero_encuesta={{element.id_aplicacion_pk}}&url={{getDatosUrlNormal()}}" class="text-white" [disabled]="element.respuestas==1" mat-flat-button color="primary" target="_blank" rel="noopener" (click)="enviarDatos(element.id_encuesta_fk,element.id_aplicacion_pk )"> 

                    
                            <mat-icon>bar_chart</mat-icon> Ver reporte
                        </a>
                    </td>
                </ng-container>
              
                
                <!-- Symbol Column -->
                <ng-container matColumnDef="eliminar">
                    <th id="eliminar" mat-header-cell *matHeaderCellDef> Eliminar </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="warn" (click)="delete(element.id_aplicacion_pk)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>            
        </mat-card-content>
        <mat-card-actions>
            <mat-paginator [length]="length"
               [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions"
               (page)="pageEvent = $event">
            </mat-paginator>
        </mat-card-actions>
    </mat-card>
</div>