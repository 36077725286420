import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class EvaluacionesService {
  private ruta = "/php/evaluaciones-service.php";
  private endpoint = "";
  private laravelapi = "";
  constructor(private _http:HttpClient,private _configService:ConfigurationService) {
    this.endpoint = this._configService.getConfiguration().hostapi;
    this.laravelapi = this._configService.getConfiguration().laravelapi;
  }

  find(nombre:string,nivel:string,grado:string,indice:number,registros:number):Observable<any>{
    return this._http.get(this.endpoint+this.ruta+"?opc=find&nombre="+nombre+"&nivel="+nivel+"&grado="+grado+"&i="+indice+"&r="+registros);
    // return this._http.get("http://localhost/ng-san-juan-pablo/php/evaluaciones-service.php?opc=find&nombre="+nombre+"&nivel="+nivel+"&grado="+grado+"&i="+indice+"&r="+registros);
  }
  update(json:any):Observable<any>{
    return this._http.post(this.endpoint+this.ruta+"?opc=modifica",json);
  }
  findByKey(idAlumno:number, idCiclo:number, idNivel:number, idGrado:number):Observable<any>{
    return this._http.get(this.endpoint+this.ruta+"?opc=findByKey&idAlumno="+idAlumno+"&ciclo="+idCiclo+"&nivel="+idNivel+"&grado="+idGrado)
  }

  public getEvaluaciones(p: number, r: number, s: string):any{
    return this._http.get(this.laravelapi+"Evaluaciones?filas="+r+'&page='+p+'&buscador='+s);
  }

  public getEvaluacionesMod(numero_evaluacion_resultado: number, estado: string):any{
    return this._http.get(this.laravelapi+"EvaluacionesMod?numero_evaluacion_resultado="+numero_evaluacion_resultado+'&estado='+estado);
  }




}
