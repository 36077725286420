import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DesitionBoxComponent } from 'src/app/modales/desition-box/desition-box.component';
import { EvaluacionesService } from 'src/app/services/evaluaciones.service';
import { ParametrosService } from 'src/app/services/parametros.service';
import { SessionServiceService } from 'src/app/services/session-service.service';
const ELEMENT_DATA: any[] = [
];
@Component({
  selector: 'app-gestor-de-evaluaciones',
  templateUrl: './gestor-de-evaluaciones.component.html',
  styleUrls: ['./gestor-de-evaluaciones.component.scss']
})
export class GestorDeEvaluacionesComponent implements OnInit {
  length = 0;
  pageSize = 50;
  pageSizeOptions: number[] = [5, 20, 50, 100, 150];

  // MatPaginator Output
  //pageEvent: PageEvent;
  
  isLoading = false;
  displayedColumns: string[] = ['matricula','nombre', 'proceso', 'nivel','grado', 'resultadosea', 'resultadosep','sugerencia','decision','autorizar'];
  dataSource = ELEMENT_DATA;
  formulario = new FormGroup({
    nombre : new FormControl("",[]),
    nivel: new FormControl("",[]),
    grado: new FormControl("",[])
    //nivel: new FormControl("",[])
  });
  niveles=[];
  grupos=[];
  public id_perfil: any;
  constructor(private _parametrosService:ParametrosService,private _evaluacionesService:EvaluacionesService,public dialog:MatDialog, public _session:SessionServiceService) {
    this._parametrosService.findAll("niveles").subscribe(result=>{
      console.log(result);
      this.niveles=result.datos.niveles;
    },()=>{
      console.info("");
    });
    
    
  }
  getGrados(id){
    this._parametrosService.findAllDinamic("grados",id).subscribe(result=>{
      this.grupos = result.datos.grados;
    });
  }
  ngOnInit(): void {
    this.id_perfil =  this.getSesion().id_perfil_fk;
    console.log(this.id_perfil);
    if(this.id_perfil == 5){
      this.formulario.patchValue({ nivel: '0' });
    }else if(this.id_perfil == 11){
      this.formulario.patchValue({ nivel: '1' });
    }else if(this.id_perfil == 12){
      this.formulario.patchValue({ nivel: '3' });
    }else if(this.id_perfil == 6){
      this.formulario.patchValue({ nivel: '1' });
    }else if(this.id_perfil == 7){
      this.formulario.patchValue({ nivel: '2' });
    }else if(this.id_perfil == 8){
      this.formulario.patchValue({ nivel: '3' });
    }else if(this.id_perfil == 9){
      this.formulario.patchValue({ nivel: '4' });
    }
    this.buscador(0);
  }

  getSesion(){
    return this._session.getSession().getUsuario();
  }

  pageEvent(event: any){
    console.info(event);
    this.pageSize = event.pageSize;

    this.buscador(event.pageIndex);
  }

  buscador(indice:number){
    this._evaluacionesService.find(this.formulario.controls["nombre"].value,this.formulario.controls["nivel"].value,this.formulario.controls["grado"].value,0,this.pageSize).subscribe(result=>{
      console.log(result);
      this.dataSource = result.datos.evaluaciones;
      this.length = result?.datos?.evaluaciones?.length;
    });
  }
  save(evaluacion,ciclo,nivel,grado,alumno,desicion){
    console.info(desicion);
  }
  print(info){
    console.info(info);
  }
  actualizar(campo:string,valor:string,evaluacion,ciclo,nivel,grado,alumno){
    console.info(valor);
    let json = {
      campo:campo,
      valor:valor,
      evaluacion:evaluacion,
      ciclo:ciclo,
      nivel:nivel,
      grado:grado,
      alumno:alumno
    }
    document.getElementById("spin_"+campo+"_"+evaluacion+"_"+ciclo+"_"+nivel+"_"+grado+"_"+alumno).style.display = "block";
    this._evaluacionesService.update(json).subscribe(result=>{
      document.getElementById("spin_"+campo+"_"+evaluacion+"_"+ciclo+"_"+nivel+"_"+grado+"_"+alumno).style.display = "none";
    })
  }
  autorizar(campo:string,valor:string,evaluacion,ciclo,nivel,grado,alumno): void {
    const dialogRef = this.dialog.open(DesitionBoxComponent, {
      //width: '250px',
      data: {title:"Solicitud de confirmación", text:"¿Está seguro que desea autorizar este registro?",icon:"help_outline"}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);  
      if(result){
        this.isLoading = true;
        let json = {
          campo:campo,
          valor:valor,
          evaluacion:evaluacion,
          ciclo:ciclo,
          nivel:nivel,
          grado:grado,
          alumno:alumno
        }
        this.isLoading = true;
        this._evaluacionesService.update(json).subscribe(result=>{
          this.isLoading = false;
          this.buscador(0);
        })
        
      }
    });
  }
}
