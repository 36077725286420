

<div class="container-fluid mt-5">
    <div class="row">
        <mat-form-field appearance="outline" class="input-full-w">
            <mat-label>Ciclo</mat-label>
            <mat-select [(ngModel)]="id_ciclo">
                <mat-option *ngFor="let p of ciclos" [value]="p.id_ciclo_pk">{{p.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<br><br>
<div class="card-container p-5 pt-0">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Historial de pagos</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            <div class="spinner" *ngIf="isLoading">
                <mat-spinner color="accent"></mat-spinner>
            </div>

                <!-- tabla -->
                <div class="row mt-3 mat-elevation-z8">
                    
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows>
                        <caption><!--Parrilla--></caption>
                        <!-- contenido -->

                        <ng-container matColumnDef="id">
                            <th id="id" mat-header-cell *matHeaderCellDef> ID </th>
                            <td mat-cell *matCellDef="let eee">{{eee?.id_ticket_pk}}</td>
                        </ng-container>

                        <ng-container matColumnDef="f">
                            <th id="f" mat-header-cell *matHeaderCellDef> FECHA DE PAGO </th>
                            <td mat-cell *matCellDef="let element">{{element?.fecha_alta}}</td>
                        </ng-container>

                        <ng-container matColumnDef="forma_de_pago">
                            <th id="forma_de_pago" mat-header-cell *matHeaderCellDef> FORMA DE PAGO </th>
                            <td mat-cell *matCellDef="let eee">
                                <span *ngIf="eee?.forma_de_pago == 1">
                                    EFECTIVO
                                </span>
                                <span *ngIf="eee?.forma_de_pago == 2">
                                    TRANSFERENCIA
                                </span>
                                <span *ngIf="eee?.forma_de_pago == 3">
                                    DEPOSITO
                                </span>

                            </td>
                        </ng-container>

                        

                        <ng-container matColumnDef="ver_ticket">
                            <th id="ver_ticket" mat-header-cell *matHeaderCellDef class="text-center p-1"> VER TICKET </th>
                            <td mat-cell *matCellDef="let eee" class="text-center p-1">
                                <button type="button" mat-mini-fab color="primary" (click)="consultandoTicket(eee)">
                                    <mat-icon class="material-icons" >
                                        open_in_new
                                    </mat-icon>
                                </button>


                            </td>
                        </ng-container>

                        <ng-container matColumnDef="expand">
                            <th id="espand" mat-header-cell *matHeaderCellDef aria-label="expand row" (click)="expandedElement = eee">VER</th>
                            <td mat-cell *matCellDef="let eee">
                                <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === eee ? null : eee); $event.stopPropagation()">
                                    <mat-icon *ngIf="expandedElement !== eee" >keyboard_arrow_down</mat-icon>
                                    <mat-icon *ngIf="expandedElement === eee">keyboard_arrow_up</mat-icon>
                                    <span>{{eee?.tesoreria_movimientosd?.length}}</span>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail" >
                            <td mat-cell *matCellDef="let eee; let i = index"  [attr.colspan]="displayedColumns.length">
                                <div [@detailExpand]="eee == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden" >
                                   
                                    <table class="table">
                                        <caption><!--Parrilla--></caption>
                                        <thead>
                                          <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Concepto</th>
                                            <th scope="col">Recargos</th>
                                            <th scope="col">Precio unitario</th>
                                          </tr>
                                        </thead>
 
                                        

                                        <tbody>
                                            <tr *ngFor="let d of eee?.tesoreria_movimientos">
                                                <td>{{d?.id_operacion_pk}}</td>
                                                <td>{{d?.conceptos?.nombre}}</td>
                                                <td>{{d?.recargos}}</td>
                                                <td>{{d?.precio_unitario}}</td>
                                              </tr>
                                        </tbody>

                                    </table>


                                    
                                </div>
                            </td>
                        </ng-container>
                    
                        <!-- Symbol Column -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let eee; columns: displayedColumns;"
                        class="example-eee-row"
                        [class.example-expanded-row]="expandedElement === eee"
                        (click)="expandedElement = expandedElement === eee ? null : eee"
                        (click)="getObtenerMovimientosAlumno(eee?.id_concepto_pk , eee?.id_ciclo_pk ,idAlumno)"
                        >

                        <!-- Extra row to show detail content column -->
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];"  class="example-detail-row" ></tr>
                    </table>
                </div>
                <!-- fin tabla -->

                
            </mat-card-content>
            <mat-card-actions align="start">
                <mat-paginator (page)="pageEvent($event)" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"  aria-label="Selecione una pagina">
                </mat-paginator>
            </mat-card-actions>
        </mat-card>
    </div>