//General
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
//Error
import { ErrorStateMatcher } from '@angular/material/core';
/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
//Servicio
import { BibliotecaGeneralService } from 'src/app/services/biblioteca-general.service';

@Component({
  selector: 'app-acciones-biblioteca-general',
  templateUrl: './acciones-biblioteca-general.component.html',
  styleUrls: ['./acciones-biblioteca-general.component.scss'],
  providers: [ BibliotecaGeneralService ]
})
export class AccionesBibliotecaGeneralComponent implements OnInit {

  //General
  public matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public form: UntypedFormGroup = this.fb.group({
    nombre: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)]),
    numero_documento: new UntypedFormControl('', []),
    numero_categoria: new UntypedFormControl(1, []),
  });
  //All
  public accion: string = '';
  public isLoading: boolean = false;
  public id_doc: number = -1;
  public id_cat: number = -1;
  public archivo: any;
  public categorias: any = [];

  constructor(
    private fb: UntypedFormBuilder,
    public  _dialog: MatDialog,
    private _BibliotecaGeneralService: BibliotecaGeneralService,
    private _router: Router,
    private _route: ActivatedRoute,
    ) { 
    
    
    this.getConsultarCategoriasBibliotecaTodas();
    /*modificar*/ 
    this._route.queryParams.subscribe(
      params => {
        this.id_doc = +params['id_doc'];
        this.id_cat = +params['id_cat'];
        if(this.id_doc){
          this.form.patchValue({ numero_depa: this.id_doc, nombre: params['nombre'], numero_categoria: this.id_cat});
          
        }
      });
    /*fin modificar*/
  }

  ngOnInit(): void {
    //accion
    let url_list = this._router.url.split("/");
    this.accion = url_list[3].split("?")[0];
  }

  public getConsultarCategoriasBibliotecaTodas():any{
    const formData = new FormData();
    this.isLoading = true;
    this._BibliotecaGeneralService.getConsultarCategoriasBibliotecaTodas().subscribe(
      response => {
        //this.res(response);
        console.log(response);
        this.isLoading = false;
        if(response.estado == 200){
          this.categorias = response.data;
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', text: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public postRegistrarDocGeneral():any{
    const formData = new FormData();
    formData.set('nombre', this.form.value.nombre);
    formData.set('numero_categoria', this.form.value.numero_categoria);
    formData.set('pdf', this.archivo);
    this.isLoading = true;
    this._BibliotecaGeneralService.postRegistrarDocGeneral(formData).subscribe(
      response => {
        this.res(response);
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', text: 'Error con el Servicio.', icon:'error'}});
        this.isLoading = false;
      });
  }

  public onchangeArchivo_org(event: any) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      var caso = this.size(event);
      if (caso != 3) {
        if(caso == 1){
          this.archivo = file;
        }
        console.log(this.archivo);
        if(caso == 2){

          this._dialog.open(MessageBoxComponent, { disableClose: true ,data: {title: 'Error', text: 'Maximo tamaño del PDF 10MB.', icon:'error'}});
        }
      }else{
        this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Error con el Servicio.', icon:"error_outline", href:null}});

      }
    }
  }

  public size(evt: any){
    var a = 1;
    var b = 2;
    var c = 3;
    var d = 4;
    if (evt.target.files && evt.target.files.length) {
      const [file] = evt.target.files;
      //file.type.startsWith('application/pdf')
      if(file.type.startsWith('application/pdf')){
        if(evt.target.files[0].size <= 10485760){
          console.log('Maximo tamaño de imagen 10MB');
          return a;
        }else{
          return b;
        }
      }else{
        return c;
      }
    }else{
      return d;
    }
  }

  /*fin modificar*/
  public postModificarDocGeneral():any{
    const formData = new FormData();
    formData.set('nombre', this.form.value.nombre);
    formData.set('numero_categoria', this.form.value.numero_categoria);
    if(this.archivo){
      formData.set('pdf', this.archivo);
    }   
    formData.set('numero_documento', this.id_doc.toString());
    this.isLoading = true;
    this._BibliotecaGeneralService.postModificarDocGeneral(formData).subscribe(
      response => {console.log(response);
        if(response.estado == 200 || response.estado == 404){
          this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: 'Operación exitosa', icon:"done", href:null}});
          this._router.navigate(['biblioteca/biblioteca-general']);
        }else{
          this.res(response);
        }
      },
      error => {console.log(<any>error);
        this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Error con el Servicio.', icon:"error_outline", href:null}});
        this.isLoading = false;
      });
  }
  /*modificar*/

  public res(response: any){
    this.isLoading = false;
    if(response.estado == 200){
      this._router.navigate(['biblioteca/biblioteca-general']);
      this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Exito", text: 'Operación exitosa', icon:"done", href:null}});
    }else{
      this._dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Error", text: 'Fallo en el servicio, vuelva intentarlo', icon:"error_outline", href:null}});
    }
  }



}
