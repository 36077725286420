<br><br>
<div class="card-container encuesta">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Administrador de usuarios</mat-card-title>
            <mat-card-subtitle>Gestiona los usuarios del sistema</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
            <form autocomplete="off" (ngSubmit)="sendForm()" [formGroup]="formulario">
                <mat-form-field appearance="fill" class="bt col-md-6" >
                    <mat-label>Nombre</mat-label>
                    <input matInput placeholder="" formControlName="nombre">
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-6" >
                    <mat-label>Apellido paterno</mat-label>
                    <input matInput placeholder="" formControlName="apellidop">
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-6" >
                    <mat-label>Apellido materno</mat-label>
                    <input matInput placeholder="" formControlName="apellidom">
                </mat-form-field>
                <mat-form-field appearance="fill" class="bt col-md-6" >
                    <mat-label>Correo</mat-label>
                    <input matInput placeholder="" formControlName="correo">
                </mat-form-field>
                
                <mat-form-field appearance="fill" class="bt col-md-6" >
                    <mat-label>Licencia</mat-label>
                    <mat-select formControlName="id_perfil_fk">
                        <mat-option [value]="license.id_perfil_pk" *ngFor="let license of licenses">{{license.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div>
                    <mat-toolbar color="warn" *ngIf="pwrd.value != pwrd2.value">
                        Las contraseñas no coinciden
                    </mat-toolbar>
                    <mat-form-field appearance="fill" class="bt col-md-6" >
                        <mat-label>Contraseña</mat-label>
                        <input matInput #pwrd type="password" placeholder="" formControlName="password">
                        
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="bt col-md-6" >
                        <mat-label>Confirmar contraseña</mat-label>
                        <input type="password" #pwrd2 matInput placeholder="" formControlName="confirma">
                        
                    </mat-form-field>
                </div>
                <div class="text-align-center">
                    <button mat-flat-button color="primary" type="submit">Guardar</button>&nbsp;
                    <button mat-stroked-button color="primary" type="submit" [routerLink]="['/administracion/user-mannager']">Cancelar</button>
                </div>
                
            </form>
        </mat-card-content>
    </mat-card>
</div>
