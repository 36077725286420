import { Component, OnInit, Inject } from '@angular/core';
import { FormArray,FormBuilder, FormGroup,FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';

import { Router, ActivatedRoute, Params } from '@angular/router'; 
import { TtrSesionService } from 'src/app/services/tutores/ttr-sesion.service';

import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-ttr-inicio',
  templateUrl: './ttr-inicio.component.html',
  styleUrls: ['./ttr-inicio.component.scss'],
  providers: [ TtrSesionService ]
})
export class TtrInicioComponent implements OnInit {

  

  constructor(){
   
  }

  ngOnInit(): void {
  
  }

  


}
