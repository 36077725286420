
<div class="card-container ciclo-escolar">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Gestor de caja</mat-card-title>
            <mat-card-subtitle>Gestión y control de caja</mat-card-subtitle>
        </mat-card-header>
    </mat-card>
</div>

