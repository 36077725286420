import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { TtrService } from 'src/app/services/tutores/ttr-service.service';

@Injectable({
  providedIn: 'root'
})

export class TtrSesionService extends TtrService{

  private laravelapi = "";
  constructor(
    private _http:HttpClient,
    private _configService:ConfigurationService
  ){ 
    super();
    this.laravelapi = this._configService.getConfiguration().laravelapi;
  }

  getDatosSesion(){    
    console.log(this.configuracion());
    return this._http.get(this.laravelapi+'DatosSesion', this.configuracion());
  }


  postIniciarSesion(datos: any){
    let headers = new HttpHeaders().set('Accept', 'application/json');
    return this._http.post(this.laravelapi+'IniciarSesion', datos, {headers: headers});
  }

  postRecuperarCuenta(datos: any){
    let headers = new HttpHeaders().set('Accept', 'application/json');
    return this._http.post(this.laravelapi+'RecuperarCuenta', datos, {headers: headers});
  }


  postInformacionCuenta(datos: any){    
    console.log(this.configuracion());
    return this._http.post(this.laravelapi+'InformacionCuenta', datos, this.configuracion());
  }

  postModificarCuentaTutor(datos: any){    
    console.log(this.configuracion());
    return this._http.post(this.laravelapi+'ModificarCuentaTutor', datos, this.configuracion());
  }




  postConsultarEncuestaTutores(datos: any){
    console.log(this.configuracion());
    return this._http.post(this.laravelapi+'ConsultarEncuestaTutores', datos, this.configuracion());
    //let headers = new HttpHeaders()
    //   .set('Accept', 'application/json');
    //return this._http.post(this.laravelapi+'ConsultarEncuestaTutores', datos, {headers: headers});


  }


  getDataToken(){
    let token = sessionStorage.getItem('token');
    if(token){
      return token;
    }else{
      return '';
    }
  }



  getDataSJP(){
    let data_girasol = sessionStorage.getItem('data_sjp');
    if(data_girasol){
      return data_girasol;
    }else{
      return '';
    }
  }
  

  




}