<div class="card-container encuesta">
    <mat-card class="example-card">
      <div class="spinner" *ngIf="isLoading">
        <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
      </div>
      
      <mat-card-header>
        <mat-card-title>Expedientes Psicopedagógicos</mat-card-title>
        <mat-card-subtitle>Búsqueda de expedientes.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <form autocomplete="no" (ngSubmit)="buscar()" [formGroup]="formulario">
            <mat-form-field appearance="fill" class="bt col-md-6" >
                <mat-label>Buscar</mat-label>
                <input matInput placeholder="Por nombre, grado, grupo" formControlName="busqueda" autocomplete="off">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>&nbsp;
            <!--<mat-form-field appearance="fill" class="bt col-md-3">
                <mat-label>Nivel</mat-label>
                <mat-select formControlName="nivel">
                  <mat-option value="">Sin filtro</mat-option>
                  <mat-option value="Prescolar">Preescolar</mat-option>
                  <mat-option value="Primaria">Primaria</mat-option>
                </mat-select>
            </mat-form-field>-->&nbsp;
            <button mat-flat-button color="accent">Buscar</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button type="button" mat-flat-button color="primary" (click)="exporter.exportTable('xlsx',{fileName:'Registro de alumnos', sheet: 'alumnos', Props: {Author: '3demak'}})">Reporte Excel</button>&nbsp;&nbsp;
            
        </form>
        
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <table mat-table matTableExporter [dataSource]="dataSource" class="mat-elevation-z0" #exporter="matTableExporter" hiddenColumns="[6][7][8]">
            <caption></caption>
            <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
            
            <!-- Position Column -->
            <ng-container matColumnDef="nombre">
                <th  scope="col" mat-header-cell *matHeaderCellDef> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.nombre}} {{element.apellidop}} {{element.apellidom}}</td>
            </ng-container>
            
            <!-- Name Column -->
            <ng-container matColumnDef="proceso">
                <th  scope="col" mat-header-cell *matHeaderCellDef> Nivel </th>
                <td mat-cell *matCellDef="let element"> Primaria </td>
            </ng-container>
            
            <!-- Weight Column -->
            <ng-container matColumnDef="estatus">
                <th  scope="col" mat-header-cell *matHeaderCellDef> Grado </th>
                <td mat-cell *matCellDef="let element"> 
                6to
                    
                </td>
            </ng-container>
            
            <!-- Symbol Column -->
            <ng-container matColumnDef="nivel">
                <th  scope="col" mat-header-cell *matHeaderCellDef> Grupo </th>
                <td mat-cell *matCellDef="let element"> A </td>
                </ng-container>
    
            <!-- Symbol Column -->
            <ng-container matColumnDef="grado">
                <th  scope="col" mat-header-cell *matHeaderCellDef> Ficha </th>
                <td mat-cell *matCellDef="let element"> 
                    <button mat-mini-fab color="accent">
                        <mat-icon>badge</mat-icon>
                    </button>
                </td>
            </ng-container>
    
                <!-- Symbol Column -->
                <ng-container matColumnDef="grupo">
                <th  scope="col" mat-header-cell *matHeaderCellDef> Estatus </th>
                <td mat-cell *matCellDef="let element"> 
                    <mat-chip-list>
                        <mat-chip>Normal</mat-chip>
                    </mat-chip-list>
                </td>
                </ng-container>
    
                <!-- Symbol Column -->
            <ng-container matColumnDef="ficha">
                <th  scope="col" mat-header-cell *matHeaderCellDef> Exp. Psicopedagógico </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button color="warn" [routerLink]="['ficha']" [queryParams]="{id:element.id_alumno_pk}">
                        <mat-icon>description</mat-icon>
                    </button>
                </td>
            </ng-container>
    
            
    
            <!-- Symbol Column -->
            <ng-container matColumnDef="seleccionar">
                <th  scope="col" mat-header-cell *matHeaderCellDef> Expediente </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-mini-fab color="accent" [routerLink]="['expediente']" [queryParams]="{id:element.id_alumno_pk}">
                        <mat-icon>folder</mat-icon>
                    </button>
                </td>
            </ng-container>
    
            <!-- Symbol Column -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-card-content>
      <mat-card-actions align="right">
        <mat-paginator [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent($event)">
        </mat-paginator>
      </mat-card-actions>
    </mat-card>
</div>