import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment'; 
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class EncuestasServiceService {
  private base_url = environment.base_url;
  endpoint = "";
  constructor(private _http:HttpClient,private _config:ConfigurationService) {
    this.endpoint = _config.getConfiguration().hostapi;
  }

  findAll(busqueda:string):Observable<any>{
    // return this._http.get(this.endpoint+"/php/encuestas-service.php?opc=find&busqueda="+busqueda);
    return this._http.get(this.endpoint+"/php/encuestas-service.php?opc=find&busqueda="+busqueda);
    // return this._http.get("http://localhost/ng-san-juan-pablo/php/encuestas-service.php?opc=find&busqueda="+busqueda);
  }
  getInventario(busqueda:string,limit:number,offset:number):Observable<any>{
    // return this._http.get(this.endpoint+"/php/encuestas-service.php?opc=getInventario&busqueda="+busqueda+"&limit="+limit+"&offset="+offset);
    return this._http.get(this.endpoint+"/php/encuestas-service.php?opc=getInventario&busqueda="+busqueda+"&limit="+limit+"&offset="+offset);
  }
  getEncuestas():Observable<any>{
    // return this._http.get(this.endpoint+"/php/encuestas-service.php?opc=getEncuestas");
    return this._http.get(this.endpoint+"/php/encuestas-service.php?opc=getEncuestas");
  }
  getEncuestaById(id:number):Observable<any>{
    // return this._http.get(this.endpoint+"/php/encuestas-service.php?opc=getEncuesta&id="+id);
    return this._http.get(this.endpoint+"/php/encuestas-service.php?opc=getEncuesta&id="+id);
  }
  saveAplicacion(json:any):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  let options = { headers: headers };
    return this._http.post(this.endpoint+"/php/encuestas-service.php?opc=saveAplicacion",json,options);
    // return this._http.post("http://localhost/ng-san-juan-pablo/php/encuestas-service.php?opc=saveAplicacion",json,options);
  }

  updateAplicacion(id:number,json:any):Observable<any>{
    let access_token: string = "";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
  });

  let options = { headers: headers };
    return this._http.post(this.endpoint+"/php/encuestas-service.php?opc=updateAplicacion&id="+id,json,options);
  }

  deleteEncuestaById(id:number):Observable<any>{
    return this._http.delete(this.endpoint+"/php/encuestas-service.php?opc=deleteAplicacion&id="+id);
  }
}
