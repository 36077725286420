import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showFiller = false;
  title = 'ng-san-juan-pablo';

  public route_path: string;
  public url_lista: any;

  

  constructor(
    public _router: Router,
    private _route: ActivatedRoute
  ){
    this.route_path = '';
    this.url_lista = [];

    
  }

  ngDoCheck(){
    //console.log(this._router);
    //console.log(this._router.url);

    let urlList = this._router.url.split("/")
    //console.log(urlList);
    this.url_lista = urlList;
    this.route_path = this._router.url;
  }
}
