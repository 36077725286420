<div class="card-container">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>Evaluación Picopedagógica</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <app-ficha-informativa></app-ficha-informativa>
            
            <div class="bt col-md-12">
                <mat-form-field appearance="outline" class="bt col-md-8">
                    <mat-label>Motivo de valoración</mat-label>
                    <input matInput type="text" >
                </mat-form-field>
                <mat-form-field appearance="outline" class="bt col-md-8">
                    <mat-label>Instrumentos o habilidades utilizados</mat-label>
                    <input matInput type="text" >
                </mat-form-field>

                <mat-form-field appearance="outline" class="bt col-md-8">
                    <mat-label>Evaluación socio emocional</mat-label>
                    <textarea matInput  ></textarea>
                </mat-form-field>
                <mat-form-field appearance="outline" class="bt col-md-4">
                    <mat-label>Resultado</mat-label>
                    <mat-select>
                        <mat-option value="1">Baja</mat-option>
                        <mat-option value="2">Alta</mat-option>
                        <mat-option value="3">Seguimiento</mat-option>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field appearance="outline" class="bt col-md-8">
                    <mat-label>Evaluación Psicopedagógica</mat-label>
                    <textarea matInput  ></textarea>
                </mat-form-field>
                <mat-form-field appearance="outline" class="bt col-md-4">
                    <mat-label>Resultado</mat-label>
                    <mat-select>
                        <mat-option value="1">Baja</mat-option>
                        <mat-option value="2">Alta</mat-option>
                        <mat-option value="3">Seguimiento</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="bt col-md-12">
                    <mat-label>Conclusiones y recomendaciones</mat-label>
                    <textarea matInput  ></textarea>
                </mat-form-field>
                
                <div class="bt col-md-12">

                    <h3>Proceso Intervención</h3>
                    <mat-radio-group aria-label="Select an option" class="bt col-md-8" style="vertical-align: top;">
                        <mat-radio-button value="1">Normal</mat-radio-button>&nbsp;
                        <mat-radio-button value="2">Intervención</mat-radio-button>
                    </mat-radio-group>

                    <div class="bt col-md-4">
                        <mat-form-field appearance="outline" class="bt col-md-12">
                            <mat-label>Documento de soporte</mat-label>
                            <input matInput placeholder="Placeholder">
                            <mat-icon matSuffix>attachment</mat-icon>
                            
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="bt col-md-12">
                            <mat-label>Documento de soporte</mat-label>
                            <input matInput placeholder="Placeholder">
                            <mat-icon matSuffix>attachment</mat-icon>
                            
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="bt col-md-12">
                            <mat-label>Documento de soporte</mat-label>
                            <input matInput placeholder="Placeholder">
                            <mat-icon matSuffix>attachment</mat-icon>
                            
                        </mat-form-field>
                        

                        <button mat-mini-fab color="accent">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
                
                  
            </div>
        </mat-card-content>
        <mat-card-actions align="right">
            <button mat-flat-button color="accent">
                Guardar
            </button>
            <button mat-stroked-button color="default">
                Cancelar
            </button>
        </mat-card-actions>
    </mat-card>
</div>