export class User {

    public id_usuario_pk:Number;
    public nombre:String;
    public apellidop:String;
    public apellidom:String;
    public correo:String;
    public id_perfil_fk:Number;
    public password:String;
    public usuario: String;
    public cedula: String;
    public puesto: String;
    constructor(){
        //this is intentional
    }

}