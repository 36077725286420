import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MessageBoxComponent } from 'src/app/modales/message-box/message-box.component';
import { User } from 'src/app/model/user';
import { UsersServiceService } from 'src/app/services/users-service.service';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

  id:number;
  isLoading = false;
  formulario = new FormGroup({
    nombre: new FormControl("",[Validators.required]),
    apellidop:new FormControl("",[Validators.required]),
    apellidom:new FormControl("",[Validators.required]),
    correo:new FormControl("",[Validators.required]),
    password:new FormControl("",[Validators.required]),
    id_perfil_fk : new FormControl("",[Validators.required]),
    confirma: new FormControl("",[Validators.required]),
  });
  constructor(private dialog:MatDialog,private _usuarios:UsersServiceService,private activatedRoute:ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params['id']; 
    });
  }
  usuario = new User();
  licenses = [];

  get form(){
    return this.formulario.controls;
  }
  ngOnInit(): void {
    this.formulario.controls["password"].setValidators([]);
    this.formulario.controls["confirma"].setValidators([]);
    if(this.id>0){
      this.isLoading=true;
      this._usuarios.getUsuarioById(this.id).subscribe(result=>{
        if(result.code==200){
          this.usuario = result.datos;
          this.form.nombre.setValue(this.usuario.nombre.toString());
          this.form.apellidop.setValue(this.usuario.apellidop.toString());
          this.form.apellidom.setValue(this.usuario.apellidom.toString());

          this.form.correo.setValue(this.usuario.correo.toString());
          //this.form.password.setValue(this.usuario.password);
          //this.form.confirma.setValue(this.usuario.password);
          this.form.id_perfil_fk.setValue(this.usuario.id_perfil_fk.toString());
          this.isLoading=false;

        }else{
          this.isLoading=false;
        }
      },error=>{
        console.log(error);
        this.isLoading=false;
      });
    }
    this._usuarios.getLicenses().subscribe(result=>{
      console.log("result licences>", result);
      if(result.code == 200){
        this.licenses = result.datos.licencias;
      }
    },error=>{
      console.log(error);
    });
  }

  sendForm(){
    if(this.id>0){
      if(this.formulario.valid){
        this.isLoading=true;
        this._usuarios.actualizarUsuario(this.id,this.formulario.getRawValue()).subscribe(result=>{
          this.responseActualizarUsuario(result);
        },error=>{
          this.isLoading=false;
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al modificar el usuario", text: "No fué posible modificar el usuario, si piensa que ésto es un error, contacte al administrador del sistema", icon:"error_outline", href:null}});
        })
      }
    }else{
      if(this.formulario.valid){
        this.isLoading=true;
        this._usuarios.altaUsuario(this.formulario.getRawValue()).subscribe(result=>{
          this.isLoading=false;
          this.responseAltaUsuario(result);
        },error=>{
          this.isLoading=false;
          this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al crear el usuario", text: "No fué posible crear el usuario, si piensa que ésto es un error, contacte al administrador del sistema", icon:"error_outline", href:"/administracion/user-mannager/new"}});
        })
      }
    }
    
  }

  responseActualizarUsuario(result:any){
    if(result.code==200){
      this.isLoading=false;
      this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "El usuario ha actualizado con éxito", text: "La información del usuario se ha actualizadoen el sistema", icon:"done", href:"/administracion/user-mannager"}});
    }else{
      this.isLoading=false;
      this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al modificar el usuario", text: "No fué posible modificar el usuario, si piensa que ésto es un error, contacte al administrador del sistema", icon:"error_outline", href:null}});
    }
  }

  responseAltaUsuario(result:any){
    if(result.code==200){
      this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "El usuario ha sido creado con éxito", text: "El usuario ya puede ingresar al sistema", icon:"done", href:"/administracion/user-mannager"}});
    }else{
      this.dialog.open(MessageBoxComponent,{ disableClose: true ,data: {title: "Problemas al crear el usuario", text: "No fué posible crear el usuario, si piensa que ésto es un error, contacte al administrador del sistema", icon:"error_outline", href:"/administracion/user-mannager/new"}});
    }
  }
}
