import { Component } from '@angular/core';
/**
 *
 *
 * @export
 * @class EvaluacionPsicologicaComponent
 */
@Component({
  selector: 'app-evaluacion-psicologica',
  templateUrl: './evaluacion-psicologica.component.html',
  styleUrls: ['./evaluacion-psicologica.component.scss']
})

export class EvaluacionPsicologicaComponent  {

  

}
