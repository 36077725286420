<div class="card-container encuesta container-fluid" >
    <mat-card class="example-card">
        <div id="htmlData">
            <mat-card-header>
                <mat-card-title>Gestor de evaluaciones</mat-card-title>
                <mat-card-subtitle>Bandeja de entrada de evaluaciones</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                
                <form autocomplete="no" [formGroup]="formulario" (ngSubmit)="buscador(0)">
                    
                    <div class="row">
                        <div class="col-lg-4 col-md-4">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Buscar</mat-label>
                                <input matInput placeholder="Nombre del alumno" formControlName="nombre" autocomplete="off" #buscar>
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2 col-lg-2">
                            <!--
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Nivel</mat-label>
                                <mat-select formControlName="nivel" (selectionChange)="getGrados($event.value)">
                                    <mat-option value="">-- Ninguno -- </mat-option>
                                    <mat-option value="{{nivel.id_nivel_pk}}" *ngFor="let nivel of niveles">{{nivel.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            -->

                            <mat-form-field appearance="fill" class="w-100" *ngIf="id_perfil == 5 || id_perfil == 1 || id_perfil == 2">
                                <mat-label>Nivel</mat-label>
                                <mat-select #cboNivel (selectionChange)="getGrados($event.value)" formControlName="nivel">
                                    <mat-option value="">-- Ninguno -- </mat-option>
                                    <mat-option *ngFor="let nivel of niveles"  [value]="nivel.id_nivel_pk">
                                        {{nivel.descripcion}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="w-100" *ngIf="id_perfil == 11">
                                <mat-label>Nivel</mat-label>
                                <mat-select #cboNivel (selectionChange)="getGrados($event)" formControlName="nivel">
                                    <mat-option [value]="1"> Preescolar </mat-option>
                                    <mat-option [value]="2"> Primaria </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="w-100" *ngIf="id_perfil == 12">
                                <mat-label>Nivel</mat-label>
                                <mat-select #cboNivel (selectionChange)="getGrados($event)" formControlName="nivel">
                                    <mat-option [value]="3"> Secundaria </mat-option>
                                    <mat-option [value]="4"> Preparatoria </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="w-100" *ngIf="id_perfil == 6">
                                <mat-label>Nivel</mat-label>
                                <mat-select #cboNivel (selectionChange)="getGrados($event)" formControlName="nivel">
                                    <mat-option [value]="1"> Preescolar </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="w-100" *ngIf="id_perfil == 7">
                                <mat-label>Nivel</mat-label>
                                <mat-select #cboNivel (selectionChange)="getGrados($event)" formControlName="nivel">
                                    <mat-option [value]="2"> Primaria </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="w-100" *ngIf="id_perfil == 8">
                                <mat-label>Nivel</mat-label>
                                <mat-select #cboNivel (selectionChange)="getGrados($event)" formControlName="nivel">
                                    <mat-option [value]="3"> Secundaria </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="w-100" *ngIf="id_perfil == 9">
                                <mat-label>Nivel</mat-label>
                                <mat-select #cboNivel (selectionChange)="getGrados($event)" formControlName="nivel">
                                    <mat-option [value]="4"> Preparatoria </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2 col-md-2">
                            <mat-form-field appearance="fill" class="w-100" style="width: 100%; max-width: 250px;">
                                <mat-label>Grado</mat-label>
                                <mat-select formControlName="grado">
                                    <mat-option value="">-- Ninguno --</mat-option>
                                    <mat-option value="{{grupo.id_grado_pk}}" *ngFor="let grupo of grupos">{{grupo.descripcion}}</mat-option>
                                    
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <button mat-mini-fab color="accent" (click)="print(buscar.value)"><mat-icon>search</mat-icon></button>&nbsp;&nbsp;
                            <button type="button" mat-flat-button color="primary">Reporte Excel</button>&nbsp;&nbsp;
                        </div>
                    </div>
                    
                    
                    
                    
                    
                </form>

                <div class="row">
                    <div class="col">

                    
                        <table mat-table matTableExporter [dataSource]="dataSource" class="mat-elevation-z0" #exporter="matTableExporter" hiddenColumns="[6][7][8]">
                            <caption *ngIf="length<=0">No hay evaluaciones pendientes</caption>
                            <!--- Note that these columns can be defined in any order.
                                    The actual rendered columns are set as a property on the row definition" -->
                            
                            <!-- Position Column -->
                            <ng-container matColumnDef="matricula">
                                <th scope="col" mat-header-cell *matHeaderCellDef> Matricula </th>
                                <td mat-cell *matCellDef="let element"> {{element.matricula}}</td>
                            </ng-container>
                            
                            <!-- Name Column -->
                            <ng-container matColumnDef="nombre">
                                <th scope="col" mat-header-cell *matHeaderCellDef> Nombre </th>
                                <td mat-cell *matCellDef="let element"> {{element.nombre}} {{element.apellidop}} {{element.apellidom}} </td>
                            </ng-container>
                            
                            <!-- Weight Column -->
                            <ng-container matColumnDef="proceso">
                                <th scope="col" mat-header-cell *matHeaderCellDef> Proceso </th>
                                <td mat-cell *matCellDef="let element"> 
                                <mat-chip-list>
                                    <mat-chip color="primary">Inscripción</mat-chip>
                                </mat-chip-list>
                                    
                                </td>
                            </ng-container>
                            
                            <!-- Symbol Column -->
                            <ng-container matColumnDef="nivel">
                                <th scope="col" mat-header-cell *matHeaderCellDef> Programa </th>
                                <td mat-cell *matCellDef="let element"> {{element.nivel}} </td>
                                </ng-container>
            
                            <!-- Symbol Column -->
                            <ng-container matColumnDef="grado">
                                <th scope="col" mat-header-cell *matHeaderCellDef> Grado </th>
                                <td mat-cell *matCellDef="let element"> {{element.grado}} </td>
                            </ng-container>
            
                                <!-- Symbol Column -->
                                <ng-container matColumnDef="resultadosea">
                                <th scope="col" mat-header-cell *matHeaderCellDef>  </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Resultado Evaluación Académica</mat-label>
                                        <input matInput autocomplete="off" #evaluacionAcademica maxlength="200" [value]="element.resultadoea" (change)="actualizar('evaluacion_academica',$event.target.value,element.id_evaluacion_fk,element.id_ciclo_fk,element.id_nivel_fk,element.id_grado_fk,element.id_alumno_fk)">
                                        <mat-icon matSuffix class="rotating " style="display:none" color="primary" id="spin_evaluacion_academica_{{element.id_evaluacion_fk}}_{{element.id_ciclo_fk}}_{{element.id_nivel_fk}}_{{element.id_grado_fk}}_{{element.id_alumno_fk}}">autorenew</mat-icon>
                                    </mat-form-field>
                                </td>
                                </ng-container>
            
                                <!-- Symbol Column -->
                            <ng-container matColumnDef="resultadosep">
                                <th scope="col" mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Resultado Evaluación Familiar</mat-label>
                                        <input matInput autocomplete="off" #evaluacionPsicometrica maxlength="200" [value]="element.resultadoep" (change)="actualizar('evaluacion_psicometrica',$event.target.value,element.id_evaluacion_fk,element.id_ciclo_fk,element.id_nivel_fk,element.id_grado_fk,element.id_alumno_fk)">
                                        <mat-icon matSuffix class="rotating " style="display:none" color="primary" id="spin_evaluacion_psicometrica_{{element.id_evaluacion_fk}}_{{element.id_ciclo_fk}}_{{element.id_nivel_fk}}_{{element.id_grado_fk}}_{{element.id_alumno_fk}}">autorenew</mat-icon>
                                    </mat-form-field>
                                </td>
                            </ng-container>
                        
                            <!-- Symbol Column -->
                            <ng-container matColumnDef="sugerencia">
                                <th scope="col" mat-header-cell *matHeaderCellDef>  </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Sugerencia</mat-label>
                                        <mat-select #sugerencia (selectionChange)="actualizar('sugerencia',$event.value,element.id_evaluacion_fk,element.id_ciclo_fk,element.id_nivel_fk,element.id_grado_fk,element.id_alumno_fk)" [value]="element.sugerencia">
                                            <mat-option>-- Ninguno --</mat-option>
                                            <mat-option value="Aceptado">Aceptado</mat-option>
                                            <mat-option value="No Aceptado">No aceptado</mat-option>
                                            <mat-option value="Condicionado">Condicionado</mat-option>
                                            
                                        </mat-select>
                                        <mat-icon matSuffix class="rotating " style="display:none" color="primary" id="spin_sugerencia_{{element.id_evaluacion_fk}}_{{element.id_ciclo_fk}}_{{element.id_nivel_fk}}_{{element.id_grado_fk}}_{{element.id_alumno_fk}}">autorenew</mat-icon>
                                    </mat-form-field>
                                </td>
                            </ng-container>
            
                            <!-- Symbol Column -->
                            <ng-container matColumnDef="decision">
                                <th scope="col" mat-header-cell *matHeaderCellDef>  </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Decisión</mat-label>
                                        <mat-select #desicion (selectionChange)="actualizar('decision',$event.value,element.id_evaluacion_fk,element.id_ciclo_fk,element.id_nivel_fk,element.id_grado_fk,element.id_alumno_fk)" [value]="element.decision">   
                                            <mat-option>-- Ninguno --</mat-option>
                                            <mat-option value="Aceptado">Aceptado</mat-option>
                                            <mat-option value="No Aceptado">No aceptado</mat-option>
                                            <mat-option value="Condicionado">Condicionado</mat-option>
                                        </mat-select>
                                        <mat-icon matSuffix class="rotating " style="display:none" color="primary" id="spin_decision_{{element.id_evaluacion_fk}}_{{element.id_ciclo_fk}}_{{element.id_nivel_fk}}_{{element.id_grado_fk}}_{{element.id_alumno_fk}}">autorenew</mat-icon>
                                    </mat-form-field>
                                </td>
                            </ng-container>
        
                            <!-- Symbol Column -->
                            <ng-container matColumnDef="accion">
                            <th scope="col" mat-header-cell *matHeaderCellDef> Guardar </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button color="accent" (click)="save(element.id_evaluacion_fk,element.id_ciclo_fk,element.id_nivel_fk,element.id_grado_fk,element.id_alumno_fk,desicion)">
                                    <mat-icon>save</mat-icon>
                                </button>
                            </td>
                            </ng-container>
        
                            
        
                            <!-- Symbol Column -->
                            <ng-container matColumnDef="autorizar">
                            <th scope="col" mat-header-cell *matHeaderCellDef> Autorizar </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button color="primary" (click)="autorizar('estatus',1,element.id_evaluacion_fk,element.id_ciclo_fk,element.id_nivel_fk,element.id_grado_fk,element.id_alumno_fk)">
                                    <mat-icon>approval</mat-icon>
                                </button>
                            </td>
                            </ng-container>
            
                            <!-- Symbol Column -->
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </div>
                
                
                
            </mat-card-content>
            <mat-card-actions>
                <mat-paginator [length]="length"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   (page)="pageEvent($event)">
                </mat-paginator>
            </mat-card-actions>
        </div>
    </mat-card>
</div>