<div class="container-fluid no-padding">
  <mat-card class="example-card">
    <mat-card-content>
      <div class="icon">
        <img class="logo" src="/assets/img/LOGOTIPO.png" alt="Logo SanJuanPablo" height="auto"/>
      </div>
      <div class="formulario">
        <span class="subtitle">Entrar a encuesta</span>
        <form autocomplete="off" [formGroup]="form" (ngSubmit)="sendForm()">
            <mat-form-field appearance="standard">
                <mat-label>Token de encuesta</mat-label>
                <input matInput type="text" formControlName="token">
                <mat-icon matSuffix color="primary">vpn_key</mat-icon>
            </mat-form-field>
            <button mat-flat-button color="primary">Entrar</button>
        </form>
      </div>
    </mat-card-content>
    <mat-card-actions>
      
    </mat-card-actions>
  </mat-card>
</div>