import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ControlEscolarService } from 'src/app/services/control-escolar.service';
import { ParametrosService } from 'src/app/services/parametros.service';

@Component({
  selector: 'app-asignacion-de-grupos',
  templateUrl: './asignacion-de-grupos.component.html',
  styleUrls: ['./asignacion-de-grupos.component.scss']
})
export class AsignacionDeGruposComponent implements OnInit {

  constructor(private _snackBar: MatSnackBar,private parametrosService:ParametrosService, private controlEscolarService:ControlEscolarService) { }
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  displayedColumns: string[] = ['matricula', 'genero','nombre','asignar'];
  ciclos=[];
  niveles=[];
  grados=[];
  grupos=[];
  alumnosDeGrupo=[];
  alumnos=[];
  isLoading=false;
  isLoadingNiveles=false;
  isLoadingGrados=false;
  isLoadingAlumnosDeGrupo=false;
  ngOnInit(): void {
    this.obtenerGrupos();
    this.parametrosService.findAll("ciclos").subscribe(result=>{
      this.ciclos=result.datos.ciclos;
    },error=>{
      this.ciclos=[];
    })
  }

  obtenerNiveles(ciclo:number){
    this.isLoadingNiveles=true;
    this.alumnos=[];
    this.parametrosService.findAllDinamic("nivelesByCiclo",ciclo).subscribe(result=>{
      this.isLoadingNiveles=false;
      this.niveles=result.datos.niveles;
    },error=>{
      this.isLoadingNiveles=false;
      this.niveles=[];
    });
  }
  obntenerGrados(nivel:number,ciclo:number){
    this.isLoadingGrados=true;
    this.alumnos=[];
    this.parametrosService.findAllDinamic2("gradosByCicloAndNivel",ciclo,nivel).subscribe(result=>{
      this.isLoadingGrados=false;
      this.grados=result.datos.grados;
    },error=>{
      this.isLoadingGrados=false;
      this.grados=[];
    });
  }
  obtenerGrupos(){
    this.parametrosService.findAll("grupos").subscribe(result=>{
      this.grupos=result.datos.grupos;
    },error=>{
      this.grupos=[];
    });
  }
  getAlumnos(ciclo,nivel,grado){
    this.alumnos=[];
    this.isLoading=true;
    this.controlEscolarService.getAlumnosByCicloNivelGrado(ciclo,nivel,grado).subscribe(result=>{
      this.isLoading=false;
      this.alumnos=result.datos.alumnos;      
    },error=>{
      this.isLoading=false;
      this.alumnos=[];
    });
  }
  getAlumnosGrupo(ciclo,nivel,grado,grupo){
    this.isLoadingAlumnosDeGrupo=true;
    this.alumnosDeGrupo=[];
    this.controlEscolarService.getAlumnosByCicloNivelGradoGrupo(ciclo,nivel,grado,grupo).subscribe(result=>{
      this.isLoadingAlumnosDeGrupo=false;
      this.alumnosDeGrupo=result.datos.alumnos;
    },error=>{
      this.isLoadingAlumnosDeGrupo=false;
      this.alumnosDeGrupo=[];
    });
  }
  asignarAlumno(alumno:any,grupo:number){
    
    this.controlEscolarService.asignarAlumno({"grupo":grupo,"control":alumno.id_control_escolar_pk}).subscribe(result=>{
      let message = "El alumno ["+alumno.matricula+"] "+alumno.apellidop+" "+alumno.apellidom+" "+alumno.nombre;
      this.alerta(message+" se ha asignado al grupo");
    },error=>{

    });
  }
  desasignarAlumno(alumno:any){
    this.controlEscolarService.asignarAlumno({"grupo":0,"control":alumno.id_control_escolar_pk}).subscribe(result=>{
      let message = "El alumno ["+alumno.matricula+"] "+alumno.apellidop+" "+alumno.apellidom+" "+alumno.nombre;
      this.alerta(message+" se ha desasignado del grupo");
    },error=>{

    });
  }

  alerta(mensaje:string){
    document.getElementById("actualizarAlumnos").click();
    document.getElementById("actualizarGrupo").click();
    this._snackBar.open(mensaje, 'Aceptar', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
